import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { LocationService } from 'src/app/services/location.service';
import { ModalService } from 'src/app/services/modal.service';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from '../terms-of-use/terms-of-use.component';
import { CustomValidator } from './../../_common/customvalidators';
import { profileTypeEnum } from './../../_common/enum.enum';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { MasterApiService } from './../../services/master-api.service';
import { ToasterService } from './../../services/toaster.service';
import { CategoriesComponent } from './../categories/categories.component';

declare var google: any;

export interface SellerRegistration {
	first_name?: string;
	last_name?: string;
	email?: string;
	country_code?: string;
	mobile_no?: string;
	password?: string;
	platform?: string;
	user_type?: string;
	company_name?: string;
	seller_type?: string;
	company_addresses?: CompanyAddress[];
	company_categories?: CompanyCategory[];
	seller_class?: string;
	is_also_warehouse_address?: boolean;
}

export interface CompanyAddress {
	address_type_id?: number;
	address?: string;
	zip_code?: string;
	country_id?: number;
	state_id?: number;
	district_id?: number;
	taluka_id?: number;
	gst_number?: string;
	longitude?: string;
	latitude?: string;
	contact_no?: any;
	is_also_warehouse_address?: boolean;
}

export interface CompanyCategory {
	category_id?: number;
}

@Component({
	selector: 'app-seller-registration',
	templateUrl: './seller-registration.component.html',
	styleUrls: ['./seller-registration.component.scss'],
})
export class SellerRegistrationComponent implements OnInit, OnDestroy {
	@ViewChild(CategoriesComponent) categoriesData;
	@Output() customEvent = new EventEmitter<boolean>();
	categoryNotChecked: boolean = false;
	isSubmitted = false;
	profileEnum = profileTypeEnum;
	sellerRegisterationType = this.profileEnum.Individual;
	countryList: any = [];
	talukaList: any = [];
	currentStatePage = 1;
	currentCityPage = 1;
	public addressIndex: any;
	private subscription: Subscription = new Subscription();

	tabList = [
		{
			"name": 'Individual Seller',
			"id": 1,
			"value": "INDIVIDUAL"
		},
		{
			"name": 'Enterprise Seller',
			"id": 2,
			"value": "ENTERPRISE"
		},

	]
	public registrationForm: FormGroup;
	public addressForm: FormGroup;

	isLoading = false;
	isLogin = false;
	passwordType = 'password';
	confirmPasswordType = 'password';
	locationParams: CompanyAddress[] = [];
	isBecomeSellerReq = false;
	loginUserData: any;
	fromPage: any;
	userData: any;
	certificate: any;
	IdProof: any;
	locationTag = [];
	wareHouseAddressTypeId: any;
	isLocation = true;
	hqAddressValue: any;
	predictionLength = 0;
	wareHouseAddressPredictionLength = 0;
	districtList: any = [];
	stateList: any = [];
	isCountryLoading = false;
	isStateLoading = false;
	isDistrictLoading = false;
	isCityLoading = false;
	isTalukaLoading = false;
	stateCount = 0;
	districtCount = 0;
	talukaCount = 0;
	countryCount = 0;
	currentCountryPage = 1;
	country_id = 1;
	state_id = null;
	district_id = null;
	taluka_id = null;
	enterprise_seller_type: any;
	gstNumbersArray = [];
	isWareHouseAddressCheck = true;

	public validation_messages = {
		first_name: [
			{ type: 'required', message: 'Please enter first name' },
			{ type: 'invalidName', message: 'Please enter a valid first name' },
			{ type: 'maxlength', message: 'First name cannot be more than 50 character' },
		],
		last_name: [
			{ type: 'required', message: 'Please enter last name' },
			{ type: 'invalidName', message: 'Please enter a valid last name' },
			{ type: 'maxlength', message: 'Last name cannot be more than 50 character' },
		],
		email: [
			{ type: 'required', message: 'Please enter email' },
			{ type: 'invalidEmail', message: 'Enter valid email id' },
		],
		phone_no: [
			{ type: 'required', message: 'Please enter mobile number' },
			{ type: 'invalidNumber', message: 'Enter valid mobile number' }
		],
		password: [
			{ type: 'required', message: 'Please enter password' },
			{ type: 'invalidPassword', message: 'Password should be a combination of alphanumeric with a minimum of 8 characters and maximum of 12 characters with one special character and one capital letter' },
		],
		confirm_password: [
			{ type: 'required', message: 'Please re-enter your password' },
			{ type: 'passMismatch', message: 'Password mismatch' },
		],
		company_name: [
			{ type: 'required', message: 'Please enter company name' },
			{ type: 'invalidName', message: 'Please enter a valid company name' },
			{ type: 'maxlength', message: 'Company name cannot be more than 50 character' },
		],
		hq_address: [
			{ type: 'required', message: 'Please enter head quarter address' },
			{ type: 'invalidName', message: 'Please enter valid head quarter address' },
		],
		location_tag: [],
		country: [],
		state: [],
		district: [],
		taluka: [],
		city: [],
		zipcode: [],
		gst: [
			{ type: 'required', message: 'Please enter GST number' },
			{ type: 'minlength', message: 'GST should be 15 character long' },
			{ type: 'maxlength', message: 'GST should be 15 character long' },
			{ type: 'invalidGST', message: 'Please enter valid GST number' },

		],
		seller_type: [
			{ type: 'required', message: 'Please select seller type' },
		],
		termsCondition: [
			{ type: 'required', message: 'Please accept terms and conditions' },
		],
	}

	fieldData = {
		"name": "Name",
		"email": "Email",
		"mobile_no": "Contact number"
	}
	cityCount = 0;
	autocompleteItems: any = [];
	wareHouseAutocompleteItems: any = [];
	GoogleAutocomplete: any;
	latitude;
	longitude;
	callGoogleApi = true;
	wareHouseCallGoogleApi = true;
	geocoder;
	isAutoSelected = false;
	wareHouseIsAutoSelected = false;
	company_name = '';
	contact_no = '';
	public sellerType = [
		{
			seller_type: "MANUFACTURER",
			seller_type_id: 1,
			seller_type_label: "Manufacturer"
		},
		{
			seller_type: "STOCKIST",
			seller_type_id: 2,
			seller_type_label: "Stockist"
		},
		{
			seller_type: "DISTRIBUTOR",
			seller_type_id: 3,
			seller_type_label: "Distributor"
		},
		{
			seller_type: "AUTHORISED DEALER",
			seller_type_id: 4,
			seller_type_label: "Authorised Dealer "
		},
		{
			seller_type: "RETAILER",
			seller_type_id: 5,
			seller_type_label: "Retailer"
		},
		{
			seller_type: "TRADER",
			seller_type_id: 6,
			seller_type_label: "Trader"
		}
	]

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		public commonService: CommonService,
		private navCtrl: NavController,
		private toasterService: ToasterService,
		private dataService: ApiService,
		private masterApi: MasterApiService,
		private platform: Platform,
		private locationService: LocationService,
		private location: Location,
		private modalService: ModalService,
		private elementRef: ElementRef,
		public getPlatformService: GetPlatformService
	) { }

	ngOnInit() {
		this.isBecomeSellerReq = false;
		this.commonService.isLoggedIn = this.commonService.localStorageGet('accessToken') || this.commonService.accessToken ? true : false;
		if (this.commonService.isLoggedIn) this.isLocation = true;
		const data = this.commonService.localStorageGet('accessToken') ? this.commonService.localStorageGet('info') : null;
		this.userData = data ? JSON.parse(data) : {};
		this.commonService.eventObservable.subscribe((data: any) => {
			if (data.event == 'isBecomeSeller') {
				this.isBecomeSellerReq = data['data'];
				if (this.isBecomeSellerReq) {
					this.commonService.localStorageSet("becomeSeller", this.isBecomeSellerReq);
				}
			}
		})
		this.registrationForm = this.initializeForm();
		this.registrationForm.reset();
		this.isBecomeSellerReq = this.commonService.localStorageGet('isBecomeSeller') ? true : false;
		this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
		this.geocoder = new google.maps.Geocoder;
		this.autocompleteItems = [];
		if (this.isBecomeSellerReq) {
			this.getUserDetail();
		}
		this.getLocationTag();
		this.addressForm = this.sellerAddress();
		this.registrationForm.controls['address']['controls'][0]['controls']['location_tag'].setValue(1);
		this.commonService.scrollToTop();
		this.registrationForm.controls['address']['controls'][0]['controls']['warehouseAddressCheck'].setValue(true);
		this.registrationForm.controls['termsCondition'].setValue(false);

	}

	ionViewWillEnter() {
		this.sellerRegisterationType = this.profileEnum.Individual;
		this.subscription = new Subscription();
		this.registrationForm.reset();
		this.registrationForm = this.initializeForm();
		if (this.sellerRegisterationType == this.profileEnum.Individual) {
			this.registrationForm.get('seller_type').clearValidators();
		}
		this.registrationForm.controls['address']['controls'][0]['controls']['warehouseAddressCheck'].setValue(true);
		this.registrationForm.controls['termsCondition'].setValue(false);

	}

	getUserDetail() {
		this.commonService.getUserDetail(this.userData.isCustomer ? 'CUSTOMER' : 'SELLER').then((data) => {
			this.company_name = data['company_name'];
			this.contact_no = data['mobile_no'];
			this.registrationForm = this.initializeForm(data, true);
			if (this.isBecomeSellerReq) {
				this.registrationForm.controls['address']['controls'][0]['controls']['warehouseAddressCheck'].setValue(true);
				this.registrationForm.controls['termsCondition'].setValue(false);

				this.sellerRegisterationType = this.userData.customer_type;
				this.registrationForm.get('password').clearValidators();
				this.registrationForm.get('confirm_password').clearValidators();
				if (this.sellerRegisterationType == this.profileEnum.Enterprise) {
					this.registrationForm.get('seller_type').setValidators([Validators.required]);
					this.registrationForm.get('seller_type').updateValueAndValidity();
				} else {
					this.registrationForm.get('seller_type').clearValidators();
					this.registrationForm.get('seller_type').updateValueAndValidity();
				}

			}
		}, (error: any) => {
			this.toasterService.errorToast(error['message']);
		});
	}

	getLocationTag() {
		const params = {
			user_type: 'SELLER'
		}
		this.subscription.add(
			this.dataService.get(this.commonService.isLoggedIn ? URLS.getAddressType : URLS.locationTagPublic, params).subscribe(data => {
				this.isLoading = false;
				if (data['code'] == 200) {
					this.locationTag = data['data'];
					this.locationTag.forEach(element => {
						element['disabled'] = false;
						if (element.address_type == "DISPATCH LOCATION") {
							this.wareHouseAddressTypeId = element.address_type_id;
						}
					});

					this.locationTag.forEach(element => {
						if (element.address_type == 'DISPATCH LOCATION') {
							element['disabled'] = true;
						}

					});
					this.locationTag.forEach(element => {
						if (element.address_type == 'HEADQUARTER') {
							element['disabled'] = true;
						}

					});

				}
				else {
					this.locationTag = [];
				}
			}, error => {
				this.locationTag = [];
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	initializeForm(data?, isDisable?) {
		return this.formBuilder.group({
			first_name: new FormControl({ value: data ? data['first_name'] : null, disabled: isDisable ? isDisable : false }, {
				validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.nameValidator]),
			}),
			last_name: new FormControl({ value: data ? data['last_name'] : null, disabled: isDisable ? isDisable : false }, {
				validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.nameValidator]),
			}),
			email: new FormControl({ value: data ? data['email'] : null, disabled: isDisable ? isDisable : false }, {
				validators: Validators.compose([Validators.required, CustomValidator.emailValidator]),
			}),
			phone_no: new FormControl({ value: data ? data['mobile_no'] : null, disabled: isDisable ? isDisable : false }, {
				validators: Validators.compose([Validators.required, CustomValidator.phoneValidator]),
			}),
			password: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.passwordPatternValidator]),
			}),
			confirm_password: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.confirmPassword]),
			}),
			seller_type: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			company_name: new FormControl({ value: data ? data['company_name'] : null, disabled: data && data['company_name'] ? isDisable : false }, {
				validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.nameValidator]),
			}),
			address: new FormArray([this.sellerAddress()], Validators.required),
			termsCondition: new FormControl(true, {
				validators: Validators.compose([Validators.requiredTrue]),
			}),
		});


	}

	sellerAddress(gstDisabled?): FormGroup {
		return this.formBuilder.group({
			location_tag: new FormControl({ value: this.isLocation ? 1 : null, disabled: false }, {
				validators: Validators.compose([Validators.required]),
			}),
			hq_address: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			country: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			state: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			district: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			taluka: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			zipcode: new FormControl(null, {
				validators: Validators.compose([Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
			}),
			gst: new FormControl({
				value: gstDisabled == true ? this.registrationForm.controls['address']['controls'][0]['controls']['gst'].value : null,
				disabled: false
			}, {
				validators: Validators.compose([Validators.required, Validators.maxLength(15), Validators.minLength(15), CustomValidator.gstValidator]),
			}),
			latitude: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			longitude: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			contact_no: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			warehouseAddressCheck: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			wareHouseAddress: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			wareHouseAddressCountry: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			wareHouseAddressState: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			wareHouseAddressDistrict: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			wareHouseAddressTaluka: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			wareHouseAddressZipcode: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			wareHouseAddressLatitude: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			wareHouseAddressLongitude: new FormControl(null, {
				validators: Validators.compose([]),
			}),
		});
	}

	get address(): FormArray {
		return this.registrationForm.controls["address"] as FormArray;
	}

	removeAddressAt(i) {
		this.address.removeAt(i);
	}

	addAddress() {
		this.isSubmitted = false;
		if (this.hqAddressValue && this.hqAddressValue.length > 0) {
			this.locationTag.forEach(element => {
				if (element.address_type == 'HEADQUARTER') {
					element['disabled'] = true;
				}

			});
		}

		if (!this.address.valid) {
			this.isSubmitted = true;
			this.isLocation = true;
			return;
		} else {
			if (this.address.controls && this.address.controls.length >= 10) {
				this.toasterService.errorToast("Only 10 addresses can be added!");
				return;
			}
			this.isLocation = false;
			if (this.address.length > 9) {
				this.toasterService.warningToast("You cannot add more than 10 address");
				return;
			}
			this.address.push(this.sellerAddress(this.address.length > 0 ? true : false));
		}
	}

	phoneClick(e) {
		if (e.keyCode < 48 || e.keyCode > 57) {
			e.preventDefault();
		}
	}

	restrictSpecialChar(event, i?) {
		this['isAutoSelected' + i] = true;
	}

	wareHouseRestrictSpecialChar() {
		this.wareHouseIsAutoSelected = true;
	}


	numberOnlyValidation(event: any) {
		const pattern = /[0-9]/;
		let inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			event.preventDefault();
		}
	}


	getCountry(shortCode?, index?, isWareHouse?) {
		if (shortCode) {
			return new Promise((resolve, reject) => {
				this.isCountryLoading = true;
				let param = {
					short_name: shortCode
				}
				this.countryList = [];
				this.masterApi.getCountry(param).then((data) => {
					this.isCountryLoading = false;
					this.countryList = this.countryList.concat(data['data']['countriesList']);
					if (this.countryList && this.countryList.length) {
						if (isWareHouse) {
							this.registrationForm.controls['address']['controls'][index]['controls']['wareHouseAddressCountry'].setValue(this.countryList[0].country_id);
						} else {
							this.registrationForm.controls['address']['controls'][index]['controls']['country'].setValue(this.countryList[0].country_id);
						}
					}
					resolve(data);
				}, error => {
					reject(error);
					this.countryList = [];
				});
			})
		} else {
			return new Promise((resolve, reject) => {
				this.isCountryLoading = true;
				let param = {
					short_name: "IN"
				}
				this.countryList = [];
				this.masterApi.getCountry(param).then((data) => {
					this.isCountryLoading = false;
					this.countryList = this.countryList.concat(data['data']['countriesList']);
					if (this.countryList && this.countryList.length) {
						if (isWareHouse) {
							this.registrationForm.controls['address']['controls'][index]['controls']['wareHouseAddressCountry'].setValue(this.countryList[0].country_id);
						} else {
							this.registrationForm.controls['address']['controls'][index]['controls']['country'].setValue(this.countryList[0].country_id);
						}
					}
					resolve(data);
				}, error => {
					reject(error);
					this.countryList = [];
				});
			})
		}

	}

	getState(shortCode?, index?, isWareHouse?) {
		if (shortCode) {
			return new Promise((resolve, reject) => {
				let params = {
					short_name: shortCode
				}
				this.isStateLoading = true;
				this.stateList = [];
				this.masterApi.getState(params).then((data) => {
					this.isStateLoading = false;
					this.stateList = this.stateList.concat(data['data']['statesList']);
					this.stateCount = data['data']['total_count'];
					if (this.stateList && this.stateList.length) {
						if (isWareHouse) {
							this.registrationForm.controls['address']['controls'][index]['controls']['wareHouseAddressState'].setValue(this.stateList[0].state_id);
						} else {
							this.registrationForm.controls['address']['controls'][index]['controls']['state'].setValue(this.stateList[0].state_id);
						}
						this.state_id = this.stateList[0].state_id
					}
					resolve(data);
				}, error => {
					reject(error);
					this.isStateLoading = false;
					this.stateList = [];
					const params = {
						country_id: this.country_id
					}
					this.masterApi.getState(params).then((data) => {
						this.isStateLoading = false;
						this.stateList = this.stateList.concat(data['data']['statesList']);
						this.stateCount = data['data']['total_count'];
						resolve(data);
					}, error => {
						reject(error);
						this.isStateLoading = false;
						this.stateList = [];

					});

				});
			})
		} else {
			return new Promise((resolve, reject) => {
				const params = {
					country_id: this.country_id
				}
				this.isStateLoading = true;
				this.stateList = [];
				this.masterApi.getState(params).then((data) => {
					this.isStateLoading = false;
					this.stateList = this.stateList.concat(data['data']['statesList']);
					this.stateCount = data['data']['total_count'];
					if (this.stateList && this.stateList.length) {
						if (isWareHouse) {
							this.registrationForm.controls['address']['controls'][index]['controls']['wareHouseAddressState'].setValue(this.stateList[0].state_id);
						} else {
							this.registrationForm.controls['address']['controls'][index]['controls']['state'].setValue(this.stateList[0].state_id);
						}
						this.state_id = this.stateList[0].state_id
					}
					resolve(data);
				}, error => {
					reject(error);
					this.isStateLoading = false;
					this.stateList = [];
				});
			})
		}

	}


	getDistrict(shortCode?, index?, isWareHouse?) {
		if (shortCode) {
			return new Promise((resolve, reject) => {
				let params = {
					short_name: shortCode
				}
				this.districtList = [];
				this.masterApi.getDistrict(params).then((data) => {
					this.districtList = this.districtList.concat(data['data']['districtList']);
					this.districtCount = data['data']['total_count'];
					this.district_id = this.districtList[0].district_id;
					if (this.districtList && this.districtList.length) {
						if (isWareHouse) {
							this.registrationForm.controls['address']['controls'][index]['controls']['wareHouseAddressDistrict'].setValue(this.districtList[0].district_id);
							this.getTaluka(this.locationService.taluka_shortname, index, isWareHouse, this.district_id);
						} else {
							this.registrationForm.controls['address']['controls'][index]['controls']['district'].setValue(this.districtList[0].district_id);
							this.getTaluka(this.locationService.taluka_shortname, index, false, this.district_id);
						}
					}
					resolve(data);
				}, error => {
					reject(error);
					this.districtList = [];
					setTimeout(() => {
						if (this.state_id) {
							return new Promise((resolve, reject) => {
								let params = {
									state_id: this.state_id
								}
								this.districtList = [];
								this.masterApi.getDistrict(params).then((data) => {
									this.districtList = this.districtList.concat(data['data']['districtList']);
									this.districtCount = data['data']['total_count'];
									resolve(data);
								}, error => {
									reject(error);
									this.districtList = [];
								});
							})
						}
					}, 500);
				});
			})
		} else {
			setTimeout(() => {
				if (this.state_id) {
					return new Promise((resolve, reject) => {
						let params = {
							state_id: this.state_id
						}
						this.districtList = [];
						this.masterApi.getDistrict(params).then((data) => {
							this.districtList = this.districtList.concat(data['data']['districtList']);
							this.districtCount = data['data']['total_count'];
							resolve(data);
						}, error => {
							reject(error);
							this.districtList = [];
						});
					})
				}
			}, 500);
		}
	}

	getTaluka(shortCode?, index?, isWareHouse?, district_id?) {
		if (shortCode) {
			return new Promise((resolve, reject) => {

				let params = {
					short_name: shortCode,
					district_id: district_id
				}
				this.talukaList = [];
				this.masterApi.getTaluka(params).then((data) => {
					this.talukaList = this.talukaList.concat(data['data']['talukaList']);
					this.talukaCount = data['data']['total_count'];
					if (this.talukaList && this.talukaList.length) {
						if (isWareHouse) {
							this.registrationForm.controls['address']['controls'][index]['controls']['wareHouseAddressTaluka'].setValue(this.talukaList[0].taluka_id);
						} else {
							this.registrationForm.controls['address']['controls'][index]['controls']['taluka'].setValue(this.talukaList[0].taluka_id);
						}
					}
					resolve(data);
				}, error => {
					reject(error)
					this.talukaList = [];
					setTimeout(() => {
						if (this.district_id) {
							return new Promise((resolve, reject) => {
								let params = {
									district_id: this.district_id
								}
								this.talukaList = [];
								this.masterApi.getTaluka(params).then((data) => {
									this.talukaList = this.talukaList.concat(data['data']['talukaList']);
									this.talukaCount = data['data']['total_count'];
									resolve(data);
								}, error => {
									reject(error)
									this.talukaList = [];
								});
							})
						}
					}, 700);
				});
			})
		} else {
			setTimeout(() => {
				if (this.district_id) {
					return new Promise((resolve, reject) => {
						let params = {
							district_id: this.district_id
						}
						this.talukaList = [];
						this.masterApi.getTaluka(params).then((data) => {
							this.talukaList = this.talukaList.concat(data['data']['talukaList']);
							this.talukaCount = data['data']['total_count'];
							resolve(data);
						}, error => {
							reject(error)
							this.talukaList = [];
						});
					})
				}
			}, 700);
		}
	}

	countryChange(data) {
		this.country_id = data.country_id;
	}

	stateChange(data, i, isWareHouse?) {
		this.state_id = data.state_id;
		if (isWareHouse) {
			if (this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressDistrict'].value) {
				this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressDistrict'].reset();
			}
			if (this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressTaluka'].value) {
				this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressTaluka'].reset();
			}
			if (this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressZipcode'].value) {
				this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressZipcode'].reset();
			}
		} else {
			if (this.registrationForm.controls['address']['controls'][i]['controls']['district'].value) {
				this.registrationForm.controls['address']['controls'][i]['controls']['district'].reset();
			}
			if (this.registrationForm.controls['address']['controls'][i]['controls']['taluka'].value) {
				this.registrationForm.controls['address']['controls'][i]['controls']['taluka'].reset();
			}
			if (this.registrationForm.controls['address']['controls'][i]['controls']['zipcode'].value) {
				this.registrationForm.controls['address']['controls'][i]['controls']['zipcode'].reset();
			}
		}
		if (data.state_id) {
			return new Promise((resolve, reject) => {
				let params = {
					state_id: data.state_id
				}
				this.districtList = [];
				this.masterApi.getDistrict(params).then((data) => {
					this.districtList = this.districtList.concat(data['data']['districtList']);
					this.districtCount = data['data']['total_count'];
					resolve(data);
				}, error => {
					reject(error);
					this.districtList = [];
				});
			})
		}
	}

	districtChange(data, i, isWareHouse?) {
		this.district_id = data.district_id;
		if (isWareHouse) {
			if (this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressTaluka'].value) {
				this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressTaluka'].reset();
			}
			if (this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressZipcode'].value) {
				this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressZipcode'].reset();
			}
		} else {
			if (this.registrationForm.controls['address']['controls'][i]['controls']['taluka'].value) {
				this.registrationForm.controls['address']['controls'][i]['controls']['taluka'].reset();
			}
			if (this.registrationForm.controls['address']['controls'][i]['controls']['zipcode'].value) {
				this.registrationForm.controls['address']['controls'][i]['controls']['zipcode'].reset();
			}
		}
		if (data.district_id) {
			return new Promise((resolve, reject) => {
				let params = {
					district_id: data.district_id
				}
				this.talukaList = [];
				this.masterApi.getTaluka(params).then((data) => {
					this.talukaList = this.talukaList.concat(data['data']['talukaList']);
					this.talukaCount = data['data']['total_count'];
					resolve(data);
				}, error => {
					reject(error)
					this.talukaList = [];
				});
			})
		}
	}

	talukaChange(data, i, isWareHouse?) {
		this.taluka_id = data.taluka_id ? data.taluka_id : null;
		if (isWareHouse) {
			if (this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressZipcode'].value) {
				this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressZipcode'].reset();
			}
		} else {
			if (this.registrationForm.controls['address']['controls'][i]['controls']['zipcode'].value) {
				this.registrationForm.controls['address']['controls'][i]['controls']['zipcode'].reset();
			}
		}
	}

	public findInvalidControls() {
		const invalid = [];
		const controls = this.registrationForm.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name);
			}
		}
		return invalid;
	}

	login() {
		this.navCtrl.navigateRoot(['/login']);
	}

	gstNoChange(i) {
		if (this.gstNumbersArray && this.gstNumbersArray.length) {
			this.gstNumbersArray.splice(i, 1);
		}
	}

	onSellerRegister() {
		this.isSubmitted = true;
		let sellerRegister: SellerRegistration = {};
		let paramsLocation: CompanyAddress = {};
		let wareHouseParams: any = {};
		let myLoation: any = {};
		let customNotification = {};
		const becomeSellerParam = new FormData();
		let encryptedData = '';
		this.locationParams = [];
		this.registrationForm.controls['address']['controls'][0]['controls']['location_tag'].setValue(1);
		this.customEvent.emit(true);
		this.addVlaidatorAddressForm();

		for (const i in this.registrationForm.controls) {
			if (i == "address") {
				const controlNames = Object.keys(this.address.controls);
				for (const j in this.address.controls) {
					const controlIndex = controlNames.indexOf(j);
					for (const k in this.addressForm.controls) {
						if (k == 'location_tag' && this.address.controls[j]['controls'][k].value) {
							paramsLocation.address_type_id = this.address.controls[j]['controls'][k].value;
						}
						if (k == 'hq_address' && this.address.controls[j]['controls'][k].value) {
							paramsLocation.address = this.address.controls[j]['controls'][k].value;
						}
						if (k == 'country' && this.address.controls[j]['controls'][k].value) {
							paramsLocation.country_id = this.address.controls[j]['controls'][k].value;
						}
						if (k == 'state' && this.address.controls[j]['controls'][k].value) {
							paramsLocation.state_id = this.address.controls[j]['controls'][k].value;
						}
						if (k == 'district' && this.address.controls[j]['controls'][k].value) {
							paramsLocation.district_id = this.address.controls[j]['controls'][k].value;
						}
						if (k == 'taluka' && this.address.controls[j]['controls'][k].value) {
							paramsLocation.taluka_id = this.address.controls[j]['controls'][k].value ? this.address.controls[j]['controls'][k].value : null;
						} else if (k == 'taluka' && !this.address.controls[j]['controls'][k].value) {
							delete paramsLocation.taluka_id
						}
						if (k == 'zipcode' && this.address.controls[j]['controls'][k].value) {
							paramsLocation.zip_code = this.address.controls[j]['controls'][k].value;
						}
						if (k == 'gst' && this.address.controls[j]['controls'][k].value) {
							paramsLocation.gst_number = this.address.controls[j]['controls'][k].value;
						}
						if (k == 'latitude' && this.address.controls[j]['controls'][k].value) {
							paramsLocation.latitude = this.address.controls[j]['controls'][k].value.toString();
						}
						if (k == 'longitude' && this.address.controls[j]['controls'][k].value) {
							paramsLocation.longitude = this.address.controls[j]['controls'][k].value.toString();
						}
						if (k == 'contact_no' && this.address.controls[j]['controls'][k].value) {
							paramsLocation.contact_no = this.registrationForm.value.phone_no ? this.registrationForm.value.phone_no : this.contact_no;
						}
						this.address.controls[j]['controls'][k].markAsDirty();
						this.address.controls[j]['controls'][k].markAsTouched();
						this.address.controls[j]['controls'][k].updateValueAndValidity();
					}
					if (!this.isWareHouseAddressCheck && controlIndex == 0) {
						wareHouseParams.address_type_id = this.wareHouseAddressTypeId
						if (this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddress'].value) {
							wareHouseParams.address = this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddress'].value
						}
						if (this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressCountry'].value) {
							wareHouseParams.country_id = this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressCountry'].value;
						}
						if (this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressState'].value) {
							wareHouseParams.state_id = this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressState'].value;
						}
						if (this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressDistrict'].value) {
							wareHouseParams.district_id = this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressDistrict'].value;
						}
						if (this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressTaluka'].value) {
							wareHouseParams.taluka_id = this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressTaluka'].value;
						}
						if (this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressZipcode'].value) {
							wareHouseParams.zip_code = this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressZipcode'].value;
						}
						if (this.registrationForm.controls['address']['controls'][0]['controls']['gst'].value) {
							wareHouseParams.gst_number = this.registrationForm.controls['address']['controls'][0]['controls']['gst'].value
						}
						if (this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressLongitude'].value) {
							wareHouseParams.longitude = this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressLongitude'].value;
						}
						if (this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressLatitude'].value) {
							wareHouseParams.latitude = this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressLatitude'].value;
						}

						this.locationParams.push(wareHouseParams)
					}

					paramsLocation.contact_no = this.registrationForm.value.phone_no ? this.registrationForm.value.phone_no : this.contact_no;
					myLoation = { ...paramsLocation }
					this.locationParams.push(myLoation);
					this.locationParams.map((obj, index) => {
						if (this.isWareHouseAddressCheck && index == 0) {
							obj.is_also_warehouse_address = this.isWareHouseAddressCheck
						} else {
							delete obj.is_also_warehouse_address
						}
					})
					if (myLoation.gst_number) {
						this.gstNumbersArray.push(myLoation.gst_number);
						this.gstNumbersArray = Array.from(new Set(this.gstNumbersArray))
					}
				}
			} else {
				this.registrationForm.controls[i].markAsDirty();
				this.registrationForm.controls[i].updateValueAndValidity();
				this.registrationForm.controls[i].markAsTouched();
			}
		}

		// //for seller registration condition
		// if (!this.commonService.sellerIdProof || !this.commonService.sellerCertificate) {
		// 	this.commonService.scrollToTop();
		// 	this.commonService.clearDataEmit(false, true);
		// 	if (this.categoriesData.categoriesId.length < 1) {
		// 		this.categoryNotChecked = true;
		// 		this.commonService.clearDataEmit(true, true);
		// 	} else {
		// 		this.categoryNotChecked = false;
		// 	}
		// 	return;
		// }




		if (this.registrationForm.invalid) {
			for (const i in this.registrationForm.controls) {
				if (this.registrationForm.controls[i].invalid) {
					this.scrollToInvalidControl(i);
				}
			}
			return
		}


		this.commonService.clearDataEmit(false, true);
		if (this.categoriesData.categoriesId.length < 1) {
			this.categoryNotChecked = true;
			this.commonService.clearDataEmit(true, false);
			return;
		} else {
			this.categoryNotChecked = false;
		}

		this.isLoading = true;

		if (!this.isBecomeSellerReq) {
			sellerRegister.first_name = this.registrationForm.value.first_name?.trim();
			sellerRegister.last_name = this.registrationForm.value.last_name?.trim();
			sellerRegister.email = this.registrationForm.value.email?.toLowerCase();
			sellerRegister.password = this.registrationForm.value.password;
			sellerRegister.mobile_no = this.registrationForm.value.phone_no;
			sellerRegister.seller_type = this.sellerRegisterationType == this.profileEnum.Enterprise ? 'ENTERPRISE' : 'INDIVIDUAL';
			sellerRegister.user_type = this.sellerRegisterationType == this.profileEnum.Enterprise ? 'ENTERPRISE_SELLER' : 'INDIVIDUAL_SELLER';
			if (this.platform.is('desktop')) {
				sellerRegister.platform = "WEB";
			} else if (this.platform.is('ios')) {
				sellerRegister.platform = "IOS";
			} else if (this.platform.is('android')) {
				sellerRegister.platform = "ANDROID";
			}
			else {
				sellerRegister.platform = "WEB";
			}
			sellerRegister.country_code = this.commonService.countryCode;
			sellerRegister.company_name = this.registrationForm.value.company_name?.trim();
			sellerRegister.company_addresses = this.locationParams;
			sellerRegister.company_categories = this.categoriesData.categoriesId;
			if (this.sellerRegisterationType == this.profileEnum.Enterprise) {
				sellerRegister.seller_class = this.enterprise_seller_type;
			}
			encryptedData = this.commonService.encryptData(JSON.stringify(sellerRegister));
			this.fromPage = "registration";

			let platformType;

			if (this.platform.is('desktop')) {
				platformType = "WEB";
			} else if (this.platform.is('ios')) {
				platformType = "IOS";
			} else if (this.platform.is('android')) {
				platformType = "ANDROID";
			} else {
				platformType = "WEB";
			}
			let otpParam = {
				"mobile_no": sellerRegister.mobile_no,
				"purpose_type": "REGISTRATION",
				"platform": platformType,
				"email": sellerRegister.email,
				"gst_numbers": this.gstNumbersArray,
				"company_name": sellerRegister.company_name,
				"user_type": "SELLER",
				"request_type": this.sellerRegisterationType == this.profileEnum.Enterprise ? 'ENTERPRISE' : 'INDIVIDUAL'

			}
			if (this.sellerRegisterationType == this.profileEnum.Enterprise) {
				otpParam["reference_type"] = "ENTERPRISE_USERS"
			}
			this.subscription.add(
				this.dataService.post(URLS.generateOtp, otpParam).subscribe(data => {
					this.isLoading = false;
					if (data['code'] == 200) {
						let userData = data['data'];
						this.commonService.localStorageSet('seller_registration', encryptedData);
						this.commonService.localStorageSet('registration_type', "seller");
						let encryptedUserDetails = this.commonService.encryptData(JSON.stringify(userData));
						this.commonService.localStorageSet('user_details', encryptedUserDetails);
						this.fromPage = "registration"
						this.categoriesData.categoriesId = [];
						this.categoriesData.categoriesList.forEach(item => item.checked = false);
						this.commonService.localStorageSet('uuid', userData.uuid ? userData.uuid : '');
						this.gstNumbersArray = [];
						this.registrationForm.reset();
						this.sellerRegisterationType = this.profileEnum.Individual;
						this.commonService.clearDataEmit();
						this.toasterService.successToast(data['message'], 'top');
						this.navCtrl.navigateForward('/two-factor-authentication', { queryParams: { fromPage: this.fromPage } });
					}
					else {
						this.toasterService.errorToast(data['message'], 'top');
					}
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);
				})
			)
		}

		//for become a seller condition
		else {
			becomeSellerParam.append('company_name', this.company_name?.trim() ? this.company_name?.trim() : this.registrationForm.value.company_name?.trim());
			becomeSellerParam.append('company_addresses', JSON.stringify(this.locationParams));
			becomeSellerParam.append('company_categories', JSON.stringify(this.categoriesData.categoriesId));
			if (this.sellerRegisterationType == this.profileEnum.Enterprise) {
				becomeSellerParam.append('seller_class', this.enterprise_seller_type);
			}
			if (this.commonService.sellerIdProof) becomeSellerParam.append('id_proof', this.commonService.sellerIdProof);
			if (this.commonService.sellerCertificate) becomeSellerParam.append('cr_certificate', this.commonService.sellerCertificate);
			this.subscription.add(
				this.dataService.post(URLS.becomeASeller, becomeSellerParam).subscribe(data => {
					this.isSubmitted = true;
					this.categoriesData.categoriesId = [];
					this.commonService.sellerIdProof = null;
					this.commonService.sellerCertificate = null;
					this.categoriesData.categoriesList.forEach(item => item.checked = false);
					if (data['code'] == 200) {
						this.registrationForm.reset();
						let userData = data['data'];
						this.commonService.localStorageSet('uuid', userData.uuid ? userData.uuid : '');
						this.commonService.setEvent('profileData', 'true');
						let userResponseInfo = {
							user_type: userData['user']['user_type'] ? userData['user']['user_type'] : '',
							customer_type: userData['user']['customer_type'] ? userData['user']['customer_type'] : '',
							seller_type: userData['user']['seller_type'] ? userData['user']['seller_type'] : '',
							user_profile: userData['user']['seller_type'] != 'NONE' ? userData['user']['seller_type'] : userData['user']['customer_type'],
							approval_status: userData['user']['approval_status'] ? userData['user']['approval_status'] : '',
							seller_approval_status: userData['user']['seller_approval_status'] ? userData['user']['seller_approval_status'] : '',
							name: userData['user']['first_name'] + ' ' + userData['user']['last_name'],
							isSeller: userData['user']['user_type'] == 'BOTH' || userData['user']['user_type'] == this.profileEnum.Seller ? true : false,
							isCustomer: false

						}
						this.commonService.userData = userResponseInfo;
						this.commonService.localStorageSet('info', JSON.stringify(userResponseInfo));
						this.commonService.setEvent('sidemenuChnage', "true");
						// this.navCtrl.navigateRoot(['/home/account-details'], { animated: false });
						this.navCtrl.navigateRoot(['/home/document-registration'], { animated: false });
						customNotification['message'] = data['message'];
						customNotification['link'] = '/home/document-registration';
						customNotification['type'] = 'warning';
						setTimeout(() => {
							this.commonService.setEvent('alertMessage', customNotification);
						}, 500);
					}
					else {
						this.toasterService.errorToast(data['message'], 'top');
					}
					this.isLoading = false;
				}, error => {
					this.categoriesData.isfrmChecked = false;
					this.isSubmitted = true;
					this.categoriesData.categoriesId = [];
					this.isLoading = false;
					this.categoriesData.categoriesId = [];
					this.categoriesData.categoriesList.forEach(item => item.checked = false);
					this.toasterService.errorToast(error['message']);
				})
			)
		}

		this.isSubmitted = false;
	}


	async userTypeChange(val) {
		this.isLocation = true;
		this.sellerRegisterationType = val;
		this.commonService.clearDataEmit();
		this.registrationForm.reset();
		this.registrationForm = this.initializeForm();
		this.registrationForm.controls['address']['controls'][0]['controls']['warehouseAddressCheck'].setValue(true);
		this.registrationForm.controls['termsCondition'].setValue(false);

		await this.resetAddressForm();
		this.gstNumbersArray = [];
		if (this.sellerRegisterationType == this.profileEnum.Enterprise) {
			this.registrationForm.get('seller_type').setValidators([Validators.required]);
			this.registrationForm.get('seller_type').updateValueAndValidity();
		} else {
			this.registrationForm.get('seller_type').clearValidators();
			this.registrationForm.get('seller_type').updateValueAndValidity();
		}
	}

	resetAddressForm() {
		for (let i = 0; i < this.address.length; i++) {
			this.registrationForm.controls['address']['controls'][i]['controls']['hq_address'].clearValidators();
			this.registrationForm.controls['address']['controls'][i]['controls']['location_tag'].clearValidators();
			this.registrationForm.controls['address']['controls'][i]['controls']['country'].clearValidators();
			this.registrationForm.controls['address']['controls'][i]['controls']['state'].clearValidators();
			this.registrationForm.controls['address']['controls'][i]['controls']['district'].clearValidators();
			this.registrationForm.controls['address']['controls'][i]['controls']['taluka'].clearValidators();
			this.registrationForm.controls['address']['controls'][i]['controls']['zipcode'].clearValidators();
			this.registrationForm.controls['address']['controls'][i]['controls']['gst'].clearValidators();
		}
	}

	addVlaidatorAddressForm() {
		for (let i = 0; i < this.address.length; i++) {
			this.registrationForm.controls['address']['controls'][i]['controls']['hq_address'].setValidators([Validators.required]);
			this.registrationForm.controls['address']['controls'][i]['controls']['country'].setValidators([Validators.required]);
			this.registrationForm.controls['address']['controls'][i]['controls']['state'].setValidators([Validators.required]);
			this.registrationForm.controls['address']['controls'][i]['controls']['district'].setValidators([Validators.required]);
			this.registrationForm.controls['address']['controls'][i]['controls']['taluka'].setValidators([Validators.required]);
			this.registrationForm.controls['address']['controls'][i]['controls']['zipcode'].setValidators([Validators.required, Validators.maxLength(6), Validators.minLength(6)]);
			this.registrationForm.controls['address']['controls'][i]['controls']['gst'].setValidators([Validators.required, Validators.maxLength(15), Validators.minLength(15), CustomValidator.gstValidator]);
		}
	}

	getUploadData(data) {
		this.commonService.sellerCertificate = data['CERTIFICATE'];
		this.commonService.sellerIdProof = data['ID'];

	}

	cancel() {
		this.location.back();
	}

	sellerTypeChange(event) {
		this.enterprise_seller_type = event.seller_type
	}

	onHqAddressChange(event) {
		this.hqAddressValue = event.target.value;
		if (this.hqAddressValue && this.hqAddressValue.length > 0) {
			this.locationTag.forEach(element => {
				if (element.address_type == 'HEADQUARTER') {
					element['disabled'] = true;
				}

			});
		} else {
			this.locationTag.forEach(element => {
				if (element.address_type == 'HEADQUARTER') {
					element['disabled'] = false;
				}

			});
		}

	}

	openAddressOptions(optionData) {
		if (this.hqAddressValue && this.hqAddressValue.length > 0) {
			optionData.forEach(element => {
				if (element.address_type == 'HEADQUARTER') {
					element['disabled'] = true;
				}

			});
		}
	}

	getPlaceAutocomplete(val, i) {
		const inputValue = val.target.value;
		this[`predictionLength${i}`] = 0;
		if (inputValue.trim() === '') {
			this[`autocompleteItems${i}`] = [];
			this.registrationForm.controls['address']['controls'][i]['controls']['hq_address'].setValue(null);
			return;
		}
		if (this[`callGoogleApi${i}`]) {
			this.registrationForm.controls['address']['controls'][i]['controls']['country'].reset();
			this.registrationForm.controls['address']['controls'][i]['controls']['state'].reset();
			this.registrationForm.controls['address']['controls'][i]['controls']['district'].reset();
			this.registrationForm.controls['address']['controls'][i]['controls']['taluka'].reset();
			this.registrationForm.controls['address']['controls'][i]['controls']['zipcode'].reset();
			this.countryList = [];
			this.districtList = [];
			this.stateList = [];
			this.talukaList = [];
			this.locationService.getPlaceAutocomplete(this.GoogleAutocomplete, inputValue);
			this[`autocompleteItems${i}`] = this.locationService.autocompleteItems
			this[`predictionLength${i}`] = this[`autocompleteItems${i}`].length;
			if (this.autocompleteItems.length > 0) { this[`isAutoSelected${i}`] = false };

		} else {
			setTimeout(() => {
				this[`callGoogleApi${i}`] = true;
			}, 500);
		}


	}

	getWareHouseAddressPlaceAutocomplete(val) {
		const inputValue = val.target.value;
		this.wareHouseAddressPredictionLength = 0;
		if (inputValue.trim() === '') {
			this.wareHouseAutocompleteItems = [];
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddress'].setValue(null);
			return;
		}
		if (this.wareHouseCallGoogleApi) {
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressCountry'].reset();
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressState'].reset();
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressDistrict'].reset();
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressTaluka'].reset();
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressZipcode'].reset();
			this.countryList = [];
			this.districtList = [];
			this.stateList = [];
			this.talukaList = [];
			this.locationService.getPlaceAutocomplete(this.GoogleAutocomplete, inputValue);
			this.wareHouseAutocompleteItems = this.locationService.autocompleteItems
			this.wareHouseAddressPredictionLength = this.wareHouseAutocompleteItems.length;
			if (this.wareHouseAutocompleteItems.length > 0) { this.wareHouseIsAutoSelected = false };
		} else {
			setTimeout(() => {
				this.wareHouseCallGoogleApi = true;
			}, 500);
		}
	}

	selectSearchResult(item, i) {
		this[`callGoogleApi${i}`] = false;
		this[`predictionLength${i}`] = 0;
		let locationName = item['description'];
		this[`autocompleteItems${i}`] = [];
		this[`isAutoSelected${i}`] = false;
		this.locationService.getselectedAutoMap(this.geocoder, item).then(data => {
			if (this.locationService.country_shortname) {
				this.getCountry(this.locationService.country_shortname, i);
			} else {
				this.getCountry(null);
			}
			if (this.locationService.state_shortname) {
				this.getState(this.locationService.state_shortname, i);
			}
			if (this.locationService.district_shortname) {
				this.getDistrict(this.locationService.district_shortname, i);
			} else {
				this.getDistrict(null);
			}
			if (this.locationService.zipcode_shortname) {
				this.registrationForm.controls['address']['controls'][i]['controls']['zipcode'].setValue(this.locationService.zipcode_shortname);
			}
		})
		this.registrationForm.controls['address']['controls'][i]['controls']['hq_address'].setValue(locationName);
		setTimeout(() => {
			this.registrationForm.controls['address']['controls'][i]['controls']['latitude'].setValue(this.locationService.latitude.toString());
			this.registrationForm.controls['address']['controls'][i]['controls']['longitude'].setValue(this.locationService.longitude.toString());
		}, 1000);

	}

	wareHouseSelectSearchResult(item) {
		this.wareHouseCallGoogleApi = false;
		this.wareHouseAddressPredictionLength = 0;
		let locationName = item['description'];
		this.wareHouseAutocompleteItems = [];
		this.wareHouseIsAutoSelected = false;
		this.locationService.getselectedAutoMap(this.geocoder, item).then(data => {
			if (this.locationService.country_shortname) {
				this.getCountry(this.locationService.country_shortname, 0, true);
			} else {
				this.getCountry(null);
			}
			if (this.locationService.state_shortname) {
				this.getState(this.locationService.state_shortname, 0, true);
			}
			if (this.locationService.district_shortname) {
				this.getDistrict(this.locationService.district_shortname, 0, true);
			} else {
				this.getDistrict(null);
			}
			if (this.locationService.zipcode_shortname) {
				this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressZipcode'].setValue(this.locationService.zipcode_shortname);
			}
		})
		this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddress'].setValue(locationName);
		setTimeout(() => {
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressLatitude'].setValue(this.locationService.latitude.toString());
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressLongitude'].setValue(this.locationService.longitude.toString());
		}, 1000);
	}

	ngOnDestroy() {
		this.commonService.localStorageRemove("becomeSeller");
	}

	async termsPopUp() {
		let obj = {
			component: TermsOfUseComponent,
			backdropDismiss: true,
			canDismiss: true,
			cssClass: 'terms-of-use',
			componentProps: {
				isModal: true
			}

		};
		let modal = this.modalService.openModal(obj);
	}

	async privacyPopUp() {
		let obj = {
			component: PrivacyPolicyComponent,
			backdropDismiss: true,
			canDismiss: true,
			cssClass: 'privacy-policy',
			componentProps: {
				isModal: true
			}

		};
		let modal = this.modalService.openModal(obj);
	}

	onWarehouseAddressChange(event) {
		this.isWareHouseAddressCheck = event.detail.checked;
		if (!this.isWareHouseAddressCheck) {
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddress'].setValidators([Validators.required]);
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressCountry'].setValidators([Validators.required]);
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressState'].setValidators([Validators.required]);
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressDistrict'].setValidators([Validators.required]);
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressTaluka'].setValidators([Validators.required]);
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressZipcode'].setValidators([Validators.required]);
		} else {
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddress'].clearValidators();
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressCountry'].clearValidators();
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressState'].clearValidators();
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressDistrict'].clearValidators();
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressTaluka'].clearValidators();
			this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressZipcode'].clearValidators();
		}

		this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddress'].updateValueAndValidity();
		this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressCountry'].updateValueAndValidity();
		this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressState'].updateValueAndValidity();
		this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressDistrict'].updateValueAndValidity();
		this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressTaluka'].updateValueAndValidity();
		this.registrationForm.controls['address']['controls'][0]['controls']['wareHouseAddressZipcode'].updateValueAndValidity();
	}

	scrollToInvalidControl(controlName) {
		const control: FormControl = this.registrationForm.get(controlName) as FormControl;
		if (control && control.invalid) {
			const invalidControl = this.elementRef.nativeElement.querySelector(`[formcontrolname="${controlName}"]`);
			if (invalidControl) {
				invalidControl.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}
	}
}

