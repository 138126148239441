<div class="forgot-container">
	<div (click)="back()" class="back mb-10 pointer">
		<img alt="Arrow" class="mr-5" src="/assets/icon/arrow-Bk.svg">
		<div class="text-link  fs-16 fw-600"> Back</div>
	</div>
	<div class="heading-label fs-28 fw-600 mb-20">
		Forgot password?
	</div>
	<div class="form-container">
		<form [formGroup]="forgotPassFromGroup">
			<div class="email common-input">
				<ion-label class="mb-5">Email Address/Phone Number *</ion-label>
				<ion-input type="email" placeholder="Enter Email Address/Phone Number" formControlName="email"
					#emailInput></ion-input>


				<ng-container *ngFor="let validation of validation_messages.email">
					<ng-container
						*ngIf="forgotPassFromGroup.get('email').hasError(validation.type) && forgotPassFromGroup.get('email').dirty">
						<ion-text color="danger" class="error-msg">
							{{validation.message }}
						</ion-text>
					</ng-container>
				</ng-container>
			</div>
			<div class="forgot-btn mt-15">
				<button class="submit-btn w-156" (click)="send()" [disabled]="isLoading">
					<ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
					Submit
				</button>
			</div>
		</form>

	</div>

</div>