import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';
import { ToasterService } from '../../services/toaster.service';

@Component({
    selector: 'app-add-edit-product-filter',
    templateUrl: './add-edit-product-filter.component.html',
    styleUrls: ['./add-edit-product-filter.component.scss'],
})
export class AddEditProductFilterComponent implements OnInit {
    @Input() step: number;
    isFilterShow: boolean = false;
    selectedCategory: any;
    public subscription = new Subscription();
    isLoading = false;
    isMasterLoading = false;
    categoryList: any = [];
    masterCategoryList: any = [];
    mainProductData: any = [];
    mainAttributeData: any = [];

    searchObj: any = [];
    mainCategoryId = null;
    categoryId = null;
    searchText = null;
    isAnyAttributeSelected: boolean = false;
    mainProduct: any;

    constructor(
        private router: Router,
        private commonService: CommonService,
        private dataService: ApiService,
        private toasterService: ToasterService,
        private navCtrl: NavController,
        private route: ActivatedRoute,
        public getPlatformService: GetPlatformService,
        private modalService: ModalService,

    ) {


    }

    ngOnInit() {
        if (this.step == 1) {
            this.isFilterShow = true;
        }
        this.getMasterCategoriesList();
        if (this.commonService.localStorageGet('filterData')) {
            let filterData: any = JSON.parse(this.commonService.localStorageGet('filterData'));
            this.mainCategoryId = +filterData.main_category_id;
            this.getCategoriesList(this.mainCategoryId);
            this.selectedCategory = +filterData.category_id;
            this.categoryId = +filterData.category_id;
            this.getMainProductMaster();
            this.mainProduct = filterData.mainProductIds ? filterData.mainProductIds[0] : null;
            this.getMainAttributeMaster(filterData.attributeIds);
        }

    }

    // search text parameter in api
    search() {
        if (this.searchText && this.searchText?.trim() == '') {
            this.searchText = null;
            return;
        }
        if (this.searchText) {
            this.searchObj['search'] = this.searchText?.trim();
        }
    }

    // search text parameter in api
    onSearch() {
        this.searchObj = {}
        if (this.searchText) {
            this.searchObj['search'] = this.searchText?.trim();
        }
        if (this.mainCategoryId) {
            this.searchObj['main_category_id'] = +this.mainCategoryId;
        }
        if (this.selectedCategory) {
            this.searchObj['category_id'] = +this.selectedCategory;
        }
        if (this.mainProduct) {
            this.searchObj['mainProductIds'] = [this.mainProduct];
        }

        let selectedAttributeData = [];
        this.mainAttributeData.forEach(element => {
            if (element.selectedOption && element.selectedOption.length > 0) {
                selectedAttributeData.push({
                    attribute_id: element.attribute_id,
                    optionIds: element.selectedOption
                })
            }
        });
        if (selectedAttributeData && selectedAttributeData.length > 0) {
            this.searchObj['attributeIds'] = selectedAttributeData;
        }
        let param = {}
        if (Object.keys(this.searchObj).length > 0) {
            param = {
                ...param,
                ...this.searchObj
            }
        }

        this.commonService.setEvent("filterSet" + this.step, {
            step: this.step,
            param: param
        });
        this.commonService.localStorageSet('filterData', JSON.stringify(param));
    }

    getMasterCategoriesList() {
        this.masterCategoryList = [];
        let param = {
            "dropdown": 'true'
        }
        let enterprise_user_id = this.commonService.localStorageGet("enterprise_user_id") ? this.commonService.localStorageGet("enterprise_user_id") : null;
        if(enterprise_user_id){
            param['enterprise_user_id'] = +enterprise_user_id
        }
        this.subscription.add(
            this.dataService.get(URLS.sellerCategories, param).subscribe(data => {
                if (data['code'] == 200 && data['data'].length > 0) {
                    let tempMasterData = data['data'];
                    const uniqueArray = Object.values(tempMasterData.reduce((acc, item) => {
                        acc[item.master_category_id] = item;
                        return acc;
                    }, {}));
                    this.masterCategoryList = uniqueArray
                }
                else {
                    this.masterCategoryList = [];
                }
            }, error => {
                this.masterCategoryList = [];
            })
        )
    }

    getCategoriesList(masterId) {
        this.categoryList = [];
        let param = {
            "dropdown": 'true'
        }
        this.subscription.add(
            this.dataService.get(URLS.sellerCategories, param).subscribe(data => {
                if (data['code'] == 200 && data['data'].length > 0) {
                    let temCategoryData = data['data'];
                    const filteredArray = temCategoryData.filter(item => item.master_category_id === masterId);
                    this.categoryList = filteredArray;
                }
                else {
                    this.masterCategoryList = [];
                }
            }, error => {
                this.masterCategoryList = [];
            })
        )

    }


    closeFilter() {
        if (this.step == 1) {
            this.searchObj = {}
            this.searchText = null;
            this.categoryId = null;
            this.mainCategoryId = null;
            this.selectedCategory = null;
            this.mainProductData = [];
            this.mainAttributeData = [];
            this.commonService.localStorageRemove('filterData');
            this.commonService.setEvent("clearFilterSet" + this.step, null);

        } else {
            this.mainAttributeData = [];
            this.getMainAttributeMaster();
        }

    }

    masterCategorySelection(event) {
        this.searchObj = {}
        this.searchText = null;
        this.selectedCategory = null;
        this.categoryId = null;
        this.categoryList = [];
        this.mainProduct = null;
        this.mainCategoryId = event.master_category_id;
        this.isAnyAttributeSelected = false;
        this.getCategoriesList(this.mainCategoryId);
        this.commonService.setEvent("clearFilterSet" + this.step, null);

    }
    categorySelection(event) {
        this.searchObj = {}
        this.searchText = null;
        this.selectedCategory = event.category_id;
        this.mainProductData = [];
        this.mainAttributeData = [];
        this.mainProduct = null;
        this.isAnyAttributeSelected = false;
        this.commonService.setEvent("clearFilterSet" + this.step, null);
        this.isMasterLoading = true;
        Promise.all([
            this.getMainProductMaster(),
            this.getMainAttributeMaster()]).
            then(data => {
                setTimeout(() => {
                    this.isMasterLoading = false;
                }, 200)
            }, error => {
                setTimeout(() => {
                    this.isMasterLoading = false;
                }, 200)
            });

    }

    getMainProductMaster() {
        this.isLoading = true;
        this.subscription.add(
            this.dataService.get(URLS.getMainProduct + "/" + this.categoryId).subscribe(data => {
                this.isLoading = false;
                if (data['code'] == 200 && data['data']) {
                    this.mainProductData = data['data'];
                }
                else {
                    this.toasterService.errorToast(data['message']);
                    this.isLoading = false;
                }
            }, error => {
                this.isLoading = false;
                this.toasterService.errorToast(error['message']);

            })
        )
    }

    getMainAttributeMaster(selectedAttribute?) {
        this.isLoading = true;

        let params = {}
        if (this.mainProduct) {
            params["mainProductIds"] = [this.mainProduct]
        }
        this.subscription.add(
            this.dataService.patch(URLS.getMainAttributes + "/" + this.categoryId, params).subscribe(data => {
                this.isLoading = false;
                if (data['code'] == 200 && data['data']) {
                    this.mainAttributeData = data['data'];
                    if (selectedAttribute) {
                        this.mainAttributeData.forEach(element => {
                            selectedAttribute.forEach(selected => {
                                if (selected.attribute_id == element.attribute_id) {
                                    element.selectedOption = selected.optionIds;
                                }
                            });
                        });


                        this.attributeSelection();
                    }
                }
                else {
                    this.toasterService.errorToast(data['message']);
                    this.isLoading = false;
                }
            }, error => {
                this.isLoading = false;
                this.toasterService.errorToast(error['message']);

            })
        )
    }


    filetrShowHandle() {
        this.isFilterShow = !this.isFilterShow
    }

    mainProductSelection(event) {
        this.mainAttributeData.forEach(element => {
            element.selectedOption = null;
        });
        this.isAnyAttributeSelected = false;
        this.isMasterLoading = true;
        Promise.all([
            this.getMainAttributeMaster()]).
            then(data => {
                setTimeout(() => {
                    this.isMasterLoading = false;
                }, 200)
            }, error => {
                setTimeout(() => {
                    this.isMasterLoading = false;
                }, 200)
            });
    }


    attributeSelection(event?) {
        let isAttributeSelected: boolean;
        this.mainAttributeData.forEach(element => {
            if (element.selectedOption && element.selectedOption.length > 0) {
                isAttributeSelected = true;
            }
        });

        if (isAttributeSelected == true) {
            this.isAnyAttributeSelected = true;
        }
        else {
            this.isAnyAttributeSelected = false;
        }
    }
}

