<div class="categories pb-15">
    <div class="title fs-16 fw-600 pb-10 categories-title">
        {{heading}}
    </div>

    <p class="fs-16 fw-500 mb-15 pb-10 subHeading">{{subHeading}}<span
            [ngClass]="isCategoryRequired && categoryNotChecked ? 'error-msg' :''" *ngIf="isCategoryRequired"> *</span>
    </p>

    <ion-row *ngIf="isLoading">
        <div class="no-data h-100">
            <app-loader></app-loader>
        </div>
    </ion-row>
    <ion-row *ngIf="!isLoading && getPlatformService.isDesktop">
        <ng-container *ngIf="categoriesList.length > 0">
            <div *ngFor="let item of categoriesList" class="cont-checkbox mb-15 mr-25">
                <div class="block pb-5">
                    <ion-item lines="none">
                        <ion-checkbox [disabled]="isCategoryDisable"
                            (ionChange)="setCategoriesId($event,item.master_category_id, item.sub_categories)"
                            [checked]="item.checked"></ion-checkbox>
                        <ion-label [ngClass]="item.checked ? 'selected-label' : ''"><img [src]="item.image_url"
                                alt="Image" />
                        </ion-label>
                    </ion-item>
                </div>
                <div class="info fs-14 fw-500" [title]="item.name">{{item.name}}</div>
            </div>
        </ng-container>
        <ng-template *ngIf="noData">
            <div class="no-data h-100">
                No Categories Found
            </div>
        </ng-template>
    </ion-row>
    <div *ngIf="!isLoading && !getPlatformService.isDesktop" class="categories-container">
        <ng-container *ngIf="categoriesList.length > 0">
            <div *ngFor="let item of categoriesList" class="categories-checkbox mb-15 mr-25">
                <div class="pb-5 category-block">
                    <ion-item lines="none">
                        <ion-checkbox (ionChange)="setCategoriesId($event,item.master_category_id, item.sub_categories)"
                            [checked]="item.checked"></ion-checkbox>
                        <ion-label [ngClass]="item.checked ? 'selected-label' : ''"><img [src]="item.image_url"
                                alt="Image" /></ion-label>
                    </ion-item>
                    <div class=" fs-14 fw-500" [title]="item.name">{{item.name}}</div>
                </div>
            </div>
        </ng-container>
        <ng-template *ngIf="noData">
            <div class="no-data h-100">
                No Categories Found
            </div>
        </ng-template>
    </div>
    <ion-row class="error-container">
        <ng-container>
            <ion-text class="" color="danger" *ngIf="isCategoryRequired && categoryNotChecked">
                Please select category
            </ion-text>
        </ng-container>
    </ion-row>
</div>