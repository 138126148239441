<div (click)="back()" class="main-content p-15">
    <img alt="Left Arrow" src="../../../assets/icon/arrow-left.svg">
    <img alt="Category image" class="master-cat-image"
        [src]="categoryData && categoryData.master_category_image_url?categoryData.master_category_image_url:''">
    <div class="fs-22 fw-600 pl-10"> {{ categoryData?.name }} </div>
</div>
<ion-content *ngIf="categoryData">
    <!-- <div class="categories mt-25 pb-15">
        <div class="scroll-container" size="4">
            <div class="block mb-20" *ngFor="let item of categoriesData;">
                <div (click)="categorieClick(item)">
                    <input type="radio" [id]="item.master_category_name" />
                    <label [for]="item.master_category_name"><img class="label-image" alt="Image"
                            [src]="item.image_url" /></label>
                    <div class="info fs-14 fw-500">{{item.master_category_name}}</div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="filter-accordian-container">
        <ion-accordion-group multiple="true" class="filter-accordion">
            <ion-accordion>
                <ion-item slot="header" color="light">
                    <ion-label>Categories</ion-label>
                </ion-item>
                <div class="categories pb-10" slot="content">
                    <div class="scroll-container" size="4">
                        <div class="block" *ngFor="let item of categoriesData;">
                            <div class="mt-10 category-container" (click)="categorieClick(item)"
                                [class.selected-category]="selectedCategory === item">
                                <div class="label-image">
                                    <input type="radio" [id]="item.master_category_name" />
                                    <label [for]="item.master_category_name"><img class="label-image" alt="Image"
                                            [src]="(item && item.image_url) ? item.image_url : '../../../assets/product/product7.svg'" />
                                    </label>
                                </div>
                                <div>
                                    <div class="info fs-14 fw-500">
                                        {{item.master_category_name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ion-accordion>
        </ion-accordion-group>
    </div>



    <ng-container *ngIf="categoryData.sub_categories && categoryData.sub_categories.length">
        <div class="sub-menu" *ngFor="let data of categoryData.sub_categories">
            <div class="title fs-16 fw-600" (click)="toggleMenu(data); getProductList(data.category_id)">
                {{ data.name }}
                <div class="triangle-icon">
                    <img *ngIf="!data.isMenuVisible" src="/assets/icon/dropdown-bottom.svg" alt="triangle-icon"
                        height="100%" width="100%">
                    <img *ngIf="data.isMenuVisible" class="rotate-180" src="/assets/icon/dropdown-blue-bottom.svg"
                        alt="triangle-icon" height="100%" width="100%">
                </div>
            </div>
            <ion-list *ngIf="data.isMenuVisible && productsList[data.category_id]?.length">
                <ion-item lines='none' *ngFor="let menu of productsList[data.category_id]">
                    <div class="product-list-container pb-10">
                        <div class="product-image">
                            <img class="prod-img" [src]="menu.img ? menu.img : '/assets/product/product7.svg'" alt="">
                        </div>
                        <a class=" fs-16 fw-600" (click)="openItem(data,menu)">
                            {{ menu.name }}
                        </a>
                    </div>
                </ion-item>
            </ion-list>
            <div class="no-product-found" *ngIf="data.isMenuVisible && !productsList[data.category_id]?.length">
                No product found in this category.
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!categoryData.sub_categories || categoryData.sub_categories.length === 0">
        <div class="no-record-found">
            No record
        </div>
    </ng-container>
</ion-content>