<div class="home-page app-header">
	<app-header></app-header>
</div>

<ion-content *ngIf="!isLoading">
	<ion-grid class="page-scroll-top">
		<ion-row itemscope [itemtype]="webUrl">
			<ion-col sizeLg="6" sizeSm="12">
				<div class="request-container">
					<div class="heading">
						Drop a question
					</div>
					<form [formGroup]="contactRequestForm">
						<app-personal-detail [fieldName]="fieldData" [formGroup]="$any(contactRequestForm)"
							[placeholder]="'Email'" [isHideVerifiedBtn]="true"></app-personal-detail>
						<div class="description-box">
							<ion-label>
								What's on your mind? <span
									[ngClass]="contactRequestForm.get('description').hasError('required') && contactRequestForm.get('description').dirty ? 'error-msg' : ''">*</span>
							</ion-label>
							<ion-textarea formControlName="description" rows="7"></ion-textarea>
							<ng-container *ngFor="let validation of validation_messages.description">
								<ng-container
									*ngIf="contactRequestForm.get('description').hasError(validation.type) && contactRequestForm.get('description').dirty">
									<ion-text color="danger" class="error-msg">
										{{validation.message }}
									</ion-text>
								</ng-container>
							</ng-container>
						</div>
						<div>
							<div class="form-button mt-30">
								<button [disabled]="isDisabled" type="primary" (click)="submit()"
									class="mr-20 w-147 submit-btn">
									<ion-spinner class="spinner-btn " name="lines-sharp-small"
										*ngIf="isLoading"></ion-spinner>
									Submit
								</button>
							</div>
						</div>
					</form>
				</div>
			</ion-col>
			<ion-col sizeLg="6" sizeSm="12">
				<div class="right-side">
					<div class="heading">
						Contact information
					</div>
					<p class="info" itemprop="description">
						We are dedicated to providing you with excellent customer service at all times. If you have any
						questions or concerns,
						please do not hesitate to contact us using the information provided below. Your feedback is
						valuable and we are
						committed to continuously improving our services to meet your needs.
					</p>
					<div class="item-block">
						<div class="item">
							<img src="assets/icon/map.svg" alt="Map Icon" class="icon">
							<span class="detail" itemprop="address">A-4, 4th Floor, Kalp Business Center, City Light
								Road, Surat - 395007
							</span>
						</div>
						<div class="item">
							<img src="assets/icon/phone-call.svg" alt="Phone Icon" class="icon">
							<span class="detail" itemprop="tel"><a href="tel:+91-848-8882448"
									class="pointer border-none">+91-848-8882448</a>
							</span>
						</div>
						<div class="item mail">
							<img src="assets/icon/envelope.svg" alt="Mail Icon" class="icon">
							<a itemprop="email" class="detail pointer"
								href="mailto:info@conkart.com">info@conkart.com</a>
						</div>
					</div>
				</div>
			</ion-col>
		</ion-row>
	</ion-grid>
	<div class="wrapper-block home-page">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
</ion-content>

<ion-content *ngIf="isLoading">
	<app-loader></app-loader>
</ion-content>