<div class="form-container mt-m20 company-form">
    <div class="title fs-16 pl-5 fw-600 pb-15">
        Company details
    </div>
    <div [formGroup]="formGroup">
        <ion-row class="mb-5">
            <ion-col>
                <ion-label>Enterprise/Company name *</ion-label>
                <ion-input placeholder="Enter Enterprise/Company Name" capitalizeFirst formControlName="company_name"
                    (keypress)="restrictSpecialChar($event)" maxlength="50"></ion-input>
                <ng-container *ngFor="let validation of validation_messages.company_name">
                    <ng-container
                        *ngIf="formGroup.get('company_name').hasError(validation.type) && formGroup.get('company_name').dirty">
                        <ion-text color="danger" class="error-msg">
                            {{validation.message }}
                        </ion-text>
                    </ng-container>
                </ng-container>
            </ion-col>
        </ion-row>
        <ion-row class="mb-5">
            <ion-col>
                <ion-label>Email *</ion-label>
                <ion-input placeholder="Enter Email" class="pt-20" type="email" formControlName="email"
                    (keydown.space)="$event.preventDefault();"></ion-input>
                <ng-container *ngFor="let validation of validation_messages.email">
                    <ng-container
                        *ngIf="formGroup.get('email').hasError(validation.type) && formGroup.get('email').dirty">
                        <ion-text color="danger" class="error-msg">
                            {{validation.message}}
                        </ion-text>
                    </ng-container>
                </ng-container>
            </ion-col>
        </ion-row>
        <ion-row class="mb-5">
            <ion-col>
                <ion-label>Mobile Number *</ion-label>
                <ion-input type="tel" placeholder="Enter Mobile Number" (keypress)="phoneClick($event)"
                    inputmode="numeric" formControlName="phone_no" maxlength="10">
                    <label for="" class="country-code mr-10 pl-15">
                        +91 <img class="ml-5" src="./assets/svg/arrowdown.svg" alt="Image">
                    </label>
                </ion-input>
                <ng-container *ngFor="let validation of validation_messages.phone_no">
                    <ng-container
                        *ngIf="formGroup.get('phone_no').hasError(validation.type) && formGroup.get('phone_no').dirty">
                        <ion-text color="danger" class="error-msg">
                            {{validation.message }}
                        </ion-text>
                    </ng-container>
                </ng-container>
            </ion-col>
        </ion-row>
    </div>
</div>