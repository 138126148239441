<div class="enterprise-container autoComplete" [ngClass]="maxHeight ? 'autoBox' : 'mb-20'">
    <div class="title fs-16 fw-600 pb-10 pl-5 pt-20">Company details
    </div>
    <div [formGroup]="formGroup">
        <ion-row>
            <ion-col class="mb-10">
                <ion-label> Enterprise/Company name <span
                        [ngClass]="formGroup.get('company_name').hasError('required') && formGroup.get('company_name').dirty ? 'error-msg' : ''">*</span></ion-label>
                <ion-input placeholder="Enter Enterprise/Company Name" capitalizeFirst
                    formControlName="company_name"></ion-input>
                <ng-container *ngFor="let companyVal of company_validation_messages.company_name">
                    <ng-container
                        *ngIf="formGroup.get('company_name').hasError(companyVal.type) && formGroup.get('company_name').dirty">
                        <ion-text color="danger" class="error-msg">
                            {{companyVal.message }}
                        </ion-text>
                    </ng-container>
                </ng-container>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col class="mb-10">
                <div class="mapOption">
                    <ion-label>HQ office Location <span
                            [ngClass]="formGroup.get('hq_location').hasError('required') && formGroup.get('hq_location').dirty ? 'error-msg' : ''">*</span></ion-label>
                    <ion-input type="text" placeholder="Enter HQ Office Location" #searchInput
                        (keypress)="addressChange()" (ionChange)="getPlaceAutocomplete($event)" class="custom-input"
                        formControlName="hq_location" [title]="addressTitle">
                        <img alt="Map Icon" class="input-icon" src="/assets/icon/map.svg">
                    </ion-input>
                    <ion-text color="danger" class="absolute" *ngIf="commonservice.isAutoSelected"> Please add valid
                        address
                    </ion-text>

                    <ion-list class="autoAddress" *ngIf="autocompleteItems.length > 0" class="autocomplete">
                        <div class="items" *ngFor="let item of autocompleteItems"
                            (click)="selectSearchResult(item,formGroup.controls.hq_location)">{{
                            item.description }}</div>
                    </ion-list>
                    <ng-container *ngFor="let companyVal of company_validation_messages.hq_location">
                        <ng-container
                            *ngIf="formGroup.get('hq_location').hasError(companyVal.type) && formGroup.get('hq_location').dirty && !commonservice.isAutoSelected">
                            <ion-text color="danger" class="error-msg">
                                {{companyVal.message}}
                            </ion-text>
                        </ng-container>
                    </ng-container>
                </div>
            </ion-col>
        </ion-row>
        <ng-container *ngIf="companyAddress">
            <ion-row>
                <ion-col class="mb-10" size="12">
                    <ion-label>Country <span
                            [ngClass]="formGroup.get('country').hasError('required') && formGroup.get('country').dirty ? 'error-msg' : ''">*</span></ion-label>
                    <ng-select placeholder="Select Country" (change)="countryChange($event)" class="select-input mt-5"
                        [items]="countryList" bindLabel="country" bindValue="country_id" formControlName="country"
                        [clearable]="false" [searchable]="false">
                    </ng-select>
                    <ng-container *ngFor="let companyVal of company_validation_messages.country">
                        <ng-container
                            *ngIf="formGroup.get('country').hasError(companyVal.type) && formGroup.get('country').dirty">
                            <ion-text color="danger" class="error-msg">
                                {{companyVal.message}}
                            </ion-text>
                        </ng-container>
                    </ng-container>
                </ion-col>
                <ion-col class="mb-10" size="12">
                    <ion-label>State <span
                            [ngClass]="formGroup.get('state').hasError('required') && formGroup.get('state').dirty ? 'error-msg' : ''">*</span></ion-label>
                    <ng-select placeholder="Select State" [loading]="isStateLoading" (change)="stateChange($event)"
                        class="select-input mt-5" [items]="stateList" bindLabel="state" bindValue="state_id"
                        formControlName="state" [clearable]="false" [searchable]="false">
                    </ng-select>
                    <ng-container *ngFor="let companyVal of company_validation_messages.state">
                        <ng-container
                            *ngIf="formGroup.get('state').hasError(companyVal.type) && formGroup.get('state').dirty">
                            <ion-text color="danger" class="error-msg">
                                {{companyVal.message}}
                            </ion-text>
                        </ng-container>
                    </ng-container>
                </ion-col>
                <ion-col class="mb-10" size="12">
                    <ion-label class="mb-5">District <span
                            [ngClass]="formGroup.get('district').hasError('required') && formGroup.get('district').dirty ? 'error-msg' : ''">*</span></ion-label>
                    <ng-select placeholder="Select District" [loading]="isDistrictLoading" class="select-input mt-5"
                        [items]="districtList" bindLabel="district" bindValue="district_id" formControlName="district"
                        (change)="districtChange($event)" [clearable]="false" [searchable]="false">
                    </ng-select>
                    <ng-container *ngFor="let companyVal of company_validation_messages.district">
                        <ng-container
                            *ngIf="formGroup.get('district').hasError(companyVal.type) && formGroup.get('district').dirty">
                            <ion-text color="danger" class="error-msg">
                                {{companyVal.message}}
                            </ion-text>
                        </ng-container>
                    </ng-container>
                </ion-col>
                <ion-col class="mb-10" size="12">
                    <ion-label class="mb-5">Taluka <span
                            [ngClass]="formGroup.get('taluka').hasError('required') && formGroup.get('taluka').dirty ? 'error-msg' : ''">*</span></ion-label>
                    <ng-select placeholder="Select Taluka" [loading]="isCityLoading" class="select-input mt-5"
                        [items]="talukaList" bindLabel="taluka" bindValue="taluka_id" formControlName="taluka"
                        [clearOnBackspace]="talukaList && talukaList.length ? false : true"
                        [clearable]="talukaList && talukaList.length ? false : true" (change)="talukaChange($event)"
                        [addTag]="talukaList && talukaList.length ? false : true" [addTagText]="'Select'"
                        [editableSearchTerm]="talukaList && talukaList.length ? false : true"
                        [searchable]="talukaList && talukaList.length ? false : true">
                    </ng-select>
                    <ng-container *ngFor="let companyVal of company_validation_messages.taluka">
                        <ng-container
                            *ngIf="formGroup.get('taluka').hasError(companyVal.type) && formGroup.get('taluka').dirty">
                            <ion-text color="danger" class="error-msg">
                                {{companyVal.message}}
                            </ion-text>
                        </ng-container>
                    </ng-container>
                </ion-col>
                <ion-col class="mb-10" size="12">
                    <ion-label>Zipcode <span
                            [ngClass]="formGroup.get('zipcode').hasError('required') && formGroup.get('zipcode').dirty ? 'error-msg' : ''">*</span></ion-label>
                    <ion-input capitalizeFirst formControlName="zipcode" placeholder="Enter Zipcode"
                        (keypress)="numberOnlyValidation($event)" minlength="6" maxlength="6"></ion-input>
                    <ng-container *ngFor="let companyVal of company_validation_messages.zipcode">
                        <ng-container
                            *ngIf="formGroup.get('zipcode').hasError(companyVal.type) && formGroup.get('zipcode').dirty">
                            <ion-text color="danger" class="error-msg">
                                {{companyVal.message}}
                            </ion-text>
                        </ng-container>
                    </ng-container>
                </ion-col>
            </ion-row>
        </ng-container>
    </div>
</div>