import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ToasterService } from '../../services/toaster.service';

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {


	public subscription = new Subscription();
	isLoading = false;


	widthConfig = ['300px', '150px', '150px', '40px'];
	column_settings = [
		{ primaryKey: 'notification_title', header: 'Title' },
		{ primaryKey: 'notification_type', header: 'Category' },
		{ primaryKey: 'created_at', header: 'Date' },
		{ primaryKey: '', header: 'Action', action: true }
	];

	listData = [];
	itemsPerPage = this.commonService.list;
	currentPage = 1;
	enableNext: boolean = false;
	totalCount: any;
	totalUnreadCount: any;
	paginationVal = 10;

	constructor(
		private formBuilder: FormBuilder,
		public commonService: CommonService,
		private toasterService: ToasterService,
		private dataService: ApiService,
		public getPlatformService: GetPlatformService,
		public datePipe: DatePipe,
		public router: Router
	) {
		this.currentPage = this.commonService.localStorageGet('pageIndexNotification');
		if (!this.currentPage) {
			this.currentPage = 1
		}
	}



	ngOnInit() {
		this.commonService.scrollToTop();
		this.getListData();
	}

	getListData() {
		let param = {}
		param['page'] = this.currentPage;
		param['limit'] = this.itemsPerPage;
		param['user_type'] = this.commonService.userData['isSeller'] ? 'SELLER' : 'CUSTOMER';
		param = {
			...param,
		}

		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.notificationList, param).subscribe(data => {
				this.isLoading = false;
				if (data['code'] == 200) {
					let result = data['data'];
					this.commonService.scrollToTop();

					this.totalCount = result.total_count;
					this.totalUnreadCount = result.total_unread_count;

					this.commonService.localStorageSet('unreadNotificationCount', result.total_unread_count.toString());

					this.commonService.notificationCount = result.total_unread_count;

					this.listData = data['data']['userNotificationList'];
					this.listData.forEach((element, index) => {
						element['notification_type'] = element['notification_type'];
						element['id'] = element['user_notification_id'];
						element['index'] = index + 1;
						element['notification_title'] = element['notification_text'];
						element['is_read'] = element['is_read'];
						element['reference_id'] = element['reference_id'];
						element['is_read'] = element['is_read'];
						element['created_at'] = element['created_at'];
					});
				}


				else {
					this.listData = [];
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.listData = [];
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);

			})
		)

	}


	dateTimeShow(date) {
		let currentDate = moment();
		let d = moment.duration(currentDate.diff(date));
		if (d['_data']['days'] < 2) {
			return moment(date).fromNow();
		}
		else {
			return moment(date).format('DD-MM-yyyy')
		}
	}

	notificationRedirect(dataItem) {
		this.commonService.localStorageSet('pageIndexNotification', this.currentPage);
		if (dataItem.is_read != true) {
			let param = {
				"user_notification_id": dataItem.id,
				"is_read": true
			}

			this.isLoading = true;
			this.subscription.add(
				this.dataService.put(URLS.notificationUpdate, param).subscribe(data => {
					this.isLoading = false;
					if (data['code'] == 200) {
						let result = data['data'];
						this.commonService.localStorageSet('unreadNotificationCount', this.totalUnreadCount > 0 ? (this.totalUnreadCount - 1).toString() : '0');

						this.commonService.notificationCount = result.total_unread_count;
						dataItem.is_read = true;
						this.pageRedirect(dataItem);
					}
					else {
						this.toasterService.errorToast(data['message']);
					}
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);

				})
			)
		}
		else {
			this.pageRedirect(dataItem);
		}

	}

	pageRedirect(dataItem) {
		// ['Order', 'Quote', 'Payment', 'Credit', 'General', 'Blog', 'News Latter', 'Important Notice']
		if (dataItem.notification_type == "News Latter") {
			this.router.navigateByUrl('/newsletter/' + dataItem.reference_id);
		}
		if (dataItem.notification_type == "Order") {
			this.commonService.userData['isSeller'] ?
				this.router.navigateByUrl('/home/account-details/manage-orders/order-details/' + dataItem.reference_id) :
				this.router.navigateByUrl('/home/account-details/my-orders/my-order-details/' + dataItem.reference_id);
		}
		if (dataItem.notification_type == "Quote") {
			this.commonService.localStorageSet("status", "REQUESTED");

			this.commonService.userData['isSeller'] ?
				this.router.navigateByUrl('/home/account-details/manage-quotes/' + dataItem.reference_id) :
				this.router.navigateByUrl('/home/account-details/my-quote-requests/' + dataItem.reference_id);
		}
		if (dataItem.notification_type == "Blog") {
			this.router.navigateByUrl('/blog/' + dataItem.reference_id);
		}
		if (dataItem.notification_type == "Payment") {
			this.commonService.userData['isSeller'] ?
				this.router.navigateByUrl('/home/account-details/manage-orders') :
				this.router.navigateByUrl('/home/account-details/my-orders');
		}
		if (dataItem.notification_type == "General" || dataItem.notification_type == "Important Notice"
			|| dataItem.notification_type == "Credit") {
			this.getListData();
		}
	}

	changeListItem() {
		this.currentPage = 1;
		this.itemsPerPage = this.paginationVal
		this.getListData();
	}

	onChange(event): void {
		this.currentPage = event;
		this.getListData()
	}

}
