import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/services/api-data.service';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { ModalService } from '../../services/modal.service';

enum OrderStatus {
	ORDER_INITIATED = 'ORDER INITIATED',
	CONFIRMED = 'ORDER CONFIRMED',
	DISPATCHED = 'DISPATCHED',
	DELIVERED = 'DELIVERED',
	COMPLETED = 'COMPLETED',
	REJECTED = 'REJECTED',
}

@Component({
	selector: 'app-order-tracking-modal',
	templateUrl: './order-tracking-modal.component.html',
	styleUrls: ['./order-tracking-modal.component.scss'],
})
export class OrderTrackingModalComponent implements OnInit {

	@Input() id;
	@Input() type;
	@Input() is_credit_order;
	public subscription = new Subscription();
	isLoading: boolean = false;
	orderDetails;


	trackingData: any = {}

	constructor(
		private modalService: ModalService,
		public getPlatformService: GetPlatformService,
		private toasterService: ToasterService,
		private dataService: ApiService,
	) { }

	ngOnInit() {
		if (this.type == 'customer') {
			this.getOrderHistory();
		}
		else {
			this.getOrderHistory();
		}
	}

	getOrderHistory() {
		let param = {
			"order_item_id": this.id
		};
		this.subscription.add(
			this.dataService.get(URLS.customerOrderHistory, param).subscribe(data => {
				this.isLoading = false;
				if (data['code'] == 200) {
					this.trackingData.productData = [];
					let orderDetails = {};
					orderDetails['product_name'] = data['data']['product_name'];
					orderDetails['sku'] = data['data']['sku'];
					orderDetails['image_url'] = data['data']['image_url'];
					orderDetails['product_attributes'] = data['data']['product_attributes'];
					this.trackingData.productData.push(orderDetails);
					if (data['data']['history']) {
						let history = data['data']['history'];
						this.trackingData.productData[0]['tracking_status'] = [];
						let orderDate = {
							'date': history.order_date,
							'status': 1,
							'complete': history.order_date ? true : false,
							'name': "Order Date"
						}
						let orderCredit
						if (this.is_credit_order) {
							orderCredit = {
								'date': history.order_date,
								'status': 2,
								'complete': this.is_credit_order,
								'name': "Credit Order"
							}
						}
						let orderConfirm = {
							'date': history.order_confirmed,
							'status': 2,
							'complete': history.order_confirmed ? true : false,
							'name': "Order Confirmed"
						}
						let OrderDispatched = {
							'date': history.order_dispatched,
							'status': 3,
							'complete': history.order_dispatched ? true : false,
							'name': "Order Dispatched"
						}
						let delivered = {
							'date': history.delivery_date,
							'status': 4,
							'complete': history.delivery_date ? true : false,
							'name': "Delivered On"
						}
						let qcApproval = {
							'date': data['data']['order_status'] == OrderStatus.COMPLETED ? history.qc_aprroved_date : history.qc_rejected_date,
							'status': 4,
							'complete': history.qc_rejected_date || history.qc_aprroved_date ? true : false,
							'name': data['data']['order_status'] == OrderStatus.COMPLETED ? 'Quality Check Approved' : data['data']['order_status'] == OrderStatus.REJECTED ? "Quality Check Rejected" : ''
						}
						this.trackingData.productData[0]['tracking_status'].push(orderDate);
						if (!this.is_credit_order) {
							this.trackingData.productData[0]['tracking_status'].push(orderConfirm);
						} else {
							this.trackingData.productData[0]['tracking_status'].push(orderCredit);
						}
						this.trackingData.productData[0]['tracking_status'].push(OrderDispatched);
						if (history.delivery_date) this.trackingData.productData[0]['tracking_status'].push(delivered);
						if (data['data']['order_status'] == OrderStatus.COMPLETED || data['data']['order_status'] == OrderStatus.REJECTED) this.trackingData.productData[0]['tracking_status'].push(qcApproval);

					}
				}
				else {
					this.toasterService.errorToast(data['message'], 'top');
				}
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	close(isDraft) {
		this.modalService.dismissModal({ isDraft });
	}

}
