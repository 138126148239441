<div class="add-company-address p-50 autoComplete">
    <h2 class="mt-0 mb-20 fw-600">{{addressId ? 'Edit Address':'Add Address'}}</h2>

    <div class="address-container">
        <form [formGroup]="addEditAddressForm">
            <div class="address-info">
                <ion-row class="mb-5">
                    <ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
                        <ion-label>Company Name <span
                                [ngClass]="isSubmitted && addEditAddressForm.get('company_name').errors?.required ? 'error-msg' : ''">*</span></ion-label>
                        <ion-input capitalizeFirst placeholder="Enter Company Name"
                            formControlName="company_name"></ion-input>
                        <ng-container *ngFor="let validation of validation_messages.company_name">
                            <ng-container
                                *ngIf="addEditAddressForm.get('company_name').hasError(validation.type) && addEditAddressForm.get('company_name').dirty">
                                <ion-text color="danger" class="error-msg">
                                    {{validation.message }}
                                </ion-text>
                            </ng-container>
                        </ng-container>

                    </ion-col>
                    <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
                        <ion-label>Location Tag <span
                                [ngClass]="isSubmitted && addEditAddressForm.get('location_tag').errors?.required ? 'error-msg' : ''">*</span></ion-label>
                        <ng-select class="select-input mt-5" placeholder="Select Location Tag"
                            (change)="locationTagChange($event)" [items]="locationTag" bindLabel="name" bindValue="id"
                            formControlName="location_tag" [clearable]="false">
                        </ng-select>

                        <ng-container *ngFor="let validation of validation_messages.location_tag">
                            <ng-container
                                *ngIf="addEditAddressForm.get('location_tag').hasError(validation.type) && addEditAddressForm.get('location_tag').dirty">
                                <ion-text color="danger" class="error-msg">
                                    {{validation.message }}
                                </ion-text>
                            </ng-container>
                        </ng-container>

                    </ion-col>

                </ion-row>
                <ion-row class="mb-5">
                    <ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
                        <ion-label>Name Your <span>{{locationName}} <span
                                    [ngClass]="isSubmitted && addEditAddressForm.get('warehouse_name').errors?.required ? 'error-msg' : ''">*</span></span></ion-label>

                        <ion-input capitalizeFirst placeholder="Enter {{locationName}}"
                            formControlName="warehouse_name"></ion-input>

                        <ng-container *ngFor="let validation of validation_messages.warehouse_name">
                            <ng-container
                                *ngIf="addEditAddressForm.get('warehouse_name').hasError(validation.type) && addEditAddressForm.get('warehouse_name').dirty">
                                <ion-text color="danger" class="error-msg">
                                    {{validation.message }}
                                </ion-text>
                            </ng-container>
                        </ng-container>

                    </ion-col>
                    <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
                        <div class="mapOption" [ngClass]="isAutoSelected ? 'mb-10' : ''">
                            <ion-label>Address <span
                                    [ngClass]="isSubmitted && addEditAddressForm.get('address').errors?.required ? 'error-msg' : ''">*</span></ion-label>
                            <ion-input (keypress)="addressChange()" placeholder="Enter Address"
                                (ionChange)="getPlaceAutocomplete($event)" capitalizeFirst
                                formControlName="address"></ion-input>
                            <ion-text color="danger" class="absolute" *ngIf="isAutoSelected">
                                Please add
                                valid address </ion-text>
                            <ion-list class="autoAddress" *ngIf="autocompleteItems.length > 0" class="autocomplete">
                                <div class="items" *ngFor="let item of autocompleteItems"
                                    (click)="selectSearchResult(item,addEditAddressForm.controls.address)">{{
                                    item.description }}</div>
                            </ion-list>
                            <ng-container *ngFor="let validation of validation_messages.address">
                                <ng-container
                                    *ngIf="addEditAddressForm.get('address').hasError(validation.type) && addEditAddressForm.get('address').dirty && !isAutoSelected">
                                    <ion-text color="danger" class="error-msg">
                                        {{validation.message }}
                                    </ion-text>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ion-col>
                </ion-row>
                <ion-row class="mb-5">
                    <ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
                        <ion-label>Country <span
                                [ngClass]="isSubmitted && addEditAddressForm.get('country').errors?.required ? 'error-msg' : ''">*</span></ion-label>
                        <ng-select (change)="countryChange($event)" placeholder="Select Country"
                            class="select-input mt-5" [items]="countryList" bindLabel="country" bindValue="country_id"
                            formControlName="country" [clearable]="false">
                        </ng-select>

                        <ng-container *ngFor="let validation of validation_messages.country">
                            <ng-container
                                *ngIf="addEditAddressForm.get('country').hasError(validation.type) && addEditAddressForm.get('country').dirty">
                                <ion-text color="danger" class="error-msg">
                                    {{validation.message }}
                                </ion-text>
                            </ng-container>
                        </ng-container>

                    </ion-col>
                    <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
                        <ion-label>State <span
                                [ngClass]="isSubmitted && addEditAddressForm.get('state').errors?.required ? 'error-msg' : ''">*</span></ion-label>
                        <ng-select placeholder="Select State" [loading]="isStateLoading" (change)="stateChange($event)"
                            class="select-input mt-5" [items]="stateList" bindLabel="state" bindValue="state_id"
                            formControlName="state" [clearable]="false">
                        </ng-select>

                        <ng-container *ngFor="let validation of validation_messages.state">
                            <ng-container
                                *ngIf="addEditAddressForm.get('state').hasError(validation.type) && addEditAddressForm.get('state').dirty">
                                <ion-text color="danger" class="error-msg">
                                    {{validation.message }}
                                </ion-text>
                            </ng-container>
                        </ng-container>

                    </ion-col>
                </ion-row>
                <ion-row class="mb-5">
                    <ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
                        <ion-label class="mb-5">District <span
                                [ngClass]="isSubmitted && addEditAddressForm.get('district').errors?.required ? 'error-msg' : ''">*</span></ion-label>
                        <ng-select placeholder="Select District" [loading]="isDistrictLoading" class="select-input mt-5"
                            [items]="districtList" bindLabel="district" bindValue="district_id"
                            (change)="districtChange($event)" formControlName="district" [clearable]="false">
                        </ng-select>
                        <ng-container *ngFor="let validation of validation_messages.district">
                            <ng-container
                                *ngIf="addEditAddressForm.get('district').hasError(validation.type) && addEditAddressForm.get('district').dirty">
                                <ion-text color="danger" class="error-msg">
                                    {{validation.message }}
                                </ion-text>
                            </ng-container>
                        </ng-container>

                    </ion-col>
                    <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
                        <ion-label class="mb-5">Taluka <span
                                [ngClass]="isSubmitted && addEditAddressForm.get('taluka').errors?.required ? 'error-msg' : ''">*</span></ion-label>
                        <ng-select placeholder="Select Taluka" [loading]="isCityLoading" class="select-input mt-5"
                            [items]="talukaList" bindLabel="taluka" bindValue="taluka_id" formControlName="taluka"
                            [clearOnBackspace]="talukaList && talukaList.length ? false : true"
                            [clearable]="talukaList && talukaList.length ? false : true"
                            [addTag]="talukaList && talukaList.length ? false : true" [addTagText]="'Select'"
                            [editableSearchTerm]="talukaList && talukaList.length ? false : true"
                            (change)="talukaChange($event)"
                            [searchable]="talukaList && talukaList.length ? false : true">
                        </ng-select>
                        <ng-container *ngFor="let validation of validation_messages.taluka">
                            <ng-container
                                *ngIf="addEditAddressForm.get('taluka').hasError(validation.type) && addEditAddressForm.get('taluka').dirty">
                                <ion-text color="danger" class="error-msg">
                                    {{validation.message }}
                                </ion-text>
                            </ng-container>
                        </ng-container>

                    </ion-col>
                </ion-row>
                <ion-row class="mb-5">
                    <ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
                        <ion-label>Zipcode <span
                                [ngClass]="isSubmitted && addEditAddressForm.get('zipcode').errors?.required ? 'error-msg' : ''">*</span></ion-label>

                        <ion-input minlength="6" maxlength="6" placeholder="Enter Zipcode"
                            (keypress)="numberOnlyValidation($event)" formControlName="zipcode"></ion-input>

                        <ng-container *ngFor="let validation of validation_messages.zipcode">
                            <ng-container
                                *ngIf="addEditAddressForm.get('zipcode').hasError(validation.type) && addEditAddressForm.get('zipcode').dirty">
                                <ion-text color="danger" class="error-msg">
                                    {{validation.message }}
                                </ion-text>
                            </ng-container>
                        </ng-container>

                    </ion-col>
                    <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
                        <ion-label>Contact number <span
                                [ngClass]="isSubmitted && addEditAddressForm.get('contact_no').errors?.required ? 'error-msg' : ''">*</span></ion-label>

                        <ion-input type="tel" (keypress)="phoneClick($event)" placeholder="Enter Mobile Number"
                            inputmode="numeric" formControlName="contact_no" class="contact-number" maxlength="10">
                            <label for="" class="country-code ml-5 mr-10">
                                +91 <img class="pl-5" alt="Country Extension" src="./assets/svg/arrowdown.svg" priority>
                            </label>
                        </ion-input>
                        <ng-container *ngFor="let validation of validation_messages.contact_no">
                            <ng-container
                                *ngIf="addEditAddressForm.get('contact_no').hasError(validation.type) && addEditAddressForm.get('contact_no').dirty">
                                <ion-text color="danger" class="error-msg">
                                    {{validation.message }}
                                </ion-text>
                            </ng-container>
                        </ng-container>

                    </ion-col>
                </ion-row>
                <ion-row class="mb-5">
                    <ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
                        <ion-label>GST Number <span
                                [ngClass]="isSubmitted && addEditAddressForm.get('gst').errors?.required ? 'error-msg' : ''">*</span></ion-label>
                        <ion-input capitalizeFirst formControlName="gst" placeholder="Enter GST Number"
                            (keypress)="restrictSpecialChar($event)" minlength="15 " maxlength="15"></ion-input>
                        <ng-container *ngFor="let validation of validation_messages.gst">
                            <ng-container
                                *ngIf="addEditAddressForm.get('gst').hasError(validation.type) && addEditAddressForm.get('gst').dirty">
                                <ion-text color="danger" class="error-msg">
                                    {{validation.message }}
                                </ion-text>
                            </ng-container>
                        </ng-container>
                    </ion-col>
                </ion-row>
            </div>
        </form>
        <div class="form-container form-button mt-30 ">
            <button [disabled]="isLoading" (click)="addAddress()" type="primary" class="mr-20 w-156 submit-btn">
                <ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
                {{btnTxt}}
            </button>
            <button (click)="cancel()" class="clear-btn w-156">Cancel</button>

        </div>
    </div>
</div>