<div class="mt-15 filter-div">
    <div class="align-center space-between p-15 drop-div">
        <p class="m-0 p-0 fw-600 fs-20">Filter</p>
        <img alt="Search Icon" class="arrow-img cursor-pointer" (click)="filetrShowHandle()"
            [ngClass]="isFilterShow ? 'rotate-img':''" ngSrc="/assets/icon/dropdown-bottom.svg" priority height="100"
            width="100">
    </div>

    <div *ngIf="isFilterShow" class="p-15">

        <div *ngIf="step < 4 ">
            <ion-row class="p-0 mt-10 ">
                <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6"
                    class="align-center p-0 m-0 category-selection-filter">
                    <div class="width-100" [ngClass]="getPlatformService.isDesktop ? null :'mb-10'">
                        <ion-label class="fw-600">Main Category: <span>*</span></ion-label>
                        <ng-select [searchable]="true" placeholder="Select Main Categories" class="select-input mt-5"
                            [(ngModel)]="mainCategoryId" [items]="masterCategoryList" bindLabel="master_category_name"
                            bindValue="master_category_id" [clearable]="false" [disabled]="step != 1"
                            (change)="masterCategorySelection($event)">
                        </ng-select>
                    </div>
                </ion-col>

                <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6"
                    class="align-center p-0 m-0 category-selection-filter">
                    <div class="width-100" [ngClass]="getPlatformService.isDesktop ? 'ml-25' :'mb-10'">
                        <ion-label class="fw-600">Sub Category: <span>*</span></ion-label>
                        <ng-select [searchable]="true" placeholder="Select Sub Categories" class="select-input mt-5"
                            [(ngModel)]="categoryId" [items]="categoryList" bindLabel="name" bindValue="category_id"
                            [clearable]="false" (change)="categorySelection($event)"
                            [disabled]="step != 1 || !mainCategoryId">
                        </ng-select>
                    </div>
                </ion-col>
            </ion-row>
            <ng-container *ngIf="categoryId && !isMasterLoading">
                <p class="p-0 mb-0 fw-600">Main Product:</p>
                <ion-row class="p-0 mt-5">
                    <ion-col size-xs="12" size-sm="12" size-md="8" size-lg="12" size-xl="12"
                        class="align-center p-0 m-0 category-selection-filter">
                        <div class="width-100" [ngClass]="getPlatformService.isDesktop ? 'null' :'mb-10'">
                            <ng-select [searchable]="true" placeholder="Select Main Product" class="select-input mt-5"
                                [(ngModel)]="mainProduct" [items]="mainProductData" bindLabel="product_name"
                                [multiple]="false" bindValue="product_id" [clearable]="false"
                                (change)="mainProductSelection($event)" [disabled]="step != 1">
                            </ng-select>
                        </div>
                    </ion-col>
                </ion-row>
            </ng-container>
            <ng-container *ngIf="categoryId && mainAttributeData.length > 0 && !isMasterLoading">
                <p class="p-0 mb-0 mt-15 fw-600">Main Attribute</p>
                <ion-row class="p-0 mt-5">
                    <ng-container *ngFor="let data of mainAttributeData;let i = index">
                        <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6"
                            class="align-center p-0 m-0 category-selection-filter mt-10"
                            [ngClass]="i % 2 == 0 && getPlatformService.isDesktop? 'pr-12': getPlatformService.isDesktop ? 'pl-12' : ''">
                            <div class="width-100" [ngClass]="getPlatformService.isDesktop ? 'null' :'mb-10'">
                                <ion-label>{{data.attribute_name}}: <span>*</span></ion-label>
                                <ng-select [searchable]="true" [placeholder]="'Select ' + data.attribute_name"
                                    class="select-input mt-5" [(ngModel)]="data.selectedOption" [closeOnSelect]="false"
                                    [items]="data.optionValues" bindLabel="value" bindValue="option_id"
                                    [clearable]="false" [multiple]="true" (change)="attributeSelection($event)">
                                </ng-select>
                            </div>
                        </ion-col>
                    </ng-container>
                </ion-row>
            </ng-container>
            <ng-conatainer *ngIf="categoryId && !isMasterLoading">
                <ion-row class="p-0 mt-15">
                    <ion-col size-xs="12" size-sm="12" size-md="8" size-lg="12" size-xl="12"
                        class="align-center p-0 m-0 search-by-name-filter">
                        <div class="width-100" [ngClass]="getPlatformService.isDesktop ? '':'mb-10'">
                            <ion-input (keyup.enter)="search()" [(ngModel)]="searchText" type="text"
                                placeholder="Search By Name" [disabled]="!categoryId"></ion-input>
                        </div>
                    </ion-col>
                </ion-row>
            </ng-conatainer>

            <!-- ----------- Skeleton view -------------- -->
            <ng-container>
                <ng-container *ngIf="isMasterLoading && !mainProduct">
                    <p class="p-0 mb-0 fw-600">Main Product:</p>
                    <ion-row class="p-0 mt-5">
                        <ion-col size-xs="12" size-sm="12" size-md="8" size-lg="12" size-xl="12"
                            class="align-center p-0 m-0 category-selection-filter">
                            <div class="width-100" [ngClass]="getPlatformService.isDesktop ? 'null' :'mb-10'">
                                <ion-skeleton-text class="skeleton-container width-100 h-50" name="lines-sharp-small"
                                    [animated]="true"></ion-skeleton-text>
                            </div>
                        </ion-col>
                    </ion-row>
                </ng-container>
                <ng-container *ngIf="isMasterLoading">
                    <p class="p-0 mb-0 mt-15 fw-600">Main Attribute</p>
                    <ion-row class="p-0 mt-5">
                        <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6"
                            class="align-center p-0 m-0 category-selection-filter mt-10"
                            [ngClass]="getPlatformService.isDesktop? 'pr-12': ''">
                            <div class="width-100" [ngClass]="getPlatformService.isDesktop ? 'null' :'mb-10'">
                                <ion-skeleton-text class="skeleton-container width-100 h-50" name="lines-sharp-small"
                                    [animated]="true"></ion-skeleton-text>
                            </div>
                        </ion-col>
                        <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6"
                            class="align-center p-0 m-0 category-selection-filter mt-10"
                            [ngClass]="getPlatformService.isDesktop? 'pl-12': ''">
                            <div class="width-100" [ngClass]="getPlatformService.isDesktop ? 'null' :'mb-10'">
                                <ion-skeleton-text class="skeleton-container width-100 h-50" name="lines-sharp-small"
                                    [animated]="true"></ion-skeleton-text>
                            </div>
                        </ion-col>
                    </ion-row>
                </ng-container>
                <ng-conatainer *ngIf="isMasterLoading">
                    <ion-row class="p-0 mt-15">
                        <ion-col size-xs="12" size-sm="12" size-md="8" size-lg="12" size-xl="12"
                            class="align-center p-0 m-0 search-by-name-filter">
                            <div class="width-100" [ngClass]="getPlatformService.isDesktop ? '':'mb-10'">
                                <ion-skeleton-text class="skeleton-container width-100 h-50" name="lines-sharp-small"
                                    [animated]="true"></ion-skeleton-text>
                            </div>
                        </ion-col>
                    </ion-row>
                </ng-conatainer>
            </ng-container>


        </div>
        <div class="align-center mt-20 justify-end">
            <div>
                <!-- !categoryId && !searchText || !isAttributeSelected -->
                <button (click)="onSearch()" class="primary-btn align-center p-15"
                    [ngClass]="!getPlatformService.isDesktop ? 'width-100':null"
                    [disabled]="!categoryId || !mainCategoryId || !isAnyAttributeSelected">
                    <span>Apply</span>
                </button>
            </div>
            <div class="ml-25">
                <button (click)="closeFilter()" class="grey-btn align-center p-15"
                    [ngClass]="!getPlatformService.isDesktop ? 'width-100':null" [disabled]="!categoryId">
                    <span>Clear</span>
                </button>
            </div>
        </div>
    </div>
</div>