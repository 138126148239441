<div class="payment-waiting-container">
	<div class="icon-container">
		<div class="box">₹</div>
	</div>

	<div>
		<div class="text-center mt-30 fw-500 fs-18 waiting-desription">Thank you for waiting. Our payment gateway will
			be update
			shortly.<br /> We
			appreciate your understanding.</div>
	</div>


</div>