import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ToasterService } from '../../services/toaster.service';

@Component({
	selector: 'app-manage-vehicles',
	templateUrl: './manage-vehicles.component.html',
	styleUrls: ['./manage-vehicles.component.scss'],
})
export class ManageVehiclesComponent implements OnInit {
	isLoading: boolean = false;
	public subscription = new Subscription();
	widthConfig = ['20px', '200px', '180px', '200px', '170px', '170px', '170px', '220px', '160px', '200px'];
	column_settings = [
		{ primaryKey: 'id', header: '#' },
		{ primaryKey: 'vehicle_name', header: 'Vehicle name', image: true },
		{ primaryKey: 'vehicle_type', header: 'Vehicle type' },
		{ primaryKey: 'category_list', header: 'Categories' },
		{ primaryKey: 'vehicle_no', header: 'Vehicle Reg #' },
		{ primaryKey: 'min_capacity', header: 'Min Capacity' },
		{ primaryKey: 'max_capacity', header: 'Max Capacity' },
		{ primaryKey: 'standard_price', header: 'Standard Price / KM', is_price: true, other_text: '(Including GST)' },
		{ primaryKey: 'created_at', header: 'Created At' },
		{ primaryKey: '', header: 'Action', action: true }
	];
	listOfAllData = [];
	totalCount: number = 0;
	public itemsPerPage = this.commonService.list;
	public currentPage = 1;
	public enableNext: boolean = false;
	pageNo = 1;
	pagesize = this.commonService.list;
	paginationVal = 10;
	constructor(
		private router: Router,
		public commonService: CommonService,
		private dataService: ApiService,
		private toasterService: ToasterService,
		private navCtrl: NavController,
		private datePipe: DatePipe,
		public getPlatformService: GetPlatformService
	) {
		this.commonService.eventObservable.subscribe((data: any) => {
			if (data.event == 'manage_vehicles') {
				this.pageNo = this.commonService.localStorageGet('pageIndex');
				this.currentPage = this.commonService.localStorageGet('pageIndex');
				this.getVehicleList();
			}
		})
	}

	ngOnInit() {
		this.commonService.scrollToTop();
		this.getVehicleList()
	}


	getVehicleList(event?) {
		const params = {
			page: this.currentPage,
			limit: this.itemsPerPage
		}
		if (event) {
			if (event.sort_by.primaryKey == "id") {
				params['sort_by'] = "vehicle_id"
			}
			else if (event.sort_by.primaryKey == "vehicle_type") {
				params['sort_by'] = "vehicle_type_name"
			}
			else if (event.sort_by.primaryKey == "vehicle_no") {
				params['sort_by'] = "registration_plate_number"
			}
			else if (event.sort_by.primaryKey == "min_capacity") {
				params['sort_by'] = "minimum_capacity"
			}
			else if (event.sort_by.primaryKey == "max_capacity") {
				params['sort_by'] = "maximum_capacity"
			}
			else if (event.sort_by.primaryKey == "standard_price") {
				params['sort_by'] = "standard_per_km_price"
			}
			else {
				params['sort_by'] = event.sort_by.primaryKey
			}
			params['sort_type'] = event.sort_type;
		}
		this.listOfAllData = [];

		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.vehicleList, params).subscribe(data => {
				if (data['code'] == 200) {
					this.isLoading = false;
					if (data['data']) {
						let listData = data['data']['vehicleList'];
						this.totalCount = data['data']['total_count'];
						if (listData && listData.length) {
							let user_rights_data: any = [];
							listData.forEach((element, index) => {
								let obj = {
									index: (this.pageNo - 1) * this.itemsPerPage + index + 1,
									id: element.vehicle_id,
									vehicle_name: element.vehicle_name,
									img: element.vehicle_images && element.vehicle_images.length > 0 ? element.vehicle_images[0]['image_url'] : './assets/svg/truck.svg',
									vehicle_type: element.vehicle_type_name,
									vehicle_no: element.registration_plate_number,
									min_capacity: element.minimum_capacity + " " + element.categories[0].unit_name,
									max_capacity: element.maximum_capacity + " " + element.categories[0].unit_name,
									standard_price: element.standard_per_km_price,
									created_at: this.datePipe.transform(element.created_at, 'dd-MM-YYYY'),
									isActive: element.is_active,
									edit: true,
									view: true,
									updateToggle: true,
									viewLink: '/home/account-details/manage-vehicles/view-vehicle',
									category_list: element.categories.map(data => data.category_name)
								}

								this.listOfAllData.push(obj);

							});
						}
					}
				}
				else {
					this.isLoading = false;
					this.toasterService.errorToast(data['message'], 'top');
				}
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message'], 'top');
			})
		)
	}


	onChange(event): void {
		this.currentPage = event;
		this.pageNo = this.currentPage;
		this.getVehicleList();
	}

	addVehicle() {
		this.router.navigate(['/home/account-details/manage-vehicles/add-vehicle']);
	}

	changeListItem() {
		this.currentPage = 1;
		this.pageNo = 1;
		this.pagesize = this.paginationVal;
		this.itemsPerPage = this.paginationVal
		this.getVehicleList();
	}
}
