import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { CommonService } from '../services/common.service';


@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {

    constructor(private router: Router, public commonService: CommonService, public navCtrl: NavController) { }

    ngOnInit() {
        this.commonService.scrollToTop()
    }

    homeClick() {
        if ((this.commonService.userData.isCustomer && this.commonService.isLoggedIn) || !this.commonService.isLoggedIn) {
            this.navCtrl.navigateRoot(['/home']);
        }
        else if (this.commonService.userData.isSeller) {
            this.navCtrl.navigateRoot(['/home/account-details/dashboard']);
            this.commonService.scrollToTop();
        }
    }

}
