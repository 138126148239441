<div class="success-container">
    <div class="close-div p-5" (click)="cancel()">
        <img alt="CLose Icon" src="/assets/icon/close.svg" alt="" slot="end" class="close-icon">
    </div>
    <div class="description-container">
        <img alt="Warning" src="/assets/svg/warning.svg" alt="" class="pb-20">

        <p class="description-text fs-16 fw-500">{{description}}</p>

        <div [ngClass]="getPlatformService.isDesktop ? 'display-center width-100 ': 'mobile-full-btn'">
            <button type="primary" class="primary-btn w-138" [ngClass]="!getPlatformService.isDesktop ? 'width-100': null"
                (click)="close(true)">
                <span>Yes</span>
            </button>

            <button class="clear-btn w-138" (click)="close(false)" [ngClass]="!getPlatformService.isDesktop ? 'width-100 mt-20': 'ml-20'">
                <span [ngClass]="!getPlatformService.isDesktop ? 'ph-30': ''">Cancel</span>
            </button>
        </div>

    </div>
</div>