import { Injectable } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(
        private toastController: ToastController,
        private navCtrl: NavController,

  ) { }

    async successToast(msg,position?){
        const toast = await this.toastController.create({
        message: msg,
        position: 'top' ,
        cssClass: 'success-top' ,        
        duration:3000,
        buttons: [
            {
                side: 'start',
                icon: '/assets/icon/ok.svg',
                handler: () => {
                }
            }, {
                side: 'end',
                icon: 'close',
                role: 'cancel',
                handler: () => {
                }
            }
        ]
        });
        toast.present();
    }

    async warningToast(msg,position?,link?){
        const toast = await this.toastController.create({
        message: msg,
        position: 'top',
        cssClass: 'warning-top' ,
        duration : 3000,
        buttons: [
            {
                side: 'start',
                icon: '/assets/icon/warning.svg',
                handler: () => {
                }
            }, {
                side: 'end',
                icon: 'close',
                role: 'cancel',
                handler: () => {
                    this.navCtrl.navigateForward(link);

                }
            }
            
        ]
        });
        toast.present();
    }

     async errorToast(msg,position?){
        const toast = await this.toastController.create({
        message: msg,
        position: 'top' ,
        cssClass: 'error-top' ,
        duration:3000,
        buttons: [
            {
                side: 'start',
                icon: '/assets/icon/warning.svg',
                handler: () => {
                }
            }, {
                side: 'end',
                icon: 'close',
                role: 'cancel',
                handler: () => {
                }
            }
        ]
        });
        toast.present();
    }
}


