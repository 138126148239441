import { environment } from '../../environments/environment';


export const URLS = Object({
	// Authentication
	'login': `${environment.apiUrl}/website/auth/login`,
	'logout': `${environment.apiUrl}/website/auth/logout`,

	// Registration
	'customerRegistration': `${environment.apiUrl}/website/auth/customer/registration`,
	'sellerRegistration': `${environment.apiUrl}/website/auth/seller/registration`,
	'becomeASeller': `${environment.apiUrl}/website/user/become/seller`,
	'becomeASCustomer': `${environment.apiUrl}/website/user/become/customer`,


	// OTP verification
	'otpVerification': `${environment.apiUrl}/website/auth/verify/otp`,
	'generateOtp': `${environment.apiUrl}/website/auth/generate/otp`,
	'verifyEmailOtp': `${environment.apiUrl}/website/auth/verify/email_otp`,
	'generateEmailOtp': `${environment.apiUrl}/website/auth/generate/email_otp`,


	// Forgot Password
	'forgotPassword': `${environment.apiUrl}/website/auth/forget/password`,

	//master's Api
	'country': `${environment.apiUrl}/website/user/country/get`,
	'state': `${environment.apiUrl}/website/user/states/get`,
	'district': `${environment.apiUrl}/website/user/district/get`,
	'city': `${environment.apiUrl}/website/user/city/get`,

	//categories
	'categories': `${environment.apiUrl}/website/categories/get`,
	'masterCategory': `${environment.apiUrl}/website/mastercategory/get`,

	//create new password
	'createPassword': `${environment.apiUrl}/website/auth/reset/password`,

	//change password
	'changePassword': `${environment.apiUrl}/website/auth/change/password`,

	//address
	'getAddress': `${environment.apiUrl}/website/address/get`,
	'addAddress': `${environment.apiUrl}/website/address/save`,
	'editAddress': `${environment.apiUrl}/website/address/edit`,
	'getAddressType': `${environment.apiUrl}/website/user/addresstype/get`,
	'deleteAddress': `${environment.apiUrl}/website/address/delete`,
	'locationTagPublic': `${environment.apiUrl}/website/user/addresstag/get`,

	'userDetail': `${environment.apiUrl}/website/user/profile/get`,

	//account menu
	'accountMenu': `${environment.apiUrl}/website/menu`,
	'profileSwitch': `${environment.apiUrl}/website/user/switchProfile`,


	//banner
	'getBannerData': `${environment.apiUrl}/website/banners/get`,

	// most selling products
	'getSellingProducts': `${environment.apiUrl}/website/selling/products/get`,

	//recenty added products
	'getRecentProducts': `${environment.apiUrl}/website/recently/added/products/get`,

	//recomended products
	'getRecomendedProducts': `${environment.apiUrl}/website/recommended/products/get`,

	//brands
	'getBrands': `${environment.apiUrl}/website/brands/get`,

	//news letter
	'newsLetter': `${environment.apiUrl}/website/add/newsletters`,

	// update profile
	'updateProfile': `${environment.apiUrl}/website/user/profile/update`,

	//manage company details
	'sellerProgressBar': `${environment.apiUrl}/website/company/progress/get`,
	'sellerCompanyDetail': `${environment.apiUrl}/website/company/info/get`,
	'sellerCategories': `${environment.apiUrl}/website/company/categories/get`,
	'sellerCompanyInfoUpdate': `${environment.apiUrl}/website/company/info/update`,
	'sellerAddressDetail': `${environment.apiUrl}/website/company/addresses/get`,
	'sellerAddAddress': `${environment.apiUrl}/website/company/address/add`,
	'sellerAddressDelete': `${environment.apiUrl}/website/company/addresses/delete`,
	'sellerAddressEdit': `${environment.apiUrl}/website/company/address/update`,
	'sellerCatUpdate': `${environment.apiUrl}/website/company/categories/update`,
	'getCompanyBankDetail': `${environment.apiUrl}/website/company/bankdetails/get`,
	'addCompanyBankDetail': `${environment.apiUrl}/website/company/bankdetails/add`,
	'updateCompanyBankDetail': `${environment.apiUrl}/website/company/bankdetails/update`,
	'deleteCompanyBankDetail': `${environment.apiUrl}/website/company/bankdetails/delete`,



	// manage users
	'userToggle': `${environment.apiUrl}/website/manageuser/active`,
	'adminToggle': `${environment.apiUrl}/website/manageuser/activeadmin`,
	'getAddressList': `${environment.apiUrl}/website/manageuser/address`,
	'addUser': `${environment.apiUrl}/website/manageuser/add`,
	'getUser': `${environment.apiUrl}/website/manageuser/list`,
	'deleteUser': `${environment.apiUrl}/website/manageuser/delete`,
	'editUser': `${environment.apiUrl}/website/manageuser/edit`,
	'userQuotesRequest': `${environment.apiUrl}/website/manageuser/quotes/list`,
	'userQuotesRequestDetail': `${environment.apiUrl}/website/manageuser/quote/detail`,

	//product listing
	'getProducts': `${environment.apiUrl}/website/products/get`,

	//product details apis
	'getRelatedProducts': `${environment.apiUrl}/website/products/related/get`,
	'getProductDetails': `${environment.apiUrl}/website/products/detail/get`,
	'getBestDeal': `${environment.apiUrl}/website/products/best-deal/get`,
	'getSellerProductDetails': `${environment.apiUrl}/website/seller/products/get`,
	'updateSellerProductDetails': `${environment.apiUrl}/website/seller/products/update`,

	//related products
	'relatedProducts': `${environment.apiUrl}/website/products/related/get`,

	//cart
	'cartList': `${environment.apiUrl}/website/cart/list`,
	'cartDelete': `${environment.apiUrl}/website/cart/delete`,
	'cartUpdate': `${environment.apiUrl}/website/cart/update`,
	'addToCart': `${environment.apiUrl}/website/cart/add`,
	'clearCart': `${environment.apiUrl}/website/cart/clear`,

	//choose delivery location popup
	'addDeliveryAddress': `${environment.apiUrl}/website/customer/add/address`,

	//checkout
	'orderSummaryDetails': `${environment.apiUrl}/website/products/buy-now-details/get`,

	//sellerSummary
	'sellerAvailable': `${environment.apiUrl}/website/quotes/availablesellers/get`,
	'createQuotes': `${environment.apiUrl}/website/quotes/customer/request/create`,

	//reviews and rating
	'addReview': `${environment.apiUrl}/website/products/review/add`,
	'getReviews': `${environment.apiUrl}/website/products/review`,

	//order UTR
	'orderUTRUpdate': `${environment.apiUrl}/website/customer/order/utr/update`,

	//seller Quotes
	'sellerQuotesList': `${environment.apiUrl}/website/quotes/seller/get`,
	'sellerQuoteDetail': `${environment.apiUrl}/website/quotes/seller/details`,
	'addSellerQuote': `${environment.apiUrl}/website/quotes/seller/bid`,
	'quoteWithdrawl': `${environment.apiUrl}/website/seller/manage/quote/withdraw`,
	'sellerQuoteEdit': `${environment.apiUrl}/website/quotes/seller/bid/edit`,

	//customer Quotes
	'customerQuotesList': `${environment.apiUrl}/website/quotes/customermyquotelist/get`,
	'customerQuoteDetail': `${environment.apiUrl}/website/customer/my/quote/details`,

	//seller products
	'sellerProductList': `${environment.apiUrl}/website/seller/products/get`,
	'nonSellerProductList': `${environment.apiUrl}/website/seller/products/get/sub-products`,
	'selerProductAsCat': `${environment.apiUrl}/website/seller/products/sub-products/category-wise`,
	'addBulkSellerProduct': `${environment.apiUrl}/website/seller/products/bulk-create`,
	'deleteProduct': `${environment.apiUrl}/website/seller/products/delete`,
	'sellerStatusUpdate': `${environment.apiUrl}/website/seller/products/status/update`,
	'sellerCatDetail': `${environment.apiUrl}/website/seller/products/get/category-wise`,
	'updateBulkSellerProduct': `${environment.apiUrl}/website/seller/products/update/bulk/price`,
	'sellerSubProductDelete': `${environment.apiUrl}/website/seller/products/delete`,
	'sellerUpdatePriceStatus': `${environment.apiUrl}/website/seller/products/get/price-update`,

	//operational area
	'manageOperationalAreaList': `${environment.apiUrl}/website/seller/operationalarea/list`,
	'addOperationalArea': `${environment.apiUrl}/website/seller/operationalarea/add`,
	'deleteOperationalArea': `${environment.apiUrl}/website/seller/operationalarea/delete`,
	'optionalAreaStatus': `${environment.apiUrl}/website/seller/operationalarea/active`,
	'editOperationalArea': `${environment.apiUrl}/website/seller/operationalarea/edit`,
	'district_city': `${environment.apiUrl}/website/user/district/get`,
	'taluka': `${environment.apiUrl}/website/user/taluka/get`,


	//warehouse address
	'warehouseddress': `${environment.apiUrl}/website/seller/warehouse`,

	//order
	'placeOrder': `${environment.apiUrl}/website/order/placeorder`,

	//rebid
	'rebidRequest': `${environment.apiUrl}/website/quotes/customer/rerequest/create`,
	'placeQuoteOrder': `${environment.apiUrl}/website/quotes/customer/approvequoteorder/create`,
	'closeRequest': `${environment.apiUrl}/website/quotes/customer/close`,

	//manage earnings
	'manageEarnings': `${environment.apiUrl}/website/seller/earnings`,

	// blog
	'getBlog': `${environment.apiUrl}/website/blog/list`,
	'getBlogComments': `${environment.apiUrl}/website/blog/listcomments`,
	'addComment': `${environment.apiUrl}/website/blog/addcomment`,
	'getBlogCategory': `${environment.apiUrl}/website/blog/categories`,
	'addBlog': `${environment.apiUrl}/website/blog/add`,
	'likeComment': `${environment.apiUrl}/website/blog/comment/like`,
	'dislikeComment': `${environment.apiUrl}/website/blog/comment/dislike`,

	//consultant request
	'submitConsultantRequest': `${environment.apiUrl}/website/consultant/request`,

	//contact us
	'createContact': `${environment.apiUrl}/website/contactus/create`,

	//CMS Pages
	'getFaq': `${environment.apiUrl}/website/cms/faqs/get`,
	'getPrivacyPolicy': `${environment.apiUrl}/website/privacypolicy`,
	'getAboutUs': `${environment.apiUrl}/website/aboutus`,
	'getTermsOfUse': `${environment.apiUrl}/website/termsofuse`,
	'getSellerTermsOfUse': `${environment.apiUrl}/website/sellertermsofuse`,


	//header category dropdown
	'getCategories': `${environment.apiUrl}/website/home/categories/get`,

	//newsletter apis
	'getNewsLetter': `${environment.apiUrl}/website/newsletters`,

	//customer orders
	'customerOrderList': `${environment.apiUrl}/website/customer/orders/list`,
	'customerOrderDetail': `${environment.apiUrl}/website/customer/orders/details`,
	'customerOrderAccept': `${environment.apiUrl}/website/customer/order/accept`,
	'customerOrderReject': `${environment.apiUrl}/website/customer/order/reject`,
	'customerOrderUTR': `${environment.apiUrl}/website/customer/order/addutr`,
	'customerOrderHistory': `${environment.apiUrl}/website/customer/order/history`,

	// searh api
	'search': `${environment.apiUrl}/website/search/filter`,
	'searchedProductAdd': `${environment.apiUrl}/website/searched/product/add`,

	// payout request
	'payoutRequest': `${environment.apiUrl}/website/seller/payout_request`,

	//manager-order
	'sellerManageOrderList': `${environment.apiUrl}/website/seller/orders/list`,
	'sellerManageOrderDetails': `${environment.apiUrl}/website/seller/orders/details`,
	'updateSellerManageOrderDetails': `${environment.apiUrl}/website/seller/order/updatestatus`,
	'sellerManageOrderWarehouseAddress': `${environment.apiUrl}/website/seller/warehouse`,
	'updateReceivePaymentStatus': `${environment.apiUrl}/website/seller/payment/receive`,

	//seller dashboard
	'sellerDashboard': `${environment.apiUrl}/website/seller/dashboard`,
	'addressChangeOnCheckout': `${environment.apiUrl}/website/products/best-deal/checkout`,

	'vehicleTypeList': `${environment.apiUrl}/website/vehicle/types/get`,
	'vehicleCreate': `${environment.apiUrl}/website/vehicle/create`,
	'vehicleList': `${environment.apiUrl}/website/vehicle/get`,
	'vehicleDetail': `${environment.apiUrl}/website/vehicle/get/detail`,
	'vehicleUpdate': `${environment.apiUrl}/website/vehicle/update`,
	'vehicleUpdateToggle': `${environment.apiUrl}/website/vehicle/update/status`,

	'notificationList': `${environment.apiUrl}/website/user-notifications/get`,
	'notificationUpdate': `${environment.apiUrl}/website/user-notifications/update`,

	//add products steps
	'getStepOne': `${environment.apiUrl}/website/seller/products/get/sub-products/step/1`,
	'getStepTwo': `${environment.apiUrl}/website/seller/products/get/sub-products/step/2`,
	'getStepThree': `${environment.apiUrl}/website/seller/products/get/sub-products/step/3`,
	'getStepFour': `${environment.apiUrl}/website/seller/products/get/sub-products/step/4`,


	//manageSeller
	'getMainProduct': `${environment.apiUrl}/website/seller/subcategory/mainproduct`,
	'getMainAttributes': `${environment.apiUrl}/website/seller/subcategory/attributes`,

	//singleSUbProduct
	'getSingleSubProduct': `${environment.apiUrl}/website/products/subproduct/get`,
	'getMainProductDetail': `${environment.apiUrl}/website/products/mainproductdetail/get`,

	//Customer Dashboard
	'customerDashboard': `${environment.apiUrl}/website/customer/dashboard`,
	'paymentKey': `${environment.apiUrl}/website/order/payment/initiate`

})

export const SECRET_KEY: string = `${environment.rsa_public_key}`;

