<div class="home-page app-header">
	<app-header></app-header>
</div>

<ion-content>
	<ion-grid class="page-scroll-top">
		<ion-row>
			<ion-col size="12" size-sm="12" size-md="12" size-lg="7.5" size-xl="7.5">
				<div class="blog-details-container" *ngFor="let blog of blogData">
					<div class="blog-image">
						<img [src]="blog.image_url" alt="Image" />
					</div>
					<div class="blog-date">
						{{ blog.date | date: 'dd MMMM yyyy'}}
					</div>
					<div class=" pb-10">
						<span>
							Published by:
						</span>
						<span class="fw-600 ">
							{{ blog.user_name}}
						</span>
					</div>
					<div class="blog-name pb-20">
						{{ blog.name }}
					</div>
					<div [innerHTML]="blog.description | safe :'html'" class="blog-description">
					</div>
				</div>
				<div class="blog-comment-container mt-40" *ngIf="blogData.length">
					<div class="blog-add-comment mv-20 ph-30" [formGroup]="blogFormGroup">
						<ion-row>
							<ion-col size="12">
								<div class="blog-comment-header pb-20">
									Add comment
								</div>
							</ion-col>
							<ion-col size="6" *ngIf="!commonService.isLoggedIn">
								<div class="email pb-15">
									<ion-label>Email *</ion-label>
									<ion-input formControlName="email" type="email"
										(keydown.space)="$event.preventDefault();"></ion-input>
									<ng-container *ngFor="let validation of validation_messages.email">
										<ng-container
											*ngIf="blogFormGroup.get('email').hasError(validation.type) && blogFormGroup.get('email').dirty">
											<ion-text color="danger" class="error-msg">
												{{validation.message }}
											</ion-text>
										</ng-container>
									</ng-container>
								</div>
							</ion-col>
							<ion-col size="12">
								<div class="blog-comment pb-30">
									<ion-label>Comment *</ion-label>
									<ion-textarea capitalizeFirst formControlName="comment" rows="7"></ion-textarea>
									<ng-container *ngFor="let validation of validation_messages.comment">
										<ng-container
											*ngIf="blogFormGroup.get('comment').hasError(validation.type) && blogFormGroup.get('comment').dirty">
											<ion-text color="danger" class="error-msg">
												{{validation.message }}
											</ion-text>
										</ng-container>
									</ng-container>
								</div>
							</ion-col>
							<ion-col size="12">
								<div class="submit-button">
									<button type="primary" class="submit-btn w-138" (click)="addBlogComment()">
										Submit
										<ion-spinner class="spinner-btn" name="lines-sharp-small"
											*ngIf="isLoading"></ion-spinner>
									</button>
								</div>
							</ion-col>
						</ion-row>
					</div>
					<div class="comments-list-container"
						[ngClass]="blogCommentList.length < 1 ? 'comment-list-border-none' : ''">
						<div class="coment-count-header pl-20 pb-20">
							Comments({{ commentCount ? commentCount : 0 }})
						</div>
						<div class="comments-list pl-50 pr-50" *ngFor="let list of blogCommentList;let last = last;">
							<div class="user-name-date pt-30 pb-15">
								<div class="user-name fw-600">
									{{ list.user_name }}
								</div>
								<div class="comment-date">
									{{ list.created_at | date: 'dd-MM-yyyy' }}
								</div>
							</div>
							<div class="user-comment pb-30" [ngClass]="[ last ? 'comment-last' : '']">
								{{ list.comment }}
								<div class="comment-like pt-10" *ngIf="commonService.isLoggedIn">
									<ng-container *ngIf="list.is_liked">
										<div (click)="likeDislike(list)">
											<img src="assets/icon/liked.svg" title="dislike?" alt="Liked Icon" /><span
												class="pl-5">{{
												(list.like_count ) }}</span>
										</div>
									</ng-container>
									<ng-container *ngIf="!list.is_liked">
										<div (click)="likeDislike(list)">
											<img src="assets/icon/disliked.svg" title="like?"
												alt="Disliked Icon" /><span class="pl-5">{{ (
												list.like_count ) }}</span>
										</div>
									</ng-container>
								</div>
							</div>
							<div class="load-more pt-20 pb-20" (click)="loadMoreComments()"
								*ngIf="last && enableNextComment">
								<span class="load-more-text">Load more
									comments</span>
							</div>
						</div>
					</div>
				</div>
				<div class="arrow-container mt-30">
					<div class="prev mr-50" (click)="prevBlog()" [class.disabled]="isPrevDisabled()">
						<img src="/assets/icon/arrow-left.svg" alt="Left Arrow Icon" />
					</div>
					<div class="next ml-50" (click)="nextBlog()" [class.disabled]="isNextDisabled()">
						<img src="/assets/icon/arrow-right.svg" alt="Right Arrow Icon">
					</div>
				</div>
			</ion-col>
			<ion-col size="12" size-sm="12" size-md="12" size-lg="4.5" size-xl="4.5">
				<div class="right-container ml-15">
					<div class="social-media-links">
						<div class="follow-btn-header fw-600 pb-20">
							Click the following button to add blog
						</div>
						<div class="social-media-header">
							<button type="primary" class="mr-20  w-182 submit-btn" (click)="goToAddBlog()">
								Add blog
							</button>
						</div>
					</div>
					<div class="recent-posts mt-30">
						<div class="recent-posts-header pb-30">
							Recent posts
						</div>
						<div class="post-contaner mb-30" *ngFor=" let post of recentPost;let last = last;"
							[ngClass]="[ last ? 'last-post' : '']" (click)="gotToBlogDetail(post.blog_id)">
							<div class="post-image">
								<img [src]="post.image_url" alt="Image" />
							</div>
							<div class="post-description pl-20">
								<div class="post-name pb-15">
									{{ post.name }}
								</div>
								<div class="post-date">
									{{ post.date | date: 'dd MMMM yyyy'}}
								</div>
								<div class=" post-date pt-5 ">
									<span>
										Published by:
									</span>
									<span class="fw-600 ">
										{{ post.user_name}}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ion-col>
		</ion-row>
	</ion-grid>
	<div class="wrapper-block home-page">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
</ion-content>