import { DOCUMENT, isPlatformServer } from '@angular/common';
import { EventEmitter, Inject, Injectable, Output, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import * as CryptoJS from 'crypto-js';
import { Subject, Subscription } from 'rxjs';
import { SECRET_KEY, URLS } from './../_config/api.config';
import { ApiService } from './api-data.service';
import { AuthenticationService } from './authentication.service';
import { CartService } from './cart.service';
import { ToasterService } from './toaster.service';

var cryptoJS = require("crypto-js");

export interface UserDetail {
	user_type?: string,
	customer_type?: string,
	seller_type?: string,
	user_profile?: string,
	approval_status?: string,
	isCustomer?: boolean,
	isSeller?: boolean,
	name?: string,
	is_admin?: boolean,
}
@Injectable({
	providedIn: 'root'
})
export class CommonService {
	notificationCount: any;
	countryCode: string = '+91';
	twoFaEnabled = false;
	public eventObservable = new Subject<any>();
	public event: any = {};
	accessToken: string;
	isLoggedIn = false;
	userData: UserDetail = {};
	userEmail: any;
	public productsLimit: number = 8;
	public categoriesLimit: number = 12;
	public mastersLimit: number = 10;
	public profilePicture: any;
	public list: number = 10;
	is_admin: boolean = false;
	is_sub_user: boolean = false;
	is_sub_user_admin: boolean = false;
	private subscription: Subscription = new Subscription();
	@Output() callSortByData: any = new EventEmitter();
	@Output() callAddressData: any = new EventEmitter();
	@Output() menuData: any = new EventEmitter();
	@Output() updateProfile: any = new EventEmitter();
	@Output() documentfile: any = new EventEmitter();
	@Output() addressLatLong: any = new EventEmitter();
	@Output() quoteListing: any = new EventEmitter();
	@Output() filterData: any = new EventEmitter();
	@Output() clearData: any = new EventEmitter();
	@Output() categoryData: any = new EventEmitter();
	sellerIdProof: any;
	sellerCertificate: any;
	currencySymbol = "₹";
	isAutoSelected = false;
	paginationList = [
		{
			label: "10",
			value: 10
		},
		{
			label: "20",
			value: 20
		}
	]

	@Output() profilePictureUpdated: any = new EventEmitter();
	isCartDisable: boolean;
	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		@Inject(DOCUMENT) private document: Document,
		private authService: AuthenticationService,
		private router: Router,
		private dataService: ApiService,
		public toastService: ToasterService,
		private navCtrl: NavController,
		private cartService: CartService,
	) { }

	public encryptData(data: any) {
		try {
			var encryptedData = CryptoJS.AES.encrypt(data, SECRET_KEY);
			return encryptedData.toString();
		} catch (e) {
			console.log(e);
		}
	}

	public decryptData(encryptedData: string) {
		if (!encryptedData) {
			return null;
		}
		try {
			var bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
			var decryptedData = bytes.toString(CryptoJS.enc.Utf8);

			return decryptedData;
		} catch (e) {
			console.log(e);
		}
	}

	localStorageSet(key: string, value: any) {
		if (!isPlatformServer(this.platformId) && key && value) {
			var encryptedData = CryptoJS.AES.encrypt(value.toString(), SECRET_KEY);
			localStorage.setItem(key, encryptedData);
		}
	}

	localStorageGet(key) {
		if (!isPlatformServer(this.platformId)) {
			const encryptedData = localStorage.getItem(key);
			if (encryptedData) {
				try {
					const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
					const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
					return decryptedData;
				} catch (error) {
					return null;
				}
			} else {
				return null;
			}
		}
		return null;
	}

	localStorageRemove(key) {
		if (!isPlatformServer(this.platformId)) {
			localStorage.removeItem(key);
		}
	}

	localStorageClear() {
		if (!isPlatformServer(this.platformId)) {
			localStorage.clear();
		}
		localStorage.clear();
	}

	sortByData(data?) {
		this.callSortByData.emit({
			sort_by_params: data
		})
	}

	public setEvent(event: string, data: any) {
		this.event = { 'event': event, 'data': data };
		this.eventObservable.next(this.event);
	}

	public logout(disableMsg?) {
		this.authService.logout().subscribe((data) => {
			if (data['code'] === 200) {
				this.localStorageClear();
				this.cartService.cartCount = 0;
				this.userData = {};
				this.isLoggedIn = false;
				this.accessToken = null;
				this.is_admin = false;
				this.is_sub_user = false;
				this.navCtrl.navigateRoot(['/home']);
				if (!disableMsg) this.toastService.successToast('Logged Out Successfully'); //Static Toast message data['message']
			}
			else {
				this.isLoggedIn = false;
				this.userData = {};
				this.localStorageClear();
				this.accessToken = null;
				this.is_admin = false;
				this.is_sub_user = false;
				this.navCtrl.navigateRoot(['/home']);
				this.toastService.errorToast(data['message']);  //Error Message on Logged out
			}
		}),
			error => {
				console.log(error);

			}
	}

	scrollToTop() {
		const element = this.document.querySelector('#main-content');
		if (element) {
			if (this.document.getElementsByClassName('page-scroll-top') && this.document.getElementsByClassName('page-scroll-top').length) {
				for (let i = 0; i < this.document.getElementsByClassName('page-scroll-top').length; i++) {
					this.document.getElementsByClassName('page-scroll-top')[i].scrollIntoView({ behavior: 'smooth' })
				}

			}
		}
	}

	addressData() {
		this.callAddressData.emit();
	}

	quoteListingData(data) {
		this.quoteListing.emit({
			tabValue: data
		});
	}

	getUserDetail(userType?) {
		if (this.isLoggedIn) {
			let params = {
				"user_type": this.userData.isCustomer ? 'CUSTOMER' : 'SELLER'
			}
			return new Promise((resolve, reject) => {
				this.subscription.add(
					this.dataService.get(URLS.userDetail, params).subscribe(data => {
						if (data['code'] == 200) {
							let userDetail = data['data'];
							if (userDetail) {
								this.localStorageSet('user_type', userDetail.user_type);
								this.localStorageSet('customer_approval', userDetail.approval_status);
								this.localStorageSet('seller_approval', userDetail.seller_approval_status);
								this.localStorageSet('customer_type', userDetail.customer_type);
								this.localStorageSet('user_email', userDetail.email);
								this.localStorageSet('mobile_no', userDetail.mobile_no);
								this.localStorageSet('user_id', userDetail.user_id);
								this.localStorageSet('enterprise_user_id', userDetail.enterprise_user_id);
								this.profilePictureUpdated.emit({
									user_detail: userDetail
								});

							}
							resolve(userDetail);
						}
						else {
							reject();
						}
					}, (error: any) => {
						reject(error);
					})
				)
			})
		}

	}


	getCategoryList(param?) {
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.categories, param).subscribe(data => {
					if (data['code'] == 200 && data['data']['categoriesList'].length > 0) {
						let categoriesList = data['data']['categoriesList'];
						categoriesList.forEach(element => {
							element.image_url = element.image_url ? element.image_url : '../../../assets/product/product7.svg';
						});
						resolve(categoriesList)
					}
					else {
						reject(data);
					}
				}, error => {
					reject(error)
				})
			)
		})

	}

	getWarehouseAddress(param?) {
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.warehouseddress, param).subscribe(data => {
					if (data['code'] == 200 && data['data'].length > 0) {
						let addressList = data['data'];
						resolve(addressList)
					}
					else {
						reject(data);
					}
				}, error => {
					reject(error)
				})
			)
		})

	}

	callmenuData(data?) {
		this.menuData.emit(data ? data : null);
	}

	updateProfileData(userData?) {
		this.updateProfile.emit({
			userData: userData
		});
	}

	documentFile(data, type) {
		if (type == 'CERTIFICATE') {
			this.documentfile.emit({ certificate: data });
		}
		else if (type == 'ID') {
			this.documentfile.emit({ ID: data });
		}
	}

	addressEmit(data) {
		this.addressLatLong.emit({ data: data });
	}

	filterDataEmit(data, option?) {
		this.filterData.emit({
			filterData: data,
			optionData: option
		})
	}

	clearDataEmit(categoryChecked?, isDocument?) {
		this.clearData.emit({
			iscategoryChecked: categoryChecked,
			isDocument: isDocument
		});
	}

	emitCategoryData(data?) {
		this.categoryData.emit({
			categoryData: data
		})
	}

	public trimUrl(value: any) {
		if (value) {
			return value.trim().replace(/[\s()&@!,.;:]+/g, '-');
		}
	}



}
