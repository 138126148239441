import { Injectable } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  isDesktop = false;
  modal:any ;
  constructor(private modalCtrl: ModalController,
    private platform: Platform,
    private navCtrl: NavController,
    ) {
    this.isDesktop = this.platform.is('desktop')
  }
  async openModal(modalOptions, callback = null) {
    if (!modalOptions) {
      return;
    }
    if (!modalOptions['component']) {
      return;
    }
    modalOptions['componentProps'] = {
      ...modalOptions['componentProps'],
      isDesktop: this.isDesktop
    }
    let obj = {
      componentProps: { isDesktop: this.isDesktop },
    }
    if (!this.platform.is('desktop')) {
      obj['initialBreakpoint'] = 0.5
    }
    this.modal = await this.modalCtrl.create(modalOptions);
    this.modal.present();

    const { data, role } = await this.modal.onWillDismiss();
    this.modal.present();
    return await this.modal.onDidDismiss();
  }
  

  dismissModal(dismissValue = null) {
    this.modalCtrl.dismiss(dismissValue).catch(err => {
      this.navCtrl.back()
    })
  }

    dismissModalwithData(value) {
        if (this.modal) {
            this.modalCtrl.dismiss({ data: value });
        }

    }

}
