<div class="home-page app-header">
    <app-header></app-header>
</div>

<ion-content>
    <ion-grid class="page-scroll-top">
        <ion-row>
            <ion-col size="12" size-sm="12" size-md="12" size-lg="7.5" size-xl="7.5">
                <div class="blog-details-container" *ngFor="let list of newsLetterData">
                    <div class="blog-image">
                        <img alt="Image" [src]="list.image_url">
                    </div>
                    <div class="blog-date">
                        {{ list.date | date: 'dd MMMM yyyy'}}
                    </div>
                    <div class="blog-name">
                        {{ list.name }}
                    </div>
                    <div class="blog-description" [innerHTML]=" list.description | safe :'html'">
                    </div>
                </div>
                <div class="arrow-container mt-30">
                    <div class="prev mr-50" (click)="prevBlog()" [class.disabled]="isPrevDisabled()">
                        <img alt="Left Arrow" class="without-hover" src="/assets/icon/arrow-left.svg">
                        <img alt="Right Arrow" class="with-hover rotate-arrow" src="/assets/icon/arrow-right-white.svg">
                    </div>
                    <div class="next ml-50" (click)="nextBlog()" [class.disabled]="isNextDisabled()">
                        <img alt="Left Arrow" class="without-hover" src="/assets/icon/arrow-right.svg">
                        <img alt="Right Arrow" class="with-hover" src="/assets/icon/arrow-right-white.svg">
                    </div>
                </div>
            </ion-col>
            <ion-col size="12" size-sm="12" size-md="12" size-lg="4.5" size-xl="4.5">
                <div class="right-container ml-15">
                    <div class="recent-posts">
                        <div class="recent-posts-header pb-30">
                            Recent newsletter
                        </div>
                        <div class="recent-post-conatiner">
                            <div class="post-contaner mb-30" *ngFor=" let post of recentPost;let last = last;"
                                [ngClass]="[ last ? 'last-post' : '']" (click)="gotToBlogDetail(post.newsletter_id)">
                                <div class="post-image">
                                    <img alt="Image" [src]="post.image_url">
                                </div>
                                <div class="post-description pl-20">
                                    <div class="post-name pb-15">
                                        {{ post.name }}
                                    </div>
                                    <div class="post-date">
                                        {{ post.date | date: 'dd MMMM yyyy'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
    <div class="wrapper-block home-page">
        <div class="app-footer">
            <app-footer></app-footer>
        </div>
    </div>
</ion-content>