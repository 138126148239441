<div class="manage-user">
    <div class="form-button add-user" *ngIf="commonService.userData.isCustomer">
        <button class="submit-btn w-200" (click)="addUser()" [disabled]="isCustomerApproval" [ngClass]="[ isCustomerApproval ? 'not-allowed' : '']">Add
            New User</button>
    </div>
    <div class="form-button add-user" *ngIf="!commonService.userData.isCustomer">
        <button class="submit-btn w-200" (click)="addUser()" [disabled]="isSellerApproval" [ngClass]="[ isSellerApproval ? 'not-allowed' : '']">Add
            New User</button>
    </div>
    <ng-container *ngIf="!isLoading">
        <div class="list mt-30">
            <app-table-listing [settings]="column_settings" [widthConfig]="widthConfig" [pageType]="'manage_user'"
                [listOfAllData]="listOfAllData" (sortByEvent)="getUsers($event)" [isLoading]="isLoading"></app-table-listing>
        </div>
    </ng-container>
</div>