<ion-app>
	<ion-menu side="end" *ngIf="commonService.isLoggedIn " contentId="main-content"
		[swipeGesture]="!getPlatfrom.isDesktop &&commonService.isLoggedIn? true : false">
		<ion-content>
			<ion-list lines="none">
				<ion-menu-toggle>
					<app-account-menu [isMobileSideMenu]="true"></app-account-menu>
				</ion-menu-toggle>
			</ion-list>
		</ion-content>
	</ion-menu>
	<ion-router-outlet [animated]="false" id="main-content"></ion-router-outlet>


</ion-app>


<!-- ----------------------------- Disclaimer Dialog ----------------------------------- -->
<div class="disclaimer-box" *ngIf="disclaimerDialog">
	<div class="showmodal">
		<div class="modal-content">
			<div>
				<h3 class="text-center fw-600">Disclaimer</h3>
				<ion-icon name="close-outline" class="close-icon-disclaimer pointer"
					(click)="closeDisclaimer()"></ion-icon>
			</div>

			<p class="fs-18 fw-400">
				All rights related to Conkart, including but not limited to intellectual property rights,
				trademarks,
				copyrights, and
				any other proprietary rights, are exclusively assigned to and held by Conkart.
				<br><br>
				Unauthorized use or
				reproduction of any
				Conkart materials is strictly prohibited.
				Any trademarks, copyrights, or other intellectual property rights mentioned or displayed on the
				Conkart
				platform that
				belong to third party owners are the sole property of their respective owners.
				<br><br> Conkart acknowledges
				these
				rights and
				does not claim any ownership over them.
				<br><br>All rights are reserved by their respective trademark and
				copyright
				owners.
				Any questions, concerns, or require further information regarding the Conkart platform, please do
				not
				hesitate to
				contact us at info@conkart.com. Our team is available to assist you with any inquiries you may have.
			</p>
		</div>
	</div>

</div>