<ion-header mode="md">
    <ion-toolbar mode="md">
        <div class="d-flex">
            <div class="product-title fs-16 fw-500 p-10" (click)="goBack()">
                <img src="assets/icon/arrow-left.svg">
            </div>
            <div class="searh-container">
                <form>
                    <ion-searchbar #searchbar autocomplete='off' autocorrect='off' autocapitalize="none" debounce="500"
                        inputmode="search" type="search" name="search" enterkeyhint="search" placeholder="Search..."
                        (ionClear)="onSearchClear()" search-icon="./assets/svg/search.svg" [(ngModel)]="searchValue"
                        (ionChange)="onSearch()" (ionInput)="onSearchInput($event)" autocorrect="off" [ngClass]="isSearched || searchValue ? 'hide-search' : ''"
                        mode="md"></ion-searchbar>
                </form>
            </div>
        </div>
        <div class="data-count fw-600 ion-text-right" *ngIf="searchedArray && searchedArray.length">
            Total {{ searchedArray.length }} items
        </div>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div class="search-list-container" *ngIf="searchedArray && searchedArray.length && !isLoading">
        <ion-list class="search-list">
            <ion-item class="search-item" *ngFor="let product of searchedArray; let i = index" (click)="searchNavigation(product)">
                <ion-label class="search-label" *ngIf="product.product_name">
                    <div class="product-name fw-600">
                        {{ product.product_name }}
                    </div>
                    <div class="sub-header fw-500 fs-14">
                        {{ product.product_type }}
                    </div>
                </ion-label>
            </ion-item>
        </ion-list>
    </div>
    <div class="ion-text-center pt-40">
        <ion-spinner name="bubbles" *ngIf="isLoading"></ion-spinner>
    </div>
    <div>
        <div *ngIf="searchedArray && searchedArray.length < 1 && !isLoading" class="no-record-found">
            No record
        </div>
    </div>
    <ion-infinite-scroll *ngIf="isMoreData && !isLoading" (ionInfinite)="onIonInfinite($event)">
        <ion-infinite-scroll-content loadingText="Please wait..." loadingSpinner="bubbles"></ion-infinite-scroll-content>
    </ion-infinite-scroll>
</ion-content>