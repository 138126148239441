import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
    selector: 'app-product-add-success-modal',
    templateUrl: './product-add-success-modal.component.html',
    styleUrls: ['./product-add-success-modal.component.scss'],
})
export class ProductAddSuccessModalComponent implements OnInit {
    @Input() title;
    @Input() description;
    constructor(
        private modalService: ModalService,
    ) { }

    ngOnInit() { }

    async close(flag?) {
        this.modalService.dismissModal(flag);
    }

}
