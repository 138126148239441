import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
    public subscription = new Subscription();
    isLoading = false;
    @Input() description = '';
    @Input() param = {};
    @Input() apiURL;
    @Input() link = '';
    @Input() isRebid = false;
    @Input() subDescription = "";

    constructor(
        private modalService: ModalService,
        private dataService: ApiService,
        private toasterService: ToasterService,
        private navCtrl: NavController
    ) {

    }

    ngOnInit() {

    }

    onCancel() {
        this.modalService.dismissModal();
    }

    onSuccess() {
        if (this.isRebid) {
            this.openRequestQuoteModal();
        } else {
            this.subscription.add(
                this.dataService.post(this.apiURL, this.param).subscribe(data => {
                    if (data['code'] == 200) {
                        this.modalService.dismissModal(data['data']);
                        this.navCtrl.navigateBack(this.link);
                    }
                    else {
                        this.toasterService.errorToast(data['message']);
                    }
                    this.isLoading = false;
                }, error => {
                    this.isLoading = false;
                    this.toasterService.errorToast(error['message']);

                })
            )
        }

    }

    openRequestQuoteModal() {
        this.modalService.dismissModal();
        setTimeout(() => {
            let options: any = {
                id: "requestQuoteModal",
                backdropDismiss: true,
            };
            options['component'] = RequestQuoteComponent;
            options['componentProps'] = {
                quoteData: this.param,
                shoppingCartBTn: "My shooping cart",
                isRebid: this.isRebid
            };
            this.modalService.openModal(options)
        }, 300);
    }

}
