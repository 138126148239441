<div class="request-quote-container">
	<div class="cross-icon ion-text-right" (click)="onCancel()">
		<img alt="Close Icon" src="assets/icon/close.svg">
	</div>
	<div class="request-quote" [formGroup]="addressDetailsformGroup">



		<div class="request-quote-header">
			<div *ngIf="!isRebid">
				Request for Quote
			</div>
			<div *ngIf="isRebid">
				Request for Re-Bid
			</div>
			<div class="header-container">
				<ion-row class="m-0 p-0 mt-10">
					<ion-col size-xs="10" size-sm="10" size-md="10" size-lg="10" size-xl="10" class="m-0 p-0">
						<div class="fs-16 fw-600 p-0">Item</div>
					</ion-col>
					<ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="2" class="m-0 p-0">
						<div class="fs-16 fw-600">Qty</div>
					</ion-col>
				</ion-row>
			</div>
		</div>
		<div class="quote-container">
			<div class="itemScroll">
				<div class="">
					<ion-row class="m-0 p-0 product-detail-container">
						<ion-col size-xs="10" size-sm="10" size-md="10" size-lg="10" size-xl="10" class="m-0 p-0">
							<div class="product-detail">
								<div class="product-image">
									<img alt="Image"
										[src]="quoteData.image_url ? quoteData.image_url : '/assets/product/product7.svg'">
								</div>
								<div class="product-description">
									<div class="product-name">
										{{quoteData.product_name}}
									</div>
									<div class="product-sku">
										<div class="sku-header">
											SKU:
										</div>
										<div class="sku">
											{{quoteData.sku}}
										</div>
									</div>
									<div class="product-sku">
										<div class="sku-header">
											Unit:
										</div>
										<div class="sku">
											{{quoteData.unit_name}}
										</div>
									</div>
									<div class="product-sku">
										<div class="sku-header">
											Package Info:
										</div>
										<div class="sku">
											{{ quoteData &&
											quoteData.package_value ?
											quoteData.package_value + " " +quoteData.unit_name
											: quoteData.unit_name
											}} x {{quoteData.quantity }} Quantity = {{ quoteData &&
											quoteData.package_value ?
											quoteData.package_value * quoteData.quantity + " " +quoteData.unit_name
											: quoteData.quantity +" "+ quoteData.unit_name
											}}
										</div>
									</div>

									<div class="product-attribute" *ngFor="let oneAttr of quoteData.product_attributes">
										<div class="attribute-header" *ngIf="oneAttr.attribute_name">
											{{oneAttr.attribute_name}}:
										</div>
										<div class="attribute" *ngIf="oneAttr.attribute_name">
											{{oneAttr.value}}
										</div>
									</div>
								</div>
							</div>
						</ion-col>
						<ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="2" class="m-0 p-0">
							<div class="product-quantity">
								<span class="qty-text" *ngIf="!getPlatformService.isDesktop">Qty</span>
								<div class="qty-div">
									<p class="fs-16 fw-500">{{quoteData.quantity}}</p>
									<div class="up-down ml-10">
									</div>
								</div>
							</div>
						</ion-col>
					</ion-row>


				</div>
			</div>
			<div class="address-details">
				<ion-row class="shipping-address-row">
					<ion-col class="shipping-address-col pr-20" size-xs="12" size-sm="12" size-md="12" size-lg="12"
						size-xl="12">
						<ion-label class="shipping-address-label">Shipping Address</ion-label>
						<div class="mt-10 mb-15">{{quoteData.address}}</div>
						<ng-container *ngFor="let validation of validation_messages.shipping_address">
							<ng-container
								*ngIf="addressDetailsformGroup.get('shipping_address').hasError(validation.type) && addressDetailsformGroup.get('shipping_address').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>
					</ion-col>
				</ion-row>
				<ion-row class="radius-row pt-15">
					<ion-col class="radius-col" [ngClass]="getPlatformService.isDesktop ? 'pr-20':null" size-xs="12"
						size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label class="radius-label">Area *</ion-label>
						<ion-input placeholder="Please enter area" formControlName="radius"
							(keypress)="onlyNumber($event)" inputmode="numeric" maxlength="4"
							(ionChange)="radiusInput($event)">
							<label slot="end">
								KM
							</label>
						</ion-input>
						<ng-container *ngFor="let validation of validation_messages.radius">
							<ng-container
								*ngIf="addressDetailsformGroup.get('radius').hasError(validation.type) && addressDetailsformGroup.get('radius').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="increaseRadius">
							<br>
							<ion-text class="error-text">
								{{errorMsg}}
							</ion-text>
						</ng-container>
						<div class="Available-seller" *ngIf="quotesAvailable">
							<div class="seller-number" *ngIf="sellerAvailable.length != 0">
								{{sellerAvailable.length}} {{sellerAvailable.length > 1 ? 'Sellers are' : 'Seller'}}
								available
							</div>
							<div class="seller-number" *ngIf="sellerAvailable.length == 0">
								No Seller available
							</div>
							<div *ngIf="availableSellers && sellerAvailable.length < 5" class="seller-text">
								{{ availableSellers }}
							</div>
							<div *ngIf="!availableSellers" class="seller-text">
								Sellers Are Available To Quote
							</div>
						</div>
					</ion-col>
					<ion-col class="timeframe-col" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label class="timeframe-label">Timeframe *</ion-label>
						<ion-input type="text" placeholder="Enter timeframe (1-72 hours)" (keypress)="isNumeric($event)"
							formControlName="timeframe" min="1" max="72" class="timeframe-input" min="1" max="72"
							(ngModelChange)="timeSelectChange($event)">
						</ion-input>
						<ng-container *ngFor="let validation of validation_messages.timeframe">
							<ng-container
								*ngIf="addressDetailsformGroup.get('timeframe').hasError(validation.type) && addressDetailsformGroup.get('timeframe').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>
						<div class="pt-5 flex">
							<div><b>Note: </b></div>
							<div class="pl-5">
								Consider <u class="fw-600">holidays</u> <span class="fw-600">, </span><u
									class="fw-600">weekend</u> & assume working
								hours of seller as <u class="fw-600">10:00 AM -
									6:00 PM</u>
								while
								entering the time.
							</div>
						</div>
					</ion-col>

				</ion-row>

			</div>
			<div class="order-on-credit">
				<div class="form-container form-button mt-30  ml-5">
					<button [disabled]="isDisable" (click)="onSubmit()" class="mr-20  w-147 submit-btn">
						<ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
						Submit
					</button>
					<button class="clear-btn w-147" (click)="onCancel()">Cancel</button>
				</div>
			</div>
		</div>
	</div>

</div>