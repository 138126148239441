<div class="home-page app-header">
	<app-header></app-header>
</div>

<ion-content *ngIf="!isLoading">
	<ion-grid class="page-scroll-top">
		<ion-row>
			<ion-col>
				<div class="tab-container pb-30">
					<div class="radio_container fs-16">
						<ng-container *ngFor="let item of tabList;let i = index">
							<input (change)="userTypeChange(item.value)" [(ngModel)]="faqType" type="radio" name="radio"
								id="item{{i}}" [value]="item.value" [checked]="item.value">
							<label class="pointer" for="item{{i}}">{{item.name}}</label>
						</ng-container>
					</div>
				</div>
			</ion-col>
		</ion-row>
		<ion-row>
			<ion-col>
				<div class="tutorial-videos recommended-container">
					<ng-container *ngIf="tutorialData.length">
						<div class="fs-32 fw-600 pb-20 tutorial-videos-header">
							Tutorial videos
						</div>
						<ng-container *ngIf="customerFaq">
							<swiper #swiperVirtualRef [slidesPerView]="3" [spaceBetween]="50"
								[pagination]="{ type: 'fraction' }" [virtual]="true"
								slideActiveClass="swiper-active whyWouldIuseCustomClass" [centeredSlides]="true"
								[navigation]="true" class="mySwiper" id="mySwiperID" [config]="config"
								class="swiperSlides top-slider in-english" #productsSlides>
								<ng-container>
									<ng-template swiperSlide *ngFor="let data of tutorialData">
										<app-product-card [fromSliderComponent]="true" [data]="data"
											[isLoading]="isLoading" [faq]="faq"></app-product-card>
									</ng-template>
								</ng-container>
							</swiper>
						</ng-container>
						<ng-container *ngIf="!customerFaq">
							<swiper #swiperVirtualRef [slidesPerView]="3" [spaceBetween]="50"
								[pagination]="{ type: 'fraction' }" [virtual]="true"
								slideActiveClass="swiper-active whyWouldIuseCustomClass" [centeredSlides]="true"
								[navigation]="true" class="mySwiper" id="mySwiperID" [config]="config"
								class="swiperSlides top-slider in-english" #productsSlides>
								<ng-container>
									<ng-template swiperSlide *ngFor="let data of tutorialData">
										<app-product-card [fromSliderComponent]="true" [data]="data"
											[isLoading]="isLoading" [faq]="faq"></app-product-card>
									</ng-template>
								</ng-container>
							</swiper>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="!tutorialData.length">
						<div class="flex-center fs-22 fw-600 no-data-available align-center">
							No Tutorial Videos Available
						</div>
					</ng-container>
				</div>
			</ion-col>
		</ion-row>

		<ion-row *ngIf="faqData && faqData.length > 0">
			<ion-col>
				<div *ngFor="let faq of faqData">
					<div class="accordion-container pl-10 pr-10" *ngIf="faq.faq_list && faq.faq_list.length">
						<div class="category-name fs-32 fw-600 pb-30">
							{{ faq.category_name }}
						</div>
						<div>
							<ion-accordion-group>
								<ion-accordion toggle-icon="" [value]="list.faq_id" *ngFor="let list of faq.faq_list">
									<ion-item slot="header" color="light">
										<ion-label>
											{{ list.faq_question }}
										</ion-label>
									</ion-item>
									<div class="ion-padding" slot="content" (click)="elementClicked($event)"
										[innerHTML]="list.faq_answer | safe :'html'">
									</div>
								</ion-accordion>
							</ion-accordion-group>
						</div>
					</div>
				</div>
			</ion-col>
		</ion-row>
		<ion-row *ngIf="faqData && faqData.length < 1">
			<div class="no-record-found">
				No record
			</div>
		</ion-row>
	</ion-grid>
	<div class="wrapper-block home-page">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
</ion-content>

<ion-content *ngIf="isLoading">
	<app-loader></app-loader>
</ion-content>