import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/services/api-data.service';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { ModalService } from '../../services/modal.service';
import { ThankYouComponent } from '../thank-you/thank-you.component';

@Component({
	selector: 'app-request-quote',
	templateUrl: './request-quote.component.html',
	styleUrls: ['./request-quote.component.scss'],
})
export class RequestQuoteComponent implements OnInit {
	public subscription = new Subscription();
	public addressDetailsformGroup: FormGroup;
	public isLoading = false;
	public increaseRadius: boolean = false;
	public quotesAvailable: boolean = false;
	public availableSellers: any;
	@Input() quoteData: any = {};
	@Input() shoppingCartBTn = "My Shopping Cart";
	@Input() type = '';
	@Input() isRebid;
	sellerAvailable = [];
	isDisable = true;
	sellerUserId = [];
	errorMsg = '';
	timeFrameValidationMsg: any;


	creditDays = [
		{
			days: "10 days",
			credit_id: 10
		},
		{
			days: "20 days",
			credit_id: 20
		},
		{
			days: "30 days",
			credit_id: 30
		}
	]

	constructor(
		private formBuilder: FormBuilder,
		private modalService: ModalService,
		private dataService: ApiService,
		private toasterService: ToasterService,
		private cartService: CartService,
		private commonService: CommonService,
		private navCtrl: NavController,
		public getPlatformService: GetPlatformService

	) {
	}

	ngOnInit() {
		this.addressDetailsformGroup = this.initializeForm();
		this.f1.shipping_address.setValue(this.quoteData.address);
	}

	get f1() { return this.addressDetailsformGroup.controls; }


	public validation_messages = {
		shipping_address: [
			{ type: 'required', message: 'Please enter shipping address' },
		],
		radius: [
			{ type: 'required', message: 'Please enter area' },
		],
		timeframe: [
			{ type: 'required', message: 'Please enter timeframe' },
			{ type: 'min', message: 'Timeframe must be at least 1 hour.' },
			{ type: 'max', message: 'Timeframe cannot exceed 72 hours.' },
			{ type: 'pattern', message: 'Please enter valid timeframe' },
		],

	}

	initializeForm() {
		return this.formBuilder.group({
			shipping_address: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			radius: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			timeframe: new FormControl(null, {
				validators: Validators.compose([Validators.required, Validators.min(1), Validators.max(72), Validators.pattern('[0-9]*')]),
			})
		});
	}


	onlyNumber(e) {
		if (e.keyCode < 48 || e.keyCode > 57) {
			e.preventDefault();
		}
	}

	radiusInput(event) {
		if (!event.target.value) {
			this.increaseRadius = false;
			this.quotesAvailable = false;
		}
		else {
			this.increaseRadius = false;
			this.quotesAvailable = true;
			this.fetchSeller();

		}

	}

	fetchSeller() {
		let shippingAddressId = this.commonService.localStorageGet('shipping_address_id') ? +this.commonService.localStorageGet('shipping_address_id') : null
		let params = {
			radius: this.f1.radius.value,
			product_id: this.quoteData.product_id,
			quantity: parseInt(this.quoteData.quantity),
			address_id: this.quoteData.address_id ? this.quoteData.address_id : shippingAddressId,
			product_brand_mapping_id: this.quoteData.product_brand_mapping_id,
		}
		if (this.quoteData.product_package_mapping_id) params['product_package_mapping_id'] = this.quoteData.product_package_mapping_id;
		this.sellerAvailable = [];
		this.sellerUserId = [];
		this.subscription.add(
			this.dataService.get(URLS.sellerAvailable, params).subscribe(data => {
				this.isLoading = false;
				if (data['code'] == 200) {
					this.isDisable = false;
					if (data['data']) {
						const sellerLocal = data['data']['sellerList'];
						this.availableSellers = data['data']['message'];
						let sellerIds = [];
						this.sellerAvailable = sellerLocal
							.map(item => item.user_id)
							.filter((value, index, self) => {
								return self.indexOf(value) === index;
							});
						this.sellerAvailable.forEach(id => {
							if (id) {
								let userObj = {
									user_id: id
								}
								sellerIds.push(userObj);
							}
						});
						this.sellerUserId = Array.from(new Set(sellerIds));
					}
				}
				else {
					this.isDisable = true;
					this.increaseRadius = false;
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.isLoading = false;
				this.increaseRadius = false;
				this.toasterService.errorToast(error['message']);

			})
		)
	}


	onCancel() {
		this.modalService.dismissModal();
	}

	onSubmit() {
		for (const i in this.f1) {
			this.f1[i].markAsDirty();
			this.f1[i].updateValueAndValidity();
			this.f1[i].markAsTouched();
		}

		if (this.addressDetailsformGroup.invalid) {
			return;
		}
		if (!this.quotesAvailable) {
			return;
		}
		if (this.sellerAvailable.length < 1) {
			this.toasterService.warningToast("Seller are not available on selected option");
			return;
		}
		this.modalService.dismissModal();
		if (this.isRebid) {
			let param = {
				"quote_id": this.quoteData.quote_id,
				"is_active": false,
				"product_id": this.quoteData.product_id,
				"product_brand_mapping_id": this.quoteData.product_brand_mapping_id,
				"quantity": this.quoteData.quantity,
				"radius": parseInt(this.f1.radius.value),
				"timeframe": parseInt(this.f1.timeframe.value),
				"shipping_address_id": this.quoteData.address_id ? this.quoteData.address_id : +this.commonService.localStorageGet('shipping_address_id'),
				"is_credit_order": 0,
				"available_sellers": this.sellerUserId,
				"best_deal_price": parseFloat(this.quoteData.best_deal_price)
			}
			if (this.quoteData.product_package_mapping_id) param['product_package_mapping_id'] = this.quoteData.product_package_mapping_id;
			param['credit_days'] = 0;

			this.subscription.add(
				this.dataService.post(URLS.rebidRequest, param).subscribe(data => {
					this.isLoading = false;
					if (data['code'] == 200) {
						let tabValue = "REQUESTED"
						this.commonService.quoteListingData(tabValue);
						this.navCtrl.navigateForward("/home/account-details/my-quote-requests");
						this.toasterService.successToast(data['message']);
					}
					else {
						this.toasterService.errorToast(data['message']);
					}
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);

				})
			)

		} else {

			let param = {
				"product_id": this.quoteData.product_id,
				"product_brand_mapping_id": this.quoteData.product_brand_mapping_id,
				"quantity": this.quoteData.quantity,
				"radius": parseInt(this.f1.radius.value),
				"timeframe": parseInt(this.f1.timeframe.value),
				"shipping_address_id": this.quoteData.address_id ? this.quoteData.address_id : +this.commonService.localStorageGet('shipping_address_id'),
				"is_credit_order": 0,
				"available_sellers": this.sellerUserId,
				"best_deal_price": parseFloat(this.quoteData.price)
			}

			if (this.quoteData.product_package_mapping_id) param['product_package_mapping_id'] = this.quoteData.product_package_mapping_id;

			param['credit_days'] = 0;

			this.subscription.add(
				this.dataService.post(URLS.createQuotes, param).subscribe(data => {
					this.isLoading = false;
					if (data['code'] == 200) {
						if (this.type == 'cart') {
							this.deleteCardProduct(this.quoteData.cart_id)
						}
						else {
							setTimeout(() => {
								let options: any = {
									id: "thankYouModal",
									backdropDismiss: true,
								};
								options['component'] = ThankYouComponent;
								options['componentProps'] = {
									shoppingCartBTnText: "My Quote Request",
									isBtnHide: true,
									btn1Link: '/home/account-details/my-quote-requests'
								};

								this.modalService.openModal(options)
							}, 300);
						}
					}
					else {
						this.toasterService.errorToast(data['message']);
					}
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);

				})
			)
		}

	}

	deleteCardProduct(id) {
		const params = {
			"cart_id": id,
		}
		this.cartService.deleteCartItem(params).then((data) => {
			if (data) {
				// this.modalService.dismissModal();
				this.cartService.cartCount = this.cartService.cartCount - 1;
				this.commonService.setEvent("cartUpdate", "true");
				this.commonService.localStorageSet("quote_id", 'null')
				setTimeout(() => {
					let options: any = {
						id: "thankYouModal",
						backdropDismiss: false,
					};
					options['component'] = ThankYouComponent;
					options['componentProps'] = {
						shoppingCartBTnText: this.shoppingCartBTn,
						btnLink: '/home/account-details/my-quote-requests',
						btn1Link: this.cartService.cartCount > 0 ? '/home/cart' : '/home'
					};

					this.modalService.openModal(options)
				}, 300);
			}
		}, error => {
			this.toasterService.errorToast(error, 'top');
		});
	}

	timeSelectChange(event) {

		this.timeFrameValidationMsg = null;
		// start time and end time
		var startTime = moment(new Date(), 'HH:mm:ss a');
		var endTime = moment('06:00:00 pm', 'HH:mm:ss a');

		// calculate total duration
		var duration: any = moment.duration(endTime.diff(startTime));

		// duration in hours
		var hours = parseInt(duration.asHours());

		// duration in minutes
		var minutes = parseInt(duration.asMinutes()) % 60;


		if (hours < event && minutes > 0) {
			this.timeFrameValidationMsg = "Your selected timeframe will start from tomorrow 10:00 AM. as we consider quote requests till 6:00 PM.";
		}
		else if (minutes < 0) {
			this.timeFrameValidationMsg = "Your selected timeframe will start from tomorrow 10:00 AM. as we consider quote requests till 6:00 PM.";
		}
	}

	isNumeric(event) {
		var charCode = (event.which) ? event.which : event.keyCode;
		if (charCode >= 48 && charCode <= 57) {
			return true;
		} else {
			return false;
		}
	}

}
