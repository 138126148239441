<div class="my-profile-container seller-container page-scroll-top mb-50 autoComplete" id="scrollId">
	<div class="heading-label fs-28 fw-600 mt-50 pb-20">
		Request For <span *ngIf="commonService.isLoggedIn "> {{userData.customer_type == profileEnum.Enterprise ?
			'Enterprise' :
			"Individual"}}
		</span> Seller Account
		<div class="mb-10 fs-16 fw-400" *ngIf="!commonService.isLoggedIn">
			<p> Existing user? <span (click)="login()" class="text-link pointer"> Log-in</span></p>
		</div>
	</div>
	<div class="mb-30 fs-16 fw-400 w-200 desc" *ngIf="commonService.isLoggedIn"> We will use your below mentioned
		details for you to request as {{userData.customer_type == profileEnum.Enterprise ? 'Enterprise' :
		"Individual"}} seller.</div>
	<ng-container *ngIf="!commonService.isLoggedIn ">
		<div class="title pl-5 fs-16 fw-600 pb-10">
			Select User Type
		</div>
		<ion-row class="mb-5">
			<div class="tab-container">
				<div class="radio_container fs-16">
					<ng-container *ngFor="let item of tabList;let i = index">
						<input (change)="userTypeChange(item.value)" [(ngModel)]="sellerRegisterationType" type="radio"
							name="sellerRegisterationType" id="item{{i}}" [value]="item.value" [checked]="item.value">
						<label class="pointer" (click)="userTypeChange(item.value)"
							for="item{{i}}">{{item.name}}</label>
					</ng-container>
				</div>
			</div>
		</ion-row>
	</ng-container>
	<div class="account-form pt-20">
		<form [formGroup]="registrationForm">
			<ion-row class="mb-10" *ngIf="sellerRegisterationType == profileEnum.Enterprise">
				<ion-col>
					<ion-label>Seller Type <span
							[ngClass]="registrationForm.get('seller_type').hasError('required') && registrationForm.get('seller_type').dirty ? 'error-msg' : ''">*</span></ion-label>
					<ng-select class="select-input mt-5" [items]="sellerType" bindLabel="seller_type_label"
						bindValue="seller_type_id" (change)="sellerTypeChange($event)" formControlName="seller_type"
						[clearable]="false" [searchable]="false" placeholder="Select Seller Type">
					</ng-select>
					<ng-container *ngFor="let validation of validation_messages.seller_type">
						<ng-container
							*ngIf="registrationForm.get('seller_type').hasError(validation.type) && registrationForm.get('seller_type').dirty">
							<ion-text color="danger" class="error-msg">
								{{validation.message }}
							</ion-text>
						</ng-container>
					</ng-container>

				</ion-col>
			</ion-row>
			<app-personal-detail [isBecomeUserRegister]="isBecomeSellerReq"
				[placeholder]="sellerRegisterationType == profileEnum.Enterprise ? 'Please enter domain specific email Id' : ''"
				[isHideVerifiedBtn]="true" [fieldName]="fieldData" [formGroup]="$any(registrationForm)">
			</app-personal-detail>
			<ion-row class="mb-10 mt-10" *ngIf="!isBecomeSellerReq">
				<ion-col class="password">
					<ion-label>Password <span
							[ngClass]="registrationForm.get('password').hasError('required') && registrationForm.get('password').dirty ? 'error-msg' : ''">*</span></ion-label>
					<ion-input autocomplete="new-password" appBlockCopyPaste placeholder="Enter Password"
						class="registration-pass" clearOnEdit="false" type="{{passwordType}}" formControlName="password"
						(keydown.space)="$event.preventDefault();">
					</ion-input>
					<img alt="Open Lock" class="input-icon" *ngIf="passwordType === 'text'"
						(click)="passwordType = 'password'" src="/assets/icon/eye.svg">
					<img alt="Open Lock" class="input-icon" *ngIf="passwordType === 'password'"
						src="../../../assets/icon/eye-off.svg" (click)="passwordType = 'text'">
					<ng-container *ngFor="let validation of validation_messages.password">
						<ng-container
							*ngIf="registrationForm.get('password').hasError(validation.type) && registrationForm.get('password').dirty">
							<ion-text color="danger" class="error-msg">
								{{validation.message }}
							</ion-text>
						</ng-container>
					</ng-container>
				</ion-col>
			</ion-row>
			<ion-row class="mb-10 mt-20" *ngIf="!isBecomeSellerReq">
				<ion-col class="password">
					<ion-label>Confirm password <span
							[ngClass]="registrationForm.get('confirm_password').hasError('required') && registrationForm.get('confirm_password').dirty ? 'error-msg' : ''">*</span></ion-label>
					<ion-input autocomplete="new-confirm-password" appBlockCopyPaste placeholder="Re-Enter Password"
						class="registration-pass" clearOnEdit="false" type="{{confirmPasswordType}}"
						formControlName="confirm_password" (keydown.space)="$event.preventDefault();">
					</ion-input>
					<img alt="Open Lock" class="input-icon" *ngIf="confirmPasswordType === 'text'"
						(click)="confirmPasswordType = 'password'" src="/assets/icon/eye.svg">
					<img alt="Open Lock" class="input-icon" *ngIf="confirmPasswordType === 'password'"
						src="../../../assets/icon/eye-off.svg" (click)="confirmPasswordType = 'text'">
					<ng-container *ngFor="let validation of validation_messages.confirm_password">
						<ng-container
							*ngIf="registrationForm.get('confirm_password').hasError(validation.type) && registrationForm.get('confirm_password').dirty">
							<ion-text color="danger" class="error-msg">
								{{validation.message }}
							</ion-text>
						</ng-container>
					</ng-container>
				</ion-col>
			</ion-row>
			<div class="company-info pt-10">
				<div class="title pl-5 pt-5 fs-16 fw-600 pb-15">
					Company Info
				</div>
				<ion-row class="mb-5">
					<ion-col>
						<ion-label>Company Name <span
								[ngClass]="registrationForm.get('company_name').hasError('required') && registrationForm.get('company_name').dirty ? 'error-msg' : ''">*</span></ion-label>
						<ion-input capitalizeFirst formControlName="company_name" placeholder="Enter Company Name"
							(keypress)="restrictSpecialChar($event)" maxlength="50"></ion-input>
						<ng-container *ngFor="let validation of validation_messages.company_name">
							<ng-container
								*ngIf="registrationForm.get('company_name').hasError(validation.type) && registrationForm.get('company_name').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>
					</ion-col>
				</ion-row>
			</div>
			<div formArrayName="address" class="address">
				<div *ngFor="let add of address?.controls; let i = index" [formGroupName]="i">
					<div class="title pl-5 mt-25 fs-16 fw-600 pb-10">
						<span class="left"> Address <span *ngIf="i >= 1"> {{ i + 1}}</span></span>
						<span class="right pointer" (click)="removeAddressAt(i)" *ngIf="i >= 1"> <img alt="Delete Icon"
								src="/assets/icon/delete.svg"></span>
					</div>
					<ion-row class="mb-5">
						<ion-col *ngIf="i > 0">
							<ion-label>Location Tag <span
									[ngClass]="isSubmitted && address.controls[i].get('location_tag').errors?.required ? 'error-msg' : ''">*</span></ion-label>
							<ng-select class="select-input mt-5" [items]="locationTag" bindLabel="address_type"
								bindValue="address_type_id" (open)="openAddressOptions(locationTag)"
								formControlName="location_tag" [clearable]="false" placeholder="Select Location Tag"
								[searchable]="false">
								<ng-option *ngFor="let option of locationTag" [value]="option.address_type_id"
									[attr.disabled]="option.disabled">
									{{ option.address_type }}
								</ng-option>
							</ng-select>

							<label class="error-msg"
								*ngIf="isSubmitted && address.controls[i].get('location_tag').errors?.required">
								<ion-text color="danger" class="error-msg">
									Please select location tag
								</ion-text>
							</label>

						</ion-col>
					</ion-row>
					<ion-row class="mb-5">
						<ion-col>
							<div class="mapOption mb-10">
								<ion-label>HQ Address <span
										[ngClass]="isSubmitted && address.controls[i].get('hq_address').errors?.required ? 'error-msg' : ''">*</span></ion-label>
								<ion-input placeholder="Enter Headquarter Address" id="{{ 'id_address' + (i+1) }}"
									(ionChange)="getPlaceAutocomplete($event,i)" capitalizeFirst
									formControlName="hq_address" (keypress)="restrictSpecialChar($event,i)"
									(ionChange)="onHqAddressChange($event)"></ion-input>
								<span class="error-text absolute" *ngIf="this['isAutoSelected' + i]"> Please add valid
									address
								</span>

								<ion-list class="autoAddress" *ngIf="this['predictionLength' + i] > 0"
									class="autocomplete">
									<div class="items" *ngFor="let item of this['autocompleteItems' + i]"
										(click)="selectSearchResult(item,i)">{{
										item.description }}</div>
								</ion-list>
								<label class="error-msg"
									*ngIf="isSubmitted && address.controls[i].get('hq_address').errors?.required">
									<ion-text color="danger" class="error-msg">
										Please enter HQ address
									</ion-text>

								</label>
							</div>
						</ion-col>
					</ion-row>
					<ion-row class="mb-5">
						<ion-col>
							<ion-label>Country <span
									[ngClass]="isSubmitted && address.controls[i].get('country').errors?.required ? 'error-msg' : ''">*</span></ion-label>
							<ng-select placeholder="Select Country" (change)="countryChange($event, i)"
								class="select-input mt-5" [items]="countryList" bindLabel="country"
								bindValue="country_id" formControlName="country" [clearable]="false"
								[searchable]="false">
							</ng-select>
							<label class="error-msg"
								*ngIf="isSubmitted && address.controls[i].get('country').errors?.required">

								<ion-text color="danger" class="error-msg">
									Please select country
								</ion-text>
							</label>
						</ion-col>
					</ion-row>
					<ion-row class="mb-5">
						<ion-col>
							<ion-label>State <span
									[ngClass]="isSubmitted && address.controls[i].get('state').errors?.required ? 'error-msg' : ''">*</span></ion-label>
							<ng-select placeholder="Select State" [loading]="isStateLoading"
								(change)="stateChange($event, i, true)" class="select-input mt-5" [items]="stateList"
								bindLabel="state" bindValue="state_id" formControlName="state" [clearable]="false"
								[searchable]="false">
							</ng-select>
							<label class="error-msg"
								*ngIf="isSubmitted && address.controls[i].get('state').errors?.required">
								<ion-text color="danger" class="error-msg">
									Please select state
								</ion-text>
							</label>
						</ion-col>
					</ion-row>
					<ion-row class="mb-5">
						<ion-col>
							<ion-label class="mb-5">District <span
									[ngClass]="isSubmitted && address.controls[i].get('district').errors?.required ? 'error-msg' : ''">*</span></ion-label>
							<ng-select placeholder="Select District" [loading]="isDistrictLoading"
								class="select-input mt-5" [items]="districtList" bindLabel="district"
								bindValue="district_id" formControlName="district" (change)="districtChange($event, i)"
								[clearable]="false" [searchable]="false">
							</ng-select>
							<label class="error-msg"
								*ngIf="isSubmitted && address.controls[i].get('district').errors?.required">
								<ion-text color="danger" class="error-msg">
									Please select district
								</ion-text>

							</label>

						</ion-col>

					</ion-row>
					<ion-row class="mb-5">
						<ion-col>
							<ion-label class="mb-5">Taluka <span
									[ngClass]="isSubmitted && address.controls[i].get('taluka').errors?.required ? 'error-msg' : ''">*</span></ion-label>
							<ng-select placeholder="Select Taluka" [loading]="isCityLoading" class="select-input mt-5"
								[items]="talukaList" bindLabel="taluka" bindValue="taluka_id" formControlName="taluka"
								[clearOnBackspace]="talukaList && talukaList.length ? false : true"
								[clearable]="talukaList && talukaList.length ? false : true"
								(change)="talukaChange($event, i)"
								[addTag]="talukaList && talukaList.length ? false : true" [addTagText]="'Select'"
								[editableSearchTerm]="talukaList && talukaList.length ? false : true"
								[searchable]="talukaList && talukaList.length ? false : true">
							</ng-select>
							<label class="error-msg"
								*ngIf="isSubmitted && address.controls[i].get('taluka').errors?.required">
								<ion-text color="danger" class="error-msg">
									Please select taluka
								</ion-text>
							</label>

						</ion-col>
					</ion-row>
					<ion-row class="mb-5">
						<ion-col>
							<ion-label>Zipcode <span
									[ngClass]="isSubmitted && address.controls[i].get('zipcode').errors?.required ? 'error-msg' : ''">*</span></ion-label>
							<ion-input capitalizeFirst formControlName="zipcode" placeholder="Enter Zipcode"
								(keypress)="numberOnlyValidation($event)" minlength="6" maxlength="6"></ion-input>

							<label class="error-msg"
								*ngIf="isSubmitted && address.controls[i].get('zipcode').errors?.required">
								<ion-text color="danger" class="error-msg">
									Please enter Zipcode
								</ion-text>
							</label>
							<label class="error-msg"
								*ngIf="isSubmitted && address.controls[i].get('zipcode').errors?.minlength">
								<ion-text color="danger" class="error-msg">
									Please enter minimum 6 character
								</ion-text>
							</label>
						</ion-col>
					</ion-row>
					<ion-row class="mb-5">
						<ion-col>
							<ion-label>GST Number <span
									[ngClass]="isSubmitted && address.controls[i].get('gst').errors?.required ? 'error-msg' : ''">*</span></ion-label>
							<ion-input capitalizeFirst formControlName="gst" placeholder="Enter GST Number"
								(keypress)="restrictSpecialChar($event)" minlength="15" maxlength="15"
								(ionChange)="gstNoChange(i)"></ion-input>
							<label class="error-msg"
								*ngIf="isSubmitted && address.controls[i].get('gst').errors?.required">
								<ion-text color="danger" class="error-msg">
									Please enter GST number
								</ion-text>

							</label>
							<label class="error-msg"
								*ngIf="isSubmitted && address.controls[i].get('gst').errors?.invalidGST">
								<ion-text color="danger" class="error-msg">
									Please enter valid GST number
								</ion-text>

							</label>
						</ion-col>
					</ion-row>
					<div lines="none" mode="md" class="checkbox-item mt-10 ml-5" *ngIf="i == 0">
						<ion-checkbox slot="start" class="mr-10 checkbox" formControlName="warehouseAddressCheck"
							[ngModelOptions]="{standalone: true}"
							(ionChange)="onWarehouseAddressChange($event)"></ion-checkbox>
						<ion-label class="checkbox-label">
							Dispatch location same as HQ address
						</ion-label>
						<ng-container *ngIf="!isWareHouseAddressCheck">
							<ion-row class="mt-15 mb-5">
								<ion-col>
									<div class="mapOption mb-10">
										<ion-label>Dispatch location <span
												[ngClass]="isSubmitted && address.controls[0].get('wareHouseAddress').errors?.required ? 'error-msg' : ''">*</span></ion-label>
										<ion-input placeholder="Enter Dispatch Location"
											formControlName="wareHouseAddress"
											(ionChange)="getWareHouseAddressPlaceAutocomplete($event)"
											(keypress)="wareHouseRestrictSpecialChar()"></ion-input>
										<span class="error-text absolute" *ngIf="wareHouseIsAutoSelected"> Please add
											valid
											warehouse address
										</span>
										<ion-list class="autoAddress" *ngIf="wareHouseAddressPredictionLength > 0"
											class="autocomplete">
											<div class="items" *ngFor="let item of wareHouseAutocompleteItems"
												(click)="wareHouseSelectSearchResult(item)">{{
												item.description }}</div>
										</ion-list>
										<label class="error-msg"
											*ngIf="isSubmitted && address.controls[0].get('wareHouseAddress').errors?.required">
											<ion-text color="danger" class="error-msg">
												Please enter warehouse address
											</ion-text>

										</label>
									</div>
								</ion-col>
							</ion-row>
							<ion-row class="mb-5">
								<ion-col>
									<ion-label>Country <span
											[ngClass]="isSubmitted && address.controls[0].get('wareHouseAddressCountry').errors?.required ? 'error-msg' : ''">*</span></ion-label>
									<ng-select placeholder="Select Country" class="select-input mt-5"
										(change)="countryChange($event, 0)" [items]="countryList" bindLabel="country"
										bindValue="country_id" formControlName="wareHouseAddressCountry"
										[clearable]="false" [searchable]="false">
									</ng-select>
									<label class="error-msg"
										*ngIf="isSubmitted && address.controls[0].get('wareHouseAddressCountry').errors?.required">
										<ion-text color="danger" class="error-msg">
											Please select country
										</ion-text>
									</label>
								</ion-col>
							</ion-row>
							<ion-row class="mb-5">
								<ion-col>
									<ion-label>State <span
											[ngClass]="isSubmitted && address.controls[0].get('wareHouseAddressState').errors?.required ? 'error-msg' : ''">*</span></ion-label>
									<ng-select placeholder="Select State" [loading]="isStateLoading"
										class="select-input mt-5" [items]="stateList" bindLabel="state"
										bindValue="state_id" (change)="stateChange($event, 0)"
										formControlName="wareHouseAddressState" [clearable]="false"
										[searchable]="false">
									</ng-select>
									<label class="error-msg"
										*ngIf="isSubmitted && address.controls[0].get('wareHouseAddressState').errors?.required">
										<ion-text color="danger" class="error-msg">
											Please select state
										</ion-text>
									</label>
								</ion-col>
							</ion-row>
							<ion-row class="mb-5">
								<ion-col>
									<ion-label class="mb-5">District <span
											[ngClass]="isSubmitted && address.controls[0].get('wareHouseAddressDistrict').errors?.required ? 'error-msg' : ''">*</span></ion-label>
									<ng-select placeholder="Select District" [loading]="isDistrictLoading"
										class="select-input mt-5" [items]="districtList" bindLabel="district"
										bindValue="district_id" (change)="districtChange($event, 0, true)"
										formControlName="wareHouseAddressDistrict" [clearable]="false"
										[searchable]="false">
									</ng-select>
									<label class="error-msg"
										*ngIf="isSubmitted && address.controls[0].get('wareHouseAddressDistrict').errors?.required">
										<ion-text color="danger" class="error-msg">
											Please select district
										</ion-text>

									</label>
								</ion-col>

							</ion-row>
							<ion-row class="mb-5">
								<ion-col>
									<ion-label class="mb-5">Taluka <span
											[ngClass]="isSubmitted && address.controls[0].get('taluka').errors?.required ? 'error-msg' : ''">*</span>
									</ion-label>
									<ng-select placeholder="Select Taluka" [loading]="isCityLoading"
										class="select-input mt-5" (change)="talukaChange($event, 0, true)"
										[items]="talukaList" bindLabel="taluka" bindValue="taluka_id"
										formControlName="wareHouseAddressTaluka"
										[clearOnBackspace]="talukaList && talukaList.length ? false : true"
										[clearable]="talukaList && talukaList.length ? false : true"
										[addTag]="talukaList && talukaList.length ? false : true"
										[addTagText]="'Select'"
										[editableSearchTerm]="talukaList && talukaList.length ? false : true"
										[searchable]="talukaList && talukaList.length ? false : true">
									</ng-select>
									<label class="error-msg"
										*ngIf="isSubmitted && address.controls[0].get('wareHouseAddressTaluka').errors?.required">
										<ion-text color="danger" class="error-msg">
											Please select taluka
										</ion-text>
									</label>
								</ion-col>
							</ion-row>
							<ion-row class="mb-5">
								<ion-col>
									<ion-label>Zipcode <span
											[ngClass]="isSubmitted && address.controls[0].get('wareHouseAddressZipcode').errors?.required ? 'error-msg' : ''">*</span></ion-label>
									<ion-input capitalizeFirst formControlName="wareHouseAddressZipcode"
										placeholder="Enter Zipcode" (keypress)="numberOnlyValidation($event)"
										minlength="6" maxlength="6"></ion-input>
									<label class="error-msg"
										*ngIf="isSubmitted && address.controls[0].get('wareHouseAddressZipcode').errors?.required">
										<ion-text color="danger" class="error-msg">
											Please enter Zipcode
										</ion-text>
									</label>
									<label class="error-msg"
										*ngIf="isSubmitted && address.controls[0].get('wareHouseAddressZipcode').errors?.minlength">
										<ion-text color="danger" class="error-msg">
											Please enter minimum 6 character
										</ion-text>
									</label>
								</ion-col>
							</ion-row>
						</ng-container>
					</div>
				</div>
				<div class="form-button mt-30 ml-5">
					<button class="new-address clear-btn w-200" (click)="addAddress()">New Address</button>
				</div>
			</div>
			<div class="upload mt-25 pb-15">
				<div class="title pl-5 fs-16 fw-600">
					Upload Documents
				</div>
				<app-document (uploadEvent)="getUploadData($event)"
					[customEventFromParent]="customEvent | async"></app-document>
			</div>
			<div class="categories pb-15">
				<app-categories [isCategoryRequired]="true" [categoryNotChecked]="categoryNotChecked"
					[heading]="'Categories'"
					[subHeading]="'Choose Categories Of Products You Deal With'"></app-categories>
			</div>

			<div class="form-inputs">
				<div lines="none" mode="md" class="checkbox-item mb-5">
					<ion-checkbox slot="start" class="mr-10 checkbox" formControlName="termsCondition"
						[ngModelOptions]="{standalone: true}"></ion-checkbox>
					<ion-label class="checkbox-label">
						Agree to <span class="text-link pointer" (click)="termsPopUp()"> Terms of
							use</span> & <span (click)="privacyPopUp()" class="text-link pointer">Privacy
							policy</span>
					</ion-label>
				</div>
				<ng-container *ngFor="let validation of validation_messages.termsCondition">
					<ng-container
						*ngIf="registrationForm.get('termsCondition').hasError(validation.type) && registrationForm.get('termsCondition').dirty">
						<ion-text color="danger" class="error-msg">
							{{validation.message }}
						</ion-text>
					</ng-container>
				</ng-container>
			</div>
		</form>
	</div>



	<div class="form-container d-flex form-button mt-30">
		<button class="submit-btn w-152 mr-20 mb-10" [disabled]="isLoading" (click)="onSellerRegister()">
			<ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
			Register
		</button>
		<button class="clear-btn w-147" (click)="cancel()">Cancel</button>
	</div>
</div>