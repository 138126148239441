import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-company-info',
    templateUrl: './company-info.component.html',
    styleUrls: ['./company-info.component.scss'],
})
export class CompanyInfoComponent implements OnInit {
    @Input() formGroup!: FormGroup<any>;
    isLoading = false;
    @Input() fieldName: any = {};
    public validation_messages = {
        company_name: [
            { type: 'required', message: 'Please enter enterprise/company name' },
            { type: 'invalidName', message: 'Please enter a valid enterprise/company name' },
            { type: 'maxlength', message: 'Enterprise/company name cannot be more than 50 character' },
        ],
        email: [
            { type: 'required', message: 'Please enter email' },
            { type: 'invalidEmail', message: 'Enter valid email id' },
        ],
        phone_no: [
            { type: 'required', message: 'Please enter mobile number' },
            { type: 'invalidNumber', message: 'Enter valid mobile number' }
        ],

    }

    constructor() { }

    ngOnInit() {
    }

    restrictSpecialChar(event) {
        var k;
        k = event.charCode;
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 39 || (k >= 48 && k <= 57));
    }

    phoneClick(e) {
        if (e.keyCode < 48 || e.keyCode > 57) {
            e.preventDefault();
        }
    }

}
