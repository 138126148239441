import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-payment-gateway-waiting-modal',
	templateUrl: './payment-gateway-waiting-modal.component.html',
	styleUrls: ['./payment-gateway-waiting-modal.component.scss'],
})
export class PaymentGatewayWaitingModalComponent implements OnInit {

	constructor() { }

	ngOnInit() { }

}
