<div class="delivery autoComplete">
	<div class="close-div p-5" (click)="close()">
		<img alt="Close Icon" src="/assets/icon/close.svg" slot="end" class="close-icon">
	</div>
	<div class="heading-label fs-28 fw-600 mb-0 text-center">
		<h1 class="fs-28 fw-600">Choose delivery address</h1>
	</div>
	<div class="mb-30 fs-18 fw-400 text-center desc">
		<p>Please provide the delivery location for your order.</p>
	</div>
	<div class="flex flex-center">
		<ion-row class="address-row">
			<ion-col class="address-col" size="12" *ngIf="!commonService.isLoggedIn">
				<div class="mapOption">
					<ion-input type="text" appBlockCopyPaste #searchInput (ionChange)="getPlaceAutocomplete($event)"
						(keypress)="addressChange()" (ionFocus)="addressFocus()" class="custom-input"
						placeholder="Search your address" [(ngModel)]="addressValue">
						<img alt="Map Icon" class="input-icon" src="/assets/icon/map.svg">
					</ion-input>
					<div class="pt-5">
						<span class="error-text error-msg" *ngIf="isAutoSelected || !addressValue"> Please add valid
							address </span>
						<span class="error-text error-msg" *ngIf="googleNotFoundAddress">
							Unable to find the given address in google suggestions please enter a valid address
						</span>
					</div>
					<ion-list class="autoAddress pt-0" *ngIf="autocompleteItems.length > 0" class="autocomplete">
						<div class="items" *ngFor="let item of autocompleteItems" (click)="selectSearchResult(item)">{{
							item.description }}</div>
					</ion-list>
				</div>
			</ion-col>
			<ion-col class="address-col" size="12" *ngIf="commonService.isLoggedIn">
				<div class="mapOption">
					<ng-container *ngIf="addressDetails">
						<ng-select class="select-input mt-5" placeholder="Select delivery location"
							[items]="addressDetails" bindLabel="address" bindValue="address_id"
							[(ngModel)]="addressValue" [clearable]="false" [searchable]="true"
							(ngModelChange)="shippingAddressChange($event)">
							<ng-option *ngFor="let option of addressDetails" [value]="option.address_id"
								[attr.disabled]="option.disabled">
								{{ option.address }}
							</ng-option>
						</ng-select>
						<div class="address-container" *ngIf="isLoading">
							<ion-skeleton-text class="skeleton-container width-100" name="lines-sharp-small"
								[animated]="true"></ion-skeleton-text>
						</div>
						<div class="pt-5">
							<span class="error-text error-msg" *ngIf="isAutoSelected || !addressValue"> Please add valid
								address </span>
						</div>
					</ng-container>

					<div class="delivery-btn mv-30 align-center space-between" *ngIf="!addressDetails">

						<span class="error-text error-msg">
							No Address found
						</span>

						<button class="submit-btn w-200" [routerLink]="'/account-details/addresses/add-address'">
							Add Address
						</button>
					</div>

				</div>
			</ion-col>
			<ion-col size="12" class="location-tag-col mb-10 mt-10">
				<ng-select class=" select-input mt-5" placeholder="Select a location tag" [items]="locationTag"
					bindLabel="name" bindValue="id" (focus)="locationFocus()" (change)="locationChange($event)"
					[(ngModel)]="locationSelection" [clearable]="false">
				</ng-select>
				<ng-option *ngFor="let option of locationTag" [value]="option.address_type_id"
					[attr.disabled]="option.disabled">
					{{ option.address_type }}
				</ng-option>
				<div class="pt-5">
					<ion-text *ngIf="isLocationTagError" class="error-text error-msg mt-m10">
						Please select location tag
					</ion-text>
				</div>
			</ion-col>
		</ion-row>
	</div>
	<div class="delivery-btn mt-30 text-center" *ngIf="addressDetails">
		<button class="submit-btn w-200" (click)="onSubmit()">
			Submit
		</button>
	</div>




</div>