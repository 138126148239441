import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/services/api-data.service';
import { ProductDetailPageService } from 'src/app/services/product-detail-page.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { CommonService } from '../../services/common.service';
@Component({
    selector: 'app-sub-categories',
    templateUrl: './sub-categories.component.html',
    styleUrls: ['./sub-categories.component.scss'],
})
export class SubCategoriesComponent implements OnInit, OnDestroy {
    public subscription = new Subscription();
    selectedId: number;
    public categoryData: any;
    productsList = [];
    isMenuVisible: boolean = false;


    constructor(
        private router: Router,
        private navCtrl: NavController,
        public commonService: CommonService,
        private location: Location,
        private dataService: ApiService,
        private toasterService: ToasterService,
        private productDetailPageService: ProductDetailPageService
    ) {
        this.subscription = this.commonService.categoryData.subscribe(data => {
            if (data) {
                this.categoryData = data.categoryData;
                if (this.categoryData.sub_categories && this.categoryData.sub_categories.length > 0) {
                    this.categoryData.sub_categories[0].isMenuVisible = true;
                    this.getProductList(this.categoryData.sub_categories[0].category_id);
                }
            }
        });
    }

    ngOnInit() {
        let categoryDataFromLocal = JSON.parse(this.commonService.localStorageGet("categories_data_for_mobile"));
        if (categoryDataFromLocal) {
            this.categoryData = categoryDataFromLocal;
            if (this.categoryData.sub_categories && this.categoryData.sub_categories.length > 0) {
                this.categoryData.sub_categories[0].isMenuVisible = true;
                this.getProductList(this.categoryData.sub_categories[0].category_id);
            }
        }
        this.selectedCategory = {};
        this.getCategories()
    }



    async openItem(menu, option) {
        this.productDetailPageService.goToProductDetail(option, true);
    }

    back() {
        this.location.back();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    toggleMenu(data) {
        data.isMenuVisible = !data.isMenuVisible;
        this.categoryData.sub_categories.forEach(subCategory => {
            if (subCategory !== data && subCategory.isMenuVisible) {
                subCategory.isMenuVisible = false;
            }
        });
    }

    getProductList(categoryId) {
        return new Promise((resolve, reject) => {
            let params = {};
            params = {
                category_id: categoryId
            };

            this.subscription.add(
                this.dataService.get(URLS.getProducts, params).subscribe(data => {
                    if (data['code'] == 200) {
                        if (data['data']) {
                            let products = data['data']['productsList'];
                            if (products && products.length) {
                                this.productsList[categoryId] = [];
                                products.forEach(element => {
                                    let obj = {
                                        img: element.image_url ? element.image_url : "../../../assets/product/product7.svg",
                                        name: element.product_name ? element.product_name : "--",
                                        unit_name: element.unit_name ? element.unit_name : "--",
                                        type: element.category_name ? element.category_name : "--",
                                        product_id: element.product_id ? element.product_id : null,
                                        sub_product_id: element.sub_product_id ? element.sub_product_id : null,
                                        product_brand_mapping_id: element.product_brand_mapping_id ? element.product_brand_mapping_id : null,
                                        product_package_mapping_id: element.product_package_mapping_id ? element.product_package_mapping_id : null,
                                        category: element.category_name ? element.category_name : null,
                                        category_id: element.category_id ? element.category_id : null,
                                        is_new: element.is_new ? element.is_new : false,
                                        rating_avg: element.productAvgRatings
                                    };
                                    if (element.product_reviews_ratings && element.product_reviews_ratings.length) {
                                        obj['ratings'] = element.product_reviews_ratings[0]['rating_avg'] ? element.product_reviews_ratings[0]['rating_avg'] : null;
                                    }
                                    this.productsList[categoryId].push(obj);
                                });
                            }
                        }
                        resolve(data);
                    } else {
                        reject(data);
                        this.toasterService.errorToast(data['message']);
                    }
                }, error => {
                    reject(error);
                    this.toasterService.errorToast(error['message']);
                })
            );
        });
    }

    categoriesData: any[] = []
    getCategories() {
        return new Promise((resolve, reject) => {
            let params = {};
            this.subscription.add(
                this.dataService.get(URLS.masterCategory, params).subscribe(data => {
                    if (data['code'] == 200 && data['data']['masterCategoryList'].length > 0) {
                        this.categoriesData = data['data']['masterCategoryList'];
                        this.categoriesData.forEach(element => {
                            element.image_url = element.master_category_image_url ? element.master_category_image_url : '../../../assets/product/product7.svg';
                            element.name = element.master_category_name
                        });
                        resolve(data);
                    }
                    else {
                        reject(data);
                        this.categoriesData = [];
                    }
                }, error => {
                    reject(error)
                    this.categoriesData = [];
                })
            )
        })
    }

    selectedCategory: any;
    async categorieClick(data) {
        this.selectedCategory = data;
        await this.navCtrl.navigateForward('/sub-categories', { animated: false });
        this.commonService.emitCategoryData(data);
        this.commonService.localStorageSet("categories_data_for_mobile", JSON.stringify(data));
    }
};

