import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CustomValidator } from 'src/app/_common/customvalidators';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/services/api-data.service';
import { CommonService } from 'src/app/services/common.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { ModalService } from '../../services/modal.service';
import { GetPlatformService } from './../../services/get-platform.service';

@Component({
    selector: 'app-escrow',
    templateUrl: './escrow.component.html',
    styleUrls: ['./escrow.component.scss'],
})
export class EscrowComponent implements OnInit {
    @Input() shoppingCartBTnText;
    @Input() order_Id = null;
    @Input() hideCloseBtn: any;
    @Input() submitBtnText: any;
    @Input() submitBtnLink?: any;
    @Input() cancelBtnText: any;
    @Input() cancelBtnLink?: any;
    @Input() modalType = null;
    utrNo: any;
    isValidUTR: any;

    public subscription = new Subscription();
    isLoading: boolean = false;

    constructor(
        private modalService: ModalService,
        private navCtrl: NavController,
        private commonService: CommonService,
        private toasterService: ToasterService,
        private dataService: ApiService,
        public getPlatformService: GetPlatformService

    ) { }

    ngOnInit() { }

    onCancel() {
        this.modalService.dismissModal();
    }

    redirectToCart() {
        this.navCtrl.navigateBack('/home/cart', { animated: false });
        this.onCancel();
    }


    utrValidation() {
        this.isValidUTR = CustomValidator.UTRValidator(this.utrNo);
    }

    onSubmit() {
        let URL = '';
        this.isValidUTR = CustomValidator.UTRValidator(this.utrNo);
        if (this.isValidUTR && this.isValidUTR.invalidUTR) {
            this.toasterService.errorToast("Please enter valid UTR number");
            return;
        }
        const params = {
            "order_id": this.order_Id,
            "utr_number": this.utrNo
        }
        this.isLoading = true;
        if (this.modalType == 'customer_order') {
            this.subscription.add(
                this.dataService.post(URLS.customerOrderUTR, params).subscribe(data => {
                    if (data['code'] == 200) {
                        this.toasterService.successToast(data['message']);
                        this.modalService.dismissModal(true);
                    }
                    else {
                        this.toasterService.errorToast(data['message']);
                    }
                    this.isLoading = false;
                }, error => {
                    this.isLoading = false;
                    this.toasterService.errorToast(error['message']);

                })
            )
        } else {
            this.subscription.add(
                this.dataService.put(URLS.orderUTRUpdate, params).subscribe(data => {
                    if (data['code'] == 200) {
                        this.toasterService.successToast(data['message']);
                        this.modalService.dismissModal(true);
                    }
                    else {
                        this.toasterService.errorToast(data['message']);
                    }
                    this.isLoading = false;
                }, error => {
                    this.isLoading = false;
                    this.toasterService.errorToast(error['message']);

                })
            )
        }


    }

    redirect() {
        this.navCtrl.navigateRoot(this.cancelBtnLink, { animated: false });
        this.onCancel();
    }
}
