<div class="mt-m20 bank-form">

	<div class="display-flex space-between mt-10"
		[ngClass]="!isSellerApproval && !getPlatformService.isDesktop?'add-bank-container':''">
		<div class="title fs-16 pl-5 fw-600 pb-10">
			Bank Details
		</div>
		<div class=" ml-7 justify-end display-flex" *ngIf="!isSellerApproval && !getPlatformService.isDesktop">
			<button (click)="addMoreBank()" type="button" class="fiter-btn add-bank-btn w-138">
				Add Bank
			</button>
		</div>
	</div>

	<form [formGroup]="bankForm" class="mt-10">
		<div formArrayName="banks">

			<div class="border mb-10" *ngFor="let add of bank?.controls; let i = index" [formGroupName]="i">
				<ion-row class="mb-5">
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="4" size-xl="4">
						<ion-label>Bank Account Number *</ion-label>
						<ion-input formControlName="account_no" placeholder="Enter Bank Account Number" maxlength="18"
							type="tel" (keypress)="isnumeric($event)" inputmode="numeric"></ion-input>
						<ng-container *ngFor="let validation of validation_messages.account_no">
							<ng-container
								*ngIf="bank.controls[i].get('account_no').hasError(validation.type) && bank.controls[i].get('account_no').dirty">
								<ion-text (keypress)="isNumber($event)" color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>
					</ion-col>

					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="4" size-xl="4">
						<ion-label>Account Holder Name *</ion-label>
						<ion-input class="pt-20" formControlName="account_holder"
							placeholder="Enter Account Holder Name"></ion-input>
						<ng-container *ngFor="let validation of validation_messages.account_holder">
							<ng-container
								*ngIf="bank.controls[i].get('account_holder').hasError(validation.type) && bank.controls[i].get('account_holder').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>

					</ion-col>

					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="3" size-xl="3">
						<ion-label>IFSC Code *</ion-label>
						<ion-input placeholder="Enter IFSC Code" formControlName="ifsc" maxlength="11" type="text"
							(keypress)="restrictSpecialChar($event)" inputmode="text">
						</ion-input>
						<ng-container *ngFor="let validation of validation_messages.ifsc">
							<ng-container
								*ngIf="bank.controls[i].get('ifsc').hasError(validation.type) && bank.controls[i].get('ifsc').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>
					</ion-col>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="1" size-xl="1">
						<ng-container *ngIf="!isSellerApproval">
							<div class="display-flex mt-30 delete-icon justify-end" *ngIf="bank.length > 1">
								<div class="tooltip  pointer " data-tooltip="Delete">
									<img alt="Image" data-tooltip="Delete"
										(click)="isDeleteShow ? removeBank(i,add) : null"
										*ngIf="!add.value.approval_status" src=" ./assets/icon/delete.svg"
										class="ml-15 pointer tooltip">
								</div>
							</div>
						</ng-container>

					</ion-col>
				</ion-row>

				<div *ngFor="let data of bank.value">
					<p class="error-text"
						*ngIf="bank.controls[i].get('account_no').value == data.account_no && data.error">
						{{data.error}}</p>
				</div>

				<div class="mb-5 ml-5" [ngClass]="bank.length > 1 ? 'default-sec' : ''">
					<div class="name check-box-container fs-16 fw-500">
						<ion-checkbox slot="start" formControlName="isDefault"
							(ionChange)="setDefaultBank($event, i, add)"></ion-checkbox>
						<ion-label (click)="labelClick($event, i, add)" class="ml-10 label-cursor">Default
							Bank</ion-label>
					</div>
					<ng-container *ngIf="!isSellerApproval">
						<div class="mt-20" *ngIf="add.value.isEdit">
							<button [disabled]="isBankLoading || add.value.approval_status" (click)="update(add)"
								type="button" class="mr-20 submit-btn w-138">
								<ion-spinner class="spinner-btn" name="lines-sharp-small"
									*ngIf="isBankLoading"></ion-spinner>
								Update
							</button>
						</div>
					</ng-container>
				</div>

			</div>
		</div>
	</form>
</div>
<div class="mt-20 ml-7 justify-end display-flex" *ngIf="!isSellerApproval && getPlatformService.isDesktop">
	<button (click)="addMoreBank()" type="button" class="fiter-btn w-138">
		Add Bank
	</button>
</div>


<div class="form-button mt-30 ml-5 mt-20 flex-center" *ngIf="!isSellerApproval && isSubmitVisible">
	<button type="button" [disabled]="isLoading" (click)="onBankSubmit()" class="mr-20 submit-btn w-138">
		<ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
		Save
	</button>
	<button class="clear-btn w-147" (click)="cancel()">Cancel</button>
</div>