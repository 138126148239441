<div class="success-container">
    <div class="close-div p-5" (click)="close()">
        <img alt="Close Icon" src="/assets/icon/close.svg" alt="" slot="end" class="close-icon">
    </div>
    <div class="description-container">
        <img alt="Success Icon" src="/assets/svg/success.svg" alt="" class="pb-20">

        <p class="description-title fs-28 fw-b">{{title}}</p>
        <p class="description-text fs-16 fw-500">{{description}}!</p>
    </div>
</div>