import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { LocationService } from 'src/app/services/location.service';
import { MasterApiService } from '../../services/master-api.service';

declare var google: any;

@Component({
    selector: 'app-enterprise-detail',
    templateUrl: './enterprise-detail.component.html',
    styleUrls: ['./enterprise-detail.component.scss'],
})
export class EnterpriseDetailComponent implements OnInit {
    isEnterpriced = false;
    addressTitle: string = '';
    @Input() formGroup!: FormGroup<any>;
    @Input() maxHeight = false;
    @ViewChild("searchInput") public searchInput: ElementRef;
    @Input() companyAddress: boolean = false;
    autocompleteItems: any = [];
    GoogleAutocomplete: any;
    latitude;
    longitude;
    callGoogleApi = true;
    geocoder;
    public isAutoSelected = false;
    country_id = 1;
    state_id = null;
    districtList: any = [];
    districtCount = 0;
    isStateLoading = false;
    district_id = null;
    taluka_id = null;
    talukaList: any = [];
    talukaCount = 0;
    isDistrictLoading = false;
    isCountryLoading = false;
    countryList: any = [];
    stateList: any = [];
    stateCount = 0

    public company_validation_messages = {
        company_name: [
            { type: 'required', message: 'Please enter company name' },
            { type: 'invalidName', message: 'Please enter a valid company name' },
        ],
        hq_location: [
            { type: 'required', message: 'Please select loaction' },
        ],
        country: [
            { type: 'required', message: 'Please select country' },
        ],
        state: [
            { type: 'required', message: 'Please select state' },
        ],
        district: [
            { type: 'required', message: 'Please select district' },
        ],
        taluka: [
            { type: 'required', message: 'Please select taluka' },
        ],
        zipcode: [
            { type: 'required', message: 'Please select zipcode' },
            { type: 'minLength', message: 'Please enter minimum 6 character' },
        ],
    }
    constructor(
        public locationService: LocationService,
        public commonservice: CommonService,
        private masterApi: MasterApiService
    ) { }

    ngOnInit() {
        this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
        this.geocoder = new google.maps.Geocoder;
        this.autocompleteItems = [];
    }

    ionViewWillEnter() {
        this.autocompleteItems = [];
    }

    async getPlaceAutocomplete(val) {
        if (val.srcElement && this.formGroup.controls.hq_location.value) {
            val.srcElement.children[1].attributes[8].nodeValue = this.formGroup.controls.hq_location.value;
        }

        const inputValue = val.target.value;
        if (inputValue.trim() === '') {
            this.autocompleteItems = [];
            this.formGroup.patchValue({
                hq_location: null
            });
            return;
        }
        if (this.callGoogleApi) {
            this.formGroup.controls.country.reset();
            this.formGroup.controls.state.reset();
            this.formGroup.controls.district.reset();
            this.formGroup.controls.taluka.reset();
            this.formGroup.controls.zipcode.reset();
            this.countryList = [];
            this.stateList = [];
            this.districtList = [];
            this.talukaList = [];
            await this.locationService.getPlaceAutocomplete(this.GoogleAutocomplete, inputValue);
            this.autocompleteItems = this.locationService.autocompleteItems;
            if (this.autocompleteItems.length > 0) { this.commonservice.isAutoSelected = false };
        } else {
            setTimeout(() => {
                this.callGoogleApi = true;
            }, 500);
        }

    }

    /**
  * SELECT SPECIF LOCATION AND REDIRECTING ON SAME THROUGH MAP 
 */
    selectSearchResult(item, formcontrolName) {
        this.callGoogleApi = false;
        this.commonservice.isAutoSelected = false;
        setTimeout(() => {
            this.autocompleteItems = [];
        });
        this.locationService.getselectedAutoMap(this.geocoder, item, formcontrolName).then(data => {
            if (this.locationService.country_shortname) {
                this.getCountry(this.locationService.country_shortname);
            } else {
                this.getCountry(null);
            }
            if (this.locationService.state_shortname) {
                this.getState(this.locationService.state_shortname);
            } else {
                this.getState(null);
            }
            if (this.locationService.district_shortname) {
                this.getDistrict(this.locationService.district_shortname);
            } else {
                this.getDistrict(null);
            }
            if (this.locationService.taluka_shortname) {
                this.getTaluka(this.locationService.taluka_shortname);
            } else {
                this.getTaluka(null);
            }
            if (this.locationService.zipcode_shortname) {
                this.formGroup.controls.zipcode.setValue(this.locationService.zipcode_shortname);
            }
        })

    }

    addressChange() {
        this.commonservice.isAutoSelected = true;
    }

    getCountry(shortCode?) {
        if (shortCode) {
            return new Promise((resolve, reject) => {
                this.isCountryLoading = true;
                let param = {
                    short_name: shortCode
                }
                this.countryList = [];
                this.masterApi.getCountry(param).then((data) => {
                    this.isCountryLoading = false;
                    this.countryList = this.countryList.concat(data['data']['countriesList']);
                    if (this.countryList && this.countryList.length) {
                        this.formGroup.controls.country.setValue(this.countryList[0].country_id);
                    }
                    resolve(data);
                }, error => {
                    reject(error);
                    this.countryList = [];
                });
            })
        } else {
            return new Promise((resolve, reject) => {
                this.isCountryLoading = true;
                let param = {
                    short_name: "IN"
                }
                this.countryList = [];
                this.masterApi.getCountry(param).then((data) => {
                    this.isCountryLoading = false;
                    this.countryList = this.countryList.concat(data['data']['countriesList']);
                    if (this.countryList && this.countryList.length) {
                        this.formGroup.controls.country.setValue(this.countryList[0].country_id);
                    }
                    resolve(data);
                }, error => {
                    reject(error);
                    this.countryList = [];
                });
            })
        }
    }

    getState(shortCode?) {
        return new Promise((resolve, reject) => {
            let params = {
                short_name: shortCode
            }

            this.isStateLoading = true;
            this.stateList = [];
            this.masterApi.getState(params).then((data) => {
                this.isStateLoading = false;
                this.stateList = this.stateList.concat(data['data']['statesList']);
                this.stateCount = data['data']['total_count'];
                if (this.stateList && this.stateList.length) {
                    this.formGroup.controls.state.setValue(this.stateList[0].state_id);
                    this.state_id = this.stateList[0].state_id
                }
                resolve(data);
            }, error => {
                reject(error);
                this.isStateLoading = false;
                this.stateList = [];
                const params = {
                    country_id: this.country_id
                }
                this.masterApi.getState(params).then((data) => {
                    this.isStateLoading = false;
                    this.stateList = this.stateList.concat(data['data']['statesList']);
                    this.stateCount = data['data']['total_count'];
                    resolve(data);
                }, error => {
                    reject(error);
                    this.isStateLoading = false;
                    this.stateList = [];
                });
            });
        })

    }


    getDistrict(shortCode?) {
        if (shortCode) {
            return new Promise((resolve, reject) => {
                let params = {
                    short_name: shortCode
                }
                this.districtList = [];
                this.masterApi.getDistrict(params).then((data) => {
                    this.districtList = this.districtList.concat(data['data']['districtList']);
                    this.districtCount = data['data']['total_count'];
                    if (this.districtList && this.districtList.length) {
                        this.formGroup.controls.district.setValue(this.districtList[0].district_id);
                        this.district_id = this.districtList[0].district_id;
                    }
                    resolve(data);
                }, error => {
                    reject(error);
                    this.districtList = [];
                    setTimeout(() => {
                        if (this.state_id) {
                            return new Promise((resolve, reject) => {
                                let params = {
                                    state_id: this.state_id
                                }
                                this.masterApi.getDistrict(params).then((data) => {
                                    this.districtList = this.districtList.concat(data['data']['districtList']);
                                    this.districtCount = data['data']['total_count'];
                                    resolve(data);
                                }, error => {
                                    reject(error);

                                });
                            })
                        }
                    }, 500);

                });
            })
        } else {
            setTimeout(() => {
                if (this.state_id) {
                    return new Promise((resolve, reject) => {
                        let params = {
                            state_id: this.state_id
                        }
                        this.districtList = [];
                        this.masterApi.getDistrict(params).then((data) => {
                            this.districtList = this.districtList.concat(data['data']['districtList']);
                            this.districtCount = data['data']['total_count'];
                            resolve(data);
                        }, error => {
                            reject(error);

                        });
                    })
                }
            }, 500);
        }
    }

    getTaluka(shortCode?) {
        if (shortCode) {
            return new Promise((resolve, reject) => {
                let params = {
                    short_name: shortCode
                }
                this.talukaList = [];
                this.masterApi.getTaluka(params).then((data) => {
                    this.talukaList = this.talukaList.concat(data['data']['talukaList']);
                    this.talukaCount = data['data']['total_count'];
                    if (this.talukaList && this.talukaList.length) {
                        this.formGroup.controls.taluka.setValue(this.talukaList[0].taluka_id);
                    }
                    resolve(data);
                }, error => {
                    reject(error)
                    this.talukaList = [];
                    setTimeout(() => {
                        if (this.district_id) {
                            const params = {
                                district_id: this.district_id
                            }
                            this.masterApi.getTaluka(params).then((data) => {
                                this.talukaList = this.talukaList.concat(data['data']['talukaList']);
                                this.talukaCount = data['data']['total_count'];
                                resolve(data);
                            }, error => {
                                reject(error)
                                this.talukaList = [];
                            });
                        }
                    }, 700);
                });
            })
        } else {
            setTimeout(() => {
                if (this.district_id) {
                    return new Promise((resolve, reject) => {
                        let params = {
                            district_id: this.district_id
                        }
                        this.talukaList = [];
                        this.masterApi.getTaluka(params).then((data) => {
                            this.talukaList = this.talukaList.concat(data['data']['talukaList']);
                            this.talukaCount = data['data']['total_count'];
                            resolve(data);
                        }, error => {
                            reject(error)
                            this.talukaList = [];
                        });
                    })
                }
            }, 700);
        }
    }

    countryChange(data) {
        this.country_id = data.country_id;
    }

    stateChange(data) {
        this.state_id = data.state_id;
        if (this.formGroup.controls.district.value) {
            this.formGroup.controls.district.reset();
        }
        if (this.formGroup.controls.taluka.value) {
            this.formGroup.controls.taluka.reset();
        }
        if (this.formGroup.controls.zipcode.value) {
            this.formGroup.controls.zipcode.reset();
        }
        if (data.state_id) {
            return new Promise((resolve, reject) => {
                let params = {
                    state_id: data.state_id
                }
                this.districtList = [];
                this.masterApi.getDistrict(params).then((data) => {
                    this.districtList = this.districtList.concat(data['data']['districtList']);
                    this.districtCount = data['data']['total_count'];
                    resolve(data);
                }, error => {
                    reject(error);
                    this.districtList = [];
                });
            })
        }
    }

    districtChange(data) {
        this.district_id = data.district_id;
        if (this.formGroup.controls.taluka.value) {
            this.formGroup.controls.taluka.reset();
        }
        if (this.formGroup.controls.zipcode.value) {
            this.formGroup.controls.zipcode.reset();
        }
        if (data.district_id) {
            return new Promise((resolve, reject) => {
                let params = {
                    district_id: data.district_id
                }
                this.talukaList = [];
                this.masterApi.getTaluka(params).then((data) => {
                    this.talukaList = this.talukaList.concat(data['data']['talukaList']);
                    this.talukaCount = data['data']['total_count'];
                    resolve(data);
                }, error => {
                    reject(error)
                    this.talukaList = [];
                });
            })
        }
    }

    talukaChange(data) {
        if (data) {
            this.taluka_id = data.taluka_id ? data.taluka_id : null;
        }
        if (this.formGroup.controls.zipcode.value) {
            this.formGroup.controls.zipcode.reset();
        }
    }

    numberOnlyValidation(event: any) {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

}

