<ng-container *ngIf="!isModal">
    <div class="home-page app-header">
        <app-header></app-header>
    </div>

    <ion-content *ngIf="!isLoading">
        <ion-grid class="page-scroll-top">
            <ion-row>
                <ion-col>
                    <div *ngIf="aboutUsData" class="about-us-container" [innerHTML]="aboutUsData | safe :'html'">
                    </div>
                    <div *ngIf="!aboutUsData" class="no-record-found">
                        No record
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
        <div class="wrapper-block home-page">
            <div class="app-footer">
                <app-footer></app-footer>
            </div>
        </div>
    </ion-content>

    <ion-content *ngIf="isLoading">
        <app-loader></app-loader>
    </ion-content>
</ng-container>
<ng-container *ngIf="isModal">
    <div class="cross-icon ion-text-right" (click)="onCancel()">
        <img alt="Close Icon" src="assets/icon/close.svg">
    </div>
    <ion-content *ngIf="!isLoading">
        <ion-grid class="modal-grid">
            <ion-row>
                <ion-col>
                    <div *ngIf="aboutUsData" class="about-us-container-modal" [innerHTML]="aboutUsData | safe :'html'">
                    </div>
                    <div *ngIf="!aboutUsData" class="no-record-found">
                        No record
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-content>

    <ion-content *ngIf="isLoading">
        <app-loader></app-loader>
    </ion-content>
</ng-container>