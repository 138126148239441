<div class="form-container login-container">
    <div class="heading-label fs-28 fw-600 mb-0">
        Log-In
    </div>
    <div class="mb-30 fs-16 fw-400">
        <p> New User? <span class="text-link pointer" (click)="createAccount()"> Create an account</span></p>
    </div>

    <div class="form-container unselectable">
        <form [formGroup]="loginFromGroup">
            <div class="email common-input mb-10">
                <ion-label> Email address/Phone number *</ion-label>
                <input type="text" class="autocomplete-off" />
                <ion-input type="email" placeholder="Enter Email Address/Phone Number" formControlName="username"
                    autocomplete="email" #usernameInput></ion-input>
                <ng-container
                    *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'username', 'formName': loginFromGroup} }">
                </ng-container>
            </div>
            <div class="password psw-position common-input">
                <ion-label> Password *</ion-label>
                <input type="text" class="autocomplete-off" />
                <ion-input placeholder="Enter Password" class="login-pass input-with-lock-icon" type="{{passwordType}}"
                    clearOnEdit="false" formControlName="password" (keydown.space)="$event.preventDefault();"
                    autocomplete="new-password">
                </ion-input>
                <img alt="Input Toggle Icon" class="input-icon input-toggle-icon" *ngIf="passwordType === 'text'"
                    (click)="passwordType = 'password'" src="/assets/icon/eye.svg">
                <img alt="Input Toggle Icon" class="input-icon input-toggle-icon" *ngIf="passwordType === 'password'"
                    src="../../../assets/icon/eye-off.svg" (click)="passwordType = 'text'">
                <ng-container
                    *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'password', 'formName': loginFromGroup} }">
                </ng-container>
            </div>
            <div (click)="forgetPass()" class="forgot-password  pt-30 pb-10">
                <span class="forgot fs-16 fw-600">Forgot password?</span>
            </div>
            <div class="login-bottom-container">
                <div class="login-button">
                    <button [disabled]="isLoading" class="submit-btn w-152" (click)="login()">
                        <ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
                        Log In
                    </button>
                </div>

            </div>
        </form>
    </div>
</div>
<ng-template let-formObject #validationMessages>
    <ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
        <ion-text color="danger" class="error-msg"
            *ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].dirty">
            {{validation.message}}
        </ion-text>
    </ng-container>
</ng-template>