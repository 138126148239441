import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { LocationService } from 'src/app/services/location.service';
import { CustomValidator } from './../../_common/customvalidators';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { MasterApiService } from './../../services/master-api.service';
import { ToasterService } from './../../services/toaster.service';


declare var google;

export interface AddCompanyAddress {
    address?: string;
    address_type_id?: number;
    other_address_type?: string;
    warehouse_name?: string;
    country_id?: number;
    state_id?: number;
    district_id?: number;
    taluka_id?: number;
    zip_code?: string;
    gst_number?: string;
    company_address_id?: number;
    contact_no?: string;
    country_code?: string;
    latitude?: string;
    longitude?: string;

}


@Component({
    selector: 'app-add-edit-company-address',
    templateUrl: './add-edit-company-address.component.html',
    styleUrls: ['./add-edit-company-address.component.scss'],
})
export class AddEditCompanyAddressComponent implements OnInit {
    addEditAddressForm: FormGroup;
    public validation_messages = {
        company_name: [
            { type: 'required', message: 'Please enter company name' },
            { type: 'invalidString', message: 'Please enter valid name' },
            { type: 'invalidName', message: 'Please enter valid name' },
            { type: 'maxlength', message: 'Company name should not be more than 50 characters long' }

        ],
        location_tag: [
            { type: 'required', message: 'Please select location tag' },
        ],
        warehouse_name: [
            { type: 'required', message: 'Please enter location name' },
            { type: 'invalidString', message: 'Please enter valid location name' },
            { type: 'invalidName', message: 'Please enter valid location name' },
            { type: 'maxlength', message: 'Location name should not be more than 50 characters long' }

        ],
        address: [
            { type: 'required', message: 'Please enter address' },
            { type: 'invalidString', message: 'Please enter valid address' },
            { type: 'invalidName', message: 'Please enter valid address' },
            { type: 'maxlength', message: 'Address should not be more than 200 characters long' }

        ],
        country: [
            { type: 'required', message: 'Please select country' },
        ],
        state: [
            { type: 'required', message: 'Please select state' }
        ],
        district: [
            { type: 'required', message: 'Please select district' }
        ],
        taluka: [
            { type: 'required', message: 'Please select taluka' }
        ],
        zipcode: [
            { type: 'required', message: 'Please enter zip code' },
            { type: 'invalidString', message: 'Please enter valid zip code' },
            { type: 'invalidZip', message: 'Please enter valid zip code' },
        ],
        contact_no: [
            { type: 'required', message: 'Please enter contact no' },
            { type: 'invalidString', message: 'Please enter valid contact no' },
            { type: 'invalidNumber', message: 'Please enter valid contact no' }
        ],
        gst: [
            { type: 'required', message: 'Please enter gst' },
            { type: 'minlength', message: 'GST should be 15 character long' },
            { type: 'maxlength', message: 'GST should be 15 character long' },
            { type: 'invalidGST', message: 'Please enter valid GST number' },

        ]
    }
    countryList: any = [];
    locationTag: any = [];
    cityList: any = []
    stateList: any = [];
    isCustomTagsDisabled: boolean = false;
    public subscription = new Subscription();
    isLoading = false;
    addressId: string;
    company_name = "";
    btnTxt = 'Save';
    locationName = 'Location';
    isCountryLoading = false;
    isStateLoading = false;
    isCityLoading = false;
    isDistrictLoading = false;
    stateCount = 0;
    cityCount = 0;
    countryCount = 0;
    currentCountryPage = 1;
    country_id = 1;
    state_id = null;
    selected_state_id = null;
    selected_district_id = null;
    district_id = null;
    selected_taluka_id = null;
    taluka_id = null;
    currentStatePage = 0;
    currentCityPage = 0;
    autocompleteItems: any = [];
    GoogleAutocomplete: any;
    latitude;
    longitude;
    callGoogleApi = true;
    geocoder;
    isAutoSelected = false;
    districtList: any = [];
    talukaList: any = [];
    talukaCount = 0;
    districtCount = 0;
    isSubmitted = false;
    isEditAddress = false;
    addressData: any;
    addressDetails: any;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private commonService: CommonService,
        private masterApi: MasterApiService,
        private dataService: ApiService,
        private toasterService: ToasterService,
        private navCtrl: NavController,
        private route: ActivatedRoute,
        private locationService: LocationService

    ) { }

    ngOnInit() {
        this.getCompanyDetail();
        this.commonService.scrollToTop();
        this.addEditAddressForm = this.initializeAddressForm();
        this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
        this.geocoder = new google.maps.Geocoder;
        this.autocompleteItems = [];
        this.getCountry();
        this.getAddressType();
        this.btnTxt = 'Save';
        this.route.paramMap.subscribe((params: ParamMap) => {
            this.addressId = params.get('id');
            if (this.addressId) {
                this.btnTxt = 'Update';
            }
        });
        this.getAddress();
    }

    get f1() { return this.addEditAddressForm.controls; }

    initializeAddressForm() {
        return this.formBuilder.group({
            company_name: new FormControl({ value: this.company_name ? this.company_name : null, disabled: true }, {
                validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.charnumberValidator, CustomValidator.noWhiteSpaceValidator]),
            }),
            location_tag: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            warehouse_name: new FormControl(null, {
                validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.charnumberValidator, CustomValidator.noWhiteSpaceValidator]),
            }),
            address: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator]),
            }),
            country: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            state: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            district: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            taluka: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            zipcode: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.zipCodeValidator, CustomValidator.noWhiteSpaceValidator]),
            }),
            contact_no: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.phoneValidator, CustomValidator.noWhiteSpaceValidator]),
            }),
            gst: new FormControl(null, {
                validators: Validators.compose([Validators.required, Validators.maxLength(15), Validators.minLength(15), CustomValidator.gstValidator]),
            }),

        })
    }

    getCompanyDetail() {
        this.subscription.add(
            this.dataService.get(URLS.sellerCompanyDetail).subscribe(data => {
                if (data['code'] == 200) {
                    let companyInfo = data['data'];
                    this.company_name = companyInfo.company_name;
                    this.f1.company_name.setValue(this.company_name);
                }
                else {
                    this.toasterService.errorToast(data['message']);
                }
            }, error => {
                this.toasterService.errorToast(error['message']);
            })
        )
    }

    getAddressType() {
        this.callGoogleApi = false;
        const params = {
            user_type: 'SELLER',
            customer_type: this.commonService?.userData?.customer_type == 'ENTERPRISE' ? 'ENTERPRISE' : 'INDIVIDUAL'

        }
        this.subscription.add(
            this.dataService.get(URLS.getAddressType, params).subscribe(data => {
                if (data['code'] == 200) {
                    if (data['data']) {
                        let addressType = data['data'];
                        let addressData = [];
                        addressType.forEach(element => {
                            let obj = {
                                name: element.address_type,
                                id: element.address_type_id,
                                is_active: element.is_active
                            }
                            addressData.push(obj);
                        });

                        this.locationTag = addressData
                    }
                }
                else {
                    this.toasterService.errorToast(data['message']);
                }
            }, error => {
                this.toasterService.errorToast(error['message']);

            })
        )
    }
    getAddress() {
        let param = {};
        if (this.addressId) param['company_address_id'] = parseInt(this.addressId);
        else param['address_type'] = 'HEADQUARTER';
        this.subscription.add(
            this.dataService.get(URLS.sellerAddressDetail, param).subscribe(data => {
                if (data['code'] == 200) {
                    if (data['data']) {
                        if (this.addressId) {
                            let address = data['data'][0];
                            this.addressDetails = address
                            this.selected_state_id = address.state_id;
                            this.selected_district_id = address.district_id;
                            this.selected_taluka_id = address.taluka_id;
                            if (address.country_id) {
                                this.getCountry("IN").then((country) => {
                                    this.f1.country.setValue(address.country_id ? address.country_id : null);
                                });
                            }
                            if (address.state_code) {
                                this.getState(address.state_code).then((state) => {
                                    this.f1.state.setValue(address.state_id ? address.state_id : null);
                                });
                            }
                            if (address.district_code) {
                                this.getDistrict(address.district_code).then((district) => {
                                    this.f1.district.setValue(address.district_id ? address.district_id : null);
                                });
                            }
                            if (address.taluka_code) {
                                this.getTaluka(address.taluka_code).then((taluka) => {
                                    this.f1.taluka.setValue(address.taluka_id ? address.taluka_id : null);
                                });
                            }
                            this.f1.company_name.setValue(address.company_name);
                            if (address.address_type == "HEADQUARTER") {
                                this.f1.location_tag.disable();
                            }

                            this.f1.location_tag.setValue(address.address_type_id);
                            this.f1.warehouse_name.setValue(address.warehouse_name);
                            this.f1.address.setValue(address.address);
                            this.f1.contact_no.setValue(address.contact_no);
                            this.f1.zipcode.setValue(address.zip_code);
                            this.f1.gst.setValue(address.gst_number);
                            this.locationName = address['address_type'];
                            this.locationService.latitude = address.latitude;
                            this.locationService.longitude = address.longitude;
                            this.isEditAddress = true;
                            if (this.addressId) {
                                if (this.f1.address.value) {
                                    this.getPlaceAutocomplete(null, this.f1.address.value);
                                }
                            }

                            this.addressData = address;
                        }
                        else {
                            if (data['data']['HEADQUARTER']) this.f1.gst.setValue(data['data']['HEADQUARTER'][0]['gst_number']);
                        }

                    }
                }
                else {
                    this.toasterService.errorToast(data['message']);
                    this.isEditAddress = false;
                }
            }, error => {
                this.toasterService.errorToast(error['message']);
                this.isEditAddress = false;
            })
        )
    }


    numberOnlyValidation(event: any) {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }


    getCountry(shortCode?) {
        if (shortCode) {
            return new Promise((resolve, reject) => {
                this.isCountryLoading = true;
                let param = {
                    short_name: shortCode
                }
                this.countryList = [];
                this.masterApi.getCountry(param).then((data) => {
                    this.isCountryLoading = false;
                    this.countryList = this.countryList.concat(data['data']['countriesList']);
                    if (this.countryList && this.countryList.length) {
                        this.f1.country.setValue(this.countryList[0].country_id);
                    }
                    resolve(data);
                }, error => {
                    reject(error);
                    this.countryList = [];
                });
            })
        } else {
            return new Promise((resolve, reject) => {
                this.isCountryLoading = true;
                let param = {
                    short_name: "IN"
                }
                this.countryList = [];
                this.masterApi.getCountry(param).then((data) => {
                    this.isCountryLoading = false;
                    this.countryList = this.countryList.concat(data['data']['countriesList']);
                    resolve(data);
                }, error => {
                    reject(error);
                    this.countryList = [];
                });
            })
        }

    }

    getState(shortCode?) {
        return new Promise((resolve, reject) => {
            let params = {
                short_name: shortCode
            }

            this.isStateLoading = true;
            this.stateList = [];
            this.masterApi.getState(params).then((data) => {
                this.isStateLoading = false;
                this.stateList = this.stateList.concat(data['data']['statesList']);
                this.stateCount = data['data']['total_count'];
                if (this.stateList && this.stateList.length) {
                    this.f1.state.setValue(this.stateList[0].state_id);
                    this.state_id = this.stateList[0].state_id
                }
                resolve(data);
            }, error => {
                reject(error);
                this.isStateLoading = false;
                this.stateList = [];
                const params = {
                    country_id: this.country_id
                }
                this.masterApi.getState(params).then((data) => {
                    this.isStateLoading = false;
                    this.stateList = this.stateList.concat(data['data']['statesList']);
                    this.stateCount = data['data']['total_count'];
                    if (this.selected_state_id) {
                        this.f1.state.setValue(this.selected_state_id)
                    }
                    resolve(data);
                }, error => {
                    reject(error);
                    this.isStateLoading = false;
                    this.stateList = [];
                });
            });
        })

    }


    getDistrict(shortCode?) {
        if (shortCode) {
            return new Promise((resolve, reject) => {
                let params = {
                    short_name: shortCode
                }
                this.districtList = [];
                this.masterApi.getDistrict(params).then((data) => {
                    this.districtList = this.districtList.concat(data['data']['districtList']);
                    this.districtCount = data['data']['total_count'];
                    if (this.districtList && this.districtList.length) {
                        this.f1.district.setValue(this.districtList[0].district_id);
                        this.district_id = this.districtList[0].district_id;
                        this.getTaluka(this.locationService.taluka_shortname, this.district_id)
                    }
                    resolve(data);
                }, error => {
                    reject(error);
                    this.districtList = [];
                    setTimeout(() => {
                        if (this.state_id) {
                            return new Promise((resolve, reject) => {
                                let params = {
                                    state_id: this.state_id
                                }
                                this.masterApi.getDistrict(params).then((data) => {
                                    this.districtList = this.districtList.concat(data['data']['districtList']);
                                    this.districtCount = data['data']['total_count'];
                                    if (this.selected_district_id) {
                                        this.f1.district.setValue(this.selected_district_id)
                                    }
                                    resolve(data);
                                }, error => {
                                    reject(error);

                                });
                            })
                        }
                    }, 500);

                });
            })
        } else {
            setTimeout(() => {
                if (this.state_id) {
                    return new Promise((resolve, reject) => {
                        let params = {
                            state_id: this.state_id
                        }
                        this.districtList = [];
                        this.masterApi.getDistrict(params).then((data) => {
                            this.districtList = this.districtList.concat(data['data']['districtList']);
                            this.districtCount = data['data']['total_count'];
                            if (this.selected_district_id) {
                                this.f1.district.setValue(this.selected_district_id)
                                this.getTaluka();
                            }
                            resolve(data);
                        }, error => {
                            reject(error);

                        });
                    })
                }
            }, 500);
        }
    }

    getTaluka(shortCode?, district_id?) {
        if (shortCode) {
            return new Promise((resolve, reject) => {
                let params = {
                    short_name: shortCode,
                    district_id: district_id
                }
                this.talukaList = [];
                this.masterApi.getTaluka(params).then((data) => {
                    this.talukaList = this.talukaList.concat(data['data']['talukaList']);
                    this.talukaCount = data['data']['total_count'];
                    if (this.talukaList && this.talukaList.length) {
                        this.f1.taluka.setValue(this.talukaList[0].taluka_id);
                    }
                    resolve(data);
                }, error => {
                    reject(error)
                    this.talukaList = [];
                    setTimeout(() => {
                        if (this.district_id) {
                            const params = {
                                district_id: this.district_id
                            }
                            this.masterApi.getTaluka(params).then((data) => {
                                this.talukaList = this.talukaList.concat(data['data']['talukaList']);
                                this.talukaCount = data['data']['total_count'];
                                if (this.selected_taluka_id) {
                                    this.f1.taluka.setValue(this.selected_taluka_id)
                                }
                                resolve(data);
                            }, error => {
                                reject(error)
                                this.talukaList = [];
                            });
                        }
                    }, 700);
                });
            })
        } else {
            setTimeout(() => {
                if (this.district_id) {
                    return new Promise((resolve, reject) => {
                        let params = {
                            district_id: this.district_id
                        }
                        this.talukaList = [];
                        this.masterApi.getTaluka(params).then((data) => {
                            this.talukaList = this.talukaList.concat(data['data']['talukaList']);
                            this.talukaCount = data['data']['total_count'];
                            if (this.selected_taluka_id) {
                                this.f1.taluka.setValue(this.selected_taluka_id)
                            }
                            resolve(data);
                        }, error => {
                            reject(error)
                            this.talukaList = [];
                        });
                    })
                }
            }, 700);
        }
    }
    countryChange(data) {
        this.country_id = data.country_id;
    }

    stateChange(data) {
        this.state_id = data.state_id;
        if (this.f1.district.value) {
            this.f1.district.reset();
        }
        if (this.f1.taluka.value) {
            this.f1.taluka.reset();
        }
        if (this.f1.zipcode.value) {
            this.f1.zipcode.reset();
        }
        if (data.state_id) {
            return new Promise((resolve, reject) => {
                let params = {
                    state_id: data.state_id
                }
                this.districtList = [];
                this.masterApi.getDistrict(params).then((data) => {
                    this.districtList = this.districtList.concat(data['data']['districtList']);
                    this.districtCount = data['data']['total_count'];
                    resolve(data);
                }, error => {
                    reject(error);
                    this.districtList = [];
                });
            })
        }
    }

    districtChange(data) {
        this.district_id = data.district_id;
        if (this.f1.taluka.value) {
            this.f1.taluka.reset();
        }
        if (this.f1.zipcode.value) {
            this.f1.zipcode.reset();
        }
        if (data.district_id) {
            return new Promise((resolve, reject) => {
                let params = {
                    district_id: data.district_id
                }
                this.talukaList = [];
                this.masterApi.getTaluka(params).then((data) => {
                    this.talukaList = this.talukaList.concat(data['data']['talukaList']);
                    this.talukaCount = data['data']['total_count'];
                    resolve(data);
                }, error => {
                    reject(error)
                    this.talukaList = [];
                });
            })
        }
    }

    talukaChange(data) {
        if (data) {
            this.taluka_id = data.taluka_id ? data.taluka_id : null;
        }
        if (this.f1.zipcode.value) {
            this.f1.zipcode.reset();
        }
    }


    addAddress() {
        this.isSubmitted = false;
        let isAddressChanged: boolean = false;
        for (const i in this.f1) {
            this.f1[i].markAsDirty();
            this.f1[i].updateValueAndValidity();
            this.f1[i].markAsTouched();
        }

        if (this.addEditAddressForm.invalid) {
            this.isSubmitted = true;
            this.commonService.scrollToTop();
            return;
        }
        if (this.isAutoSelected) {
            return;
        }

        let addAddress: AddCompanyAddress = {};

        addAddress.address_type_id = this.addEditAddressForm.value.location_tag;
        addAddress.warehouse_name = this.addEditAddressForm.value.warehouse_name;
        addAddress.address = this.addEditAddressForm.value.address;
        addAddress.state_id = this.addEditAddressForm.value.state;
        addAddress.district_id = this.addEditAddressForm.value.district;
        if (this.addEditAddressForm.value.taluka) {
            addAddress.taluka_id = this.addEditAddressForm.value.taluka;
        }
        addAddress.zip_code = this.addEditAddressForm.value.zipcode;
        addAddress.country_id = this.addEditAddressForm.value.country;
        addAddress.contact_no = this.addEditAddressForm.value.contact_no;
        addAddress.country_code = "+91";
        addAddress.latitude = this.locationService.latitude.toString();
        addAddress.longitude = this.locationService.longitude.toString();
        addAddress.gst_number = this.addEditAddressForm.value.gst;
        if (this.addressId) {
            addAddress.company_address_id = +this.addressId
        }
        this.isLoading = true;
        if (!this.addressId) {
            this.subscription.add(
                this.dataService.post(URLS.sellerAddAddress, addAddress).subscribe(data => {
                    if (data['code'] == 200) {
                        if (data['data']) {
                            this.addEditAddressForm.reset();
                            this.navCtrl.navigateBack("/home/account-details/manage-company-detail");
                            this.backtoAddressTap();
                            this.commonService.scrollToTop();
                            this.toasterService.successToast(data['message']);
                        }
                        this.isLoading = false;
                    }
                    else {
                        this.toasterService.errorToast(data['message']);
                    }
                    this.isLoading = false;
                }, error => {
                    this.isLoading = false;
                    this.toasterService.errorToast(error['message']);

                })
            )

        } else {
            if (this.addressDetails.address != this.f1.address.value) {
                isAddressChanged = true;
            }
            if (this.addressDetails.address_type_id != this.f1.location_tag.value) {
                isAddressChanged = true;
            }
            if (this.addressDetails.company_name != this.f1.company_name.value) {
                isAddressChanged = true;
            }
            if (this.addressDetails.contact_no != this.f1.contact_no.value) {
                isAddressChanged = true;
            }
            if (this.addressDetails.country_id != this.f1.country.value) {
                isAddressChanged = true;
            }
            if (this.addressDetails.district_id != this.f1.district.value) {
                isAddressChanged = true;
            }
            if (this.addressDetails.gst_number != this.f1.gst.value) {
                isAddressChanged = true;
            }
            if (this.addressDetails.state_id != this.f1.state.value) {
                isAddressChanged = true;
            }
            if (this.addressDetails.zip_code != this.f1.zipcode.value) {
                isAddressChanged = true;
            }
            if (this.addressDetails.warehouse_name != this.f1.warehouse_name.value) {
                isAddressChanged = true;
            }

            if (!isAddressChanged) {
                this.toasterService.warningToast("No data updated")
                this.isLoading = false
                return
            }
            this.subscription.add(
                this.dataService.put(URLS.sellerAddressEdit, addAddress).subscribe(data => {
                    if (data['code'] == 200) {
                        if (data['data']) {
                            this.addEditAddressForm.reset();
                            this.navCtrl.navigateBack("/home/account-details/manage-company-detail");
                            this.backtoAddressTap();
                            this.commonService.scrollToTop();
                            this.toasterService.successToast(data['message']);
                        }
                        this.isLoading = false;
                    }
                    else {
                        this.toasterService.errorToast(data['message']);
                    }
                    this.isLoading = false;
                }, error => {
                    this.isLoading = false;
                    this.toasterService.errorToast(error['message']);

                })
            )
        }


    }

    locationTagChange(val) {
        let value = val.name.toLowerCase();
        this.locationName = value.charAt(0).toUpperCase() + value.slice(1);
    }

    restrictSpecialChar(event) {
        var k;
        k = event.charCode;  //         k = event.keyCode;  (Both can be used)
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 39 || (k >= 48 && k <= 57));
    }


    phoneClick(e) {
        if (e.keyCode < 48 || e.keyCode > 57) {
            e.preventDefault();
        }
    }

    cancel() {
        this.navCtrl.navigateBack(['/home/account-details/manage-company-detail'])
        this.backtoAddressTap();
    }

    backtoAddressTap() {
        this.commonService.localStorageSet("tabval", "2")
    }

    async getPlaceAutocomplete(val, addressVal?) {
        let inputValue: any;
        if (val) {
            inputValue = val.target.value;
        } else {
            inputValue = addressVal;
            this.callGoogleApi = true;
        }
        if (inputValue.trim() === '') {
            this.autocompleteItems = [];
            this.addEditAddressForm.patchValue({
                address: null
            });
            return;
        }
        if (this.callGoogleApi) {
            this.f1.country.reset();
            this.f1.state.reset();
            this.f1.district.reset();
            this.f1.taluka.reset();
            if (!this.isEditAddress) {
                this.f1.zipcode.reset();
            }
            this.countryList = [];
            this.stateList = [];
            this.districtList = [];
            this.talukaList = [];
            await this.locationService.getPlaceAutocomplete(this.GoogleAutocomplete, inputValue);
            this.autocompleteItems = this.locationService.autocompleteItems;

            if (this.autocompleteItems.length > 0) {
                this.isAutoSelected = false;
            };

            if (this.autocompleteItems.length > 0 && addressVal) {
                this.selectSearchResult(this.autocompleteItems[0], this.addEditAddressForm.controls.address)
            }

        } else {
            setTimeout(() => {
                this.callGoogleApi = true;
            }, 500);
        }

    }

    /**
  * SELECT SPECIF LOCATION AND REDIRECTING ON SAME THROUGH MAP
 */
    async selectSearchResult(item, formcontrolName) {
        this.callGoogleApi = false;
        this.isAutoSelected = false;
        setTimeout(() => {
            this.autocompleteItems = [];
        });
        this.locationService.getselectedAutoMap(this.geocoder, item, formcontrolName).then(data => {
            if (this.locationService.country_shortname) {
                this.getCountry(this.locationService.country_shortname);
            } else {
                this.getCountry(null);
            }
            if (this.locationService.state_shortname) {
                this.getState(this.locationService.state_shortname);
            }
            if (this.locationService.district_shortname) {
                this.getDistrict(this.locationService.district_shortname);
            } else {
                this.getDistrict(null);
            }
            if (this.locationService.zipcode_shortname) {
                this.f1.zipcode.setValue(this.locationService.zipcode_shortname);
            }
        })

    }
    addressChange() {
        this.isAutoSelected = true;
        this.isEditAddress = false;
    }
}
