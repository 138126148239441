import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { ToasterService } from './../../services/toaster.service';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {

    public subscription = new Subscription();
    public isLoading = false;
    public privacyPolicyData: any;
    @Input() isModal: boolean = false;

    constructor(
        private dataService: ApiService,
        private toasterService: ToasterService,
        public commonService: CommonService,
        private modalService: ModalService,
    ) { }


    ionViewWillEnter() {
        this.getPrivacyPolicy();
    }


    ngOnInit() {
    }

    onCancel() {
        this.modalService.dismissModal();
    }

    getPrivacyPolicy() {

        this.isLoading = true;

        this.subscription.add(
            this.dataService.get(URLS.getPrivacyPolicy).subscribe(data => {

                if (data['code'] == 200 && data['data']) {
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 300)
                    this.privacyPolicyData = data['data']['description'];
                }
                else {
                    this.isLoading = false;
                    this.toasterService.errorToast(data['message']);
                }
            }, error => {
                this.isLoading = false;
                this.toasterService.errorToast(error['message']);

            })
        )
    }

}
