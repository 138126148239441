<div class="company-detail p-10">
    <div class="progress-outer mb-10" *ngIf="progress > 0 && progress < 100">
        <div class="progress-inner" [style.width]="whichProgress(progress) + '%'">
        </div>
        <div class="percent fs-14 fw-500">
            {{whichProgress(progress)}}%
        </div>
    </div>
    <ion-row class="mb-5 isdesktopTab">
        <div class="tab-container">
            <div class="radio_container fs-16">
                <ng-container *ngFor="let item of tabs;let i = index">
                    <input [(ngModel)]="tabVal" type="radio" name="radio" id="item{{i}}" [value]="item.value"
                        [checked]="item.value">
                    <label (click)="tabChange(item.value)" class="pointer" for="item{{i}}">{{item.name}}</label>
                </ng-container>
            </div>
        </div>
    </ion-row>
    <div class="detail-container common-accordian">
        <ion-accordion-group class="isMobileAccordion" [value]="['1']" (ionChange)=" accordionChange($event)">
            <ion-accordion value="1">
                <ion-item slot="header" class="accordion">
                    <ion-label>Company Info</ion-label>
                </ion-item>
                <div class="pb-50 pt-40" slot="content">
                    <app-company-info [formGroup]="$any(companyDetailsForm)"></app-company-info>
                    <div *ngIf="!isSellerApproval" class="form-button mt-30 ml-5">
                        <button [disabled]="isCompanyLoader" type="button" (click)="onComapnySubmit()"
                            class="mr-20 w-147 submit-btn">
                            <ion-spinner class="spinner-btn" name="lines-sharp-small"
                                *ngIf="isCompanyLoader"></ion-spinner>
                            Save
                        </button>
                        <button class="clear-btn w-147" (click)="cancelInfo()">Cancel</button>
                    </div>
                </div>
            </ion-accordion>
            <ion-accordion value="2">
                <ion-item slot="header">
                    <ion-label>Addresses</ion-label>
                </ion-item>
                <div class=" accordion-content" slot="content">
                    <app-seller-address [progressBar]="progress"></app-seller-address>
                </div>
            </ion-accordion>
            <ion-accordion value="3">
                <ion-item slot="header">
                    <ion-label>Documents</ion-label>
                </ion-item>
                <div class="pb-50 pt-15 pl-5" slot="content">
                    <div class="title  mt-10 fs-16 fw-600 pb-10 ">
                        Upload Documents
                    </div>
                    <app-document [isCompany]="true" [isSellerApproval]="isSellerApproval"
                        [progressBar]="progress"></app-document>

                </div>
            </ion-accordion>
            <ion-accordion value="5">
                <ion-item slot="header">
                    <ion-label>Categories</ion-label>
                </ion-item>
                <div class="pl-5 categories" slot="content">
                    <p class="fs-16 fw-500 mb-15">Choose Categories Of Products You Deal With <span>*</span></p>
                    <ion-row>
                        <ng-container *ngIf="categoriesList.length > 0; else nodata">
                            <div *ngFor="let item of categoriesList;" class="cont-checkbox mb-15 mr-25">
                                <div class="block">
                                    <ion-item lines="none">
                                        <ion-checkbox
                                            (ionChange)="setCategoriesId($event,item.master_category_id,item);"
                                            [checked]="item.checked">
                                        </ion-checkbox>
                                        <ion-label [ngClass]="item.checked ? 'selected-label' : ''"
                                            [for]="item.master_category_id"><img alt="Image" [src]="item.image_url" />
                                            <div *ngIf="item.approval_status == 'PENDING'" class="isRequested">
                                                Requested</div>
                                        </ion-label>
                                    </ion-item>
                                </div>
                                <div [title]="item.name" class="info fs-14 fw-500">{{item.name}}</div>
                            </div>
                        </ng-container>
                        <ng-template #nodata>
                            <div class="no-data">
                                No Categories Found
                            </div>
                        </ng-template>
                    </ion-row>
                    <div *ngIf="!isSellerApproval" class="form-container form-button mt-30 ">
                        <button type="primary" [disabled]="isCatLoader ||isSaveButtonClicked"
                            class="mr-20 cat-submit submit-btn" (click)="categorieSubmit()"
                            [ngClass]="[ progress == 100 ? 'w-147' : 'w-182']">
                            {{progress == 100 ? "Save" : 'Save & Next'}}
                        </button>
                        <button class="clear-btn w-147" (click)="cancelCategories()">Cancel</button>
                    </div>
                </div>

            </ion-accordion>
            <ion-accordion value="4">
                <ion-item slot="header">
                    <ion-label>Bank Details</ion-label>
                </ion-item>
                <div class="pb-50 pt-35 bank-accordian-container" slot="content">
                    <app-bank-details [isSellerApproval]="isSellerApproval" [progress]="progress"
                        [formGroup]="$any(companyDetailsForm)"></app-bank-details>

                </div>
            </ion-accordion>


        </ion-accordion-group>

        <form [formGroup]="companyDetailsForm" class="isdesktopTab">
            <div class="company p-20" *ngIf="tabVal == '1'">
                <div class="inner">
                    <app-company-info [formGroup]="$any(companyDetailsForm)"></app-company-info>
                    <div *ngIf="!isSellerApproval" class="form-button mt-30 ml-5">
                        <button type="button" (click)="onComapnySubmit()" class="mr-20 w-147 submit-btn">
                            Save
                        </button>
                        <button class="clear-btn w-147" (click)="cancelInfo()">Cancel</button>
                    </div>
                </div>
            </div>
            <div class="p-20" *ngIf="tabVal == '2'">
                <app-seller-address [company_name]="company_name"></app-seller-address>
            </div>
            <div class="document" *ngIf="tabVal == '3'">
                <div class="inner">
                    <div class="title  mt-10 fs-16 fw-600 pb-10 ">
                        Upload Documents
                    </div>
                    <app-document [isSellerApproval]="isSellerApproval" [isCompany]="true"></app-document>
                </div>
            </div>
            <div class="bank-info pt-20 m-10" *ngIf="tabVal == '5'">
                <div class="inner">
                    <app-bank-details [isSellerApproval]="isSellerApproval" [progress]="progress"
                        [formGroup]="$any(companyDetailsForm)"></app-bank-details>
                </div>
            </div>
            <div class="categories" *ngIf="tabVal == '4'">
                <div class="title  mt-25 fs-16 fw-600 pb-10">
                    Categories
                </div>
                <p class="fs-16 fw-500 mb-15">Choose Categories Of Products You Deal With <span>*</span></p>
                <ion-row>
                    <ng-container *ngIf="categoriesList.length > 0; else nodata">
                        <div *ngFor="let item of categoriesList;">
                            <ng-container *ngIf="isSubUser && !isSubUserAdmin && item.checked">
                                <div class="block cont-checkbox mb-15 mr-25">
                                    <ion-item lines="none">
                                        <ion-checkbox
                                            (ionChange)="setCategoriesId($event,item.master_category_id,item);"
                                            [checked]="item.checked">
                                        </ion-checkbox>
                                        <ion-label [ngClass]="item.checked ? 'selected-label' : ''"
                                            [for]="item.master_category_id"><img alt="Image" [src]="item.image_url" />
                                            <div *ngIf="item.approval_status == 'PENDING'" class="isRequested">
                                                Requested</div>
                                        </ion-label>
                                    </ion-item>
                                </div>
                                <div [title]="item.name" class="info fs-14 fw-500">{{item.name}}</div>
                            </ng-container>
                            <ng-container *ngIf="(isSubUser && isSubUserAdmin) || (!isSubUser && !isSubUserAdmin)">
                                <div class="cont-checkbox mb-15 mr-25">
                                    <!--  -->
                                    <div class="block">
                                        <ion-item lines="none">

                                            <ion-checkbox
                                                (ionChange)="setCategoriesId($event,item.master_category_id,item);"
                                                [checked]="item.checked">
                                            </ion-checkbox>
                                            <ion-label [ngClass]="item.checked ? 'selected-label' : ''"
                                                [for]="item.master_category_id"><img alt="Image"
                                                    [src]="item.image_url" />
                                                <div *ngIf="item.approval_status == 'PENDING'" class="isRequested">
                                                    Requested</div>
                                            </ion-label>
                                        </ion-item>
                                    </div>
                                    <div class="info fs-14 fw-500" [title]="item.name">{{item.name}}</div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #nodata>
                        <div class="no-data">
                            No Categories Found
                        </div>
                    </ng-template>
                </ion-row>

                <div *ngIf="!isSellerApproval" class="form-container form-button mt-30 ">
                    <button [disabled]="isCatLoader || isSaveButtonClicked || requestedForCategory" type="primary"
                        class="mr-20 cat-submit submit-btn" [ngClass]="[ progress == 100 ? 'w-147' : 'w-182']"
                        (click)="categorieSubmit()">
                        {{progress == 100 ? "Save" : 'Save & Next'}}
                    </button>
                    <button class="clear-btn w-147" (click)="cancelCategories()">Cancel</button>
                </div>
            </div>
            <div class="bank-info pt-20 m-10" *ngIf="tabVal == '6'">
                <div class="inner">
                    <app-add-edit-vehicle [fromProfile]="true" [progress]="progress"
                        [isSellerApproval]="isSellerApproval"></app-add-edit-vehicle>
                </div>
            </div>
        </form>
    </div>
</div>