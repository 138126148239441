<div class="personal-detail" [formGroup]="formGroup">
	<ion-label *ngIf="!fieldName.hideFirstName && !fieldName.hideLastName">{{fieldName.name}} <span
			[ngClass]="formGroup.get('first_name').hasError('required') && formGroup.get('first_name').dirty ? 'error-msg' : ''">*</span></ion-label>
	<ion-label *ngIf="fieldName.hideFirstName && fieldName.hideLastName">{{fieldName.name}}
		<span
			[ngClass]="formGroup.get('user_name').hasError('required') && formGroup.get('user_name').dirty ? 'error-msg' : ''">*</span></ion-label>
	<ion-row class="mb-10 name-container">
		<ion-col class="name-col" *ngIf="fieldName.hideFirstName && fieldName.hideLastName">
			<ion-input capitalizeFirst placeholder="Enter Name" formControlName="user_name"
				(keypress)="restrictSpecialChar($event)" maxlength="30"></ion-input>
			<ng-container *ngFor="let validation of validation_messages.user_name">
				<ng-container
					*ngIf="formGroup.get('user_name').hasError(validation.type) && formGroup.get('user_name').dirty">
					<ion-text color="danger" class="error-msg">
						{{validation.message }}
					</ion-text>
				</ng-container>
			</ng-container>
		</ion-col>
		<ion-col class="name-col" *ngIf="!fieldName.hideFirstName" class="mr-20">
			<ion-input capitalizeFirst placeholder="First name" formControlName="first_name"
				(keypress)="restrictSpecialChar($event)" maxlength="15"></ion-input>
			<ng-container *ngFor="let validation of validation_messages.first_name">
				<ng-container
					*ngIf="formGroup.get('first_name').hasError(validation.type) && formGroup.get('first_name').dirty">
					<ion-text color="danger" class="error-msg">
						{{validation.message }}
					</ion-text>
				</ng-container>
			</ng-container>
		</ion-col>
		<ion-col class="name-col" *ngIf="!fieldName.hideLastName">
			<ion-input capitalizeFirst placeholder="Last name" formControlName="last_name"
				(keypress)="restrictSpecialChar($event)" maxlength="15"></ion-input>
			<ng-container *ngFor="let validation of validation_messages.last_name">
				<ng-container
					*ngIf="formGroup.get('last_name').hasError(validation.type) && formGroup.get('last_name').dirty">
					<ion-text color="danger" class="error-msg">
						{{validation.message }}
					</ion-text>
				</ng-container>
			</ng-container>
		</ion-col>
	</ion-row>
	<ion-row class="mb-10">
		<ion-col>
			<ion-label>{{fieldName.email}} <span
					[ngClass]="formGroup.get('email').hasError('required') && formGroup.get('email').dirty ? 'error-msg' : ''">*</span></ion-label>
			<ion-input autocomplete="new-email" class="pt-20" type="email" formControlName="email"
				placeholder="Enter Email Address" [maxlength]="50" (keydown.space)="$event.preventDefault();">
				<button *ngIf="!isHideVerifiedBtn && getPlatformService.isDesktop" class="verified-btn"
					[disabled]="true">Verified
				</button>
				<button *ngIf="!isHideVerifiedBtn && !getPlatformService.isDesktop" class="verified-btn"
					[disabled]="true"><ion-icon name="shield-checkmark"></ion-icon>
				</button>
			</ion-input>
			<ng-container *ngFor="let validation of validation_messages.email">
				<ng-container *ngIf="formGroup.get('email').hasError(validation.type) && formGroup.get('email').dirty">
					<ion-text color="danger" class="error-msg">
						{{validation.message}}
					</ion-text>
				</ng-container>
			</ng-container>
		</ion-col>
	</ion-row>
	<ion-row class="mb-10">
		<ion-col>
			<ion-label>{{fieldName.mobile_no}} <span
					[ngClass]="formGroup.get('phone_no').hasError('required') && formGroup.get('phone_no').dirty ? 'error-msg' : ''">*</span></ion-label>
			<ion-input (input)="onInputChange($event)" class="mobile-input" type="tel" (keypress)="phoneClick($event)"
				inputmode="numeric" formControlName="phone_no" placeholder="Enter Phone Number" maxlength="10">
				<label for="" class="country-code mr-10 pl-15">
					+91 <img alt="Down Arrow" class="ml-5" src="./assets/svg/arrowdown.svg">
				</label>
				<button *ngIf="!isHideVerifiedBtn && getPlatformService.isDesktop && isPhoneNumberVerifed"
					class="verified-btn" [disabled]="true">Verified
				</button>

				<button *ngIf="!isHideVerifiedBtn && !getPlatformService.isDesktop && isPhoneNumberVerifed"
					class="verified-btn" [disabled]="true"><ion-icon name="shield-checkmark"></ion-icon>
				</button>
			</ion-input>
			<ng-container *ngFor="let validation of validation_messages.phone_no">
				<ng-container
					*ngIf="formGroup.get('phone_no').hasError(validation.type) && formGroup.get('phone_no').dirty">
					<ion-text color="danger" class="error-msg">
						{{validation.message }}
					</ion-text>
				</ng-container>
			</ng-container>
		</ion-col>
	</ion-row>
</div>