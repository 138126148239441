import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { profileTypeEnum } from '../../_common/enum.enum';
import { CommonService } from '../../services/common.service';
import { CustomValidator } from './../../_common/customvalidators';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { ToasterService } from './../../services/toaster.service';


export interface ContactUsParams {
	name?: string;
	email?: string;
	country_code?: string;
	mobile_no?: number;
	message?: string;
}


@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
	profileEnum = profileTypeEnum;
	userDetails;
	public subscription = new Subscription();
	public contactRequestForm: FormGroup;
	public isDisabled = false;
	public isLoading = false;
	public webUrl: any;

	fieldData = {
		"name": "Name",
		"email": "Email",
		"mobile_no": "Mobile number",
		"hideFirstName": true,
		"hideLastName": true
	}


	public validation_messages = {
		description: [
			{ type: 'required', message: 'Please enter description' },
			{ type: 'minLengthWithSpaces', message: 'Message cannot be less than 30 character' },
			{ type: 'maxlength', message: 'Message cannot be more than 1000 character' }
		],
	}

	constructor(
		private formBuilder: FormBuilder,
		public commonService: CommonService,
		private dataService: ApiService,
		private toasterService: ToasterService,
	) { }

	ngOnInit() {
		this.contactRequestForm = this.initializeContactRequestFormGroup();
		this.webUrl = `${environment.webUrl}/contact-us`;
	}

	ionViewDidLeave() {
		this.contactRequestForm.reset();
	}
	ionViewWillEnter() {
		if (this.commonService.isLoggedIn) {
			setTimeout(() => {
				this.getUserDetail();
			}, 200);
		}
	}

	get f1() { return this.contactRequestForm.controls; }

	initializeContactRequestFormGroup() {
		return this.formBuilder.group({
			user_name: new FormControl(null, {
				validators: Validators.compose([Validators.required, Validators.maxLength(30), CustomValidator.nameValidator]),
			}),
			email: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.emailValidator]),
			}),
			phone_no: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.phoneValidator]),
			}),
			description: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.minLengthWithSpaces(30), Validators.maxLength(1000)]),
			}),
		})

	}

	getUserDetail() {
		let params = {
			"user_type": this.commonService.userData && this.commonService.userData['isCustomer'] ? this.profileEnum.Customer : this.profileEnum.Seller
		}
		this.subscription.add(
			this.dataService.get(URLS.userDetail, params).subscribe(
				data => {
					if (data['code'] == 200) {
						this.userDetails = data['data'];
						if (this.userDetails) {
							this.contactRequestForm.patchValue({
								first_name: `${this.userDetails.first_name} ${this.userDetails.last_name}`,
								email: this.userDetails.email,
								phone_no: this.userDetails.mobile_no
							});
						}
					}
				}, error => {
					console.log(error);
				})
		)

	}

	submit() {
		for (const i in this.f1) {
			this.f1[i].markAsDirty();
			this.f1[i].updateValueAndValidity();
			this.f1[i].markAsTouched();
		}

		if (this.contactRequestForm.invalid) {
			return
		}

		const params: ContactUsParams = {
			message: this.f1.description.value.trim(),
			name: this.f1.user_name.value.trim(),
			country_code: "+91",
			mobile_no: +this.f1.phone_no.value,
			email: this.f1.email.value,
		}


		this.isLoading = true;
		this.isDisabled = true;
		this.subscription.add(
			this.dataService.post(URLS.createContact, params).subscribe(data => {
				this.isLoading = false;
				if (data['code'] == 200) {
					this.contactRequestForm.reset();
					this.toasterService.successToast(data['message'], 'top');
					this.isLoading = false;
					this.isDisabled = false;
				}
				else {
					this.toasterService.errorToast(data['message'], 'top');
					this.isLoading = false;
					this.isDisabled = false;
				}
			}, error => {
				this.isLoading = false;
				this.isDisabled = false;
				this.toasterService.errorToast(error['message'], "top");

			})
		)

	}

}
