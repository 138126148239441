<ng-container *ngIf="showMsg">
	<div [ngClass]="messageData.type == 'warning' ? 'warnig-custom-text' : 'success-custom-text'">
		<img alt="Warning"
			[src]="messageData.type == 'warning' ? '/assets/icon/warning.svg' : '/assets/icon/white-checked.svg'">
		<span class="custom-notification">
			<a href="" *ngIf="messageData['link'] ; else noLink" [routerLink]="messageData.link">
				{{messageData.message}}
			</a>
			<ng-template #noLink>
				{{messageData.message}}
			</ng-template>
		</span>
	</div>
</ng-container>
<div class="my-profile-container p-50">
	<div class="profile-details">
		<div class="profile-img">
			<div class="profileIcon mr-40">
				<img alt="Profile" [src]="profileImage ? profileImage : '/assets/svg/userLarge.svg'"
					[ngClass]="[ profileImage ? 'image-uploaded' : 'profile']">
				<div class="uploaded-block" [ngClass]="isDisabled ? 'not-allowed' : 'pointer'"
					(click)="isDisabled? '':callInput();">
					<img alt="Camera" src="../../../assets/icon/camera.svg">
				</div>

				<input [disabled]="isDisabled" #file type="file" id="fileInput" (click)="file.value = null"
					(change)="fileChangeEvent($event)" hidden />
				<ng-container>
				</ng-container>
			</div>
		</div>
		<div *ngIf="!isLoading">
			<form [formGroup]="profileForm">
				<ng-container *ngIf="commonService.userData.isSeller">
					<app-personal-detail [fieldName]="fieldData" [formGroup]="$any(profileForm)"
						[placeholder]="'Email'"></app-personal-detail>
					<div class="form-button mt-30 ml-5">
						<button [disabled]="!profileImageChange && !profileForm.dirty" type="primary"
							(click)="updateProfile()" class="mr-20 w-147 submit-btn">
							<ion-spinner class="spinner-btn " name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
							Save
						</button>
						<button [disabled]="!profileImageChange && !profileForm.dirty" class="clear-btn w-147"
							(click)="cancel()">Cancel</button>
					</div>
				</ng-container>
				<ng-container *ngIf="commonService.userData.isCustomer">
					<div *ngIf="isEnterpriced" class="title fs-16 pl-5 fw-600 pb-15">
						Personal details
					</div>
					<app-personal-detail [fieldName]="fieldData" [formGroup]="$any(profileForm)"
						[placeholder]="'Email'"></app-personal-detail>


					<app-enterprise-detail [maxHeight]="true"
						*ngIf="commonService.userData.user_profile == profileEnum.Enterprise"
						[formGroup]="$any(profileForm)"></app-enterprise-detail>

					<div class="form-button mt-30 ml-5">

						<button [disabled]="!profileImageChange && !profileForm.dirty" type="primary"
							(click)="updateProfile()" class="mr-20 w-147 submit-btn">
							<ion-spinner class="spinner-btn " name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
							Save
						</button>
						<button class="clear-btn w-147" [disabled]="!profileImageChange && !profileForm.dirty"
							(click)="cancel()">Cancel</button>
					</div>
				</ng-container>
			</form>
		</div>
		<div class="form-data" *ngIf="isLoading">
			<ion-skeleton-text class="skeleton-container width-100" name="lines-sharp-small"
				[animated]="true"></ion-skeleton-text>
			<ion-skeleton-text class="skeleton-container width-100" name="lines-sharp-small"
				[animated]="true"></ion-skeleton-text>
			<ion-skeleton-text class="skeleton-container width-100" name="lines-sharp-small"
				[animated]="true"></ion-skeleton-text>
			<ion-skeleton-text class="skeleton-container width-100" name="lines-sharp-small"
				[animated]="true"></ion-skeleton-text>
			<ion-skeleton-text class="skeleton-container width-100" name="lines-sharp-small"
				[animated]="true"></ion-skeleton-text>
		</div>
	</div>
</div>