<div class="registration-container page-scroll-top" id="scrollId">
	<div class="heading-label fs-28 fw-600 mt-50">
		<span *ngIf="commonService.isLoggedIn ; else notLogInTitle">Register as {{registerationType ==
			profileEnum.Enterprise ? 'Enterprise' :
			"Individual"}} Buyer </span>
		<ng-template #notLogInTitle> Create an account</ng-template>
	</div>
	<div class="mb-30 fs-16 fw-400">
		<span *ngIf="!commonService.isLoggedIn ; else notLogInDesc">
			<p> Existing user? <span (click)="login()" class="text-link pointer"> Log-in</span></p>
		</span>
		<ng-template #notLogInDesc>
			<p class="desc"> We will use your below mentioned details for you to register as {{registerationType
				==
				profileEnum.Enterprise ? 'Enterprise' :
				"Individual"}} Buyer</p>
		</ng-template>
	</div>
	<ng-container *ngIf="!commonService.isLoggedIn">
		<div class="title pl-5 fs-16 fw-600 pb-10">
			Select User Type
		</div>
		<ion-row class="mb-25">
			<div class="tab-container">
				<div class="radio_container fs-16">
					<ng-container *ngFor="let item of tabList;let i = index">
						<input (change)="userTypeChange(item.value)" [(ngModel)]="registerationType" type="radio"
							name="registerationType" id="item{{i}}" [value]="item.value" [checked]="item.value">
						<label class="pointer" (click)="userTypeChange(item.value)"
							for="item{{i}}">{{item.name}}</label>
					</ng-container>
				</div>
			</div>
		</ion-row>
	</ng-container>

	<div class="unselectable">
		<form [formGroup]="registrationForm">
			<app-personal-detail
				[placeholder]="registerationType == profileEnum.Enterprise ? 'Please enter domain specific email Id' : ''"
				[isHideVerifiedBtn]="true" [fieldName]="fieldData" [formGroup]="$any(registrationForm)">
			</app-personal-detail>

			<ion-row class="mb-10" *ngIf="registerationType == profileEnum.Enterprise">
				<ion-col class="p-0">
					<ion-label>GST Number <span
							[ngClass]="registrationForm.get('gst').hasError('required') && registrationForm.get('gst').dirty ? 'error-msg' : ''">*</span></ion-label>
					<ion-input capitalizeFirst formControlName="gst" placeholder="Enter GST Number"
						(keypress)="restrictSpecialChar($event)" minlength="15" maxlength="15">
					</ion-input>
					<ng-container *ngFor="let validation of validation_messages.gst">
						<ng-container
							*ngIf="registrationForm.get('gst').hasError(validation.type) && registrationForm.get('gst').dirty">
							<ion-text color="danger" class="error-msg">
								{{validation.message}}
							</ion-text>
						</ng-container>
					</ng-container>
				</ion-col>
			</ion-row>

			<ion-row class="mb-20 mt-15" *ngIf="!isBecomeCustomer">
				<ion-col class="password">
					<ion-label>Password <span
							[ngClass]="registrationForm.get('password').hasError('required') && registrationForm.get('password').dirty ? 'error-msg' : ''">*</span></ion-label>
					<ion-input placeholder="Enter Password" autocomplete="new-password"
						class="registration-pass input-with-lock-icon" clearOnEdit="false" type="{{passwordType}}"
						formControlName="password" appBlockCopyPaste (keydown.space)="$event.preventDefault();">
					</ion-input>
					<img alt="Image" class="input-icon input-toggle-icon" *ngIf="passwordType === 'text'"
						(click)="passwordType = 'password'" src="/assets/icon/eye.svg">
					<img alt="Image" class="input-icon input-toggle-icon" *ngIf="passwordType === 'password'"
						src="../../../assets/icon/eye-off.svg" (click)="passwordType = 'text'">
					<ng-container *ngFor="let validation of validation_messages.password">
						<ng-container
							*ngIf="registrationForm.get('password').hasError(validation.type) && registrationForm.get('password').dirty">
							<ion-text color="danger" class="error-msg">
								{{validation.message }}
							</ion-text>
						</ng-container>
					</ng-container>
				</ion-col>
			</ion-row>
			<ion-row class="mb-20" *ngIf="!isBecomeCustomer">
				<ion-col class="password">
					<ion-label>Confirm password <span
							[ngClass]="registrationForm.get('confirm_password').hasError('required') && registrationForm.get('confirm_password').dirty ? 'error-msg' : ''">*</span></ion-label>
					<ion-input placeholder="Re-Enter Password" appBlockCopyPaste autocomplete="new-confirm-password"
						class="registration-pass input-with-lock-icon" clearOnEdit="false"
						type="{{confirmPasswordType}}" formControlName="confirm_password"
						(keydown.space)="$event.preventDefault();">
					</ion-input>
					<img alt="Password" class="input-icon input-toggle-icon" *ngIf="confirmPasswordType === 'text'"
						(click)="confirmPasswordType = 'password'" src="/assets/icon/eye.svg">
					<img alt="Password" class="input-icon input-toggle-icon" *ngIf="confirmPasswordType === 'password'"
						src="../../../assets/icon/eye-off.svg" (click)="confirmPasswordType = 'text'">
					<ng-container *ngFor="let validation of validation_messages.confirm_password">
						<ng-container
							*ngIf="registrationForm.get('confirm_password').hasError(validation.type) && registrationForm.get('confirm_password').dirty">
							<ion-text color="danger" class="error-msg">
								{{validation.message }}
							</ion-text>
						</ng-container>
					</ng-container>
				</ion-col>
			</ion-row>
			<div *ngIf="registerationType  === 'ENTERPRISE'">
				<app-enterprise-detail [isBecomeUserRegister]="isBecomeCustomer" [formGroup]="$any(registrationForm)"
					[companyAddress]="true"></app-enterprise-detail>
			</div>
			<div class="form-inputs">
				<div lines="none" mode="md" class="checkbox-item mb-5">
					<ion-checkbox slot="start" class="mr-10 checkbox" formControlName="termsCondition"
						[ngModelOptions]="{standalone: true}"></ion-checkbox>
					<ion-label class="checkbox-label">
						Agree to <span class="text-link pointer" (click)="termsPopUp()"> Terms of
							use</span> & <span (click)="privacyPopUp()" class="text-link pointer">Privacy
							policy</span>
					</ion-label>
				</div>
				<ng-container *ngFor="let validation of validation_messages.termsCondition">
					<ng-container
						*ngIf="registrationForm.get('termsCondition').hasError(validation.type) && registrationForm.get('termsCondition').dirty">
						<ion-text color="danger" class="error-msg">
							{{validation.message }}
						</ion-text>
					</ng-container>
				</ng-container>
			</div>
			<div class="register-bottom-container ml-5">
				<div class="form-container register-button d-flex">
					<button [disabled]="isLoading" class="submit-btn w-152 mr-20 mb-10" (click)="onSubmit()">
						<ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
						Register
					</button>
					<button class="clear-btn w-147" (click)="goBack()">
						Cancel
					</button>
				</div>
			</div>
		</form>
	</div>
</div>