import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { LocationService } from 'src/app/services/location.service';
import { ModalService } from 'src/app/services/modal.service';
import { MasterApiService } from '../../services/master-api.service';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { ToasterService } from './../../services/toaster.service';

declare var google: any;

@Component({
	selector: 'app-delivery-address',
	templateUrl: './delivery-address.component.html',
	styleUrls: ['./delivery-address.component.scss'],
})
export class DeliveryAddressComponent implements OnInit {

	@Input() addressValue: any;
	@Input() locationTagSelect: any;
	public locationSelection: any;
	public subscription = new Subscription();
	public isLoading = false;
	public addressDetails = [];
	public profileDetails: any;
	isStateLoading = false;
	isAddressError = false;
	public state_id: any;
	public state: any;
	country_id: any;
	isLocationTagError = false;
	locationTag: any = [];
	isAutoSelected = false;
	@ViewChild("searchInput") public searchInput: ElementRef;
	autocompleteItems: any = [];
	GoogleAutocomplete: any;
	latitude;
	longitude;
	callGoogleApi = true;
	geocoder;
	temporaryAddress = '';
	googleNotFoundAddress: boolean

	constructor(
		private modalService: ModalService,
		private modalCtrl: ModalController,
		private dataService: ApiService,
		private toasterService: ToasterService,
		public commonservices: CommonService,
		public locationService: LocationService,
		public commonService: CommonService,
		private masterApi: MasterApiService,
		private navCtrl: NavController

	) { }

	ngOnInit() {
		this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
		this.geocoder = new google.maps.Geocoder;
		this.autocompleteItems = [];
		if (this.commonService.isLoggedIn) {
			this.commonService.getUserDetail().then((data) => {
				let details = data
				this.profileDetails = details;
				this.getAddress().then(() => {
					this.getAddressType();
				});
			})
		} else {
			this.getAddressType();
		}
	}

	close() {
		this.modalService.dismissModal();
	}

	ionViewWillEnter() {
		if (this.addressValue) {
			this.temporaryAddress = this.addressValue;
			this.commonService.localStorageSet("tag", this.locationTagSelect)
			this.autocompleteItems = [];
			this.callGoogleApi = false;
			this.getPlaceAutocompleteInit(this.addressValue);
		}
		else {
			if (this.locationService.currentLocation) {
				this.autocompleteItems = [];
				this.callGoogleApi = false;
				this.addressValue = this.locationService.completeLocation;
				this.temporaryAddress = this.addressValue;
				this.getPlaceAutocompleteInit(this.addressValue);
			}
		}
	}

	addressFocus() {
		this.isAddressError = false;
	}

	addressChange() {
		this.isAutoSelected = true;
	}

	locationFocus() {
		this.isLocationTagError = false;
	}

	onSubmit() {
		this.isAddressError = false;
		this.isLocationTagError = false;
		if (!this.addressValue) {
			this.isAddressError = true;
		}
		if (!this.locationTagSelect) {
			this.isLocationTagError = true;
		}
		if (this.locationSelection) {
			this.isLocationTagError = false;
		}
		if (this.isLocationTagError || this.isAddressError) {
			return;
		}
		if (this.isAutoSelected) {
			return;
		}
		if (this.googleNotFoundAddress) {
			return;
		}

		const filteredAddresses: any = this.addressDetails.filter(item => item.address_id == this.addressValue);
		this.isLoading = true;
		const deliveryLocationData = {
			address: filteredAddresses && filteredAddresses.length > 0 ? filteredAddresses[0].address : this.addressValue,
			address_type_id: this.locationSelection,
			latitude: this.locationService.latitude.toString(),
			longitude: this.locationService.longitude.toString(),
		}

		const stateData = {
			state: this.state,
			state_id: this.state_id
		}

		let tag = +this.commonService.localStorageGet("tag");

		let existedAddress = this.addressDetails && this.addressDetails.length > 0 ?
			this.findAddressIsexist(deliveryLocationData) : null;

		if (existedAddress) {
			this.commonService.localStorageSet('shipping_address_id', existedAddress.company_address_id ? existedAddress.company_address_id : existedAddress.user_address_id);
		}
		let isAddressChanged = existedAddress ? false : ((this.temporaryAddress && this.temporaryAddress != this.addressValue) ||
			(tag != this.locationTagSelect)) ? true : false;

		this.commonservices.localStorageSet('isAddressChanged', isAddressChanged.toString());
		this.commonservices.localStorageSet('deliverLocation', JSON.stringify(deliveryLocationData));
		this.commonservices.localStorageSet('state_data', JSON.stringify(stateData))
		this.modalCtrl.dismiss(deliveryLocationData)

	}

	getState(shortCode?) {
		return new Promise((resolve, reject) => {
			let params = {
				short_name: shortCode
			}
			this.isStateLoading = true;
			this.masterApi.getState(params).then((data) => {
				this.isStateLoading = false;
				let stateList = data['data']['statesList'][0];
				this.state = stateList.state;
				this.state_id = stateList.state_id;
				resolve(data);
			}, error => {
				reject(error);
				this.isStateLoading = false;
				const params = {
					country_id: 1
				}
				this.masterApi.getState(params).then((data) => {
					resolve(data);
				}, error => {
					reject(error);
					this.isStateLoading = false;
				});
			});
		})

	}

	locationChange(event) {
		this.locationTagSelect = event.id
	}


	getAddressType() {
		const params = {
			user_type: 'CUSTOMER',
			customer_type: !this.commonService.isLoggedIn ? 'INDIVIDUAL' : this.commonService?.userData?.customer_type == 'ENTERPRISE' ? 'ENTERPRISE' : 'INDIVIDUAL'
		}
		this.subscription.add(
			this.dataService.get(URLS.getAddressType, params).subscribe(data => {
				if (data['code'] == 200) {
					if (data['data']) {
						let addressType = data['data'];
						let addressData = [];
						addressType.forEach(element => {
							let obj = {
								name: element.address_type,
								id: element.address_type_id,
								is_active: element.is_active,
								disabled: false
							}
							addressData.push(obj);
						});

						this.locationTag = addressData
						if (this.locationTag && this.locationTag.length)
							this.locationTag.filter(element => {
								if (element.id == this.locationTagSelect) {
									this.locationSelection = this.locationTagSelect
								}
							});

						if (this.addressDetails && this.addressDetails.length) {
							this.addressDetails.forEach(element => {
								if (element.address_type == 'HEADQUARTER') {
									if (this.locationTag && this.locationTag.length) {
										this.locationTag.forEach((locationElement, index) => {
											if (locationElement.name == 'HEADQUARTER') {
												locationElement['disabled'] = true;
												this.locationSelection = this.locationTag[index + 1].id;
											}
										});
									}
								}
							});
						}
					}
					this.isLoading = false;
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);

			})
		)
	}

	getAddress() {
		return new Promise((resolve, reject) => {
			this.isLoading = true;
			let params: any;
			if (this.profileDetails && this.profileDetails.customer_type != 'INDIVIDUAL') {
				params = {
					"user_type": "CUSTOMER",
					"company_id": this.profileDetails.company_id
				}
			}
			this.subscription.add(
				this.dataService.get(URLS.getAddress, params).subscribe(data => {
					if (data['code'] == 200) {
						if (data['data']) {
							this.addressDetails = data['data']['addressList'];
							if (this.addressDetails && this.addressDetails.length > 0) {
								this.addressDetails.forEach(element => {
									element.address_id = element.company_address_id ? element.company_address_id : element.user_address_id;
								});
							}
							resolve(this.addressDetails)
						}
						this.isLoading = false;
					}
					else {
						reject(data);
						this.toasterService.errorToast(data['message']);
					}
					this.isLoading = false;
				}, error => {
					reject(error);
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);

				})
			)
		})
	}

	getPlaceAutocomplete(val) {
		const inputValue = val.target.value;
		this.googleNotFoundAddress = false;
		const trimmedInput = inputValue.trim();
		if (!trimmedInput) {
			this.autocompleteItems = [];
			this.addressValue = null;
			return;
		}
		if (this.callGoogleApi) {
			this.locationService.getPlaceAutocomplete(this.GoogleAutocomplete, inputValue)
				.then(data => {
					this.autocompleteItems = this.locationService.autocompleteItems;
				}, error => {
					this.autocompleteItems = [];
					this.googleNotFoundAddress = true;
				});
			if (this.autocompleteItems.length > 0) { this.isAutoSelected = false };
		} else {
			setTimeout(() => {
				this.callGoogleApi = true;
			}, 500);
		}

	}

	getPlaceAutocompleteInit(val) {
		const inputValue = val;
		this.googleNotFoundAddress = false;
		const trimmedInput = inputValue.trim();
		if (!trimmedInput) {
			return;
		}
		if (inputValue.trim() === '') {
			this.autocompleteItems = [];
			this.addressValue = null;
			return;
		}
		this.locationService.getPlaceAutocomplete(this.GoogleAutocomplete, inputValue)
			.then(data => {
				this.autocompleteItems = this.locationService.autocompleteItems;
			}, error => {
				this.autocompleteItems = [];
				this.googleNotFoundAddress = true;
			});
		if (this.autocompleteItems.length > 0) { this.isAutoSelected = false };
	}

	/**
	* SELECT SPECIF LOCATION AND REDIRECTING ON SAME THROUGH MAP
	*/
	selectSearchResult(item) {
		this.callGoogleApi = false;
		this.isAutoSelected = false;
		this.autocompleteItems = [];
		this.locationService.getselectedAutoMap(this.geocoder, item).then((data) => {
			if (this.locationService.state_shortname) {
				this.getState(this.locationService.state_shortname);
			}
		});
		setTimeout(() => {
			this.addressValue = this.locationService.locationName;
			if (this.addressValue) {
				this.isAutoSelected = false;
			}
		}, 50);

	}


	findAddressIsexist(data) {
		// Use the find method to search for the mainobject
		return this.addressDetails.find(obj =>
			obj.address == data.address &&
			obj.address_type_id == data.address_type_id
		);
	}

	shippingAddressChange(event) {
		let address = this.addressDetails.find(obj => obj.address_id === event);
		this.locationSelection = address.address_type_id;

		const stateData = {
			state: address.state,
			state_id: address.state_id
		}

		this.locationService.latitude = address.latitude;
		this.locationService.longitude = address.longitude;
		this.commonservices.localStorageSet('isAddressChanged', true);
		this.commonservices.localStorageSet('deliverLocation', JSON.stringify(address));
		this.commonService.localStorageSet('shipping_address_id', address.address_id);
		this.commonservices.localStorageSet('state_data', JSON.stringify(stateData));

	}
}
