import { ModalService } from './../../services/modal.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss'],
})
export class SuccessModalComponent implements OnInit {
    @Input() title;
    @Input() description;
    constructor(
        private modalService: ModalService,
    ) { }

    ngOnInit() {}

    async close() {
        this.modalService.dismissModal();
    }

}
