<div class="factor-container two-fa pt-30 " id="scrollId">
	<div class="heading-label fs-28 fw-600 mb-10">
		Enter OTP
	</div>
	<div *ngIf="!verifyEmail && fromPage != 'forgotPassword'" class="sub-label fs-16 fw-400 mb-30">
		We have sent an OTP on your registered mobile number
	</div>
	<div *ngIf="verifyEmail && fromPage != 'forgotPassword'" class="sub-label fs-16 fw-400 mb-30">
		We have sent an OTP on your registered Email ID
	</div>
	<div *ngIf="fromPage == 'forgotPassword'" class="sub-label fs-16 fw-400 mb-30">
		We have sent an OTP on your registered Email ID and registered mobile number
	</div>
	<div class="otp-container">
		<ion-item-group class="ion-item-group">
			<div>
				<ion-label class="pl-30 pb-5 fs-16 fw-500">OTP *</ion-label>
				<code-input #codeInput [isCodeHidden]="isCodeHidden" [codeLength]="6"
					(codeChanged)="onOtpChanged($event)" [isPrevFocusableAfterClearing]="false"
					(codeCompleted)="onCodeCompleted($event)">
				</code-input>
			</div>

		</ion-item-group>
		<div class="otp-container-resend ion-text-center">
			<ng-container *ngIf="(!verifyEmail && otpCount <= 3) || (verifyEmail && emailOtpCount <= 3)">
				<div class="otp fs-16 fw-500" [ngClass]="isGettingOtp ?  'cursor-disabled': ''"
					*ngIf="resendTimer === null">You
					didn’t receive any code yet?
					<span class="text-link pointer" *ngIf="!isResendLoading" (click)="generateOtp()">Resend
						it</span>
					<ion-spinner class="otp-spinner-btn" name="lines-sharp-small" *ngIf="isResendLoading"></ion-spinner>
				</div>
				<div class="resend-timer" *ngIf="resendTimer !== null">
					<span class="cursor-disabled">Resend in {{ resendTimer }}</span>
				</div>
			</ng-container>

			<div class="pt-20" *ngIf="(!verifyEmail && otpCount > 3 ) || (verifyEmail && emailOtpCount > 3 )">
				<ion-text class="pt-20" color="danger">
					You have exceeded the maximum otp limit
				</ion-text>
			</div>
		</div>

	</div>
	<div class="form-container otp-bottom-container mt-30">
		<div class="register-button mr-20">
			<button [disabled]="disableBtn" type="button" class="submit-btn w-147" (keydown)="enterOtpSubmit($event)"
				(click)="verifyOtp()">
				<ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
				Verify
			</button>
		</div>
		<div>
			<button type="button" class="clear-btn w-156" (keydown)="enterOtpSubmit($event)" (click)="cancel()"
				[disabled]="isLoading">
				Cancel
			</button>
		</div>
	</div>
</div>