<div *ngIf="!isLoading">
    <div class="slide-container" *ngIf="!faq && !categories && !brand" (click)="goToDetail(data)">
        <div class="product-image-container">
            <div class="product-new" *ngIf="data.is_new">
                <button class="fs-14 fw-600">New</button>
            </div>
            <div class="product-list-image-div">
                <img alt="Image" [ngSrc]="data.img" priority width="100" height="100">
            </div>
        </div>
        <div class="detail">
            <div class="ion-text-left pl-20 pt-10 pb-10 star-rating d-flex">
                <div class="rating-stars-profile">
                    <ionic5-star-rating class="star-rating" #rating activeIcon="custom-star-fill"
                        halfIcon="custom-half-star" halfStar="true" defaultIcon="custom-star-default"
                        activeColor="#ffbb1C" defaultColor="#828282" fontSize="14px" readonly="true"
                        rating="{{data.rating_avg ? data.rating_avg : 0 }}">
                    </ionic5-star-rating>
                </div>
                <div class="rating-text fs-14" *ngIf="data.rating_avg">
                    {{ data.rating_avg }}
                </div>
            </div>
            <div class="type fs-14 fw-500 ion-text-left pl-20 pb-5"> {{data.type}}</div>
            <div class="product-brand fs-16 fw-600 ion-text-left pl-20" [title]="data.name+' '+data.unit_name">
                <span>{{ data.name }} {{ data.unit_name }}</span>
            </div>

        </div>
    </div>

    <div class="slide-container2" *ngIf="categories || brand" (click)="goToListing(data)">
        <div class="product-image-container2">
            <img alt="Image" [ngSrc]="data.img" priority width="100" height="100">
        </div>
        <div class="product-brand fs-16 fw-600 ion-text-center pt-10">
            <div class="category-brand-name">{{data.name}}</div>
        </div>
    </div>

    <div class="slide-container-faq" *ngIf="faq && !categories && !brand">
        <div class="product-image-container">
            <div class="product-list-image-div" [title]="data.title">
                <video [poster]="data.thumbnail" controls (play)="handleVideoPlay()" (pause)="handleVideoPause()"
                    #video>
                    <source [src]="data.video_url">
                </video>
                <div [ngClass]="isBrowser&&isFirefox()?'hide-on-firefox':'play-icon'" *ngIf="showPlayIcon"
                    (click)="handlePlayClick()">
                    <img alt="Play Icon" [ngSrc]="'/assets/svg/play-icon.svg'" priority>
                </div>
            </div>
            <div class="pt-15 pb-15 pl-20 pr-20 category-title">
                <div class="title pb-10" [title]="data.title">
                    {{ data.title }}
                </div>
                <div class="category-name">
                    {{ data.category_name }}
                </div>
            </div>
        </div>

    </div>
</div>

<div *ngIf="isLoading">
    <div class="slide-container" *ngIf="!categories && !brand">
        <div class="product-image-container">
            <div class="product-new" *ngIf="isNew">
                <ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
                    [animated]="true"></ion-skeleton-text>
            </div>
            <div class="product-list-image-div">
                <ion-skeleton-text class="skeleton-container" name="lines-sharp-small"
                    [animated]="true"></ion-skeleton-text>
            </div>
        </div>
        <div class="detail">
            <div class="ion-text-left pl-20 pt-5 pb-10">
                <ion-skeleton-text class="skeleton-container height-100" name="lines-sharp-small"
                    [animated]="true"></ion-skeleton-text>
            </div>
            <div class="type fs-14 fw-500 ion-text-left pl-20 pb-5">
                <ion-skeleton-text class="skeleton-container height-100" name="lines-sharp-small"
                    [animated]="true"></ion-skeleton-text>
            </div>
            <div class="product-brand fs-16 fw-600 ion-text-left pl-20">
                <span>
                    <ion-skeleton-text class="skeleton-container height-100" name="lines-sharp-small"
                        [animated]="true"></ion-skeleton-text>
                </span>
            </div>

        </div>
    </div>

    <div class="slide-container2" *ngIf="categories || brand">
        <div class="product-image-container2">
            <ion-skeleton-text class="skeleton-container width-100 height-100" name="lines-sharp-small"
                [animated]="true"></ion-skeleton-text>
        </div>
        <div class="product-brand fs-16 fw-600 ion-text-center pt-10">
            <span>
                <ion-skeleton-text class="skeleton-container width-100" name="lines-sharp-small"
                    [animated]="true"></ion-skeleton-text>
            </span>
        </div>
    </div>
</div>