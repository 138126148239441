import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { CustomValidator } from './../../_common/customvalidators';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { ToasterService } from './../../services/toaster.service';


export interface ConsultantRequest {
	first_name?: string;
	last_name?: string;
	email?: string;
	mobile_no?: number;
	description?: string;
}

@Component({
	selector: 'app-consultant-request',
	templateUrl: './consultant-request.component.html',
	styleUrls: ['./consultant-request.component.scss'],
})
export class ConsultantRequestComponent implements OnInit {

	public subscription = new Subscription();
	public consultantRequestForm: FormGroup;
	public isDisabled = false;
	public isLoading = false;

	fieldData = {
		"name": "Name",
		"email": "Email",
		"mobile_no": "Mobile number"
	}

	public validation_messages = {
		description: [
			{ type: 'required', message: 'Please enter description' },
			{ type: 'maxlength', message: 'Description should not be more than 2000 characters long' },
			{ type: 'invalidString', message: 'Please enter a valid description' }
		],
		reason: [
			{ type: 'required', message: 'Please enter reason' },
			{ type: 'maxlength', message: 'Reason should not be more than 100 characters long' },
			{ type: 'invalidString', message: 'Please enter a valid reason' }
		]

	}

	constructor(
		private formBuilder: FormBuilder,
		public commonService: CommonService,
		private dataService: ApiService,
		private toasterService: ToasterService,
		private route: ActivatedRoute,
		private router: Router
	) {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				const url = event.urlAfterRedirects;
				if (url === '/consultant-request') {
					this.consultantRequestForm.reset()
				}
			}
		});
	}

	ngOnInit() {
		this.consultantRequestForm = this.initializeConsultantRequestFormGroup()

	}

	get f1() { return this.consultantRequestForm.controls; }

	initializeConsultantRequestFormGroup() {
		return this.formBuilder.group({
			first_name: new FormControl(null, {
				validators: Validators.compose([Validators.required, Validators.maxLength(15), CustomValidator.nameValidator]),
			}),
			email: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.emailValidator]),
			}),
			phone_no: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.phoneValidator]),
			}),
			last_name: new FormControl(null, {
				validators: Validators.compose([Validators.required, Validators.maxLength(15), CustomValidator.nameValidator]),
			}),
			description: new FormControl(null, {
				validators: Validators.compose([Validators.required, Validators.maxLength(2000), CustomValidator.noWhiteSpaceValidator]),
			}),
			reason: new FormControl(null, {
				validators: Validators.compose([Validators.required, Validators.maxLength(100), CustomValidator.noWhiteSpaceValidator]),
			}),
		})

	}

	submit() {
		for (const i in this.f1) {
			this.f1[i].markAsDirty();
			this.f1[i].updateValueAndValidity();
			this.f1[i].markAsTouched();
		}

		if (this.consultantRequestForm.invalid) {
			return
		}

		const params = {
			"reason": this.f1.reason.value.trim(),
			"description": this.f1.description.value.trim(),
			"name": `${this.f1.first_name.value.trim()} ${this.f1.last_name.value.trim()}`,
			"mobile_no": this.f1.phone_no.value,
			"email": this.f1.email.value,
			"request_from": this.commonService.isLoggedIn ? "USER" : "GUEST"
		}

		this.isLoading = true;
		this.isDisabled = true;
		this.subscription.add(
			this.dataService.post(URLS.submitConsultantRequest, params).subscribe(data => {
				this.isLoading = false;
				if (data['code'] == 200) {
					this.consultantRequestForm.reset();
					this.toasterService.successToast(data['message'], 'top');
					this.isLoading = false;
					this.isDisabled = false;
				}
				else {
					this.toasterService.errorToast(data['message'], 'top');
					this.isLoading = false;
					this.isDisabled = false;
				}
			}, error => {
				this.isLoading = false;
				this.isDisabled = false;
				this.toasterService.errorToast(error['message'], "top");

			})
		)

	}

}
