
export class ColumnMap {
    primaryKey?: string;
    secondaryKey?: string;
    header?: string;
    sort?: boolean;
    status?: boolean;
    info_status?: boolean;
    action?: boolean;
    toggle?: boolean;
    image?: true;
    payment_status?: boolean;
    is_price?: boolean;
    is_qty_price?: boolean;
    expired_after?: boolean = false;
    other_text?: any;

    constructor(settings) {
        this.primaryKey = settings.primaryKey;
        this.secondaryKey = settings.secondaryKey;
        this.header = settings.header;
        this.status = settings.status;
        this.info_status = settings.info_status;
        this.sort = settings.sort;
        this.action = settings.action;
        this.toggle = settings.toggle;
        this.image = settings.image;
        this.payment_status = settings.payment_status;
        this.is_price = settings.is_price;
        this.is_qty_price = settings.is_qty_price;
        this.expired_after = settings.expired_after;
        this.other_text = settings.other_text;
    }
}
