<div class="reject-product">
    <div class="close-div p-5" *ngIf="hideCloseBtn != true">
        <img alt="Close Icon" (click)="close(false)" src="/assets/icon/close.svg" alt="" slot="end" class="close-icon">
    </div>
    <div class="title">{{title}}</div>
    <div class="desc">{{description}}</div>
    <div>
        <ion-label class="fs-16 fw-500 mb-10">Reason Of Rejection *</ion-label>
        <ion-textarea maxlength="300" (keyup)="reasonValidate($event)" [(ngModel)]="reason" rows="6" capitalizeFirst
            placeholder="Please enter reason"></ion-textarea>
        <span class="error-msg" *ngIf="reasonErr">Please enter reason </span>

    </div>
    <div class="img-upload mt-10 mb-20">
        <div class="upload-btn">
            <div class="file-upload-button mr-10  pointer fs-14 fw-600">
                <input type="file" #fileInput (click)="clearFileInput(fileInput)" (change)="uploadFile(fileInput)" />
            </div>
        </div>
        <span class="mt-5">Choose file</span>
    </div>
    <div *ngIf="isImageError" class="error-msg mt-m5">Please add image to reject </div>
    <div class="upload-files">
        <div class="upload-list fw-500" *ngFor="let onFile of uploadFiles;let i = index">
            <img alt="Close Icon" (click)="removeUpload(i)" class="close" src="../../../../assets/icon/close_grey.svg">
            {{onFile}}
        </div>
    </div>
    <div class="mt-20" [ngClass]="getPlatformService.isDesktop ? 'display-flex width-100 ': 'mobile-full-btn'">
        <button [disabled]="isLoading" type="primary" class="primary-btn"
            [ngClass]="!getPlatformService.isDesktop ? 'width-100': null" (click)="submit()">
            <ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
            <span>{{submitBtnText}}</span>
        </button>

        <button class="clear-btn" (click)="close(false)"
            [ngClass]="!getPlatformService.isDesktop ? 'width-100 mt-20': 'ml-20'">
            <span [ngClass]="!getPlatformService.isDesktop ? 'ph-30': 'ph-50'">{{cancelBtnText}}</span>
        </button>
    </div>

</div>