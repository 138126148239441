import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ModalService } from '../../services/modal.service';

@Component({
    selector: 'app-thank-you',
    templateUrl: './thank-you.component.html',
    styleUrls: ['./thank-you.component.scss'],
})
export class ThankYouComponent implements OnInit {
    @Input() shoppingCartBTnText;
    @Input() btnText = 'My Quote Requests';
    @Input() btnLink = '';
    @Input() btn1Link = '';
    @Input() title = 'Thank You';
    @Input() btnColor = "white";
    @Input() description = 'Your quotation request submitted, we will update you very soon!';
    @Input() isBtnHide = false;
    @Input() hideCloseBtn: any;
    isLoading = false;
    constructor(
        private modalService: ModalService,
        private navCtrl: NavController,

    ) { }

    ngOnInit() { }

    onCancel() {
        this.modalService.dismissModal();
    }

    redirectBtn1() {
        this.navCtrl.navigateRoot(this.btn1Link, { animated: false });
        this.onCancel();
    }

    redirect() {
        this.navCtrl.navigateRoot(this.btnLink, { animated: false });
        this.onCancel();
    }

}
