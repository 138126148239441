import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSlides, NavController } from '@ionic/angular';
import { SwiperOptions } from 'swiper';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';

@Component({
    selector: 'app-product-slider',
    templateUrl: './product-slider.component.html',
    styleUrls: ['./product-slider.component.scss'],
})
export class ProductSliderComponent implements OnInit {
    @ViewChild("productsSlides", { static: false }) slider: IonSlides;
    @ViewChild("catSlides", { static: false }) catSlider: IonSlides;

    @Input() public categories: boolean = false;
    @Input() public faq: boolean = false;
    @Input() public title = '';
    @Input() public brand: boolean = false;
    @Input() sliderData = [];
    @Input() isNew = false;
    @Input() isLoading = false;
    @Input() productNavigaiton: string = "";
    @Input() viewAllParams: any;

    config: SwiperOptions = {
        speed: 400,
        spaceBetween: 30,
        breakpoints: {
            320: {
                slidesPerView: 1.5,
            },
            360: {
                slidesPerView: 1.5,
                spaceBetween: 0,
            },
            375: {
                slidesPerView: 1.5,
                spaceBetween: 0,
            },
            385: {
                slidesPerView: 2,
            },
            488: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 3,
            },
            850: {
                slidesPerView: 3,
            },
            1100: {
                slidesPerView: 4,
            },


        },
    };
    config2: SwiperOptions = {
        initialSlide: 0,
        speed: 400,
        spaceBetween: 30,
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            360: {
                slidesPerView: 2,
            },
            375: {
                slidesPerView: 2,
            },
            385: {
                slidesPerView: 2,
            },
            488: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 3,
            },
            850: {
                slidesPerView: 3,
            },
            1100: {
                slidesPerView: 6,
            },


        },
    };
    configMobile: SwiperOptions = {
        speed: 400,
        spaceBetween: 20,
        breakpoints: {
            320: {
                slidesPerView: 1.7,
            },
            360: {
                slidesPerView: 1.9,
                spaceBetween: 0,
            },
            375: {
                slidesPerView: 1.8,
                spaceBetween: 0,
            },
            385: {
                slidesPerView: 1.9,
            },
            488: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 3,
            },
            850: {
                slidesPerView: 3,
            },
            1100: {
                slidesPerView: 4,
            },


        },
    };
    config2Mobile: SwiperOptions = {
        initialSlide: 0,
        speed: 400,
        spaceBetween: 20,
        breakpoints: {
            320: {
                slidesPerView: 2,
            },
            360: {
                slidesPerView: 2,
            },
            375: {
                slidesPerView: 2,
            },
            385: {
                slidesPerView: 2,
            },
            488: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 3,
            },
            850: {
                slidesPerView: 3,
            },
            1100: {
                slidesPerView: 6,
            },


        },
    };
    constructor(
        private navCtrl: NavController,
        private router: Router,
        public getPlatformService: GetPlatformService,
        public commonService: CommonService) { }

    ngOnInit() {
    }

    async goToProductListing() {
        this.commonService.localStorageRemove("selected_category");
        this.commonService.localStorageRemove("selected_brand");
        this.commonService.localStorageRemove("selected_option");
        this.commonService.localStorageRemove("selected_attribute");
        await this.navCtrl.navigateRoot('/home/product-listing',
            {
                animated: false, "state":
                {
                    "breadcrumb": this.title ? this.title : "Product List",
                    "url": '/home/product-listing/',
                    state: null
                }
            })
        this.commonService.filterDataEmit({
            product_navigation: this.productNavigaiton,
            isDataCalled: true,
            viewAllData: this.viewAllParams
        });
    }

}
