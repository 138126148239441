<div class="upload  pb-15">
    <p class="fs-16 fw-500 pt-10 pb-5">Director/Proprietor Id Proof<span
            [ngClass]="customEventFromParent && !idProofFile && !documentReset ? 'error-msg' : ''">*</span></p>
    <ion-row class="mb-5 id-proof">
        <ion-col>
            <div class="upload-btn">
                <div *ngIf="idProofFile" class="file-info h-40 fs-16 fw-500" [title]="idProofFile"><span>{{ idProofFile
                        }}</span></div>
                <div *ngIf="!idProofFile" class="file-info like-placeholder fs-16 fw-500">Upload Director/Proprietor Id
                    Proof</div>
                <button *ngIf="!idProofFile || idProofFile === null"
                    class="file-upload-button clear-btn pointer fs-14 fw-600">
                    <span> Upload</span>
                    <input type="file" #fileInput (click)="clearCertificateFileInput(fileInput)"
                        (change)="onIdProofSelect(fileInput)" />
                </button>
                <button *ngIf="idProofFile && isCompany" (click)="viewProof()"
                    class="w-88 h-45 clear-btn pointer fs-14 fw-600 mr-10">
                    <span>View</span>
                </button>
                <button *ngIf="idProofFile && !isSellerApproval" (click)="removeProof()"
                    class="w-112 h-45 clear-btn pointer fs-14 fw-600">
                    <span> Remove</span>
                </button>

            </div>
        </ion-col>
    </ion-row>
    <span class="error-text" *ngIf="customEventFromParent && !idProofFile && !documentReset">Please upload id proof
        file</span>
    <p class="fs-16 fw-500  pt-10 pb-5">Company Registration/GST Certificate<span
            [ngClass]="customEventFromParent && !certicateFile && !documentReset ? 'error-msg' : ''">*</span></p>
    <ion-row class="mb-5 id-proof">
        <ion-col>
            <div class="upload-btn">
                <div *ngIf="certicateFile" class="file-info h-40 fs-16 fw-500" [title]="certicateFile"><span>{{
                        certicateFile }}</span></div>
                <div *ngIf="!certicateFile" class="file-info like-placeholder fs-16 fw-500">Upload Company
                    Registration/GST Certificate</div>
                <button *ngIf="!certicateFile || certicateFile === null"
                    class="file-upload-button clear-btn pointer fs-14 fw-600">
                    <span> Upload</span>
                    <input type="file" #fileInput (click)="clearProofFileInput(fileInput)"
                        (change)="onCertificateSelect(fileInput)" />
                </button>
                <button *ngIf="certicateFile && isCompany" (click)="viewCert()"
                    class="w-88 h-45 clear-btn pointer fs-14 fw-600 mr-10">
                    <span>View</span>
                </button>
                <button *ngIf="certicateFile && !isSellerApproval" (click)="removeCert()"
                    class="w-112 h-45 clear-btn pointer fs-14 fw-600">
                    <span> Remove</span>
                </button>

            </div>
        </ion-col>
    </ion-row>
    <span class="error-text" *ngIf="customEventFromParent && !certicateFile && !documentReset">Please upload
        certificate file</span>
</div>

<ng-container *ngIf="!isSellerApproval">
    <div class="form-button mt-30 " *ngIf="isCompany">
        <button [disabled]="isLoading" (click)="onDocumentSubmit()" type="primary" class="mr-20  w-182 submit-btn">
            <ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
            {{progress == 100 ? 'Save' : 'Save & Next'}}
        </button>
        <button class="clear-btn w-147" (click)="documentCancel()">Cancel</button>
    </div>
</ng-container>