import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
	enableProdMode();
	// Only do this in production environment
	console.log = function () { };
	console.warn = function () { };
}

function bootstrap() {
	platformBrowserDynamic().bootstrapModule(AppModule)
		.catch(err => console.log(err));
};


if (document.readyState === 'complete') {
	bootstrap();
} else {
	document.addEventListener('DOMContentLoaded', bootstrap);
}

