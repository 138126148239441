<div class="success-container">
	<div class="fs-28 fw-600 text-color">
		Order Tracking
	</div>
	<div class="close-div p-5" (click)="close(false)">
		<img alt="Close Icon" src="/assets/icon/close.svg" alt="" slot="end" class="close-icon">
	</div>
	<div class="description-container scroll-content pt-20">
		<div *ngFor="let data of trackingData.productData">
			<div class="display-flex">
				<div class="image-col">
					<img alt="Image" src="{{data.image_url}}" class="product_img" />

					<div class="pb-60">
						<div *ngFor="let item of data.tracking_status;let i = index">
							<div class="tracking-div">
								<div class="vertical-line m-0 p-0"
									*ngIf="i < data.tracking_status.length && !data.warehouse"></div>
								<div [ngClass]="data.showWarehouse && i == 0 ? 'vertical-line-112' : 'vertical-line m-0 p-0'"
									*ngIf="i < data.tracking_status.length && data.warehouse"></div>
								<img alt="Check Icon" *ngIf="item.complete == true" src="/assets/icon/done.svg" alt=""
									class="tracking-icon  m-0 p-0" />
								<img alt="Select Icon" *ngIf="item.complete == false"
									src="/assets/icon/unselect-Done.svg" alt="" class="tracking-icon  m-0 p-0" />
							</div>
						</div>
					</div>
				</div>
				<div class="pl-20 pr-70">
					<div>
						<div class="product-div">
							<div class="label-head mb-10 max-width-250 custom-ellipse-text"
								[ngClass]="{'pointer': data.product_name.length > 30}"
								[attr.title]="(data.product_name && data.product_name.length > 30) ? data.product_name : ''">
								{{data.product_name }}</div>
							<p class="fs-14 pb-7 light-grey-text m-0">SKU: <span class="text-color custom-ellipse-text"
									[ngClass]="{'pointer': data.sku.length > 30}"
									[attr.title]="(data.sku && data.sku.length > 30) ? data.sku : ''">{{data.sku
									}}</span>
							</p>

							<div class="product-attribute" *ngFor="let oneAttr of data.product_attributes">
								<div class="attribute-header">
									{{oneAttr.attribute_name}}:
								</div>
								<div class="attribute attribute-value">
									{{oneAttr.value}}
								</div>
							</div>
						</div>

						<div class="max-width-200 mb-10" *ngIf="data.warehouse">
							<div class="fs-14 pb-7 m-0 align-center pointer"
								[ngClass]="data.showWarehouse ? 'blue-text fw-600':'light-grey-text'"
								(click)="data.showWarehouse = !data.showWarehouse">
								Dispatch Location/Manufacturing
								<span class="ml-5">
									<img alt="Dropdown Icon" src="/assets/icon/dropdown-bottom.svg"
										class="min-width-8" />
								</span>
							</div>
							<div class="fs-14 fw-500 text-color" *ngIf="data.showWarehouse">{{data.warehouse}}</div>
						</div>

						<div *ngFor="let item of data.tracking_status">
							<div
								[ngClass]=" !data.warehouse ? item.status == 1 ? 'mt-60' : 'mt-50' : data.showWarehouse && item.status == 1 ? 'mt-20': 'mt-50'">
								<div>
									<div class="fs-14 pb-5 light-grey-text m-0">
										{{item.name}}
									</div>
									<div class="fs-14 fw-500 text-color h-20">
										<span>{{ item.date ? (item.date | date:'dd-MM-yyyy h:mm a') : 'In
											progress'}}</span>
									</div>
								</div>
							</div>

						</div>
					</div>

				</div>
			</div>
		</div>

	</div>
</div>