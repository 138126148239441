<div class="change-password-container form-container pt-30 ">
    <div class="change-password-header fs-28 fw-600">
        Create new password
    </div>
    <form [formGroup]="changePasswordForm" class="unselectable">
        <div class="form-inputs psw-position common-input">
            <ion-label>New password *</ion-label>
            <ion-input clearOnEdit="false" class="registration-pass input-with-lock-icon"
                placeholder="Enter New Password" type="{{oldPasswordType}}" formControlName="new_password"
                (keydown.space)="$event.preventDefault();">
            </ion-input>
            <img alt="Open Lock" class="input-icon input-toggle-icon" *ngIf="oldPasswordType === 'text'"
                (click)="oldPasswordType = 'password'" src="/assets/icon/eye.svg">
            <img alt="Open Lock" class="input-icon input-toggle-icon" *ngIf="oldPasswordType === 'password'"
                src="../../../assets/icon/eye-off.svg" (click)="oldPasswordType = 'text'">
            <ng-container *ngFor="let validation of validation_messages.new_password">
                <ng-container
                    *ngIf="changePasswordForm.get('new_password').hasError(validation.type) && changePasswordForm.get('new_password').dirty">
                    <ion-text color="danger" class="error-msg">
                        {{validation.message }}
                    </ion-text>
                </ng-container>
            </ng-container>
        </div>
        <div class="form-inputs psw-position common-input">
            <ion-label>Confirm password *</ion-label>
            <ion-input clearOnEdit="false" class="registration-pass input-with-lock-icon"
                placeholder="Re-Enter New Password" type="{{newPasswordType}}" formControlName="confirm_password"
                (keydown.space)="$event.preventDefault();">
            </ion-input>
            <img alt="Open Lock" class="input-icon input-toggle-icon" *ngIf="newPasswordType === 'text'"
                (click)="newPasswordType = 'password'" src="/assets/icon/eye.svg">
            <img alt="Open Lock" class="input-icon input-toggle-icon" *ngIf="newPasswordType === 'password'"
                src="../../../assets/icon/eye-off.svg" (click)="newPasswordType = 'text'">
            <ng-container *ngFor="let validation of validation_messages.confirm_password">
                <ng-container
                    *ngIf="changePasswordForm.get('confirm_password').hasError(validation.type) && changePasswordForm.get('confirm_password').dirty">
                    <ion-text color="danger" class="error-msg">
                        {{validation.message }}
                    </ion-text>
                </ng-container>
            </ng-container>
        </div>

    </form>
    <div class="change-password-botton-div pt-10">
        <div class="change-password-botton">
            <button class="submit-btn fs-16 fw-600" (click)="save()" [disabled]="isLoading">
                <ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
                Save
            </button>
        </div>
    </div>
</div>