import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, PopoverController } from '@ionic/angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { CommonService } from './../services/common.service';

declare var cordova: any;

const CODEMESSAGE = {
	200: 'The server successfully returned the requested data.',
	201: 'New or modified data is successful.',
	202: 'A request has entered the background queue (asynchronous task).',
	204: 'The data was deleted successfully.',
	400: 'The request was made with an error and the server did not perform any operations to create or modify data.',
	401: 'User does not have permission (token, username, password is incorrect).',
	403: 'The user is authorized, but access is forbidden.',
	404: 'The request is made for a record that does not exist and the server does not operate.',
	406: 'The format of the request is not available.',
	410: 'The requested resource is permanently deleted and will not be retrieved.',
	422: 'A validation error occurred when creating an object.',
	500: 'An error occurred on the server. Please check the server.',
	502: 'Gateway error.',
	503: 'The service is unavailable and the server is temporarily overloaded or maintained.',
	504: 'The gateway timed out.',
};

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	currentRequestUrl: any;
	isPopOpen = false;
	unauthorizedCount = 0;
	constructor(
		private router: Router,
		private popoverCtrl: PopoverController,
		private commonService: CommonService,
		private navCtrl: NavController,
		private authService: AuthenticationService
	) { }

	getToken() {
		return this.commonService.localStorageGet('accessToken') || this.commonService.accessToken;
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		this.currentRequestUrl = req.url;

		const token = this.commonService.localStorageGet('accessToken');
		const fromLogin = this.commonService.localStorageGet('from_login');
		const fromRegister = this.commonService.localStorageGet('from_Register');
		const fromForgotPassword = this.commonService.localStorageGet('from_forgotPassword')

		const headers = {};
		headers['Cache-Control'] = 'no-cache';
		headers['Access-Control-Allow-Origin'] = '*';
		headers['iswebsiterequest'] = 'true';

		const publicApiArray: string[] = [
			"/website/auth/login",
			"/website/user/states/get",
			"/website/user/city/get",
			"/website/user/country/get",
			"/website/categories/get",
			"/website/banners/get",
			"/website/selling/products/get",
			"/website/recently/added/products/get",
			"/website/brands/get",
			"/website/add/newsletters",
			"/website/auth/verify/otp",
			"/website/auth/generate/otp",
			"/website/products/get",
			"/website/products/related/get",
			"/website/products/detail/get",
			"/website/products/best-deal/get",
			"/website/products/review/add",
			"/website/products/review",
			"/website/consultant/request",
			"/website/contactus/create",
			"/website/blog/addcomment",
			"/website/blog/categories",
			"/website/blog/add",
			"/website/blog/comment/like",
			"/website/blog/comment/dislike",
			"/website/blog/listcomments",
			"/website/blog/list",
			"/website/home/categories/get",
			"/website/cms/faqs/get",
			"/website/newsletters",
			"/website/user/district/get",
			"/website/user/taluka/get",
			"/website/privacypolicy",
			"/website/aboutus",
			"/website/search/filter",
			"/website/searched/product/add",
			"/website/auth/verify/email_otp",
			"/website/auth/generate/email_otp",
			'/website/termsofuse',
			'/website/user/addresstype/get',
			'/website/mastercategory/get',
			'/website/products/subproduct/get',
			'/website/products/mainproductdetail/get'
		];

		const isUrlIncluded = publicApiArray.some(url => this.currentRequestUrl.includes(url));

		if (token && !isUrlIncluded) {
			headers['authorization'] = token;
		} else {
			headers['Authorization'] = 'g48295b8-pshg-84hf-3857-936755g93756';
		}
		// Use the token in the request
		req = req.clone({
			setHeaders: headers
		});
		// Handle the request
		return next.handle(req).pipe(
			mergeMap((event: any) => {
				if (event instanceof HttpResponseBase) return this.handleData(event);
				return of(event);
			}),
			catchError((err: HttpErrorResponse) => this.handleData(err)),
		);

	}

	private checkStatus(ev: HttpResponseBase) {
		if (ev && ((ev.status >= 200 && ev.status < 300) || ev.status === 401)) {
			return;
		}
		if (ev && ev.status == 0) {
			this.commonService.localStorageSet('isNetworkConnected', false);
		}
	}

	public handleData(ev: HttpResponseBase): Observable<any> {
		this.checkStatus(ev);
		switch (ev['status']) {
			case 0:
				ev['error']['message'] = "Something went wrong!";
				return throwError(ev['error']);
			case 200:
				this.commonService.localStorageRemove('forceUpgradePopup');
				if (ev['body'] && ev['body']['code'] == 401 && !ev['url'].includes('/customer/logout')) {
					this.unauthorizedCount++;
					if (this.unauthorizedCount == 1) {
						this.logout();
						return throwError(ev['error']);
					}

				}
				break;
			case 400:
				return throwError(ev['error']);
				break;
			case 401:
				this.logout();
				return throwError(ev['error']);
				break;
			case 403:
				this.logout();
				return throwError(ev['error']);
				break;
			case 404:
				return throwError(ev['error']);
				break;
			case 409:
				return throwError(ev['error']);
				break;
			case 410:
				return throwError(ev['error']);
				break;
			case 500:
				return throwError(ev['error']);
				break;
			default:
				if (ev instanceof HttpErrorResponse) {
					console.warn(`I don't know the error, most of it is caused by the backend not supporting CORS or invalid configuration.`, ev['error']);
					return throwError(ev['error']);
				}
				break;
		}
		return of(ev);
	}

	logout() {
		this.authService.logout().subscribe((data) => {
			if (data['code'] === 200) {
				this.commonService.userData = {};
				this.commonService.isLoggedIn = false;
				this.commonService.accessToken = null;
				this.commonService.is_admin = false;
				this.commonService.is_sub_user = false;
				this.commonService.localStorageRemove('firstSelectedProduct');
				this.commonService.localStorageClear();
				this.navCtrl.navigateRoot(['/login']);
			}
			else {
				this.commonService.isLoggedIn = false;
				this.commonService.userData = {};
				this.commonService.localStorageRemove('firstSelectedProduct');
				this.commonService.localStorageClear();
				this.commonService.accessToken = null;
				this.commonService.is_admin = false;
				this.commonService.is_sub_user = false;
				this.navCtrl.navigateRoot(['/login']);
			}
		},
			error => {
				console.log(error);

			});


	}

}




