import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/services/api-data.service';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToasterService } from 'src/app/services/toaster.service';

const MAX_FILE_SIZE_IMAGE = 5 * 1024 * 1024;
const MIN_FILE_SIZE_IMAGE = 5 * 1024;
@Component({
	selector: 'app-reject-product',
	templateUrl: './reject-product.component.html',
	styleUrls: ['./reject-product.component.scss'],
})
export class RejectProductComponent implements OnInit {
	public subscription = new Subscription();
	isLoading = false;
	@Input() title = '';
	@Input() description = '';
	@Input() submitBtnText;
	@Input() cancelBtnText;
	@Input() orderId;
	@Input() hideCloseBtn?: any;

	uploadFiles = [];
	rejectForm: FormGroup;
	reason = '';
	reasonErr = false;
	uploadData = [];
	isImageError = false;

	public validation_messages = {
		reason: [
			{ type: 'required', message: 'Please enter reason' },
		],
	}

	constructor(
		private modalService: ModalService,
		public getPlatformService: GetPlatformService,
		private toasterService: ToasterService,
		private formBuilder: FormBuilder,
		private dataService: ApiService,
		private navCtrl: NavController,

	) { }

	ngOnInit() {

	}

	close(isDraft) {
		this.modalService.dismissModal({ isDraft });
	}

	uploadFile(input: HTMLInputElement): void {

		/**
		 * Format the size to a human readable string
		 *
		 * @param bytes
		 * @returns the formatted string e.g. `105 kB` or 25.6 MB
		 */
		function formatBytes(bytes: number): string {
			const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			const factor = 1024;
			let index = 0;

			while (bytes >= factor) {
				bytes /= factor;
				index++;
			}

			return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
		}

		const file = input.files[0];
		let fileSize = file['size'];
		let fileExtension = file['name'].split('.').pop().toLocaleLowerCase();
		if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
			if (fileSize > MAX_FILE_SIZE_IMAGE || fileSize < MIN_FILE_SIZE_IMAGE) {
				this.toasterService.warningToast("Please upload document within minimum 5KB or maximum 5MB");
				return;
			}
		}
		else {
			this.toasterService.warningToast("Please select JPG, JPEG and PNG format image!", 'top');
			return;
		}
		if (this.uploadData.length >= 5) {
			this.toasterService.warningToast("You cannot add more than 5 images", 'top');
			return;
		}
		else {
			this.isImageError = false;
			this.uploadFiles.push(`${file.name}`);
			this.uploadData.push(file);
		}

	}

	removeUpload(i) {
		this.uploadFiles.splice(i, 1);
		this.uploadData.splice(i, 1);
		if (this.uploadFiles.length < 1) {
			this.isImageError = true;

		}

	}

	clearFileInput(fileInput: HTMLInputElement): void {
		fileInput.value = ''; // Clear the file input value
	}

	submit() {
		if (!this.reason || this.reason == null) {
			this.reasonErr = true;
			if (this.uploadData.length < 1) {
				this.isImageError = true;
				return;
			}
			return;
		}
		if (this.uploadData.length < 1) {
			this.isImageError = true;
			return;
		}
		let formData = new FormData();
		formData.append("order_item_id", this.orderId);
		formData.append("reason_of_rejection", this.reason);
		this.uploadData.forEach(element => {
			formData.append("product_images", element);
		});
		this.isLoading = true;
		this.subscription.add(
			this.dataService.put(URLS.customerOrderReject, formData).subscribe(data => {
				this.isLoading = false;
				if (data['code'] == 200) {
					this.close(true);
					this.isImageError = false;
					this.reasonErr = false;
				}
				else {
					this.toasterService.errorToast(data['message'], 'top');
				}
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	reasonValidate(val) {
		this.reasonErr = true;
		if (val.target.value) this.reasonErr = false;
	}

}
