import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { ToasterService } from './../../services/toaster.service';

const MAX_FILE_SIZE_IMAGE = 5 * 1024 * 1024;
const MIN_FILE_SIZE_IMAGE = 5 * 1024;

@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss'],
})
export class DocumentComponent implements OnInit, OnDestroy {
    public subscription = new Subscription();
    @Output() uploadEvent = new EventEmitter();
    @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
    @Input() customEventFromParent: string;
    @Input() isCompany = false;
    progress = 0;
    fileInfo: string;
    idProofFile: string = '';
    certicateFile: string = '';
    isLoading = false;
    idProofFileData;
    certificateFileData;
    @Input() isSellerApproval;
    idFileUrl: string;
    certicateFileUrl: string;
    documentReset: boolean = false;
    isToastVisible: boolean = false;

    constructor(private commonService: CommonService,
        private toasterService: ToasterService,
        private dataService: ApiService,
        private cdr: ChangeDetectorRef) {
        this.subscription = this.commonService.clearData.subscribe(data => {
            if (data && data.isDocument) {
                this.documentReset = false;
            } else {
                this.idProofFile = null;
                this.certicateFile = null;
                this.documentReset = true;

            }
        })
    }

    ngOnInit() {
        if (this.isCompany) {
            this.getCompanyDetail();
            this.getProgressBar();
        }
    }

    getProgressBar() {
        this.subscription.add(
            this.dataService.get(URLS.sellerProgressBar).subscribe(data => {
                if (data['code'] == 200) {
                    this.progress = data['data']['progress'];
                    let localSellerApproval = this.commonService.localStorageGet('seller_approval') == 'PENDING' ? true : false;
                    if (localSellerApproval && this.progress == 100) {
                        //   this.isSellerApproval = true;
                    }
                }
                else {
                    this.toasterService.errorToast(data['message']);
                }
            }, error => {
                this.toasterService.errorToast(error['message']);
            })
        )
    }

    /**
  * Called when the value of the file input changes, i.e. when a file has been
  * selected for upload.
  *
  * @param input the file input HTMLElement
  */
    onIdProofSelect(input: HTMLInputElement): void {
        this.cdr.detectChanges();

        /**
         * Format the size to a human readable string
         *
         * @param bytes
         * @returns the formatted string e.g. `105 kB` or 25.6 MB
         */
        function formatBytes(bytes: number): string {
            const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const factor = 1024;
            let index = 0;

            while (bytes >= factor) {
                bytes /= factor;
                index++;
            }

            return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
        }
        const file = input.files[0];
        let fileSize = file['size'];
        let fileExtension = file['name'].split('.').pop().toLocaleLowerCase();
        if (fileExtension == 'webm' || fileExtension == 'mkv' || fileExtension == 'flv' || fileExtension == 'mp4' || fileExtension == 'mpg' || fileExtension == 'mpv' || fileExtension == 'ogg' || fileExtension == 'ogv' || fileExtension == 'wmv') {
            this.isToastVisible = true;
            this.toasterService.warningToast("Please select JPG, JPEG ,PNG image Or file !", 'top');
            this.toastMethodDisable();
            return;
        }

        if (fileSize > MAX_FILE_SIZE_IMAGE || fileSize < MIN_FILE_SIZE_IMAGE) {
            this.isToastVisible = true;
            this.toasterService.warningToast("Please upload document within minimum 5KB or maximum 5MB");
            this.toastMethodDisable();
            input = null;
            this.idProofFileData = null;
            this.idProofFile = null;
            return;
        }

        this.idProofFileData = file;
        this.idProofFile = `${file.name}`;
        this.uploadEvent.emit({ ID: this.idProofFileData, CERTIFICATE: this.certificateFileData });

    }

    onCertificateSelect(input: HTMLInputElement): void {

        /**
         * Format the size to a human readable string
         *
         * @param bytes
         * @returns the formatted string e.g. `105 kB` or 25.6 MB
         */
        function formatBytes(bytes: number): string {
            const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const factor = 1024;
            let index = 0;

            while (bytes >= factor) {
                bytes /= factor;
                index++;
            }

            return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
        }
        if (this.isToastVisible) {
            return;
        }
        const file = input.files[0];
        let fileSize = file['size'];
        let fileExtension = file['name'].split('.').pop().toLocaleLowerCase();
        if (fileExtension == 'webm' || fileExtension == 'mkv' || fileExtension == 'flv' || fileExtension == 'mp4' || fileExtension == 'mpg' || fileExtension == 'mpv' || fileExtension == 'ogg' || fileExtension == 'ogv' || fileExtension == 'wmv') {
            this.isToastVisible = true;
            this.toasterService.warningToast("Please select JPG, JPEG ,PNG image Or file !", 'top');
            this.toastMethodDisable();
            return;
        }
        if (fileSize > MAX_FILE_SIZE_IMAGE || fileSize < MIN_FILE_SIZE_IMAGE) {
            this.certicateFile = null;
            this.certificateFileData = null;
            this.isToastVisible = true
            this.toasterService.warningToast("Please upload document within minimum 5KB or maximum 5MB");
            this.toastMethodDisable();
            return;
        }
        this.certificateFileData = file;
        this.certicateFile = `${file.name}`;
        this.uploadEvent.emit({ ID: this.idProofFileData, CERTIFICATE: this.certificateFileData });
        this.cdr.detectChanges();

    }


    clearCertificateFileInput(fileInput: HTMLInputElement): void {
        fileInput.value = ''; // Clear the file input value
    }

    clearProofFileInput(fileInput: HTMLInputElement): void {
        fileInput.value = ''; // Clear the file input value
    }
    getCompanyDetail() {
        this.subscription.add(
            this.dataService.get(URLS.sellerCompanyDetail).subscribe(data => {
                if (data['code'] == 200) {
                    let companyInfo = data['data'];
                    this.certicateFile = companyInfo['cr_certificate_filename']
                    this.idProofFile = companyInfo['id_proof_filename']
                    this.idFileUrl = companyInfo['id_proof_url'];
                    this.certicateFileUrl = companyInfo['cr_certificate_url'];
                    this.certificateFileData = companyInfo['cr_certificate_filename']
                    this.idProofFileData = companyInfo['id_proof_filename']
                }
                else {
                    this.toasterService.errorToast(data['message']);
                }
            }, error => {
                this.toasterService.errorToast(error['message']);
            })
        )
    }

    removeProof() {
        this.idProofFileData = null;
        this.idProofFile = null;
        this.uploadEvent.emit({ ID: null, CERTIFICATE: this.certificateFileData });

    }

    removeCert() {
        this.certicateFile = null;
        this.certificateFileData = null;
        this.uploadEvent.emit({ ID: this.idProofFileData, CERTIFICATE: null });

    }

    onDocumentSubmit() {
        if (this.isToastVisible) {
            return;
        }
        let isFileNameChange: boolean = false
        if (!this.certificateFileData || !this.idProofFileData) {
            this.isToastVisible = true;
            this.toasterService.errorToast("Please add documents");
            this.toastMethodDisable();
            return;
        }

        if (this.idProofFile != this.idProofFileData) {
            isFileNameChange = true;
        }
        if (this.certicateFile != this.certificateFileData) {
            isFileNameChange = true;
        }
        if (!isFileNameChange) {
            this.isToastVisible = true;
            this.toasterService.warningToast("No data updated")
            this.toastMethodDisable();
            return
        }
        let formData = new FormData();
        if (this.certificateFileData) formData.append("cr_certificate", this.certificateFileData);
        if (this.idProofFileData) formData.append("id_proof", this.idProofFileData);
        this.isLoading = true;
        this.subscription.add(
            this.dataService.put(URLS.sellerCompanyInfoUpdate, formData).subscribe(data => {
                if (data['code'] == 200) {
                    this.isLoading = false;
                    this.toasterService.successToast(data['message']);
                    this.commonService.setEvent('documentUpdate', "true")
                }
                else {
                    this.isLoading = false;
                    this.toasterService.errorToast(data['message']);
                }
            }, error => {
                this.isLoading = false;
                this.toasterService.errorToast(error['message']);
            })
        )

    }

    buttonClick(value: string) {
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    documentCancel() {
        if (this.isToastVisible) {
            return;
        }
        this.getCompanyDetail();

        let isFileNameChange: boolean = false;
        if (this.idProofFile != this.idProofFileData) {
            isFileNameChange = true;
        }
        if (this.certicateFile != this.certificateFileData) {
            isFileNameChange = true;
        }
        if (!isFileNameChange) {
            this.isToastVisible = true;
            this.toasterService.warningToast("No data updated");
            this.toastMethodDisable();
            return
        }
    }

    toastMethodDisable() {
        setTimeout(() => {
            this.isToastVisible = false;
        }, 3010)
    }

    viewCert() {
        window.open(this.certicateFileUrl, "_blank");
    }

    viewProof() {
        window.open(this.idFileUrl, "_blank");
    }
}
