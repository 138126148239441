import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/services/api-data.service';
import { CommonService } from 'src/app/services/common.service';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ToasterService } from 'src/app/services/toaster.service';

export interface SellerDashboard {
	activeProduct?: number;
	inactiveProduct?: number;
	totalProduct?: number;
	totalActiveOrder?: number;
	totalCompletedOrder?: number;
	totalReturnedOrder: number;
	totalOrder?: number;
	receivedQuote?: number;
	submittedQuote?: number;
	orderInitiatedQuote?: number;
	expiredQuote?: number;
	closedQuote?: number;
	totalQuotes?: number;
	totalEarning?: number;
	totalSubUser?: number;
	inactiveSubUser?: number;
	activeSubUser?: number;
	orderList?: OrderList[];
}

export interface OrderList {
	order_item_id?: number;
	order_no?: string;
	created_at?: string;
	total_price?: number;
	order_status?: string;
	payment_status?: string;
	product_name?: string;
	shipping_address?: ShippingAddress;
	buyer_name?: string;
}

export interface ShippingAddress {
	state?: null | string;
	taluka?: null;
	address?: string;
	country?: null | string;
	district?: null | string;
	zip_code?: null | string;
}


@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
	widthConfig = [];
	selectInterface = { cssClass: 'custom-select-dd' };
	column_settings = [


	];
	recentOrders = [];
	public totalCount: any = 0;
	isLoading = false;
	public subscription = new Subscription();
	dashboardData: SellerDashboard;
	isAdmin;
	isSellerApproved: boolean;
	isBuyerApproved: boolean;

	constructor(public commonService: CommonService,
		public getPlatformService: GetPlatformService,
		private dataService: ApiService,
		private toasterService: ToasterService,
		private navCtrl: NavController,
		private datePipe: DatePipe,


	) {
		this.commonService.getUserDetail().then((data) => {
			this.commonService.is_admin = data['is_admin'];
			this.isSellerApproved = data && data['seller_approval_status'] == 'APPROVED' ? true : false;
			this.isBuyerApproved = data && data['approval_status'] == 'APPROVED' ? true : false;
		});
		if (this.commonService.userData.isSeller) {
			this.widthConfig = ['110px', '130px', '300px', '150px', '250px', '180px', '230px', '180px', '90px']
			this.column_settings = [{ primaryKey: 'order_item_id', header: 'Order id' },
			{ primaryKey: 'created_at', header: 'Oder date' },
			{ primaryKey: 'product_name', header: 'Product name', image: true },
			{ primaryKey: 'buyer_name', header: 'Buyer name' },
			{ primaryKey: 'ship_address', header: 'Shipping address' },
			{ primaryKey: 'total_price', header: 'Order total' },
			{ primaryKey: 'payment_status', header: 'Payment status', payment_status: true },
			{ primaryKey: 'order_status', header: 'Order status', info_status: true },
			{ primaryKey: '', header: 'Action', action: true }]
		} else {
			this.widthConfig = ['110px', '130px', '300px', '250px', '180px', '230px', '180px', '90px']
			this.column_settings = [
				{ primaryKey: 'order_item_id', header: 'Order id' },
				{ primaryKey: 'created_at', header: 'Oder date' },
				{ primaryKey: 'product_name', header: 'Product name', image: true },
				{ primaryKey: 'ship_address', header: 'Shipping address' },
				{ primaryKey: 'total_price', header: 'Order total' },
				{ primaryKey: 'payment_status', header: 'Payment status', payment_status: true },
				{ primaryKey: 'order_status', header: 'Order status', info_status: true },
				{ primaryKey: '', header: 'Action', action: true }
			]
		}
	}


	ngOnInit() {
		this.getListData();
		this.isAdmin = this.commonService.localStorageGet('is_admin') == 'true' || this.commonService.localStorageGet('is_admin') == true ? true : false;
		this.commonService.scrollToTop();

	}

	getListData() {
		this.isLoading = true;
		this.recentOrders = [];
		this.totalCount = 0;
		if (this.commonService.userData.isSeller) {
			this.subscription.add(
				this.dataService.get(URLS.sellerDashboard).subscribe(data => {
					if (data['code'] == 200 && data['data']) {
						this.isLoading = false;
						this.dashboardData = data['data'];
						if (this.dashboardData.orderList.length > 0) {
							this.recentOrders = this.dashboardData.orderList;
							this.totalCount = this.recentOrders.length;
							this.recentOrders.forEach((element, index) => {
								element['id'] = element['order_item_id'];
								element['index'] = index + 1;
								element['payment_status'] = element['payment_status'].toLowerCase();
								element['order_status'] = element['order_status'].toLowerCase();
								element['created_at'] = this.datePipe.transform(element["created_at"], 'dd-MM-yyyy');
								element["img"] = element['image_url'] ? element['image_url'] : '/assets/product/product7.svg';
								element['ship_address'] = element['shipping_address']['address'];
								element['viewLink'] = '/home/account-details/manage-orders/order-details';
								element['view'] = true;
								element['total_price'] = element['total_price'].toLocaleString();
								element['status_type'] = element['order_status'] == 'arrived' ? 'early' : element['order_status'] == 'order initiated' ? "Warning" : (element['order_status'] == 'returned' || element['order_status'] == 'rejected') ? 'fail' : element['order_status'] == "dispatched" ? 'wait' : "success";
								if (element['is_credit_order']) {
									if (element['payment_status'] == 'pending') {
										element['payment_status_type'] = 'fail';
										element['payment_days_left'] = element['day_remaining'] ? element['day_remaining'] + " Days Left" : "NA";
										element['payment_status'] = 'On Credit';
									}
									else if (element['payment_status'] == 'paid') {
										if (element['paid_in_days'] == 'Late Paid') {
											element['payment_status'] = "Late Paid";
											element['payment_status_type'] = 'fail';
											element['payment_days_left'] = element['payment_day_difference'] ? element['payment_day_difference'] + " Days Late" : "NA"
										}
										if (element['paid_in_days'] == 'Paid on Time') {
											element['payment_status'] = "paid on time";
										}
									} else {
										element['payment_status'] = "On Credit";
										element['payment_days_left'] = element['payment_day_difference'] ? element['payment_day_difference'] + " Days Left" : "NA"
									}
								} else {

									if (element['payment_status'] == 'reject') {
										element['payment_status'] = 'Rejected'
									}
								}
								if (element['order_status'] == 'completed' || element['order_status'] == 'returned') {
									if (element['is_credit_order']) {
										if (element['paid_in_days'] == 'Late Paid') {
											element['payment_status'] = "Late Paid" + element['payment_day_difference'] + "Days Late";
											element['payment_status_type'] == 'fail';
										}
										if (element['paid_in_days'] == 'Paid on Time') {
											element['payment_status'] = "paid on time";
										}
									}
								}
								element['payment_status_type'] = element['payment_status'] == 'pending verification' || element['payment_status'] == 'pending' ? 'wait' : element['payment_status'] == 'Rejected' ? 'fail' : element['payment_status'] == "On Credit" ? 'fail' : element['payment_status'] == "Late Paid" ? 'fail' : element['payment_status'] == "paid on time" ? 'early' : 'success';

							});
						}
					}
					else {
						this.isLoading = false;
						this.toasterService.errorToast(data['message']);
					}
					this.isLoading = false;
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);

				})
			)
		} else {
			this.subscription.add(
				this.dataService.get(URLS.customerDashboard).subscribe(data => {
					if (data['code'] == 200 && data['data']) {
						this.isLoading = false;
						this.dashboardData = data['data'];
						if (this.dashboardData.orderList['results'].length > 0) {
							this.recentOrders = this.dashboardData.orderList['results'];
							this.totalCount = this.recentOrders.length;
							this.recentOrders.forEach((element, index) => {
								element['id'] = element['order_item_id'];
								element['index'] = index + 1;
								element['payment_status'] = element['payment_status'].toLowerCase();
								element['order_status'] = element['order_status'].toLowerCase();
								element['created_at'] = this.datePipe.transform(element["created_at"], 'dd-MM-yyyy');
								element["img"] = element['image_url'] ? element['image_url'] : '/assets/product/product7.svg';
								element['ship_address'] = element['shipping_address']['address'];
								element['viewLink'] = '/home/account-details/my-orders/my-order-details';
								element['view'] = true;
								element['total_price'] = element['total_price'].toLocaleString();
								element['status_type'] = element['order_status'] == 'arrived' ? 'early' : element['order_status'] == 'order initiated' ? "Warning" : (element['order_status'] == 'returned' || element['order_status'] == 'rejected') ? 'fail' : element['order_status'] == "dispatched" ? 'wait' : "success";
								if (element['is_credit_order']) {
									if (element['payment_status'] == 'pending') {
										element['payment_status_type'] = 'fail';
										element['payment_days_left'] = element['day_remaining'] ? element['day_remaining'] + " Days Left" : "NA";
										element['payment_status'] = 'On Credit';
									}
									else if (element['payment_status'] == 'paid') {
										if (element['paid_in_days'] == 'Late Paid') {
											element['payment_status'] = "Late Paid";
											element['payment_status_type'] = 'fail';
											element['payment_days_left'] = element['payment_day_difference'] ? element['payment_day_difference'] + " Days Late" : "NA"
										}
										if (element['paid_in_days'] == 'Paid on Time') {
											element['payment_status'] = "paid on time";
										}
									} else {
										element['payment_status'] = "On Credit";
										element['payment_days_left'] = element['payment_day_difference'] ? element['payment_day_difference'] + " Days Left" : "NA"
									}
								} else {

									if (element['payment_status'] == 'reject') {
										element['payment_status'] = 'Rejected'
									}
								}
								if (element['order_status'] == 'completed' || element['order_status'] == 'returned') {
									if (element['is_credit_order']) {
										if (element['paid_in_days'] == 'Late Paid') {
											element['payment_status'] = "Late Paid" + element['payment_day_difference'] + "Days Late";
											element['payment_status_type'] == 'fail';
										}
										if (element['paid_in_days'] == 'Paid on Time') {
											element['payment_status'] = "paid on time";
										}
									}
								}
								element['payment_status_type'] = element['payment_status'] == 'pending verification' || element['payment_status'] == 'pending' ? 'wait' : element['payment_status'] == 'Rejected' ? 'fail' : element['payment_status'] == "On Credit" ? 'fail' : element['payment_status'] == "Late Paid" ? 'fail' : element['payment_status'] == "paid on time" ? 'early' : 'success';

							});
						}
					}
					else {
						this.isLoading = false;
						this.toasterService.errorToast(data['message']);
					}
					this.isLoading = false;
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);

				})
			)
		}

	}

	redirectPage(val) {
		switch (val) {
			case 'Product':
				this.navCtrl.navigateForward(['/home/account-details/manage-products'])
				break;
			case 'Order':
				this.navCtrl.navigateForward(['/home/account-details/manage-orders'])
				break;
			case 'Quote':
				this.navCtrl.navigateForward(['/home/account-details/manage-quotes'])
				break;
			case 'Earning':
				this.navCtrl.navigateForward(['/home/account-details/my-earnings'])
				break;
			case 'My_Quote':
				this.navCtrl.navigateForward(['/home/account-details/my-quote-requests'])
				break;
			case 'My_Order':
				this.navCtrl.navigateForward(['/home/account-details/my-orders'])
				break;
			case 'Sub_User':
				this.navCtrl.navigateForward(['/home/account-details/manage-user'])
				break;
		}
	}

}
