<div class="recommended-container">
    <div class="slider-header">
        <div class="header fs-32 fw-600" *ngIf="!isLoading">
            <h1 class="fs-32 fw-600">{{title}}</h1>
        </div>
        <div class="header fs-32 fw-600" *ngIf="isLoading">
            <ion-skeleton-text class="skeleton-container" name="lines-sharp-small"
                [animated]="true"></ion-skeleton-text>
        </div>
        <div class="view-all fs-16 fw-600" [ngClass]="{'explore-all': categories}" (click)="goToProductListing()"
            *ngIf="!faq && !isLoading && sliderData && sliderData.length > 0">
            <span class="view-all-width">
                {{ categories || brand ? '' : 'View all' }}
            </span>
            <img alt="Category Image" *ngIf="!categories && !brand" class="ml-5 no-hover-icon arrow-height" height="100"
                width="100" ngSrc="/assets/icon/arrow-right.svg" priority>
            <img alt="Brand Image" *ngIf="!categories && !brand" class="ml-5 on-hover-icon arrow-height" height="100"
                width="100" ngSrc="/assets/icon/arrow-right-blue.svg" priority>
        </div>
        <div class="view-all fs-16 fw-600" *ngIf="isLoading">
            <ion-skeleton-text class="skeleton-container" name="lines-sharp-small"
                [animated]="true"></ion-skeleton-text>
        </div>

    </div>
    <ng-container *ngIf="categories || brand; else productData">
        <ng-container *ngIf="sliderData && sliderData.length > 0">
            <swiper #swiperVirtualRef [slidesPerView]="3" [spaceBetween]="50" [pagination]="{ type: 'fraction' }"
                [virtual]="true" slideActiveClass="swiper-active whyWouldIuseCustomClass" [centeredSlides]="true"
                [navigation]="getPlatformService.isDesktop? true:false" class="mySwiper" id="mySwiperID"
                [config]="getPlatformService.isDesktop ? config2 : config2Mobile"
                class="swiperSlides top-slider in-english" #catSlides>
                <ng-template swiperSlide *ngFor="let data of sliderData">
                    <app-product-card [brand]="brand" [categories]="categories" [fromSliderComponent]="true"
                        [data]="data" [isLoading]="isLoading" class="width-100"></app-product-card>
                </ng-template>
            </swiper>
        </ng-container>
        <div *ngIf="sliderData.length < 1 && !isLoading" class="ion-text-center no-record-found pt-100 pb-180"
            [ngClass]="categories ? 'categoies-no-record' : ''">
            No {{categories ? 'category' : brand ? 'brand' : 'product' }} found
        </div>
    </ng-container>
    <ng-template #productData>
        <ng-container *ngIf="!faq">
            <ng-container *ngIf="sliderData && sliderData.length > 0">
                <swiper #swiperVirtualRef [slidesPerView]="3" [spaceBetween]="50" [pagination]="{ type: 'fraction' }"
                    [virtual]="true" slideActiveClass="swiper-active whyWouldIuseCustomClass" [centeredSlides]="true"
                    [navigation]="getPlatformService.isDesktop? true:false" class="mySwiper" id="mySwiperID"
                    [config]="getPlatformService.isDesktop ? config : configMobile"
                    class="swiperSlides top-slider in-english" #productsSlides>
                    <ng-container>
                        <ng-template swiperSlide *ngFor="let data of sliderData">
                            <app-product-card [isNew]="isNew" [fromSliderComponent]="true" [data]="data"
                                [isLoading]="isLoading"></app-product-card>
                        </ng-template>
                    </ng-container>
                </swiper>
            </ng-container>
            <div *ngIf="sliderData.length < 1 && !isLoading" class="ion-text-center no-record-found pt-80 pb-80">
                No product found
            </div>
        </ng-container>
        <ng-container *ngIf="faq">
            <ng-container *ngIf="sliderData && sliderData.length > 0">
                <swiper #swiperVirtualRef [slidesPerView]="3" [spaceBetween]="50" [pagination]="{ type: 'fraction' }"
                    [virtual]="true" slideActiveClass="swiper-active whyWouldIuseCustomClass" [centeredSlides]="true"
                    [navigation]="getPlatformService.isDesktop? true:false" class="mySwiper" id="mySwiperID"
                    [config]="getPlatformService.isDesktop ? config : configMobile"
                    class="swiperSlides top-slider in-english" #productsSlides>
                    <ng-container>
                        <ng-template swiperSlide *ngFor="let data of sliderData">
                            <app-product-card [fromSliderComponent]="true" [data]="data" [isLoading]="isLoading"
                                [faq]="faq"></app-product-card>
                        </ng-template>
                    </ng-container>
                </swiper>
            </ng-container>
            <div *ngIf="sliderData.length < 1 && !isLoading" class="ion-text-center no-record-found pt-80 pb-80">
                No record found
            </div>
        </ng-container>
    </ng-template>

</div>