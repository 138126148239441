import { URLS } from './../_config/api.config';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
// import * as crypto from 'crypto-js';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  // secret key to encrypt the login credentails
  private token = false;
  public loggedInStatus;

  constructor(
    private http: HttpClient,
  ) {
  }


   public logout() {
    return this.http.get<any>(URLS.logout);
  }
}
