<div class="main-bg">
	<div class="upper-header fs-14 fw-500 body-container m-auto">
		<div class="left-block">
			<img alt="Location Icon" *ngIf="currentLocation" class="mr-15" src="./assets/svg/location.svg"
				alt="location">
			<img alt="Location Icon" *ngIf="!currentLocation" class="mr-15 pointer" src="./assets/svg/location.svg"
				alt="location" (click)="getLocation()">
			<span *ngIf="currentLocation"> {{currentLocation}}</span>
			<span *ngIf="!currentLocation" class="pointer" (click)="getLocation()">Detect Current Location</span>
		</div>
		<div class="right-block pointer">
			<span class="support-mail"><a href="mailto:support@conkart.com"> support@conkart.com</a>
			</span>
			<span class="isDesktop" (click)="goToContact()">&nbsp; | &nbsp;Contact Us</span>
		</div>
	</div>
</div>