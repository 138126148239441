import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';

@Component({
    selector: 'app-product-save-draft',
    templateUrl: './product-save-draft-modal.component.html',
    styleUrls: ['./product-save-draft-modal.component.scss'],
})
export class ProductSaveDraftModalComponent implements OnInit {
    @Input() title;
    @Input() description;
    constructor(
        private modalService: ModalService,
        public getPlatformService: GetPlatformService,
        private navCtrl: NavController,
        public commonService: CommonService
    ) { }

    ngOnInit() { }

    cancel() {
        this.modalService.dismissModal();
    }

    close(isDraft) {
        this.modalService.dismissModal({ isDraft });
        if (isDraft) {
            this.commonService.localStorageRemove("product_category_id");
            this.commonService.localStorageRemove("product_list");
            this.commonService.localStorageRemove("product_ids");
            this.commonService.localStorageRemove("product_list_two");
            this.commonService.localStorageRemove("product_list_three");
            this.commonService.localStorageRemove("selected_product");
            this.commonService.localStorageRemove("selected_packages");
            this.commonService.localStorageRemove('filterData');
            this.commonService.localStorageRemove('selected_associate_brand');
            this.commonService.localStorageRemove('selected_associate_package');
            this.navCtrl.navigateBack('home/account-details/manage-products', { animated: false });
            this.commonService.scrollToTop();
        }
    }

}
