<div class="addresses m-10" *ngIf="!isLoading">
	<div class="address-grid">
		<ion-grid>
			<div class="align-center width-100 space-between">
				<div class="fs-16 fw-600 mb-20 heading ml-m7">Office Addresses</div>
				<div class="mb-20" *ngIf="!isSellerApproval || (isSubUser && isSubUserAdmin)">
					<button class="submit-btn w-200 address address-btn" (click)="addSellerAddress()">New
						Address</button>
				</div>
			</div>


			<ion-row class="mb-30" *ngIf="companyAddressList.office.length < 1">
				<ion-col size-sm="12" size-md="12" size-lg="12" size-xl="12" size-xs="12" class="pd-0">
					<div class="address-holder h-100">
						<div class="no-data">
							No Records
						</div>
					</div>
				</ion-col>
			</ion-row>
			<ion-row class="mb-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class=" pd-0"
					*ngFor="let address of companyAddressList.office;let i = index">
					<div class="address-holder p-20 mb-20" [ngClass]="i / 2 || i== 0 ? 'mr-20' : ''">
						<div class="address-type  fs-14 fw-600 mb-10">
							{{address.address_type}}
						</div>
						<div class="full-address-container">
							<div class="address-name mb-5 fs-16 fw-600">
								{{address.company_name}}
							</div>
							<div class="full-address fs-16 fw-500">
								{{address.address}}
								<br>{{address.zip_code}}
							</div>
							<div class="fs-16 fw-500 mt-20">
								GST No: {{address.gst_number || '--'}}
							</div>
						</div>
						<div class="pt-25 d-flex" [ngClass]=" isSellerApproval !='PENDING' ? 'pointer' : ''">
							<div class=" tooltip pointer " data-tooltip=" Edit">
								<img alt="Image" class="mr-20 tooltip" data-tooltip="Edit"
									*ngIf="!isSellerApproval || (isSubUser && isSubUserAdmin)"
									(click)="editAddress(address.company_address_id)" src="./assets/icon/edit.svg">
							</div>
							<div class="tooltip  pointer " data-tooltip="Delete">
								<img alt="Image" class="tooltip" data-tooltip="Delete"
									*ngIf="(i > 0 && !isSellerApproval) || (i > 0 && isSubUser && isSubUserAdmin)"
									(click)=" deleteConfrm(address.company_address_id)" src="./assets/icon/delete.svg">
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
		<div class="seperator" *ngIf="companyAddressList.dispatch.length > 0"></div>
		<ion-grid class="mt-20" *ngIf="companyAddressList.dispatch.length > 0">
			<div class="fs-16 fw-600 mb-20 heading ml-m7">Dispatch Locations</div>
			<ion-row class="mb-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class=" pd-0"
					*ngFor="let warehouse of companyAddressList.dispatch;let i = index">
					<div class="address-holder p-20 mb-20" [ngClass]="i / 2 || i== 0 ? 'mr-20' : ''">
						<div class="address-type fs-14 fw-600 mb-10">
							{{warehouse.address_type}} {{i+1}}
						</div>
						<div class="full-address-container">
							<div class="address-name mb-5 fs-16 fw-600">
								{{warehouse.company_name}}
							</div>
							<div class="full-address fs-16 fw-500">
								{{warehouse.address}}
								<br>{{warehouse.zip_code}}
							</div>

							<div class="fs-16 fw-500 mt-20">
								GST No: {{warehouse.gst_number || '--'}}
							</div>
						</div>

						<div class="pt-25 d-flex" [ngClass]="isSellerApproval != 'PENDING' ? 'pointer' : 'pointer'">
							<div class=" tooltip pointer " data-tooltip=" Edit">
								<img alt="Edit Icon" class="tooltip" data-tooltip="Edit"
									*ngIf="!isSellerApproval || (isSubUser && isSubUserAdmin)" class="mr-20"
									(click)="editAddress(warehouse.company_address_id)" src="./assets/icon/edit.svg">
							</div>
							<div class="tooltip  pointer " data-tooltip="Delete">
								<img alt="Delete Icon" class="tooltip" data-tooltip="Delete"
									*ngIf="!isSellerApproval || (isSubUser && isSubUserAdmin)"
									(click)="deleteConfrm(warehouse.company_address_id)" src="./assets/icon/delete.svg">
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>

</div>

<div class="addresses m-10" *ngIf="isLoading">
	<div class="address-grid">
		<ion-grid>
			<ion-row class="mb-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class=" pd-0">
					<div class="address-holder p-20">
						<div class="ske-type fs-14 fw-600 mb-10">
							<ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
								[animated]="true"></ion-skeleton-text>
						</div>
						<div class="full-address-container">
							<div class="address-name mb-5 fs-16 fw-600">
								<ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
									[animated]="true"></ion-skeleton-text>
							</div>
							<div class="full-address fs-16 fw-500">
								<ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
									[animated]="true"></ion-skeleton-text>
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class=" pd-0">
					<div class="address-holder p-20">
						<div class="ske-type fs-14 fw-600 mb-10">
							<ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
								[animated]="true"></ion-skeleton-text>
						</div>
						<div class="full-address-container">
							<div class="address-name mb-5 fs-16 fw-600">
								<ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
									[animated]="true"></ion-skeleton-text>
							</div>
							<div class="full-address fs-16 fw-500">
								<ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
									[animated]="true"></ion-skeleton-text>
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
		<div class="seperator"></div>
		<ion-grid class="mt-20">
			<ion-row class="mb-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class=" pd-0">
					<div class="address-holder p-20 mb-20" [ngClass]="i / 2 || i== 0 ? 'mr-25' : ''">
						<div class="ske-type fs-14 fw-600 mb-10">
							<ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
								[animated]="true"></ion-skeleton-text>
						</div>
						<div class="full-address-container">
							<div class="address-name mb-5 fs-16 fw-600">
								<ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
									[animated]="true"></ion-skeleton-text>
							</div>
							<div class="full-address fs-16 fw-500">
								<ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
									[animated]="true"></ion-skeleton-text>

							</div>
						</div>

					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class=" pd-0">
					<div class="address-holder p-20">
						<div class="ske-type fs-14 fw-600 mb-10">
							<ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
								[animated]="true"></ion-skeleton-text>
						</div>
						<div class="full-address-container">
							<div class="address-name mb-5 fs-16 fw-600">
								<ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
									[animated]="true"></ion-skeleton-text>
							</div>
							<div class="full-address fs-16 fw-500">
								<ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
									[animated]="true"></ion-skeleton-text>
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>

</div>