import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { URLS } from './../_config/api.config';
import { ApiService } from './api-data.service';

@Injectable({
    providedIn: 'root'
})
export class MasterApiService {
    private subscription: Subscription = new Subscription();
    constructor(
        private dataService: ApiService,
    ) { }

    getCountry(param?) {
        return new Promise((resolve, reject) => {
            this.subscription.add(
                this.dataService.get(URLS.country,param).subscribe((data: any) => {
                    if (data['code'] == 200 && data['data']['countriesList'].length > 0) {
                        let countrydata = data;
                        resolve(countrydata);
                    }
                    else {
                        reject(data);
                    }
                }, error => {
                    reject(error);
                })
            )
        })
    }

    getState(params?) {
        return new Promise((resolve, reject) => {
            this.subscription.add(
                this.dataService.get(URLS.state, params).subscribe((data: any) => {
                    if (data['code'] == 200 && data['data']['statesList'].length > 0) {
                        let stateList = data;
                        resolve(stateList);
                    }
                    else {
                        reject(data);
                    }
                }, error => {
                    reject(error);
                })
            )
        })

    }

    getDistrict(param?) {
        return new Promise((resolve, reject) => {
            this.subscription.add(
                this.dataService.get(URLS.district, param).subscribe(data => {
                    if (data['code'] == 200 && data['data']['districtList'].length > 0) {
                        let districtData = data;
                        resolve(districtData);
                    }
                    else {
                        reject(data);
                    }
                }, error => {
                    reject(error);
                })
            )
        })

    }

    getCity(param?) {
        return new Promise((resolve, reject) => {
            this.subscription.add(
                this.dataService.get(URLS.city, param).subscribe(data => {
                    if (data['code'] == 200 && data['data']['citiesList'].length > 0) {
                        let CityData = data;
                        resolve(CityData);
                    }
                    else {
                        reject(data);
                    }
                }, error => {
                    reject(error);
                })
            )
        })

    }

    getTaluka(param?){
        return new Promise((resolve, reject) => {
            this.subscription.add(
                this.dataService.get(URLS.taluka, param).subscribe(data => {
                    if (data['code'] == 200 && data['data']['talukaList'].length > 0) {
                        let talukaData = data;
                        resolve(talukaData);
                    }
                    else {
                        reject(data);
                    }
                }, error => {
                    reject(error);
                })
            )
        })
    }
}
