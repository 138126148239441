import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GetPlatformService } from '../services/get-platform.service';
@Component({
	selector: 'app-personal-detail',
	templateUrl: './personal-detail.component.html',
	styleUrls: ['./personal-detail.component.scss'],
})
export class PersonalDetailComponent implements OnInit {
	@Input() formGroup!: FormGroup<any>;
	@Input() isHideVerifiedBtn = false;
	@Input() placeholder = '';
	@Input() isBecomeUserRegister = false;
	isShowField = true;
	phoneNumber: number;
	isPhoneNumberVerifed: boolean = true;
	public validation_messages = {
		user_name: [
			{ type: 'required', message: 'Please enter name' },
			{ type: 'invalidName', message: 'Please enter a valid name' },
			{ type: 'maxlength', message: 'Name cannot be more than 30 character' },
		],
		first_name: [
			{ type: 'required', message: 'Please enter first name' },
			{ type: 'invalidName', message: 'Please enter a valid first name' },
			{ type: 'maxlength', message: 'First name cannot be more than 15 character' },
		],
		last_name: [
			{ type: 'required', message: 'Please enter last name' },
			{ type: 'invalidName', message: 'Please enter a valid last name' },
			{ type: 'maxlength', message: 'Last name cannot be more than 15 character' },
		],
		email: [
			{ type: 'required', message: 'Please enter email' },
			{ type: 'invalidEmail', message: 'Enter valid email id' },
		],
		phone_no: [
			{ type: 'required', message: 'Please enter mobile number' },
			{ type: 'invalidNumber', message: 'Enter valid mobile number' }
		],

	}

	@Input() fieldName: any = {};

	constructor(public getPlatformService: GetPlatformService) { }

	ngOnInit() {
		if (this.formGroup && this.formGroup.value.phone_no && !this.isHideVerifiedBtn) {
			this.phoneNumber = this.formGroup.value.phone_no
		}
		if (this.isBecomeUserRegister) {
			this.isShowField = false;
		}
	}

	restrictSpecialChar(event) {
		var k;
		k = event.charCode;  //         k = event.keyCode;  (Both can be used)
		// return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 39 || (k >= 48 && k <= 57));
		return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 39);
	}

	phoneClick(e) {
		if (e.keyCode < 48 || e.keyCode > 57) {
			e.preventDefault();
		}
	}



	onInputChange(event: any): void {

		const inputValue = event.target.value || null;
		if (this.phoneNumber === inputValue) {
			this.isPhoneNumberVerifed = true
		}
		if (inputValue == null) {
			this.isPhoneNumberVerifed = false
		}
		if (inputValue && this.phoneNumber != inputValue) {
			this.isPhoneNumberVerifed = false
		}
	}

}
