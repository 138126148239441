import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as lodash from 'lodash';
import { Subscription } from 'rxjs';
import { CustomValidator } from './../../_common/customvalidators';
import { profileTypeEnum } from './../../_common/enum.enum';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { ModalService } from './../../services/modal.service';
import { ToasterService } from './../../services/toaster.service';
import { DocumentComponent } from './../document/document.component';
import { SuccessModalComponent } from './../success-modal/success-modal.component';

@Component({
	selector: 'app-manage-company-details',
	templateUrl: './manage-company-details.component.html',
	styleUrls: ['./manage-company-details.component.scss'],
})
export class ManageCompanyDetailsComponent implements OnInit {
	@ViewChild(DocumentComponent) documentCompData;
	tabVal = "1";
	companyDetailsForm: FormGroup;
	public subscription = new Subscription();
	progress: any = 0;
	color: string = '#488aff';
	isModalOpen = false;
	modal: any;
	categoriesData = [];
	profileEnum = profileTypeEnum;
	company_name = '';
	isSellerApproval = false;
	certificate = '';
	IdProof = '';
	categoriesList: any = [];
	categoriesId = [];
	companyCategoryId: any = [];
	currentSelectedMasterId: any = [];
	previousSelectedMasterId: any = [];
	isBankLoader = false;
	isCompanyLoader = false;
	isCatLoader = false;
	isUploadLoader = false;
	isSubUser = false;
	isSubUserAdmin = false;
	sellerApproval: any;
	companyInfoDetails: any
	isSaveButtonClicked: boolean = false;
	requestedForCategory: boolean;
	isToastVisible: boolean = false;

	tabs = [
		{
			"name": "Company Info",
			"value": "1"
		},
		{
			"name": "Addresses",
			"value": "2"
		},
		{
			"name": "Documents",
			"value": "3"
		},
		{
			"name": "Categories",
			"value": "4"
		},
		{
			"name": "Vehicle",
			"value": "6"
		},
		{
			"name": "Bank Details",
			"value": "5"
		},

	]
	constructor(
		private formBuilder: FormBuilder,
		private commonService: CommonService,
		private modalService: ModalService,
		private dataService: ApiService,
		private toasterService: ToasterService

	) {
		this.isSubUser = this.commonService.localStorageGet('is_sub_user') == 'true' ? true : false;
		this.isSubUserAdmin = this.commonService.localStorageGet('is_sub_user_admin') == 'true' ? true : false;
		this.commonService.eventObservable.subscribe((data: any) => {
			if (data.event == 'documentUpdate') this.getProgressBar();
		})
		this.commonService.eventObservable.subscribe((data: any) => {
			if (data.event == 'isProgessCall') this.getProgressBar();
		})
	}

	initializeCompanyGroup(data?) {
		return this.formBuilder.group({
			company_name: new FormControl({ value: data ? data.company_name : '', disabled: this.isSellerApproval }, {
				validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.nameValidator]),
			}),
			email: new FormControl({ value: data ? data.email : '', disabled: true }, {
				validators: Validators.compose([Validators.required, CustomValidator.emailValidator]),
			}),
			phone_no: new FormControl({ value: data ? data.mobile_no : '', disabled: true }, {
				validators: Validators.compose([Validators.required, CustomValidator.phoneValidator]),
			}),
		})
	}

	get f1() { return this.companyDetailsForm.controls; }


	ngOnInit() {
		this.companyDetailsForm = this.initializeCompanyGroup();
		this.getProgressBar();
		this.getCompanyDetail();
		this.getCategoriesList();
		this.getCategoriesIds();
		this.commonService.scrollToTop();
		const localTab = this.commonService.localStorageGet('tabval');
		if (localTab) {
			this.tabVal = localTab;
			this.commonService.localStorageRemove('tabval');
		};

		if (this.commonService.localStorageGet('seller_approval') == 'APPROVED') {
			this.tabs.splice(4, 1); // remove vehicle tab
		}
		if (this.commonService.localStorageGet('enterprise_user_id') != 'undefined' && this.commonService.localStorageGet('enterprise_user_id') != undefined) {
			this.tabs.splice(5, 1); // remove vehicle tab
		}

	}

	getCategoriesList() {
		this.categoriesList = [];
		let param = {
			"dropdown": 'true'
		}
		this.subscription.add(
			this.dataService.get(URLS.masterCategory, param).subscribe(data => {
				if (data['code'] == 200 && data['data']['masterCategoryList'].length > 0) {
					this.categoriesList = data['data']['masterCategoryList'];
					this.categoriesList.forEach(element => {
						element.image_url = element.master_category_image_url ? element.master_category_image_url : '../../../assets/product/product7.svg';
						element.name = element.master_category_name
					});
				}
				else {
					this.categoriesList = [];
				}
			}, error => {
				this.categoriesList = [];
			})
		)
	}

	setCategoriesId(event: any, id, item?: any) {
		if (item['add_requested']) {
			return;
		}
		if (item['delete_requested']) {
			return;
		}


		const isChecked = event.target.checked;

		this.categoriesList.find(x => x.master_category_id == id).checked = isChecked;

		if (isChecked) {
			this.currentSelectedMasterId.push({ master_category_id: id });
		} else {
			// Remove the unchecked master category ID from currentSelectedMasterId
			this.currentSelectedMasterId = this.currentSelectedMasterId.filter(item => item.master_category_id !== id);
		}
		if (item.sub_categories && item.sub_categories.length) {
			if (event.target.checked) {
				item.sub_categories.map(element => {
					let categoryObj = {
						"category_id": element.category_id
					}
					if (this.categoriesId && this.categoriesId.length) {
						if (!this.categoriesId.some(obj => obj.category_id === element.category_id)) {
							this.categoriesId.push(categoryObj);
						}

					} else {
						this.categoriesId.push(categoryObj);
					}
				});
			} else {
				if (item.sub_categories && item.sub_categories.length) {
					item.sub_categories.forEach(catElement => {
						let updatedCategoryId = this.categoriesId.filter(obj => obj.category_id != catElement.category_id);
						this.categoriesId = updatedCategoryId
					});
				}
			}
		}

	}


	getProgressBar() {
		this.subscription.add(
			this.dataService.get(URLS.sellerProgressBar).subscribe(data => {
				if (data['code'] == 200) {
					this.progress = data['data']['progress'];
					if (this.progress != 100) {
						this.tabVal = '5';
					}
					this.isSellerApproval = false;
					let localSellerApproval = this.commonService.localStorageGet('seller_approval') == 'PENDING' ? true : false;
					let is_sub_user = false;
					let isSub_user_Admin = false;
					is_sub_user = this.commonService.localStorageGet('is_sub_user') == 'true' ? true : false;
					isSub_user_Admin = this.commonService.localStorageGet('is_sub_user_admin') == 'true' ? true : false;
					this.sellerApproval = this.commonService.localStorageGet('seller_approval');


					if (localSellerApproval && this.progress == 100 && !is_sub_user) {
						this.isSellerApproval = true;
						this.getCompanyDetail();
					}
					else if (is_sub_user && !isSub_user_Admin) {
						this.isSellerApproval = true;
					}
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.toasterService.errorToast(error['message']);
			})
		)
	}
	getCompanyDetail() {
		this.subscription.add(
			this.dataService.get(URLS.sellerCompanyDetail).subscribe(data => {
				if (data['code'] == 200) {
					let companyInfo = data['data'];
					this.companyInfoDetails = data['data']
					this.companyDetailsForm = this.initializeCompanyGroup(companyInfo);
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	getCategoriesIds() {
		this.companyCategoryId = [];
		this.previousSelectedMasterId = [];
		this.requestedForCategory = false;
		this.subscription.add(
			this.dataService.get(URLS.sellerCategories).subscribe(data => {
				if (data['code'] == 200) {
					if (data['data']) {
						this.companyCategoryId = data['data'];
						this.categoriesList.forEach(element => {
							this.companyCategoryId.forEach(ele => {
								if (element['master_category_id'] == ele['master_category_id']) {
									if (ele['approval_status'] != 'PENDING') {
										element['checked'] = true;
										this.previousSelectedMasterId.push({ master_category_id: ele['master_category_id'] });
									}
									element['approval_status'] = ele['approval_status'];
								}

							});
						});


						this.categoriesList.forEach(element => {
							if (element.approval_status == 'PENDING') {
								this.requestedForCategory = true
							}
						});
					}
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	whichProgress(progress: string) {
		try {
			return Math.round(+progress * 100) / 100;
		}
		catch {
			return progress;
		}
	}

	async accordionChange(e) {
		if (e.detail.value == '5') {
			this.getCategoriesList();
			setTimeout(() => {
				this.getCategoriesIds();
			}, 100);
		}
	}

	async tabChange(val) {
		this.tabVal = val;
		if (val == '4') {
			this.getCategoriesList();
			setTimeout(() => {
				this.getCategoriesIds();
			}, 100);
		}
		this.getCompanyDetail();

	}

	onComapnySubmit() {
		let isCompanyInfoDetailsChanged = false;
		for (const i in this.companyDetailsForm.controls) {
			this.companyDetailsForm.controls[i].markAsDirty();
			this.companyDetailsForm.controls[i].updateValueAndValidity();
			this.companyDetailsForm.controls[i].markAsTouched();
		}
		if (this.f1.company_name.value.trim() != this.companyInfoDetails.company_name) {
			isCompanyInfoDetailsChanged = true;
		}
		if (this.f1.email.value.trim() != this.companyInfoDetails.email) {
			isCompanyInfoDetailsChanged = true;
		}
		if (this.f1.phone_no.value.trim() != this.companyInfoDetails.mobile_no) {
			isCompanyInfoDetailsChanged = true;
		}

		if (!isCompanyInfoDetailsChanged) {
			this.isToastVisible = true;
			this.toasterService.warningToast("No data updated");
			this.toastMethodDisable();
			return
		}

		if (this.companyDetailsForm.invalid) {
			return;
		}
		let formData = new FormData();
		formData.append("company_name", this.f1.company_name.value);
		formData.append("contact_no", this.f1.phone_no.value);
		this.isCompanyLoader = true;
		this.subscription.add(
			this.dataService.put(URLS.sellerCompanyInfoUpdate, formData).subscribe(data => {
				this.isCompanyLoader = false;
				if (data['code'] == 200) {
					this.f1.company_name.setValue(this.companyInfoDetails.company_name)
					this.f1.email.setValue(this.companyInfoDetails.email)
					this.f1.phone_no.setValue(this.companyInfoDetails.mobile_no)
					this.toasterService.successToast(data['message']);
					this.getCompanyDetail();
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.isCompanyLoader = false;
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	compareCategoryIds(previousMasterCatId: any[], currentMasterCatId: any[]): boolean {
		const previousMasterCatIdList = previousMasterCatId.map(category => category.master_category_id);
		const currentMasterCatIdList = currentMasterCatId.map(category => category.master_category_id);
		const uniquePreviousArray = Array.from(new Set(previousMasterCatIdList.map(item => item)));
		const uniqueCurrentArray = Array.from(new Set(currentMasterCatIdList.map(item => item)));
		return lodash.isEqual(uniquePreviousArray, uniqueCurrentArray);
	}

	disableBtn() {
		setTimeout(() => {
			this.isSaveButtonClicked = false;
		}, 3005);
	}

	categorieSubmit() {
		if (this.isToastVisible) {
			return;
		}
		this.isSaveButtonClicked = true;
		if (this.requestedForCategory) {
			this.disableBtn();
			this.isToastVisible = true;
			this.toasterService.errorToast("Changed category request pending for approval.");
			this.toastMethodDisable();
			return;
		}
		if (this.categoriesId.length < 1) {
			this.disableBtn();
			this.isToastVisible = true;
			this.toasterService.errorToast("Please add at least one category");
			this.toastMethodDisable();
			return;
		}

		let isIdeal = this.compareCategoryIds(this.previousSelectedMasterId, this.currentSelectedMasterId)
		if (isIdeal) {
			this.disableBtn();
			this.isToastVisible = true;
			this.toasterService.warningToast("No data updated");
			this.toastMethodDisable();
			return
		}

		let param = {
			"company_categories": this.categoriesId
		}
		this.isCatLoader = true;
		this.subscription.add(
			this.dataService.put(URLS.sellerCatUpdate, param).subscribe(data => {
				this.isCatLoader = false;
				if (data['code'] == 200) {
					this.categoriesList.forEach(item => {
						item.checked = false;
						item.add_requested = false;
						item.delete_requested = false;
					});
					setTimeout(() => {
						this.getCategoriesIds();
					}, 200);
					if (this.progress != 100) {
						this.tabVal = '5';
					};
					this.disableBtn();
					this.toasterService.successToast(data['message']);
				}
				else {
					this.disableBtn();
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.isCatLoader = false;
				this.disableBtn();
				this.getCategoriesIds();
				this.toasterService.errorToast(error['message']);
			})
		)

	}

	onDocumentSubmit() {
		let formData = new FormData();
		if (!this.certificate || !this.IdProof) {
			this.toasterService.errorToast("Please add documents first");
			return;
		}
		if (this.certificate) formData.append("cr_certificate", this.certificate);
		if (this.IdProof) formData.append("id_proof", this.IdProof);
		this.isUploadLoader = true;
		this.updateCompanyInfo(formData);
	}

	updateCompanyInfo(data) {
		this.subscription.add(
			this.dataService.put(URLS.sellerCompanyInfoUpdate, data).subscribe(data => {
				if (data['code'] == 200) {
					this.isUploadLoader = false;
					this.toasterService.successToast(data['message']);
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.isUploadLoader = false;
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	onBankSubmit() {
		for (const i in this.companyDetailsForm.controls) {
			this.companyDetailsForm.controls[i].markAsDirty();
			this.companyDetailsForm.controls[i].updateValueAndValidity();
			this.companyDetailsForm.controls[i].markAsTouched();
		}
		if (this.companyDetailsForm.invalid) {
			return
		}

		const params = new FormData();
		if (this.companyDetailsForm.controls["account_holder"].value) {
			params.append("bank_account_name", this.companyDetailsForm.controls["account_holder"].value)
		}
		if (this.companyDetailsForm.controls["account_no"].value) {
			params.append("bank_account_number", this.companyDetailsForm.controls["account_no"].value)
		}
		if (this.companyDetailsForm.controls["ifsc"].value) {
			params.append("ifsc_code", this.companyDetailsForm.controls["ifsc"].value)
		}
		this.isBankLoader = true;
		this.subscription.add(
			this.dataService.put(URLS.sellerCompanyInfoUpdate, params).subscribe(data => {
				this.isBankLoader = false;
				if (data['code'] == 200) {
					this.isSellerApproval = true;
					this.getProgressBar();
					this.getCompanyDetail();
					this.commonService.setEvent('alertMessage', {});
					if (this.progress != 100) {
						let obj = {
							component: SuccessModalComponent,
							backdropDismiss: true,
							canDismiss: true,
							cssClass: 'success-modal',
							componentProps: {
								title: "Thank You For Submission",
								description: "Your Seller Account Request is under processing, We will update you very soon."
							}
						};
						this.modal = this.modalService.openModal(obj);
						this.isModalOpen = true;
						this.modal.then(value => {
							this.isModalOpen = false;
						}, error => {
							console.log(error);
						});
					}
					else {
						this.toasterService.successToast(data['message']);
					}
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.isBankLoader = false;
				this.toasterService.errorToast(error['message']);
			})
		)

	}

	toastMethodDisable() {
		setTimeout(() => {
			this.isToastVisible = false;
		}, 3010)
	}

	cancelCategories() {
		if (this.isToastVisible) {
			return;
		}
		if (this.requestedForCategory) {
			this.isToastVisible = true;
			this.toasterService.errorToast("Changed category request pending for approval.");
			this.toastMethodDisable();
			return;
		}
		let isIdeal = this.compareCategoryIds(this.previousSelectedMasterId, this.currentSelectedMasterId)
		if (isIdeal) {
			this.isToastVisible = true;
			this.toasterService.warningToast("No data updated");
			this.toastMethodDisable();
			return
		}

		this.categoriesList.forEach(element => {
			element['checked'] = false;
		});;
		this.getCategoriesIds();
	}

	cancelInfo() {
		let isCompanyInfoDetailsChanged = false;
		if (this.f1.company_name.value.trim() != this.companyInfoDetails.company_name) {
			isCompanyInfoDetailsChanged = true;
		}
		if (this.f1.email.value.trim() != this.companyInfoDetails.email) {
			isCompanyInfoDetailsChanged = true;
		}
		if (this.f1.phone_no.value.trim() != this.companyInfoDetails.mobile_no) {
			isCompanyInfoDetailsChanged = true;
		}

		if (!isCompanyInfoDetailsChanged) {
			this.isToastVisible = true;
			this.toasterService.warningToast("No data updated");
			this.toastMethodDisable();
			return
		}

		this.getCompanyDetail();
	}

}
