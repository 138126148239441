import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { ToasterService } from './../../services/toaster.service';

@Component({
    selector: 'app-newsletter-detail',
    templateUrl: './newsletter-detail.component.html',
    styleUrls: ['./newsletter-detail.component.scss'],
})
export class NewsletterDetailComponent implements OnInit {

    public subscription = new Subscription();
    public newsLetterData = [];
    public recentPost = [];
    public page = 1;
    public limit = 1;
    public enableNext: boolean = true;
    public enableNextRecent: boolean = true;
    public total_count: any
    public isLoading: boolean = false;
    public newsletter_id: any;

    public validation_messages = {
        email: [
            { type: 'required', message: 'Please enter email' },
            { type: 'invalidEmail', message: 'Enter valid email id' },
        ],
        comment: [
            { type: 'required', message: 'Please enter comment' },
        ],

    }

    constructor(
        private dataService: ApiService,
        private toasterService: ToasterService,
        private navCtrl: NavController,
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            const id = params['id'];
            this.newsletter_id = +id
            if (this.newsletter_id) {
                this.getListData();
                this.getRecentlist();
            }
        });
    }

    getListData() {
        const params = {
            newsletter_id: this.newsletter_id
        }
        this.isLoading = true;
        this.subscription.add(
            this.dataService.get(URLS.getNewsLetter, params).subscribe(data => {
                if (data['code'] == 200 && data['data']) {
                    let newsList = data['data']['newslettersList'];
                    this.enableNext = data['data']['enable_next'];
                    this.total_count = data['data']['total_count'];
                    this.newsLetterData = [];
                    if (newsList && newsList.length) {
                        newsList.forEach(element => {
                            let obj = {
                                image_url: element.image_url,
                                name: element.title,
                                date: element.created_at,
                                description: element.description,
                                newsletter_id: element.newsletter_id,
                                total_records: element.total_records
                            }
                            this.newsLetterData.push(obj);
                        });
                    }
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 2000);
                }
                else {
                    this.isLoading = false;
                    this.toasterService.errorToast(data['message']);
                }
            }, error => {
                this.isLoading = false;
                this.toasterService.errorToast(error['message']);

            })
        )

    }

    getRecentlist() {
        const params = {
            is_recent_request: true
        }
        this.isLoading = true;
        this.subscription.add(
            this.dataService.get(URLS.getNewsLetter, params).subscribe(data => {
                if (data['code'] == 200 && data['data']) {
                    let recentData = data['data']['newslettersList']
                    this.enableNextRecent = data['data']['enable_next'];
                    this.recentPost = [];
                    recentData.forEach(element => {
                        let obj = {
                            image_url: element.image_url,
                            name: element.title,
                            date: element.created_at,
                            description: element.description,
                            newsletter_id: element.newsletter_id,
                        }
                        this.recentPost.push(obj);
                    });
                }
                else {
                    this.isLoading = false;
                    this.toasterService.errorToast(data['message']);
                }
            }, error => {
                this.isLoading = false;
                this.toasterService.errorToast(error['message']);
            })
        )
    }

    prevBlog() {
        if (this.recentPost && this.recentPost.length) {
            const presentBlog = this.recentPost.find(element => element.newsletter_id == this.newsletter_id);
            const presentBlogIndex = this.recentPost.indexOf(presentBlog);
            if (presentBlogIndex > 0) {
                const data = this.recentPost[presentBlogIndex - 1];
                this.gotToBlogDetail(data.newsletter_id);
            }
        }
    }

    nextBlog() {
        if (this.recentPost && this.recentPost.length) {
            const presentBlog = this.recentPost.find(element => element.newsletter_id == this.newsletter_id);
            const presentBlogIndex = this.recentPost.indexOf(presentBlog);
            if (presentBlogIndex < this.recentPost.length - 1) {
                const data = this.recentPost[presentBlogIndex + 1];
                this.gotToBlogDetail(data.newsletter_id);
            }
        }
    }

    isPrevDisabled() {
        if (this.recentPost && this.recentPost.length) {
            const presentBlog = this.recentPost.find(element => element.newsletter_id == this.newsletter_id);
            const presentBlogIndex = this.recentPost.indexOf(presentBlog);
            return presentBlogIndex <= 0;
        }
        return true;
    }

    isNextDisabled() {
        if (this.recentPost && this.recentPost.length) {
            const presentBlog = this.recentPost.find(element => element.newsletter_id == this.newsletter_id);
            const presentBlogIndex = this.recentPost.indexOf(presentBlog);
            return presentBlogIndex >= this.recentPost.length - 1;
        }
        return true;
    }

    gotToBlogDetail(newsletter_id) {
        if (newsletter_id) {
            this.navCtrl.navigateRoot('newsletter/' + newsletter_id, { animated: false });
        }
    }

}
