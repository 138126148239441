<div class="thank-you-container">
    <div class="cross-icon ion-text-right" (click)="onCancel()" *ngIf="hideCloseBtn != true">
        <img alt="Close Icon" src="assets/icon/close.svg">
    </div>
    <div class="thank-you">
        <img alt="Success Icon" src="assets/img/escrow-pay-logo.png" class="success-icon">
        <div class="thank-you-header">
            <div class="thank-you-text">
                23123XXXXXXXXX
            </div>
        </div>
        <div class="success-container">
            <div class="success-text">
                Please enter your UTR number to process the order.
            </div>
        </div>
        <div class="input-group mb-30">
            <label class="mb-10" for="">Enter UTR *</label>
            <ion-input minlength="10" maxlength="20" type="text" placeholder="Enter UTR" class="input"
                [(ngModel)]="utrNo" (ngModelChange)="utrValidation()"></ion-input>
            <ion-text color="danger" class="error-msg mt-5" *ngIf="isValidUTR && isValidUTR.invalidUTR">
                Please enter valid UTR number
            </ion-text>
        </div>

        <div [ngClass]="getPlatformService.isDesktop ? 'display-center width-100 ': 'mobile-full-btn'">
            <button type="primary" class="primary-btn" [ngClass]="!getPlatformService.isDesktop ? 'width-100': null"
                (click)="onSubmit()">
                <ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
                <span>{{submitBtnText}}</span>
            </button>

            <button class="clear-btn" (click)="redirect()"
                [ngClass]="!getPlatformService.isDesktop ? 'width-100 mt-20': 'ml-20'">
                <ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
                <span [ngClass]="!getPlatformService.isDesktop ? 'ph-30': 'ph-50'">{{cancelBtnText}}</span>
            </button>
        </div>

    </div>
</div>