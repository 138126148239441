<div>
	<div #wrapper class="wrapper desktop-visibility"
		[ngStyle]="{'height':  !menuVisibility ? '' + (menuHeight) + 'px': ''}">
		<div class="menu-section" #menu>
			<ng-container>
				<div class="d-flex width-100">
					<div class="side-menu pointer">
						<ng-container *ngFor="let menu of menuOptions;let i = index;">
							<div [title]="menu.menu_name" (click)="catItemChange(menu)"
								[class.item-active-left]="menu && menu.id === selectedCatId" ion-item lines='none'
								class="menu pr-20 pl-20">
								<span class="fs-16 pointer">
									{{menu.menu_name }}
								</span>
								<img alt="Right Arrow" *ngIf="menu.id !== selectedCatId"
									src="../../../assets/icon/arrow-right.svg">
								<img alt="Image" *ngIf="menu.id === selectedCatId"
									src="../../../assets/icon/arrow-white-right.svg">
							</div>
						</ng-container>
					</div>
					<div class="side-menu pointer" *ngIf="menuOptions2 && menuOptions2.length > 0">
						<ng-container *ngFor="let menu of menuOptions2;let i = index;">
							<div [title]="menu.name" (click)="subCatItemChange(menu)"
								[class.item-active-left]="menu && menu.category_id === selectedSubCategory" ion-item
								lines='none' class="menu pr-20 pl-20">
								<span class="fs-16 pointer">
									{{menu.name }}
								</span>
								<img alt="Right Arrow" *ngIf="menu.category_id !== selectedSubCategory"
									src="../../../assets/icon/arrow-right.svg">
								<img alt="Image" *ngIf="menu.category_id === selectedSubCategory"
									src="../../../assets/icon/arrow-white-right.svg">
							</div>
						</ng-container>
					</div>
					<div class="menu-details">
						<div class="products-wrapper" *ngIf="productsList && productsList.length > 0 && !isLoading">
							<div class="products-name ellipse" [ngClass]="!productsList.length >= 7?'d-flex':''"
								*ngFor="let product of productsList;let l = last">
								<div class="pointer product-detail" title="{{ product.name }}"
									(click)="goToDetail(product)">
									{{ product.name }} {{ product.package_value ? product.unit_name : '' }}
								</div>
								<div class="d-flex justify-end" *ngIf="productsList.length >= 7 && l">
									<div class="view-all ion-text-right" (click)="viewAll(product)">
										View All
									</div>
								</div>
							</div>
						</div>
						<div class="no-record-container"
							[ngClass]="menuOptions2.length < 1 && !selectedImage ? 'flex' : ''"
							*ngIf="productsList && productsList.length < 1 && !isLoading">
							<div class="no-record-found mt-70">
								No records
							</div>
						</div>
						<div class="no-record-container"
							[ngClass]="menuOptions2.length < 1 && !selectedImage ? 'flex' : ''" *ngIf="isLoading">
							<div class="no-record-found mt-70">
								<app-loader></app-loader>
							</div>
						</div>
						<div class="details m-30" *ngIf="(menuOptions2 && menuOptions2.length) || selectedImage">
							<div class="product-img">
								<img alt="Image" [src]="selectedImage">
							</div>
						</div>

					</div>

				</div>

			</ng-container>
		</div>
	</div>
</div>