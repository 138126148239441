<div class="home-page app-header">
	<app-header></app-header>
</div>

<ion-content>
	<ion-grid class="page-scroll-top">
		<ion-row>
			<ion-col size="12">
				<div class="request-container container-page">
					<form [formGroup]="consultantRequestForm">
						<app-personal-detail [fieldName]="fieldData" [formGroup]="$any(consultantRequestForm)"
							[placeholder]="'Email'" [isHideVerifiedBtn]="true"></app-personal-detail>
						<div class="reason-box mb-15">
							<ion-label>
								Reason <span
									[ngClass]="consultantRequestForm.get('reason').hasError('required') && consultantRequestForm.get('reason').dirty ? 'error-msg' : ''">*</span>
							</ion-label>
							<ion-textarea placeholder="Enter Reason" mode="md" formControlName="reason" rows="2"
								maxlength="100"></ion-textarea>
							<ng-container *ngFor="let validation of validation_messages.reason">
								<ng-container
									*ngIf="consultantRequestForm.get('reason').hasError(validation.type) && consultantRequestForm.get('reason').dirty">
									<ion-text color="danger" class="error-msg">
										{{validation.message }}
									</ion-text>
								</ng-container>
							</ng-container>
						</div>
						<div class="description-box">
							<ion-label>
								Description <span
									[ngClass]="consultantRequestForm.get('description').hasError('required') && consultantRequestForm.get('description').dirty ? 'error-msg' : ''">*</span>
							</ion-label>
							<ion-textarea maxlength="2000" mode="md" placeholder="Enter Description"
								formControlName="description" rows="7"></ion-textarea>
							<ng-container *ngFor="let validation of validation_messages.description">
								<ng-container
									*ngIf="consultantRequestForm.get('description').hasError(validation.type) && consultantRequestForm.get('description').dirty">
									<ion-text color="danger" class="error-msg">
										{{validation.message }}
									</ion-text>
								</ng-container>
							</ng-container>
						</div>
						<div>
							<div class="form-button mt-30">
								<button
									[disabled]="isDisabled || !consultantRequestForm.get('first_name').value || !consultantRequestForm.get('email').value || !consultantRequestForm.get('phone_no').value || !consultantRequestForm.get('last_name').value || !consultantRequestForm.get('description').value || !consultantRequestForm.get('reason').value"
									type="primary" (click)="submit()" class="mr-20 w-147 submit-btn">
									<ion-spinner class="spinner-btn " name="lines-sharp-small"
										*ngIf="isLoading"></ion-spinner>
									Submit
								</button>
							</div>
						</div>
					</form>
				</div>
			</ion-col>
		</ion-row>
	</ion-grid>
	<div class="wrapper-block home-page">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
</ion-content>