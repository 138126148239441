import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomValidator } from './../../_common/customvalidators';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { ToasterService } from './../../services/toaster.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {


    public subscription = new Subscription();
    public changePasswordForm: FormGroup;
    isLoading = false;
    oldPasswordType = 'password';
    newPasswordType = 'password';
    confirmPasswordType = 'password';

    public validation_messages = {
        old_password: [
            { type: 'required', message: 'Please enter a old password' },
        ],
        new_password: [
            { type: 'required', message: 'Please enter a new password' },
            { type: 'invalidPassword', message: 'Password should be a combination of alphanumeric with a minimum of 8 characters and maximum of 12 characters with one special character and one capital letter' },
        ],
        confirm_password: [
            { type: 'required', message: 'Re-enter password' },
            { type: 'passMismatch', message: 'Password mismatch' },
        ],
    }

    constructor(
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private toasterService: ToasterService,
        private dataService: ApiService
    ) { }

    get f1() { return this.changePasswordForm.controls; }


    ngOnInit() {
        this.commonService.scrollToTop();
        this.changePasswordForm = this.initializeForm();
    }

    initializeForm() {
        return this.formBuilder.group({
            old_password: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            new_password: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.passwordPatternValidator]),
            }),
            confirm_password: new FormControl(null, {
                validators: Validators.compose([Validators.required,
                CustomValidator.confirmPassword]),
            })
        });
    }

    changePassword() {

        for (const i in this.f1) {
            this.f1[i].markAsDirty();
            this.f1[i].updateValueAndValidity();
            this.f1[i].markAsTouched();
        }

        if (this.changePasswordForm.invalid) {
            return;
        }

        const userInfo = {
            "old_password": this.f1.old_password.value,
            "new_password": this.f1.confirm_password.value
        }

        const encryptedData = this.commonService.encryptData(JSON.stringify(userInfo));
        if (!encryptedData) {
            return;
        }
        const params = {
            "data": encryptedData
        }

        this.isLoading = true;
        this.subscription.add(
            this.dataService.put(URLS.changePassword, params).subscribe(data => {
                if (data['code'] == 200) {
                    this.toasterService.successToast(data['message']);
                    this.changePasswordForm.reset();
                    this.commonService.logout(true);
                    this.isLoading = false;
                }
                else {
                    this.toasterService.errorToast(data['message']);
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this.toasterService.errorToast(error['message']);

            })
        )
    }

    cancel() {
        this.changePasswordForm.reset();
    }

}
