import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { CustomValidator } from './../../_common/customvalidators';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { ToasterService } from './../../services/toaster.service';

const MAX_FILE_SIZE_IMAGE = 5 * 1024 * 1024;

@Component({
    selector: 'app-add-blog',
    templateUrl: './add-blog.component.html',
    styleUrls: ['./add-blog.component.scss'],
})
export class AddBlogComponent implements OnInit {

    public subscription = new Subscription();
    blogFormGroup: FormGroup;
    public categoryList = [];
    public imageChangedEvent: any;
    public imageFile: any;
    public imagePreview: any;
    public selectedFileName: any;
    public isLoading: boolean = false;
    public imageUpload: boolean = false;
    public blog_category_id: any;
    fieldData = {
        "name": "Name",
        "email": "Email",
        "mobile_no": "Mobile number"
    }

    public validation_messages = {
        title: [
            { type: 'required', message: 'Please enter title' },
        ],
        category: [
            { type: 'required', message: 'Please select category' },
        ],
        description: [
            { type: 'required', message: 'Please enter description' }
        ]
    }

    constructor(
        private formBuilder: FormBuilder,
        private dataService: ApiService,
        private toasterService: ToasterService,
        public commonService: CommonService
    ) { }

    ngOnInit() {
        this.blogFormGroup = this.initializeBlogFromGroup();
        if (!this.commonService.isLoggedIn) {
            this.f1.email.setValidators([Validators.required, CustomValidator.emailValidator]);
            this.f1.email.updateValueAndValidity();
            this.f1.first_name.setValidators([Validators.required, Validators.maxLength(15), CustomValidator.nameValidator]);
            this.f1.first_name.updateValueAndValidity();
            this.f1.last_name.setValidators([Validators.required, Validators.maxLength(15), CustomValidator.nameValidator]);
            this.f1.last_name.updateValueAndValidity();
            this.f1.phone_no.setValidators([Validators.required, CustomValidator.phoneValidator]);
            this.f1.phone_no.updateValueAndValidity();
        }
        this.getCategory();
    }

    get f1() { return this.blogFormGroup.controls; }

    categorySelected(event) {
        this.blog_category_id = +event.blog_category_id;
    }


    getCategory() {
        this.subscription.add(
            this.dataService.get(URLS.getBlogCategory).subscribe(data => {
                if (data['code'] == 200 && data['data']) {
                    let categoryData = data['data'];
                    this.categoryList = [];
                    categoryData.forEach(element => {
                        let obj = {
                            blog_category_id: element.blog_category_id,
                            description: element.description,
                            name: element.name
                        }
                        this.categoryList.push(obj);
                    });
                }
                else {
                    this.toasterService.errorToast(data['message']);
                }
            }, error => {
                this.toasterService.errorToast(error['message']);
            })
        )
    }

    public fileChangeEvent(event: any): void {
        let fileObj = event.target.files[0];
        let fileExtension = fileObj['name'].split('.').pop().toLocaleLowerCase();
        this.selectedFileName = fileObj['name'];
        if (this.selectedFileName) {
            this.imageUpload = false;
        }
        let fileSize = fileObj['size'];
        const reader = new FileReader();
        if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
            if (fileSize < MAX_FILE_SIZE_IMAGE) {
                this.imageChangedEvent = event;
                this.imageFile = event['target']['files'][0];
                reader.readAsDataURL(event['target']['files'][0]);
                reader.onload = () => {
                    this.imagePreview = reader.result;
                };
            } else {
                this.toasterService.errorToast("The selected image has exceeded the maximum allowed size of 5 Mb.", 'top');
                this.selectedFileName = "";
            }
        } else {
            this.toasterService.errorToast("Please select JPG, JPEG and PNG format image!", 'top');
            this.selectedFileName = "";
        }
    }

    submit() {
        for (const i in this.f1) {
            this.f1[i].markAsDirty();
            this.f1[i].updateValueAndValidity();
            this.f1[i].markAsTouched();
        }

        if (!this.selectedFileName) {
            this.imageUpload = true;
            return;
        }

        if (this.blogFormGroup.invalid) {
            return;
        }

        const data = JSON.parse(this.commonService.localStorageGet('info'));
        const email = this.commonService.localStorageGet('user_email') ? this.commonService.localStorageGet('user_email') : null
        const mobile_no = this.commonService.localStorageGet('mobile_no') ? this.commonService.localStorageGet('mobile_no') : null
        let params = new FormData();

        if (this.commonService.isLoggedIn) {
            if (data && data.name) {
                params.append('user_name', data.name ? data.name : "");
            }
            if (email) {
                params.append('email', email);
            }
            if (mobile_no) {
                params.append('mobile_no', mobile_no);
            }
        } else {
            params.append('user_name', `${this.f1.first_name.value} ${this.f1.last_name.value}`);
            params.append('email', this.f1.email.value);
            params.append('mobile_no', this.f1.phone_no.value);
        }
        params.append('blog_category_id', this.blog_category_id ? this.blog_category_id : null);
        params.append('name', this.f1.title.value ? this.f1.title.value : "");
        params.append('description', this.f1.description.value ? this.f1.description.value : "");
        params.append('blog_image', this.imageFile ? this.imageFile : null);
        params.append('blog_from', this.commonService.isLoggedIn ? "USER" : "GUEST");
        this.isLoading = true;
        this.subscription.add(
            this.dataService.post(URLS.addBlog, params).subscribe(data => {
                if (data['code'] == 200 && data['data']) {
                    this.isLoading = false;
                    this.toasterService.successToast(data['message']);
                    this.selectedFileName = '';
                    this.imagePreview = null;
                    this.blogFormGroup.reset();
                }
                else {
                    this.toasterService.errorToast(data['message']);
                    this.isLoading = false;
                }
            }, error => {
                this.toasterService.errorToast(error['message']);
                this.isLoading = false;
            })
        )

    }

    initializeBlogFromGroup() {
        return this.formBuilder.group({
            first_name: new FormControl(null, {
                validators: Validators.compose([]),
            }),
            last_name: new FormControl(null, {
                validators: Validators.compose([]),
            }),
            email: new FormControl(null, {
                validators: Validators.compose([]),
            }),
            phone_no: new FormControl(null, {
                validators: Validators.compose([]),
            }),
            title: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            category: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            description: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            })
        })
    }

}
