import { Location } from "@angular/common";
import { ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { buffer, filter, map, pluck } from 'rxjs/operators';
import { CartService } from 'src/app/services/cart.service';
import { GetPlatformService } from "src/app/services/get-platform.service";
import { LocationService } from '../../services/location.service';
import { DeliveryAddressComponent } from '../delivery-address/delivery-address.component';
import { profileTypeEnum } from './../../_common/enum.enum';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { AuthenticationService } from './../../services/authentication.service';
import { CommonService } from './../../services/common.service';
import { ModalService } from './../../services/modal.service';
import { ToasterService } from './../../services/toaster.service';
import { HeaderMenuComponent } from './../header-menu/header-menu.component';
import { RegistrationTypeComponent } from './../registration-type/registration-type.component';

declare var navigator;
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	@HostListener('window: popstate', ['$event'])
	onPopState(event: Event): void {
		event.preventDefault();
		this.isMenuVisible = false;
	}

	@ViewChild('child') public child: HeaderMenuComponent;
	@HostListener('window: keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		if (event.key === 'ArrowUp' && this.selectedIndex > 0 && this.searchedArray.length > 1) {
			this.selectedIndex--;
			this.isArrowPressed = true;
		} else if (event.key === 'ArrowDown' && this.selectedIndex < this.searchedArray.length - 1 && this.searchedArray.length > 1) {
			this.selectedIndex++;
			this.isArrowPressed = true;
		} else if (event.key === 'ArrowUp' && this.selectedIndex == 0 && this.searchedArray.length > 1) {
			this.selectedIndex = this.searchedArray.length - 1;
			this.isArrowPressed = true;
		} else if (this.selectedIndex == this.searchedArray.length - 1 && this.searchedArray.length > 1) {
			this.selectedIndex = 0;
			this.isArrowPressed = true;
		}
		else if (event.key === 'Backspace' && this.isArrowPressed) {
			this.isArrowPressed = false;
		}

		if (event.key != 'Backspace' && this.isArrowPressed && this.searchedArray.length > 1) {
			this.searchedArray.forEach((element, index) => {
				if (index == this.selectedIndex) {
					this.selectedData = element
					if (this.selectedData.product_name) {
						this.searchValue = this.selectedData.product_name
					} else if (this.selectedData.category_name) {
						this.searchValue = this.selectedData.category_name
					} else if (this.selectedData.brand_name) {
						this.searchValue = this.selectedData.brand_name
					}
				}
			});
		}
	}
	profileEnum = profileTypeEnum;
	isBack = false;
	isMenuVisible: boolean = false;
	breadcrumbsArray = [];
	isInfoDropdownOpen: boolean = false;
	breadCrumbTitle = '';
	profileImage: any;
	accountTypeLabel: string = '';
	private subscription: Subscription = new Subscription();
	public searchInput: any;
	public searchValue = '';
	public productHeader: any;
	public categoryHeader: any;
	public brandHeader: any;
	public productList = [];
	public categoryList = [];
	public brandList = [];
	public searchPage = 1;
	public searchLimit = 3;
	public isSearched = false;
	public currentRoute: any;
	public isBecomeSellerReq: any;
	public isArrowPressed: boolean = false;
	public selectedData: any;
	public searchedArray: any = [];
	modal: any;
	selectedIndex = 0;

	isProductDetail: boolean = false;
	showSearch = false;
	is_sub_user: boolean = false;
	routerEvents: any;
	selectedBreadCrumb: any;
	isLoading: boolean = false;
	constructor(
		private router: Router,
		private authService: AuthenticationService,
		private navCtrl: NavController,
		public commonService: CommonService,
		private alertController: AlertController,
		private modalService: ModalService,
		private toasterService: ToasterService,
		private dataService: ApiService,
		public cartService: CartService,
		public location: Location,
		public getPlatformService: GetPlatformService,
		private locationService: LocationService,
		private route: ActivatedRoute,
		public zone: NgZone,
		private cdr: ChangeDetectorRef
	) {
		this.breadCrumb();
		this.routerEvents = this.router.events.subscribe(
			(event: any) => {
				if (event instanceof NavigationEnd) {
					this.currentRoute = event.url;
					setTimeout(() => {
						if (this.currentRoute.includes("product-detail") || this.currentRoute.includes("product-listing")) {
							let searchData = this.commonService.localStorageGet("searchData");
							this.searchValue = searchData
							if (this.searchValue) {
								this.isArrowPressed = true;
							}
							this.productList = [];
							this.searchedArray = [];
						} else {
							this.commonService.localStorageRemove("searchData");
							this.searchValue = null;
							this.isArrowPressed = false;
						}
					}, 500);
				}
			}
		)
	}


	ngOnInit() {
		this.breadCrumb();
		this.commonService.isLoggedIn = this.commonService.localStorageGet('accessToken') || this.commonService.isLoggedIn ? true : false;
		const data = this.commonService.localStorageGet('accessToken') ? this.commonService.localStorageGet('info') : null;
		this.commonService.userData = data ? JSON.parse(data) : {};
		if (this.commonService.isLoggedIn) {
			this.cartService.getCartList();
			this.commonService.getUserDetail().then((data) => {
				this.commonService.is_sub_user = data['is_sub_user'];
				this.commonService.localStorageSet('is_sub_user', data['is_sub_user']);
				this.commonService.is_admin = data['is_admin'];
				this.commonService.is_sub_user_admin = data['is_sub_user_admin'];
				this.commonService.localStorageSet('company_id', data['company_id']);
				if (data['show_message']) {
					let customNotification: any = {};
					customNotification['message'] = data['message'];
					let type = ' ';
					if (this.commonService.userData['isCustomer'] && data['approval_status'] == 'APPROVED') type = 'success';
					else if (this.commonService.userData['isSeller'] && data['seller_approval_status'] == 'APPROVED') type = 'success';
					else if (this.commonService.userData['isCustomer'] && data['approval_status'] == 'APPROVED' && data['seller_approval_status'] == 'REJECTED') type = 'warning';
					else { customNotification['type'] = 'warning' };
					this.commonService.setEvent('alertMessage', customNotification);

				}
			})
			this.subscription = this.commonService.profilePictureUpdated.subscribe(data => {
				if (data) {
					this.profileImage = data.user_detail.profile_picture;
				}
			});
		}
		this.commonService.eventObservable.subscribe((data: any) => {
			this.isInfoDropdownOpen = false;
			if (data.event == 'userResponseInfo') {
				this.commonService.userData = data['data'];
				this.profileImage = data['data']['profile_picture'];
			}
			if (data.event == 'productListHeader') {
				let data: any = [];
				let breadcrumbs = [];
				let breadcrumbData = {
					"breadcrumb": "Product List",
					"url": '/home/product-listing',
					state: null
				};
				this.breadCrumbTitle = "Product List";
				this.commonService.localStorageSet('breadcrumb', JSON.stringify(breadcrumbData))

			}
		})

	}

	ionViewWillEnter() {

		setTimeout(() => {
			this.breadCrumb();
			this.commonService.isLoggedIn = this.commonService.localStorageGet('accessToken') ? true : false;
			const data = this.commonService.localStorageGet('accessToken') ? this.commonService.localStorageGet('info') : null;
			this.commonService.userData = data ? JSON.parse(data) : {};
			this.commonService.eventObservable.subscribe((data: any) => {
				if (data.event == 'userResponseInfo') this.commonService.userData = data['data'];
			})
		}, 500);
	}

	shoppingCart() {
		if (!this.commonService.isLoggedIn) {
			this.navCtrl.navigateRoot('/login', {
				animated: false, state: {
					locationData: {
						from: 'cart'
					}
				}
			});
			return;
		}
		const token = this.commonService.localStorageGet('accessToken');
		if (token) {
			this.navCtrl.navigateRoot('/home/cart', { animated: false });
		}
		else {
			this.navCtrl.navigateBack('/login', { animated: false });
		}
	}

	async userSwitch(profile) {
		let params = {
			"user_type": profile
		}
		let name = '';
		this.subscription.add(
			this.dataService.get(URLS.profileSwitch, params).subscribe(data => {
				name = data['data']['first_name'] + ' ' + data['data']['last_name'];
				if (this.commonService.userData['isSeller']) {
					this.commonService.userData['isCustomer'] = true;
					this.commonService.userData['isSeller'] = false;
				}
				else if (this.commonService.userData['isCustomer']) {
					this.commonService.userData['isSeller'] = true;
					this.commonService.userData['isCustomer'] = false;
				}

				this.commonService.localStorageSet('user_data', JSON.stringify(this.commonService.userData));

				this.commonService.getUserDetail().then((data) => {
					this.commonService.localStorageSet('company_id', data['company_id']);
				})
				let userResponseInfo = {
					user_type: this.commonService.userData['user_type'] ? this.commonService.userData['user_type'] : '',
					customer_type: this.commonService.userData['customer_type'] ? this.commonService.userData['customer_type'] : '',
					seller_type: this.commonService.userData['seller_type'] ? this.commonService.userData['seller_type'] : '',
					user_profile: this.commonService.userData['seller_type'] && this.commonService.userData['seller_type'] != 'NONE' ? this.commonService.userData['seller_type'] : this.commonService.userData['customer_type'],
					approval_status: this.commonService.userData['approval_status'] ? this.commonService.userData['approval_status'] : '',
					isCustomer: this.commonService.userData['isCustomer'],
					isSeller: this.commonService.userData['isSeller'],
					profile_picture: this.profileImage,
					name: name,
					is_admin: this.commonService.userData['is_admin'],
					company_id: this.commonService.userData['company_id'] ? this.commonService.userData['company_id'] : null
				}
				this.commonService.userData = userResponseInfo;
				this.commonService.updateProfileData(this.commonService.userData);
				this.commonService.localStorageSet('info', JSON.stringify(userResponseInfo));
				this.commonService.localStorageSet('customer_approval', data['data']['approval_status'] ? data['data']['approval_status'] : 'NONE');
				this.commonService.localStorageSet('seller_approval', data['data']['seller_approval_status'] ? data['data']['seller_approval_status'] : 'NONE');
				this.commonService.setEvent('userResponseInfo', userResponseInfo);
				this.commonService.callmenuData({ menuChanged: true });
				this.navCtrl.navigateRoot(['/home/account-details/my-profile'], { animated: false });
				let customNotification = {};
				if (data['code'] == 200) {
					if (data['data']['show_message']) {
						customNotification['message'] = data['message'];
						customNotification['type'] = 'success';
						this.commonService.setEvent('alertMessage', customNotification);
					}
				} else {
					customNotification['message'] = data['message'];
					customNotification['type'] = 'warning'
					this.commonService.setEvent('alertMessage', customNotification)
				}

				this.commonService.localStorageRemove('unreadNotificationCount')
				this.getNotificationCount();

			}, error => {
				this.toasterService.errorToast(error['message']);

			})
		)
	}

	becomeAUser() {
		if (this.commonService.userData.user_type == this.profileEnum.Customer) {
			this.commonService.setEvent('isBecomeSeller', 'true');
			this.commonService.localStorageSet('isBecomeSeller', 'true');
			this.commonService.localStorageSet("becomeSeller", 'true');
			setTimeout(() => {
				this.navCtrl.navigateRoot(['/seller-register']);
			}, 200);
		}
		else if (this.commonService.userData.user_type == this.profileEnum.Seller) {
			this.commonService.setEvent('isBecomeCustomer', 'true');
			this.commonService.localStorageSet('isBecomeCustomer', 'true');
			this.commonService.localStorageSet("becomeCustomer", 'true');
			setTimeout(() => {
				this.navCtrl.navigateRoot(['/registration']);
			}, 200);
		}
	}

	goToPage(page) {
		if (page === 'login') {
			this.navCtrl.navigateRoot(['/login']);
		}
	}

	/////// Code for Breadcrumb Title and Nevigation Path //////////////
	breadCrumb() {
		this.router.navigated
		const navigationEnd = this.router.events.pipe(filter(val => { if (val instanceof NavigationEnd) return true; }));
		this.router.events.pipe(filter(val => {
			if (val instanceof ActivationEnd) return true;
		}),
			pluck("snapshot"),
			pluck("data"),
			buffer(navigationEnd),
			map((data: any[]) => {
				return data.reverse();
			})).subscribe((val) => {
				let breadcrumbs = [];
				this.breadcrumbsArray = [];
				let data = val;
				let customer = this.commonService.localStorageGet('customer_approval');
				let seller = this.commonService.localStorageGet('seller_approval');
				let state = this.router.getCurrentNavigation().extras.state;
				if (customer == 'APPROVED' && seller == 'APPROVED') {
					this.commonService.setEvent('alertMessage', {});
				}
				if (state) {
					data.push(state)
					this.commonService.localStorageSet('breadcrumb', JSON.stringify(state))
				} else {
					if (this.commonService.localStorageGet('breadcrumb')) {
						let breadcrumbData = JSON.parse(this.commonService.localStorageGet('breadcrumb'));
						data.push(breadcrumbData)
					}
				}
				data.map(el => {
					if (el && el['breadcrumb']) {
						if (!breadcrumbs.includes(el['breadcrumb'])) {
							breadcrumbs.push(el['breadcrumb'])
							this.breadcrumbsArray.push(el)
							this.breadcrumbsArray.forEach((element, index) => {
								if (index == this.breadcrumbsArray.length - 1) {
									this.breadCrumbTitle = element.breadcrumb;
									this.selectedBreadCrumb = element;
									this.isBack = element.back;
									if (element.productDetail) {
										this.isProductDetail = element.productDetail
									} else {
										this.isProductDetail = false;
									}
								}
							});
							this.commonService.localStorageSet('breadcrumb', JSON.stringify(this.breadcrumbsArray))
						}
					}

				});

				this.route.paramMap.subscribe(params => {
					if (this.commonService.userData.isCustomer) {
						if (params.get('category-name') && params.get('product-name')) {
							this.isProductDetail = true;
						} else {
							this.isProductDetail = false;
						}
						const categoryName = params.get('category-name') ? params.get('category-name').replace(/-/g, ' ') : null;
						const productName = params.get('product-name') ? params.get('product-name').replace(/-/g, ' ') : null;
						if (!productName && categoryName) {
							const isBreadCrumbPresent = this.breadcrumbsArray.some(obj => obj.breadcrumb == categoryName);
							if (!isBreadCrumbPresent) {
								let obj = {
									breadcrumb: categoryName,
									title: this.breadcrumbsArray[this.breadcrumbsArray.length - 1].title,
									url: `/product-detail/` + categoryName + '/' + productName
								}
								this.breadcrumbsArray.push(obj);
							}
						}
						if (productName && categoryName) {
							let product_id = +params.get("id");
							this.breadcrumbsArray = this.breadcrumbsArray.splice(0, 1);
							if (categoryName) {
								let obj = {
									breadcrumb: categoryName,
									url: `/home/product-listing/`
								}

								this.breadcrumbsArray.push(obj);
							}
							if (productName) {
								let obj = {
									breadcrumb: productName,
									url: `/product-detail/${categoryName.replace(/ /g, "-")}/${productName.replace(/ /g, "-")}/${product_id}`
								}
								this.breadcrumbsArray.push(obj);
							}

						}
					}

				});

			});

	}

	onSearchInput(event) {
		if (event.target.value) {
			this.isSearched = true;
			this.searchValue = event.target.value
			this.isArrowPressed = false;
		} else {
			this.isSearched = false;
			this.isArrowPressed = false;
			this.commonService.localStorageRemove("searchData");
		}
	}

	onSearch(event, onChangeEvent?) {
		if (onChangeEvent == 'onChange' && this.isArrowPressed) {
			return;
		}
		if (this.searchValue) {
			let searchValue = (this.searchValue && this.searchValue.trim() !== '') ? this.searchValue.trim() : null;;

			if (!searchValue) {
				this.toasterService.errorToast("Please add valid search value");
				return;
			}
			this.isLoading = true;

			const params = {
				search: this.searchValue ? this.searchValue.trim() : null,
				page: this.searchPage,
				limit: this.searchLimit
			}
			this.subscription.add(
				this.dataService.get(URLS.search, params).subscribe(data => {
					if (data['code'] == 200 && data['data']) {
						let searchData = data['data'];
						this.productList = [];
						this.searchedArray = [];
						this.categoryList = [];
						this.brandList = [];
						if (searchData && searchData.length) {
							searchData.forEach(element => {
								if (element.product_header) {
									this.productHeader = element.product_header;
								}
								if (element.brand_header) {
									this.brandHeader = element.brand_header;
								}
								if (element.category_header) {
									this.categoryHeader = element.category_header;
								}
								if (element.product_list && element.product_list.length) {
									element.product_list.forEach(element => {
										let productObj = {
											is_product: true,
											category_name: element.category_name,
											product_name: element.product_name,
											product_id: element.product_id,
											product_brand_mapping_id: element.product_brand_mapping_id ? element.product_brand_mapping_id : null,
											product_package_mapping_id: element.product_package_mapping_id ? element.product_package_mapping_id : null
										}
										this.productList.push(productObj);
									});
								}
								if (element.categoryList && element.categoryList.length) {
									element.categoryList.forEach(element => {
										let categoryObj = {
											category_name: element.name,
											category_id: element.category_id,
											is_product: false,

										}
										this.categoryList.push(categoryObj);
									});
								}
								if (element.brandList && element.brandList.length) {
									element.brandList.forEach(element => {
										let brandObj = {
											brand_name: element.brand_name,
											brand_id: element.brand_id,
											is_product: false,

										}
										this.brandList.push(brandObj);
									});
								}
							});


							this.searchedArray = [...this.productList, ...this.categoryList, ...this.brandList];

							if (this.searchedArray && this.searchedArray.length > 0) {
								this.selectedData = this.searchedArray[0];
							}
						}
						this.isLoading = false;

					}

				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);

				})
			)
		} else {
			this.productList = [];
			this.searchedArray = [];
			this.categoryList = [];
			this.brandList = [];
			this.isLoading = false;

		}
	}

	onSearchClear() {
		this.commonService.localStorageRemove("searchData");
		this.isArrowPressed = false;
	}

	// toggle menu visibility
	toggleMenu() {
		setTimeout(() => {
			this.isMenuVisible = !this.isMenuVisible;
			this.cdr.detectChanges();  // Detect changes manually
		}, 200)
	}

	openModal(e) {
		this.isInfoDropdownOpen = !this.isInfoDropdownOpen;
	}

	async goBack(breadcrumb?) {
		if (this.commonService.userData.isCustomer) {
			this.location.back();
			this.commonService.getUserDetail();
		} else if (breadcrumb) {
			await this.location.back();
			this.commonService.getUserDetail();
		} else if (!this.commonService.isLoggedIn) {
			this.location.back();
		}
		else {
			this.navCtrl.navigateForward('/home/account-details/manage-products', { animated: false })
		}
	}

	goToAccount() {
		this.navCtrl.navigateForward(['/home/account-details/my-profile'], { animated: false });
	}

	goToDashboard() {
		this.navCtrl.navigateForward(['/home/account-details/dashboard'], { animated: false });

	}

	goToConsultantRequest() {
		this.navCtrl.navigateRoot(['/home/consultant-request'], { animated: false, "state": { "breadcrumb": "Consultant Request", "url": '/home/consultant-request' } })
	}

	outsideClick() {
		this.isMenuVisible = false;
		this.productList = [];
		this.searchedArray = [];
		this.categoryList = [];
		this.brandList = [];
	}

	outSideDropdown() {
		this.isInfoDropdownOpen = false;
	}

	async searchNavigation(data) {
		if (data && data.product_id) {
			let shipping_address_id = null;
			let deliverLocation = this.commonService.localStorageGet('deliverLocation');
			if (deliverLocation) {
				let categoryName = this.commonService.trimUrl(data.category_name);
				let productName = this.commonService.trimUrl(data.product_name);

				await this.navCtrl.navigateRoot('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id, {
					animated: false, "state": {
						"breadcrumb": data.type,
						"url": '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
						"productDetail": true,
						productData: data
					}
				});
				let params = {
					"product_id": +data.product_id,
					"user_id": this.commonService.localStorageGet("user_id") ? +this.commonService.localStorageGet("user_id") : null,
					"product_brand_mapping_id": data.product_brand_mapping_id,
					"product_package_mapping_id": data.product_package_mapping_id
				}
				this.searchedProductAdd(params);
				this.commonService.localStorageSet("searchData", data.product_name)
				let searchData = this.commonService.localStorageGet("searchData");
				setTimeout(() => {
					this.searchValue = searchData
					this.searchedArray = [];
				}, 400);
				this.isArrowPressed = true;

			}
			else {
				if (!this.commonService.isLoggedIn) {
					if (data.product_id) {
						if (deliverLocation) {
							let categoryName = this.commonService.trimUrl(data.category_name);
							let productName = this.commonService.trimUrl(data.product_name);

							await this.navCtrl.navigateRoot('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id, {
								animated: false, "state": {
									"breadcrumb": data.type,
									"url": '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
									"productDetail": true,
									productData: data
								}
							});

							let params = {
								"product_id": +data.product_id,
								"product_brand_mapping_id": data.product_brand_mapping_id,
								"product_package_mapping_id": data.product_package_mapping_id
							}
							this.searchedProductAdd(params);
							this.commonService.localStorageSet("searchData", data.product_name)
							let searchData = this.commonService.localStorageGet("searchData");

							setTimeout(() => {
								this.searchValue = searchData
								this.searchedArray = [];
							}, 400);
							this.isArrowPressed = true;
						} else {
							this.commonService.localStorageSet("searchData", data.product_name)
							let searchData = this.commonService.localStorageGet("searchData");
							setTimeout(() => {
								this.searchValue = searchData
								this.searchedArray = [];
							}, 400);
							await this.getAddress(data)
						}
					}
				} else {

					this.commonService.localStorageSet("searchData", data.product_name)
					let searchData = this.commonService.localStorageGet("searchData");
					await this.getAddress(data)

					setTimeout(() => {
						this.searchValue = searchData
						this.searchedArray = [];
					}, 400);

				}
			}
		}
		if (data && (data.category_id || data.brand_id)) {
			let category_brand_name = data.category_id ? this.commonService.trimUrl(data.category_name) : this.commonService.trimUrl(data.brand_name);

			await this.navCtrl.navigateRoot('/home/product-listing/' + category_brand_name,
				{
					animated: false, "state": {
						"breadcrumb": "Product List",
						"url": '/home/product-listing/' + category_brand_name,
						stateData: data
					}
				})
			let searchObj = {}
			if (data.category_id) {
				searchObj['category_id'] = data.category_id,
					searchObj['isDataCalled'] = true
				this.commonService.localStorageSet("searchData", data.category_name)
				let searchData = this.commonService.localStorageGet("searchData");
				setTimeout(() => {
					this.searchValue = searchData
					this.searchedArray = [];
				}, 400);
				this.isArrowPressed = true;
			}
			if (data.brand_id) {
				searchObj['brand_id'] = data.brand_id,
					searchObj['isDataCalled'] = true
				this.commonService.localStorageSet("searchData", data.brand_name)
				let searchData = this.commonService.localStorageGet("searchData");
				setTimeout(() => {
					this.searchValue = searchData
					this.searchedArray = [];
				}, 400);
				this.isArrowPressed = true;
			}
			this.commonService.filterDataEmit(searchObj);

		}
		this.productList = [];
		this.searchedArray = [];
		this.categoryList = [];
		this.brandList = [];
		this.searchValue = "";
	}

	searchedProductAdd(params) {
		this.subscription.add(
			this.dataService.post(URLS.searchedProductAdd, params).subscribe(data => {
				if (data['code'] == 200) {
				}
			}, error => {
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	goToSearch() {
		this.navCtrl.navigateForward('product-search', { animated: false })
	}

	getAddress(productDat) {
		let params: any;
		if (this.commonService.isLoggedIn && this.commonService.userData && this.commonService.userData.customer_type != 'INDIVIDUAL') {
			params = {
				"user_type": "CUSTOMER",
				"company_id": this.commonService.localStorageGet('company_id')
			}
			this.subscription.add(
				this.dataService.get(URLS.getAddress, params).subscribe(data => {
					if (data['code'] == 200) {
						if (data['data']['default_shipping']) {
							let defaultAddress = data['data']['default_shipping'];
							this.locationService.latitude = defaultAddress['latitude'];
							this.locationService.longitude = defaultAddress['longitude'];
							this.commonService.localStorageSet('deliverLocation', JSON.stringify(defaultAddress))
							this.commonService.localStorageSet('shipping_address_id', defaultAddress['company_address_id'] ? defaultAddress['company_address_id'] : defaultAddress['user_address_id']);
							if (productDat.product_id) {
								let categoryName = this.commonService.trimUrl(productDat.category_name);
								let productName = this.commonService.trimUrl(productDat.product_name);

								this.navCtrl.navigateRoot('/home/product-detail/' + categoryName + "/" + productName + "/" + productDat.product_id,
									{
										animated: false, "state": {
											"breadcrumb": productDat.type,
											"url": '/home/product-detail/' + categoryName + "/" + productName + "/" + productDat.product_id,
											"productDetail": true,
											productData: productDat
										}
									});
							}
						}
						else {
							let defaultAddress = data['data']['addressList'][0];
							this.locationService.latitude = defaultAddress['latitude'];
							this.locationService.longitude = defaultAddress['longitude'];
							this.commonService.localStorageSet('deliverLocation', JSON.stringify(defaultAddress))
							this.commonService.localStorageSet('shipping_address_id', defaultAddress['company_address_id'] ? defaultAddress['company_address_id'] : defaultAddress['user_address_id']);
							if (productDat.product_id) {
								let categoryName = this.commonService.trimUrl(productDat.category_name);
								let productName = this.commonService.trimUrl(productDat.product_name);

								this.navCtrl.navigateRoot('/home/product-detail/' + categoryName + "/" + productName + "/" + productDat.product_id,
									{
										animated: false, "state": {
											"breadcrumb": productDat.type,
											"url": '/home/product-detail/' + categoryName + "/" + productName + "/" + productDat.product_id,
											"productDetail": true,
											productData: productDat
										}
									});
							}
						}
					}

				}, error => {
					this.toasterService.errorToast(error['message']);

				})
			)
		} else {
			this.addressPopUp(productDat);
		}
	}

	async logoutAlert() {
		const alert = await this.alertController.create({
			header: 'Are you sure ? ',
			message: 'You want to logout ?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					handler: () => {
					},
				},
				{
					text: 'Yes',
					role: 'confirm',
					handler: () => {
						this.logout();
					},
				},
			],
		});
		await alert.present();
		const { role } = await alert.onDidDismiss();
	}

	async addressPopUp(data) {
		let obj = {
			component: DeliveryAddressComponent,
			backdropDismiss: true,
			canDismiss: true,
			cssClass: 'delivery-modal',

		};
		let modal = this.modalService.openModal(obj);
		modal.then(res => {
			if (res['data'] && res['data']['address']) {
				if (data.product_id) {
					this.navCtrl.navigateRoot('/home/product-detail/' + data.product_id, { animated: false, "state": { "breadcrumb": data.type, "url": '/home/product-detail/' + data.product_id, "productDetail": true, productData: data } });
				}
			}
		}, error => {
			console.log(error);
		});

	}


	logout() {
		this.commonService.isLoggedIn = false;
		this.commonService.logout();
	}

	signUpTypeModal(type) {
		let obj = {
			component: RegistrationTypeComponent,
			backdropDismiss: false,
			canDismiss: true,
			cssClass: 'success-modal',
		};
		this.modal = this.modalService.openModal(obj);
		this.modal.then(value => {
		}, error => {
			console.log(error);
		});

	}

	goToHomepage() {
		if (this.commonService.isLoggedIn && this.commonService.userData['isCustomer']) {
			this.navCtrl.navigateRoot('/home', { animated: false });
		}
		if (this.commonService.isLoggedIn && this.commonService.userData['isSeller']) {
			this.navCtrl.navigateRoot('/account-details/dashboard', { animated: false });
		}
		if (!this.commonService.isLoggedIn) {
			this.navCtrl.navigateRoot('/home', { animated: false });
		}
		this.commonService.scrollToTop();
	}

	pageRedirect(url) {
		if (this.router.url == url) {
			// Refresh the current page
			this.router.navigateByUrl('/', { replaceUrl: true }).then(() => {
				this.navCtrl.navigateRoot(url, { animated: false });
			});
		}
		else if (JSON.parse(JSON.stringify(url)) == '/') {
			if (this.commonService.userData.isCustomer) {
				this.navCtrl.navigateRoot(['/'], { animated: false });

			}
			else {
				this.navCtrl.navigateRoot(['/account-details/my-profile'], { animated: false });
			}
		}
		else {
			this.navCtrl.navigateRoot(url, { animated: false });
		}
	}

	notification() {
		if (this.commonService.isLoggedIn) {
			this.navCtrl.navigateRoot('/home/account-details/notifications', { animated: false });
		} else {
			this.navCtrl.navigateRoot('/login', { animated: false });
		}
	}

	loginPage() {
		this.navCtrl.navigateRoot('/login', { animated: false });
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	getNotificationCount() {
		this.commonService.notificationCount = null;
		let param = {}
		param['page'] = 1;
		param['limit'] = 10;
		param['user_type'] = this.commonService.userData['isSeller'] ? 'SELLER' : 'CUSTOMER';
		this.subscription.add(
			this.dataService.get(URLS.notificationList, param).subscribe(data => {
				if (data['code'] == 200) {
					let result = data['data'];
					this.commonService.localStorageSet('unreadNotificationCount', result.total_unread_count.toString());
					this.commonService.notificationCount = result.total_unread_count;

				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.toasterService.errorToast(error['message']);

			})
		)

	}
}
