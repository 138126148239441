<div class="home-page app-header">
    <app-header></app-header>
</div>
<ion-content>
    <div class="not-found-container mt-20 page-scroll-top">
        <img alt="Not Found Image" src="../../assets/img/not-found.svg">
    </div>
    <div class="mt-30"
        *ngIf="(commonService.userData.isCustomer && commonService.isLoggedIn)||!commonService.isLoggedIn"
        align="center">
        <button class="submit-btn w-169 mr-20 mb-10" (click)="homeClick()">
            Go To Home
        </button>
    </div>
    <div class="mt-30" *ngIf="commonService.userData.isSeller" align="center">
        <button class="submit-btn w-169 mr-20 mb-10" (click)="homeClick()">
            Go To Dashboard
        </button>
    </div>
    <div class="wrapper-block home-page mt-40">
        <div class="app-footer">
            <app-footer></app-footer>
        </div>
    </div>
</ion-content>