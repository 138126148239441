import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/services/api-data.service';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { CartService } from '../../services/cart.service';
import { ModalService } from '../../services/modal.service';

@Component({
	selector: 'app-action-confirm-modal',
	templateUrl: './action-confirm-modal.component.html',
	styleUrls: ['./action-confirm-modal.component.scss'],
})
export class ActionConfirmModalComponent implements OnInit {
	@Input() title;
	@Input() description;
	@Input() modalImage;
	@Input() submitBtnText: any;
	@Input() submitBtnLink?: any;
	@Input() cancelBtnText: any;
	@Input() cancelBtnLink?: any;
	@Input() hideCloseBtn?: any;
	@Input() cartAddressPopUp: boolean;
	@Input() orderId;
	@Input() acceptOrder = false;
	apiCall = false;
	public subscription = new Subscription();

	constructor(
		private modalService: ModalService,
		public getPlatformService: GetPlatformService,
		private cartService: CartService,
		private navCtrl: NavController,
		private toasterService: ToasterService,
		private dataService: ApiService,

	) { }

	ngOnInit() {
	}

	close(isDraft?, label?) {
		if (this.acceptOrder) {
			if (label == 'yes') {
				this.approveProductRequest(isDraft);
			}
			else {
				this.modalService.dismissModal({ isDraft });
			}
		}
		else {
			this.modalService.dismissModal({ isDraft });
		}
	}

	clear() {
		this.cartService.clearCart().then(() => {
			this.close(true);
		});
	}

	approveProductRequest(isDraft) {
		this.apiCall = true;
		let param = {
			"order_item_id": this.orderId
		}
		this.subscription.add(
			this.dataService.put(URLS.customerOrderAccept, param).subscribe(data => {
				if (data['code'] == 200) {
					this.apiCall = false;
					this.navCtrl.navigateForward(['/home/account-details/my-orders'])
					this.modalService.dismissModal({ isDraft });
				}
				else {
					this.apiCall = false;
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.toasterService.errorToast(error['message']);

			})
		)
	}

}
