import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { CommonService } from '../../services/common.service';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit, OnDestroy {
    isLoading = false;
    noData = false;
    @Input() subHeading = '';
    @Input() heading = '';
    @Input() companyCategoryIds: any = [];
    @Input() isCategoryRequired: boolean = false;
    @Input() isCompanyRequired?: boolean = false;
    @Input() categoryNotChecked: boolean = false;
    @Input() isManageUser: boolean = false
    @Input() isCategoryDisable: boolean = false; // Define input property
    categoriesList: any = [];
    uniqueMasterCategoryIds: any = []
    private subscription: Subscription = new Subscription();
    categoriesId = [];
    isfrmChecked: any;
    is_admin = false;


    constructor(
        private dataService: ApiService,
        public commonService: CommonService,
        public getPlatformService: GetPlatformService,
        private cdr: ChangeDetectorRef
    ) {
        this.subscription = this.commonService.clearData.subscribe(data => {
            if (data && data.iscategoryChecked) {
                this.isCategoryRequired = true;
                this.categoryNotChecked = true;
                this.getCategories().then((data) => {
                    if (data) {
                        setTimeout(() => {
                            this.checkedCatId();
                        }, 500);
                    }
                });

            } else {
                this.isCategoryRequired = false;
                this.categoryNotChecked = false;
                this.getCategories().then((data) => {
                    if (data) {
                        setTimeout(() => {
                            this.checkedCatId();
                        }, 500);
                    }
                });
            }
        })
    }

    ngOnInit() {
        this.getCategories().then((data) => {
            if (data) {
                setTimeout(() => {
                    this.checkedCatId();
                    this.cdr.detectChanges();
                }, 500);
            }
        });
    }

    checkedCatId() {
        this.categoriesList.forEach(element => {
            this.companyCategoryIds.forEach(ele => {
                if (element['master_category_id'] == ele['master_category_id']) {
                    element['checked'] = true;
                    let catData = {};
                    catData['master_category_id'] = parseInt(ele['master_category_id']);
                    this.categoriesId.push(catData);
                }
            });
        });
    }

    getCategories() {
        return new Promise((resolve, reject) => {
            this.isLoading = true;
            this.noData = false;
            let params = {};
            if (this.isCompanyRequired) {
                params['company_id'] = +this.commonService.localStorageGet('company_id');
            }
            if (this.isManageUser) {
                this.subscription.add(
                    this.dataService.get(URLS.masterCategory, params).subscribe(data => {
                        if (data['code'] == 200 && data['data']['masterCategoryList'].length > 0) {
                            this.categoriesList = data['data']['masterCategoryList'];

                            this.categoriesList.forEach(element => {
                                element.image_url = element.master_category_image_url ? element.master_category_image_url : '../../../assets/product/product7.svg';
                                element.name = element.master_category_name
                            });
                            this.getCompanyCategories()
                            if (this.commonService.userData.isSeller) {
                                let categoriesdata = this.categoriesList;
                                this.categoriesList = [];
                                setTimeout(() => {
                                    let filterCompanyMasterCategories = categoriesdata.filter(item1 => {
                                        return this.uniqueMasterCategoryIds.some(item2 => {
                                            return item1.master_category_id === item2.master_category_id;
                                        });
                                    });
                                    this.categoriesList = filterCompanyMasterCategories
                                }, 500)
                            }
                            resolve(data);
                            setTimeout(() => {
                                this.isLoading = false;
                            }, 300)

                        }
                        else {
                            reject(data);
                            this.categoriesList = [];
                            this.noData = true;
                            setTimeout(() => {
                                this.isLoading = false;
                            }, 300)
                        }
                    }, error => {
                        reject(error);
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 300);
                        this.noData = true;
                        this.categoriesList = [];
                    })
                )
            } else {
                this.subscription.add(
                    this.dataService.get(URLS.masterCategory, params).subscribe(data => {
                        if (data['code'] == 200 && data['data']['masterCategoryList'].length > 0) {
                            this.categoriesList = data['data']['masterCategoryList'];
                            this.categoriesList.forEach(element => {
                                element.image_url = element.master_category_image_url ? element.master_category_image_url : '../../../assets/product/product7.svg';
                                element.name = element.master_category_name
                            });
                            resolve(data);
                            setTimeout(() => {
                                this.isLoading = false;
                            }, 300)
                        }
                        else {
                            reject(data);
                            this.categoriesList = [];
                            setTimeout(() => {
                                this.isLoading = false;
                            }, 300)
                        }
                    }, error => {
                        reject(error)
                        this.categoriesList = [];
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 300)
                    })
                )
            }
        })
    }

    setCategoriesId(event: any, id, category_data) {
        this.categoriesList.find(x => x.master_category_id == id).checked = event.target.checked;
        if (category_data && category_data.length) {
            if (event.target.checked) {
                category_data.map(element => {
                    let categoryObj = {
                        "category_id": element.category_id
                    }
                    if (this.categoriesId && this.categoriesId.length) {
                        if (!this.categoriesId.some(obj => obj.category_id === element.category_id)) {
                            this.categoriesId.push(categoryObj);
                        }

                    } else {
                        this.categoriesId.push(categoryObj);
                    }
                });
            } else {
                if (category_data && category_data.length) {
                    category_data.forEach(catElement => {
                        let updatedCategoryId = this.categoriesId.filter(obj => obj.category_id != catElement.category_id);
                        this.categoriesId = updatedCategoryId

                    });
                }
            }
        }
        if (this.categoriesId.length < 1) {
            this.categoryNotChecked = true;
        } else {
            this.categoryNotChecked = false;
        }

    }

    getCompanyCategories() {
        this.subscription.add(
            this.dataService.get(URLS.sellerCategories).subscribe(data => {
                if (data['code'] == 200) {
                    if (data['data']) {
                        let approvedCategories = data['data'].filter(item => item.approval_status === "APPROVED");
                        let masterCategoryIds = Array.from(new Set(approvedCategories.map(item => item.master_category_id)));
                        this.uniqueMasterCategoryIds = masterCategoryIds.map(id => {
                            return { master_category_id: id };
                        });
                    }
                }
                else {
                    console.log("error", data['message'])
                }
            }, error => {
                console.log("error", error.message)
            })
        )
    }

    selectAllCategories() {
        this.categoriesList.forEach(category => {
            category.checked = true;
            if (category.child_categories && category.child_categories.length) {
                category.child_categories.forEach(child => {
                    this.setCategoriesId({ target: { checked: true } }, child.master_category_id, child.child_category_data);
                });
            }
        });

        this.cdr.detectChanges();
    }
    deselectAllCategories() {
        this.categoriesList.forEach(category => {
            category.checked = false;
            if (category.child_categories && category.child_categories.length) {
                category.child_categories.forEach(child => {
                    this.setCategoriesId({ target: { checked: false } }, child.master_category_id, child.child_category_data);
                });
            }
        });
        this.categoriesId = []
        this.cdr.detectChanges();
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
