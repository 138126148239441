import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { ToasterService } from './../../services/toaster.service';

enum QuoteType {
	CLOSED = 'CLOSED',
	EXPIRED = 'EXPIRED',
	REQUESTED = 'REQUESTED',
	RECEIVED = 'RECEIVED',
	ORDER_INITIATED = 'ORDER_INITIATED'
}

@Component({
	selector: 'app-my-quote-requests',
	templateUrl: './my-quote-requests.component.html',
	styleUrls: ['./my-quote-requests.component.scss'],
})
export class MyQuoteRequestsComponent implements OnInit, OnDestroy {

	public subscription = new Subscription();

	tabs = [
		{
			"name": "Requested",
			"value": "REQUESTED"
		},
		{
			"name": "Received",
			"value": "RECEIVED"
		},
		{
			"name": "Order Initiated",
			"value": "ORDER_INITIATED"
		},
		{
			"name": "Expired",
			"value": "EXPIRED"
		},
		{
			"name": "Closed",
			"value": "CLOSED"
		},
	]
	tabVal = "REQUESTED";
	showFilter = false;
	widthConfig = ['20px', '200px', '250px', '150px', '150px', '250px', '180px', '200px', '200px', '100px'];
	paginationVal = 10;
	perPageInterface = { cssClass: 'custom-select-dd per-page-dd' };
	selectInterface = { cssClass: 'custom-select-dd' };
	paymentType: any;
	paymentData = [
		{
			"title": "Instant Payment",
			"id": "INSTANT PAYMENT",
		},
		{
			"title": "On Credit",
			"id": "ON CREDIT",
		}
	]
	column_settings = [];

	listOfAllData = [];
	public itemsPerPage = this.commonService.list;
	public currentPage = 1;
	public enableNext: boolean = false;
	public totalCount: any;
	public emitterCalled: boolean = false;
	public stateData: any;
	isQuoteLoading = false;
	public
	fromDate: any;
	toDate: any;
	searchValue: string;
	pageNo = 1;
	pagesize = this.commonService.list;
	searchObj = {};
	searchText = '';
	todaysDate = new Date();
	maxDate: any;
	lastSearchCriteria: any = {};
	lastSearchText: string | null = null;
	filterApplied: boolean = false;
	constructor(
		public getPlatformService: GetPlatformService,
		public commonService: CommonService,
		private dataService: ApiService,
		private toasterService: ToasterService,
		private datePipe: DatePipe,
		private router: Router,
		private modalController: ModalController
	) {

		this.subscription = this.commonService.quoteListing.subscribe(data => {
			if (data) {
				this.emitterCalled = true;
				this.tabVal = data.tabValue
				this.tabChange(this.tabVal);
			}
		})

		let state = this.router.getCurrentNavigation().extras.state;
		if (state) {
			this.stateData = state.tabVal;
			this.tabVal = this.stateData;
			this.tabChange(this.tabVal);
		}
	}

	ngOnInit() {
		this.column_settings = [
			{ primaryKey: 'id', header: '#' },
			{ primaryKey: 'created_at', header: 'Requested on' },
			{ primaryKey: 'product_name', header: 'Product name', image: true },
			{ primaryKey: 'quantity', header: 'Qty' },
			{ primaryKey: 'package_value', header: 'Package' },
			{ primaryKey: 'shipping_address', header: 'Shipping address' },
			{ primaryKey: 'timeframe', header: 'Timeframe' },
			{ primaryKey: 'payment_type', header: 'Payment type', payment_status: true },
			{ primaryKey: '', header: 'Action', action: true }
		]
		if (this.tabVal) {
			this.tabChange(this.tabVal);
		}

		if (!this.emitterCalled && !this.stateData && !this.tabVal) {
			this.getListData();
		}

		this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		this.commonService.scrollToTop();
	}

	accordionChange(val) {
		this.tabChange(val.target.value)
	}

	tabChange(val) {
		this.widthConfig = [];
		this.column_settings = []
		this.listOfAllData = []
		this.tabVal = val;
		switch (val) {
			case "RECEIVED":
				this.widthConfig = ['20px', '200px', '250px', '150px', '150px', '250px', '180px', '230px', '250px', '200px', '150px', '150px'];
				this.column_settings = [
					{ primaryKey: 'id', header: '#' },
					{ primaryKey: 'created_at', header: 'Requested on' },
					{ primaryKey: 'product_name', header: 'Product name', image: true },
					{ primaryKey: 'quantity', header: 'Qty' },
					{ primaryKey: 'package_value', header: 'Package' },
					{ primaryKey: 'shipping_address', header: 'Shipping address' },
					{ primaryKey: 'timeframe', header: 'Timeframe' },
					{ primaryKey: 'payment_type', header: 'Payment type', payment_status: true },
					{ primaryKey: 'total_price', header: 'Quote price', other_text: '(Excluding GST)' },
					{ primaryKey: 'estimated_delivery_date', header: 'Expected delivery' },
					{ primaryKey: 'expiredAt', header: 'Expire After' },
					{ primaryKey: '', header: 'Action', action: true }
				];
				break;
			case "REQUESTED":
				this.widthConfig = ['20px', '200px', '250px', '150px', '150px', '250px', '180px', '200px', '200px', '200px', '100px'];
				this.column_settings = [
					{ primaryKey: 'id', header: '#' },
					{ primaryKey: 'created_at', header: 'Requested on' },
					{ primaryKey: 'product_name', header: 'Product name', image: true },
					{ primaryKey: 'quantity', header: 'Qty' },
					{ primaryKey: 'package_value', header: 'Package' },
					{ primaryKey: 'shipping_address', header: 'Shipping address' },
					{ primaryKey: 'timeframe', header: 'Timeframe' },
					{ primaryKey: 'payment_type', header: 'Payment type', payment_status: true },
					{ primaryKey: 'endDate', header: 'Expire on' },
					{ primaryKey: '', header: 'Action', action: true }
				];
				break;
			case "ORDER_INITIATED":
				this.widthConfig = ['20px', '200px', '250px', '150px', '150px', '250px', '180px', '230px', '100px'];
				this.column_settings = [
					{ primaryKey: 'id', header: '#' },
					{ primaryKey: 'created_at', header: 'Requested on' },
					{ primaryKey: 'product_name', header: 'Product name', image: true },
					{ primaryKey: 'quantity', header: 'Qty' },
					{ primaryKey: 'package_value', header: 'Package' },
					{ primaryKey: 'shipping_address', header: 'Shipping address' },
					{ primaryKey: 'timeframe', header: 'Timeframe' },
					{ primaryKey: 'payment_type', header: 'Payment type', payment_status: true },
					{ primaryKey: '', header: 'Action', action: true }
				];
				break;
			case "EXPIRED":
				this.widthConfig = ['20px', '200px', '250px', '150px', '150px', '250px', '180px', '230px', '100px'];
				this.column_settings = [
					{ primaryKey: 'id', header: '#' },
					{ primaryKey: 'created_at', header: 'Requested on' },
					{ primaryKey: 'product_name', header: 'Product name', image: true },
					{ primaryKey: 'quantity', header: 'Qty' },
					{ primaryKey: 'package_value', header: 'Package' },
					{ primaryKey: 'shipping_address', header: 'Shipping address' },
					{ primaryKey: 'timeframe', header: 'Timeframe' },
					{ primaryKey: 'payment_type', header: 'Payment type', payment_status: true },
					{ primaryKey: '', header: 'Action', action: true }
				];
				break;
			case "CLOSED":
				this.widthConfig = ['20px', '200px', '250px', '150px', '150px', '250px', '180px', '230px', '250px', '200px', '100px'];
				this.column_settings = [
					{ primaryKey: 'id', header: '#' },
					{ primaryKey: 'created_at', header: 'Requested on' },
					{ primaryKey: 'product_name', header: 'Product name', image: true },
					{ primaryKey: 'quantity', header: 'Qty' },
					{ primaryKey: 'package_value', header: 'Package' },
					{ primaryKey: 'shipping_address', header: 'Shipping address' },
					{ primaryKey: 'timeframe', header: 'Timeframe' },
					{ primaryKey: 'payment_type', header: 'Payment type', payment_status: true },
					{ primaryKey: 'total_price', header: 'Quote price', other_text: '(Excluding GST)' },
					{ primaryKey: 'estimated_delivery_date', header: 'Expected delivery' },
					{ primaryKey: '', header: 'Action', action: true }
				];
				break;
		}
		this.currentPage = 1;
		this.pageNo = 1;
		this.pagesize = this.paginationVal;
		this.itemsPerPage = this.paginationVal;
		this.getListData();

	}


	filterShowButton() {
		this.showFilter = !this.showFilter;
	}


	search() {
		// Check if the search text is empty or consists of only whitespace
		if (this.searchText && this.searchText.trim() === '') {
			this.searchText = null;
			return;
		}
		// Check if the current search text is the same as the last one
		if (this.searchText === this.lastSearchText) {
			// Return or display a message indicating that the same search text is already applied
			return;
		}
		// Update the last search text
		this.lastSearchText = this.searchText;

		// Clear previous search criteria
		this.searchObj = {};

		// Add new search text if available
		if (this.searchText) {
			this.searchObj['search'] = this.searchText.trim();
			this.getListData();
		}
	}


	// search text parameter in api

	onSearch() {
		const currentSearchCriteria = {
			searchText: this.searchText,
			paymentType: this.paymentType,
			fromDate: this.fromDate,
			toDate: this.toDate
		};
		// Check if the current search criteria is the same as the last one
		if (
			currentSearchCriteria.searchText === this.lastSearchCriteria.searchText &&
			currentSearchCriteria.paymentType === this.lastSearchCriteria.paymentType &&
			currentSearchCriteria.fromDate === this.lastSearchCriteria.fromDate &&
			currentSearchCriteria.toDate === this.lastSearchCriteria.toDate
		) {
			// Return or display a message indicating that the same search criteria are already applied
			return;
		}

		// Update the last search criteria
		this.lastSearchCriteria = currentSearchCriteria;

		this.searchObj = {};
		if (this.searchText) {
			this.searchObj['search'] = this.searchText.trim();
		}
		if (this.paymentType) {
			this.searchObj['payment_status'] = this.paymentType;
		}
		if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
			if (!this.fromDate) {
				this.toasterService.errorToast("Please select From Date");
			}
			if (!this.toDate) {
				this.toasterService.errorToast("Please select To Date");
			}
			return;
		}
		if (this.fromDate && this.toDate) {
			this.searchObj['from_date'] = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
			this.searchObj['to_date'] = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
		}
		if (Object.keys(this.searchObj).length > 0) {
			this.filterApplied = true;
			this.getListData();
			this.modalController.dismiss()
		}
	}


	closeFilter() {
		if (Object.keys(this.searchObj).length > 0) {
			this.searchObj = {}
			this.searchText = null;
			this.fromDate = null;
			this.toDate = null;
			this.paymentType = null;
			this.pageNo = 1;
			this.pagesize = 10;
			this.getListData();
			this.currentPage = 1;
			this.filterApplied = false;
			this.modalController.dismiss()
		} else {
			this.searchText = null;
			this.fromDate = null;
			this.toDate = null;
			this.paymentType = null;
			this.pageNo = 1;
			this.pagesize = 10;
			this.currentPage = 1;
		}
	}

	changeListItem() {
		this.currentPage = 1;
		this.pageNo = 1;
		this.pagesize = this.paginationVal;
		this.itemsPerPage = this.paginationVal
		this.getListData();
	}

	onChange(event): void {
		this.currentPage = event;
		this.pageNo = this.currentPage;
		this.getListData()
	}

	onDateChange(datetime) {
		if (this.fromDate > this.toDate) {
			this.fromDate = this.toDate;
		}
		datetime.cancel(true);
	}

	getListData() {
		let param = {}
		param['page'] = this.pageNo == null ? 1 : this.pageNo;;
		param['limit'] = this.pagesize;
		param['status'] = this.tabVal;
		this.commonService.localStorageSet("status", this.tabVal)
		if (this.searchObj) {
			param = {
				...param,
				...this.searchObj
			}
		}
		this.isQuoteLoading = true;
		this.listOfAllData = [];
		this.subscription.add(
			this.dataService.get(URLS.customerQuotesList, param).subscribe(data => {
				this.isQuoteLoading = false;
				if (data['code'] == 200) {
					if (data['data']['manageQuoteList'].length > 0) {
						if (this.tabVal == QuoteType.RECEIVED) {
							this.widthConfig = ['20px', '200px', '250px', '150px', '150px', '250px', '180px', '230px', '250px', '200px', '180px', '380px'];
						}
						if (this.tabVal == QuoteType.ORDER_INITIATED) {
							this.widthConfig = ['20px', '200px', '300px', '150px', '150px', '250px', '180px', '230px', '300px'];
						}
						this.listOfAllData = data['data']['manageQuoteList'];
						this.totalCount = data['data']['total_count'];
						this.listOfAllData.forEach((element, index) => {
							element['timeframe_no'] = element['timeframe'];
							element['id'] = element['order_item_id'];
							element['index'] = (this.pageNo - 1) * this.pagesize + index + 1;
							if (this.tabVal == QuoteType.REQUESTED) element['requested_quote_id'] = element['quote_id'];
							else delete element['requested_quote_id'];
							let unit = element['unit_name'] ? element['unit_name'] : '--'
							element['quantity'] = element['quantity'];
							element['package_value'] = element['package_value'] ? element['package_value'] + " " + unit : unit
							element['timeframe'] = element['timeframe'] + ' hrs';
							element['payment_type'] = element['is_credit_order'] == 1 ? "On credit" : "Instant payment";
							element['payment_status_type'] = element['is_credit_order'] == 1 ? "Warning" : "success";
							element["view"] = true;
							element['img'] = element['product_image'] ? element['product_image'] : '/assets/product/product7.svg';
							element["viewLink"] = '/home/account-details/my-quote-requests';
							element["viewDetailLink"] = '/home/account-details/my-orders/my-order-details';
							element['created_at'] = this.datePipe.transform(element["created_at"], 'dd-MM-yyyy h:mm a');
							element['endDate'] = this.datePipe.transform(element["end_date"], 'dd-MM-yyyy h:mm a');
							element['expiredAt'] = this.datePipe.transform(element["expired_at"], 'dd-MM-yyyy h:mm a');
							element['estimated_delivery_date'] = this.datePipe.transform(element["estimated_delivery_date"], 'dd-MM-yyyy');
							element["isCustomBtnText"] = this.tabVal == QuoteType.ORDER_INITIATED ? "View Order" : false;
							if (element['is_credit_order'] == 1) element["payment_days_left"] = element['credit_days'] + " Days";
							if (this.tabVal != QuoteType.CLOSED && this.tabVal != QuoteType.EXPIRED && this.tabVal != QuoteType.REQUESTED) element["isCustomBtn"] = true;
							if (this.tabVal == QuoteType.ORDER_INITIATED) element['isWhiteBtn'] = true;
							if (element["total_price"]) element["total_price"] = this.commonService.currencySymbol + " " + element["total_price"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
							if (this.tabVal == QuoteType.RECEIVED) {
								element['isCustomBtn'] = true;
								element['isCustomBtnText'] = 'Place Order'
								element['isReBidBtnText'] = 'Re-Bid'
								element['type'] = 'PLACE_ORDER'
							}
							if (element['end_date']) {
								let remainingTime;
								remainingTime = new Date(element['end_date'].toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' }));
								element['remaining_time'] = this.datePipe.transform(remainingTime, 'YYYY/MM/dd HH:mm:ss');
							}
							if (element['start_date']) {
								let startTime;
								startTime = new Date(element['start_date'].toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' }));
								element['start_time'] = this.datePipe.transform(startTime, 'YYYY/MM/dd HH:mm:ss');
							}

						});
					}
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.isQuoteLoading = false;
				this.toasterService.errorToast(error['message']);

			})
		)

	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
	closeFilterModal() {
		this.modalController.dismiss();
	}

}
