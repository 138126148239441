import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { InfiniteScrollCustomEvent, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { LocationService } from '../../services/location.service';
import { ModalService } from '../../services/modal.service';
import { ToasterService } from '../../services/toaster.service';
import { DeliveryAddressComponent } from '../delivery-address/delivery-address.component';
import { URLS } from './../../_config/api.config';

@Component({
    selector: 'app-product-search',
    templateUrl: './product-search.component.html',
    styleUrls: ['./product-search.component.scss'],
})
export class ProductSearchComponent implements OnInit {

    public searchValue = '';
    public isSearched = false;
    public searchPage = 1;
    public searchLimit = 10;
    public productList = [];
    public searchedArray: any = [];
    public categoryList = [];
    public brandList = [];
    public noMoreProduct = false;
    public noMoreCategory = false;
    public noMoreBrand = false;
    public isMoreData = false;
    public isLoading = false;
    public totalArray = [];

    private subscription: Subscription = new Subscription();

    constructor(
        private toasterService: ToasterService,
        private dataService: ApiService,
        public commonService: CommonService,
        private navCtrl: NavController,
        private locationService: LocationService,
        private modalService: ModalService,
        private location: Location
    ) { }


    ngOnInit() { }

    onSearch(infiniteScrollCalled?) {
        if (this.searchValue) {
            const params = {
                search: this.searchValue ? this.searchValue.trim() : null,
                page: this.searchPage,
                limit: this.searchLimit
            }
            if (!infiniteScrollCalled) {
                this.isLoading = true;
            }
            this.subscription.add(
                this.dataService.get(URLS.search, params).subscribe(data => {
                    if (data['code'] == 200 && data['data']) {
                        let searchData = data['data'];
                        let productData = [];
                        let categoryData = [];
                        let brandData = [];
                        this.productList = [];
                        this.brandList = [];
                        this.categoryList = [];
                        if (!infiniteScrollCalled) {
                            this.searchedArray = [];
                            this.totalArray = [];
                            this.isMoreData = true;
                        }
                        if (searchData && searchData.length) {
                            searchData.forEach(element => {
                                if (element.product_list && element.product_list.length) {
                                    this.noMoreProduct = false;
                                    element.product_list.forEach(element => {
                                        let productObj = {
                                            product_name: element.product_name,
                                            product_id: element.product_id,
                                            product_brand_mapping_id: element.product_brand_mapping_id ? element.product_brand_mapping_id : null,
                                            product_package_mapping_id: element.product_package_mapping_id ? element.product_package_mapping_id : null
                                        }
                                        productData.push(productObj);
                                        this.productList.push(productObj);
                                    });
                                }
                                if (element.categoryList && element.categoryList.length) {
                                    this.noMoreCategory = false;
                                    element.categoryList.forEach(element => {
                                        let categoryObj = {
                                            category_name: element.name,
                                            category_id: element.category_id
                                        }
                                        categoryData.push(categoryObj);
                                        this.categoryList.push(categoryObj);
                                    });
                                }
                                if (element.brandList && element.brandList.length) {
                                    this.noMoreBrand = false;
                                    element.brandList.forEach(element => {
                                        let brandObj = {
                                            brand_name: element.brand_name,
                                            brand_id: element.brand_id
                                        }
                                        brandData.push(brandObj);
                                        this.brandList.push(brandObj);
                                    });
                                }
                            });

                            if (productData && productData.length) {
                                this.noMoreProduct = false;
                            } else {
                                this.noMoreProduct = true;
                            }

                            if (categoryData && categoryData.length) {
                                this.noMoreCategory = false;
                            } else {
                                this.noMoreCategory = true;
                            }

                            if (brandData && brandData.length) {
                                this.noMoreBrand = false;
                            } else {
                                this.noMoreBrand = true;
                            }

                            this.totalArray = [...this.productList, ...this.categoryList, ...this.brandList];
                            if (this.totalArray && this.totalArray.length) {
                                this.totalArray.forEach(element => {
                                    let obj = {
                                        product_name: element.product_name ? element.product_name : element.category_name ? element.category_name : element.brand_name ? element.brand_name : "",
                                        product_type: element.product_name ? "in Products" : element.category_name ? "in Categories" : element.brand_name ? "in Brands" : "",
                                        product_id: element.product_id ? element.product_id : null,
                                        category_id: element.category_id ? element.category_id : null,
                                        brand_id: element.brand_id ? element.brand_id : null,
                                        product_brand_mapping_id: element.product_brand_mapping_id ? element.product_brand_mapping_id : null,
                                        product_package_mapping_id: element.product_package_mapping_id ? element.product_package_mapping_id : null
                                    }
                                    this.searchedArray.push(obj);
                                });
                            }
                            if (!infiniteScrollCalled) {
                                this.isLoading = false;
                            }
                        }
                    }

                }, error => {
                    this.toasterService.errorToast(error['message']);
                    this.isLoading = false;
                })
            )
        } else {
            this.productList = [];
            this.searchedArray = [];
            this.totalArray = [];
            this.categoryList = [];
            this.brandList = [];
        }
    }

    onSearchInput(event) {
        if (event.target.value) {
            this.isSearched = true;
            this.searchValue = event.target.value
        } else {
            this.isSearched = false;
            this.searchedArray = [];
            this.totalArray = [];
            this.searchPage = 1;
        }
    }

    onSearchClear() {
        this.searchedArray = [];
        this.totalArray = [];
        this.searchPage = 1;
    }

    onIonInfinite(ev) {
        if (this.noMoreProduct && this.noMoreCategory && this.noMoreBrand) {
            this.isMoreData = false;
            return;
        } else {
            setTimeout(() => {
                this.isMoreData = true;
                this.searchPage++;
                this.onSearch(true);
                (ev as InfiniteScrollCustomEvent).target.complete();
            }, 1000);
        }
    }

    goBack() {
        this.location.back();
    }

    async searchNavigation(data) {
        if (data.product_id) {
            let shipping_address_id = null;
            let deliverLocation = this.commonService.localStorageGet('deliverLocation');
            if (deliverLocation) {

                let categoryName = this.commonService.trimUrl(data.category_name);
                let productName = this.commonService.trimUrl(data.product_name);


                await this.navCtrl.navigateRoot('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                    {
                        animated: false,
                        "state": {
                            "breadcrumb": data.type,
                            "url": '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                            "productDetail": true,
                            productData: data
                        }
                    });
                let params = {
                    "product_id": +data.product_id,
                    "user_id": this.commonService.localStorageGet("user_id") ? +this.commonService.localStorageGet("user_id") : null,
                    "product_brand_mapping_id": data.product_brand_mapping_id,
                    "product_package_mapping_id": data.product_package_mapping_id
                }
                this.searchedProductAdd(params);
            }
            else {
                if (!this.commonService.isLoggedIn) {
                    if (data.product_id) {
                        if (deliverLocation) {
                            let categoryName = this.commonService.trimUrl(data.category_name);
                            let productName = this.commonService.trimUrl(data.product_name);

                            await this.navCtrl.navigateRoot('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                                {
                                    animated: false,
                                    "state": {
                                        "breadcrumb": data.type,
                                        "url": '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                                        "productDetail": true,
                                        productData: data
                                    }
                                });
                            let params = {
                                "product_id": +data.product_id,
                                "product_brand_mapping_id": data.product_brand_mapping_id,
                                "product_package_mapping_id": data.product_package_mapping_id
                            }
                            this.searchedProductAdd(params);

                        } else {
                            await this.getAddress(data)
                        }
                    }
                }
            }
        }
        if (data.category_id || data.brand_id) {
            let category_brand_name = data.category_id ? this.commonService.trimUrl(data.category_name) : this.commonService.trimUrl(data.brand_name);

            await this.navCtrl.navigateRoot('/home/product-listing/' + category_brand_name,
                {
                    animated: false, "state": {
                        "breadcrumb": "Product List",
                        "url": '/home/product-listing/' + category_brand_name,
                        stateData: data
                    }
                })
            let searchObj = {}
            if (data.category_id) {
                searchObj['category_id'] = data.category_id,
                    searchObj['isDataCalled'] = true
            }
            if (data.brand_id) {
                searchObj['brand_id'] = data.brand_id,
                    searchObj['isDataCalled'] = true
            }
            this.commonService.filterDataEmit(searchObj);

        }
        this.productList = [];
        this.searchedArray = [];
        this.totalArray = [];
        this.categoryList = [];
        this.brandList = [];
        this.searchValue = "";
    }

    searchedProductAdd(params) {
        this.subscription.add(
            this.dataService.post(URLS.searchedProductAdd, params).subscribe(data => {
                if (data['code'] == 200) {
                }
            }, error => {
                this.toasterService.errorToast(error['message']);
            })
        )
    }

    getAddress(productDat) {
        let params: any;
        if (this.commonService.isLoggedIn && this.commonService.userData && this.commonService.userData.customer_type != 'INDIVIDUAL') {
            params = {
                "user_type": "CUSTOMER",
                "company_id": this.commonService.localStorageGet('company_id')
            }
            this.subscription.add(
                this.dataService.get(URLS.getAddress, params).subscribe(data => {
                    if (data['code'] == 200) {
                        if (data['data']['default_shipping']) {
                            let defaultAddress = data['data']['default_shipping'];
                            this.locationService.latitude = defaultAddress['latitude'];
                            this.locationService.longitude = defaultAddress['longitude'];
                            const deliveryLocationData = {
                                address: data['data']['default_shipping'],
                                address_type_id: defaultAddress['latitude'],
                                latitude: defaultAddress['latitude'],
                                longitude: defaultAddress['longitude'],
                                state: defaultAddress.state,
                                state_id: defaultAddress.state_id
                            }
                            this.commonService.localStorageSet('deliverLocation', JSON.stringify(deliveryLocationData))
                            this.commonService.localStorageSet('shipping_address_id', defaultAddress['company_address_id'] ? defaultAddress['company_address_id'] : defaultAddress['user_address_id']);
                            if (data.product_id) {
                                let categoryName = this.commonService.trimUrl(data.category_name);
                                let productName = this.commonService.trimUrl(data.product_name);


                                this.navCtrl.navigateRoot('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                                    {
                                        animated: false,
                                        "state": {
                                            "breadcrumb": data.type,
                                            "url": '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                                            "productDetail": true,
                                            productData: data
                                        }
                                    });
                            }
                        }
                    }

                }, error => {
                    this.toasterService.errorToast(error['message']);

                })
            )
        } else {
            this.addressPopUp(productDat);
        }
    }

    async addressPopUp(data) {
        let obj = {
            component: DeliveryAddressComponent,
            backdropDismiss: true,
            canDismiss: true,
            cssClass: 'delivery-modal',

        };
        let modal = this.modalService.openModal(obj);
        modal.then(res => {
            if (res['data'] && res['data']['address']) {
                if (data.product_id) {
                    let categoryName = this.commonService.trimUrl(data.category_name);
                    let productName = this.commonService.trimUrl(data.product_name);


                    this.navCtrl.navigateRoot('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                        {
                            animated: false,
                            "state": {
                                "breadcrumb": data.type,
                                "url": '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                                "productDetail": true,
                                productData: data
                            }
                        });
                }
            }
        }, error => {
            console.log(error);
        });

    }

}
