
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Subscription, interval, map } from 'rxjs';
import { ActionConfirmModalComponent } from 'src/app/modal/action-confirm-modal/action-confirm-modal.component';
import { PaymentGatewayWaitingModalComponent } from 'src/app/payment-gateway-waiting-modal/payment-gateway-waiting-modal.component';
import { CartService } from 'src/app/services/cart.service';
import { ModalService } from 'src/app/services/modal.service';
import { environment } from 'src/environments/environment';
import { profileTypeEnum } from '../../_common/enum.enum';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ToasterService } from '../../services/toaster.service';
import { AddQuoteComponent } from '../add-quote/add-quote.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { EscrowComponent } from '../escrow/escrow.component';
import { RejectProductComponent } from '../reject-product/reject-product.component';
import { ColumnMap } from './../../_common/layout.model';
import { URLS } from './../../_config/api.config';

declare var EasebuzzCheckout: any; // Declare EasebuzzCheckout variable

@Component({
	selector: 'app-table-listing',
	templateUrl: './table-listing.component.html',
	styleUrls: ['./table-listing.component.scss'],
})
export class TableListingComponent implements OnInit, OnDestroy {
	@Input() listOfAllData: any = [];
	@Input() tableHeight = '';
	@Input() columnKey: any = [];
	@Input() settings: any[];
	@Input() isSearch = false;
	@Input() isFilter = false;
	@Input() widthConfig = [];
	@Input() isStickyColumn = false;
	@Input() isStickyStatusActionColumn = false;
	@Input() isClose = false;
	@Input() isBtn = false;
	@Input() pageType = '';
	@Input() pageIndex?: number = 1;
	@Input() pageIndexManageOrder?: number = 1;
	@Input() pageIndexMyOrder?: number = 1;
	@Input() totalCount = 0;
	@Input() checkoutAddress = false;
	@Input() selectedAddress: any;
	@Input() userId;
	@Input() selectedTab?;
	@Input() isLoading = true;
	@Output() sortByEvent = new EventEmitter<any>();
	count = 0;
	addresCheck;
	profileEnum = profileTypeEnum;
	public subscription = new Subscription();
	timerSubscription = new Subscription();
	formattedTime: any;
	// column key
	columnMaps: ColumnMap[];
	targetTime: Date;
	remainingTime: number;
	timer: any;

	public seconds;
	public milliseconds;
	public startDate;
	public endDate;
	public zz;
	countdown: string;
	toggleUpdating: boolean;
	isToggleDisable: boolean = false
	subCategorySelected: boolean = false;
	allSelected: boolean = false;
	allSubProductSelected: boolean = false;
	selectedCount: number = 0;

	enableButtonDisabled: boolean = false;
	disableButtonDisabled: boolean = false;
	toggleValue: boolean = false;

	constructor(
		private router: Router,
		public commonService: CommonService,
		private dataService: ApiService,
		private toasterService: ToasterService,
		public alertCtrl: AlertController,
		private modalCtrl: ModalController,
		private navCtrl: NavController,
		private modalService: ModalService,
		private cartService: CartService,
		private datePipe: DatePipe,
		public getPlatformService: GetPlatformService

	) {
		setTimeout(() => {
			this.targetTime = new Date();
			this.listOfAllData.forEach((element, index) => {
				if (element.quote_id && !element.requested_quote_id && !element['manage_quote_id']) {
					this.targetTime = new Date(element.expired_at)
					this.startDate = new Date();
					this[`remainingTime${element.quote_id}`] = this.subtractDates(this.startDate, this.targetTime);
					element.remainingTime = this.remainingTime;
					this.startTimerOrder(this.targetTime, element.quote_id);
				}
				if (element.order_item_id && element.remaining_time) {
					this.startDate = new Date();
					this.endDate = new Date(element.remaining_time);
					this[`remainingTime${element.order_item_id}`] = this.subtractDates(this.startDate, this.endDate);
					this.startTimerOrder(this.endDate, element.order_item_id);
				}
				if (element.requested_quote_id || element['manage_quote_id']) {
					this.startDate = new Date(element.start_time);
					this.endDate = new Date(element.remaining_time);
					if (new Date() < this.startDate) {
						this[`remainingTime${element.quote_id}`] = element.timeframe_no + ':00:00';
					}
					else {
						this[`remainingTime${element.quote_id}`] = this.subtractDates(this.startDate, this.endDate);
						this.startTimerOrder(this.endDate, element.quote_id, index);
					}

				}

			});

		}, 500);
		this.commonService.eventObservable.subscribe((data: any) => {
			if (this.pageType == 'manage_product') {
				this.checkProductStatus()
			}
			if (data.event == 'subCategorySelected') {
				this.subCategorySelected = data.data
			}
			if (data.event == 'allProductToggleClicked') {
				this.allSelected = false
				this.selectedCount = 0
				this.commonService.setEvent("enableAllSelectedToggle", false);
			}
		})


	}


	checkProductStatus(): void {
		const allProductsActive = this.listOfAllData.every(product => product.is_active === 1);
		const allProductsInactive = this.listOfAllData.every(product => product.is_active !== 1);
		const anyInactive = this.listOfAllData.some(product => product.is_active === 0);
		this.toggleValue = !anyInactive;

		if (allProductsActive) {
			this.enableButtonDisabled = true;
			this.disableButtonDisabled = false;
		} else if (allProductsInactive) {
			this.enableButtonDisabled = false;
			this.disableButtonDisabled = true;
		} else {
			this.enableButtonDisabled = false;
			this.disableButtonDisabled = false;
		}
	}

	startTimerOrder(targetTime: Date, id, ind?) {
		this.targetTime = targetTime;
		this.timerSubscription = interval(1000)
			.pipe(
				map(() => this.calculateCountdown(targetTime))
			)
			.subscribe((countdown) => {
				this[`remainingTime${id}`] = countdown;
			});
	}

	private calculateCountdown(targetTime): string {
		const now = new Date();
		const timeDifference = targetTime.getTime() - now.getTime();

		if (timeDifference <= 0) {
			return '00:00:00';
		}

		const seconds = Math.floor(timeDifference / 1000);
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);
		const remainingSeconds = seconds % 60;

		return `${this.padWithZero(hours)}:${this.padWithZero(minutes)}:${this.padWithZero(remainingSeconds)}`;
	}

	private padWithZero(value: number): string {
		return value < 10 ? `0${value}` : value.toString();
	}


	public subtractDates(startDate: Date, endDate: Date): string {
		let dateDiff = (endDate.getTime() - startDate.getTime()) / 1000;

		var h = Math.floor(dateDiff / 3600);
		var m = Math.floor((dateDiff % 3600) / 60);
		var s = Math.floor((dateDiff % 3600) % 60);
		var hDisplay = h > 0 ? h.toString().padStart(2, '0') + ':' : '00:';
		var mDisplay = m > 0 ? m.toString().padStart(2, '0') + ':' : '00:';
		var sDisplay = s > 0 ? s.toString().padStart(2, '0') + '' : '00';

		let dateString = hDisplay + mDisplay + sDisplay;
		return dateString;
	}

	ngOnInit() {
		if (this.settings) {
			this.columnMaps = this.settings.map(col => new ColumnMap(col));
		} else {
			this.columnMaps = Object.keys(this.listOfAllData[0]).map(key => {
				return new ColumnMap({ primaryKey: key });
			});
		}

		setTimeout(() => {
			this.isLoading = false
		}, 1000)

		if (this.pageType == 'customer_address') {
			this.addresCheck = this.selectedAddress.user_address_id || null;
		}

	}

	calculateRemainingTime(targetTime) {
		const currentTime = new Date();
		return targetTime.getTime() - currentTime.getTime();
	}

	ionViewWillEnter() {
		if (this.settings) {
			this.columnMaps = this.settings.map(col => new ColumnMap(col));
		} else {
			this.columnMaps = Object.keys(this.listOfAllData[0]).map(key => {
				return new ColumnMap({ primaryKey: key });
			});
		}
	}


	startTimer(remainingTime, targetTime, id) {
		this.timer = setInterval(() => {
			this[`remainingTime${id}`] = this.calculateRemainingTime(targetTime);
			if (remainingTime <= 0) {
				clearInterval(this.timer);
			}
		}, 1000);
	}


	formatTime(time: number): string {
		const hours = Math.floor(time / 3600000);
		const minutes = Math.floor((time % 3600000) / 60000);
		const seconds = Math.floor((time % 60000) / 1000);
		return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
	}

	padZero(num: number): string {
		return num < 10 ? `0${num}` : `${num}`;
	}

	async editData(record) {
		this.commonService.localStorageSet('pageIndex', this.pageIndex);

		if (this.pageType == 'customer_address') {
			this.navCtrl.navigateForward(['/home/account-details/addresses/edit-address', record.address_id])
		}
		else if (this.pageType == 'manage_user') {
			this.navCtrl.navigateForward(['/home/account-details/manage-user/edit-user', record.id]);
		}
		else if (this.pageType == 'manage_product') {
			this.commonService.localStorageSet("isDraft", record.is_draft)
			if (record.is_draft || record.is_draft == 1) {
				this.navCtrl.navigateForward(['/home/account-details/manage-products/edit-step-2', record.seller_product_id]);
			}
			else {
				if (record.seller_product_id) {
					let categoryName = this.commonService.trimUrl(record.category_name);
					let productName = this.commonService.trimUrl(record.product_name);

					this.navCtrl.navigateRoot('/home/product-detail/' + categoryName + "/" + productName + "/" + record.seller_product_id,
						{
							animated: false,
							"state": {
								"breadcrumb": record.category_name,
								"url": '/home/product-detail/' + categoryName + "/" + productName + "/" + record.seller_product_id,
								"productDetail": true,
								productData: record,
								sellerDetail: true
							}
						});
				}
			}

		}
		else if (this.pageType == 'manage_area') {
			this.navCtrl.navigateForward(['/home/account-details/manage-operational-area/edit-area', record.id]);
		}
		else if (this.pageType == 'manage_quotes') {
			const modal = await this.modalCtrl.create({
				component: AddQuoteComponent,
				id: "requestQuoteModal",
				backdropDismiss: true,
				componentProps: {
					quote_id: record.quote_id,
					price: record.total_price,
					quote_details: record,
					delivery_date: record.esti_delivery_date,
					isEdit: true,
					quoteBidId: record.quote_bid_id
				}
			});
			modal.present();
			modal.onWillDismiss().then((data) => {
				if (data['data']) {
					let index = this.listOfAllData.findIndex(obj => obj.quote_id === data['data']['quote_id']);
					if (data['data'] && data['data']['quote_id']) {
						this.listOfAllData.splice(index, 1);
						this.commonService.setEvent(this.pageType, true)
					}
				}
			})

		}
		else if (this.pageType == 'manage_vehicles') {
			this.navCtrl.navigateForward(['/home/account-details/manage-vehicles/edit-vehicle', record.id]);
		}
		this.commonService.scrollToTop();

	}

	adminToggle(record) {
		let params = {};
		let apiURL = '';
		if (this.pageType == 'manage_user') {
			apiURL = URLS.adminToggle
			params = {
				enterprise_user_id: record.id,
				is_admin_user: record.is_admin == 0 ? true : false
			}
		}
		if (Object.keys(params).length != 0) {
			this.admintoggleData(apiURL, params);
		}

	}

	admintoggleData(apiURL, params) {
		this.subscription.add(
			this.dataService.put(apiURL, params).subscribe(data => {
				if (data['code'] == 200) {
					if (data['data']) {
						this.toasterService.successToast(data['message']);
						this.commonService.setEvent("adminToggleUpdate", true);
					}
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.toasterService.errorToast(error['message']);

			})
		)
	}

	userToggle(record) {
		this.commonService.localStorageSet('pageIndex', this.pageIndex);
		if (this.toggleUpdating == true) {
			return;
		}

		this.toggleUpdating = true;
		this.isToggleDisable = true;
		let params = {};
		let apiURL = '';
		let methodType = 'PUT';
		if (this.pageType == 'manage_user') {
			apiURL = URLS.userToggle;
			params = {
				enterprise_user_id: record.id,
				is_active: record.isActive == 0 ? true : false
			}
		}
		else if (this.pageType == 'manage_product') {
			methodType = 'PATCH';
			apiURL = URLS.sellerStatusUpdate;
			this.commonService.setEvent("isSelectAllToggleDisable", true)
			if (record.is_active == 0) {
				params = {
					"enabled_data": {
						"seller_product_ids": [
							record.id
						],

						"is_active": true
					},
				}
			} else {
				params = {
					"disabled_data": {
						"seller_product_ids": [
							record.id
						],

						"is_active": false
					},
				}
			}
		}
		else if (this.pageType == 'manage_area') {
			methodType = 'PUT';
			apiURL = URLS.optionalAreaStatus;
			params = {
				seller_operational_area_id: record.id,
				is_active: record.isActive == 0 ? true : false
			}
		}
		else if (this.pageType == 'manage_vehicles') {
			methodType = 'PUT';
			apiURL = URLS.vehicleUpdateToggle;
			params = {
				vehicle_id: record.id,
				is_active: record.isActive == 0 ? true : false
			}
		}
		if (methodType == 'PUT') {
			if (Object.keys(params).length != 0) {
				this.subscription.add(
					this.dataService.put(apiURL, params).subscribe(data => {

						if (data['code'] == 200) {
							if (data['data']) {
								this.toasterService.successToast(data['message']);
								setTimeout(() => {
									this.isToggleDisable = false
									this.toggleUpdating = false;
									this.commonService.setEvent(this.pageType, true);
								}, 3010)
							}
						}
						else {
							record.isActive = !record.isActive;
							this.toasterService.errorToast(data['message']);
							setTimeout(() => {
								this.isToggleDisable = false
								this.toggleUpdating = false;
							}, 3010)
						}
					}, error => {
						record.isActive = !record.isActive;
						this.toasterService.errorToast(error['message']);
						setTimeout(() => {
							this.toggleUpdating = false;
							this.isToggleDisable = false
						}, 3010)

					})
				)
			}
		}
		else if (methodType === 'PATCH') {
			if (Object.keys(params).length != 0) {
				this.subscription.add(
					this.dataService.patch(apiURL, params).subscribe(data => {
						if (data['code'] == 200) {
							if (data['data']) {
								this.toasterService.successToast(data['message']);
								setTimeout(() => {
									this.toggleUpdating = false;
									this.isToggleDisable = false
									this.allSelected = false
									this.selectedCount = 0
									this.commonService.setEvent(this.pageType, true);
								}, 3010)
							}
						}
						else {
							record.isActive = !record.isActive;
							this.toasterService.errorToast(data['message']);
							setTimeout(() => {
								this.toggleUpdating = false;
								this.isToggleDisable = false
							}, 3010)
						}
					}, error => {
						record.isActive = !record.isActive;
						this.toasterService.errorToast(error['message']);
						setTimeout(() => {
							this.toggleUpdating = false;
							this.isToggleDisable = false
						}, 3010)

					})
				)
			}
		}
	}

	async deleteConfrm(data, i) {
		let message = '';
		let deleteApiURL = '';
		let deleteParam = {};
		let userType = this.commonService.localStorageGet('user_type') ? this.commonService.localStorageGet('user_type') : null
		let loginType = this.commonService.userData && this.commonService.userData['isCustomer'] ? this.profileEnum.Customer : this.profileEnum.Seller;
		if (this.pageType == 'manage_area') {
			message = 'You want to delete this area?';
			deleteApiURL = URLS.deleteOperationalArea;
			deleteParam = {
				seller_operational_area_id: data.id,
			}
		}
		else if (this.pageType == 'customer_address') {
			message = 'You want to delete this address?';
			deleteApiURL = URLS.deleteAddress;
			if (data.address_id) {
				let customerType = this.commonService.localStorageGet('customer_type');
				if (customerType != 'INDIVIDUAL') {
					deleteParam['company_address_id'] = data.address_id;
				} else {
					deleteParam['user_address_id'] = data.address_id;
				}
			}
			let shipping_address_id = this.commonService.localStorageGet('shipping_address_id');
			if ((data.address_id == shipping_address_id && this.cartService.cartCount > 0) || (!shipping_address_id && this.cartService.cartCount > 0)) {
				let obj = {
					component: ActionConfirmModalComponent,
					backdropDismiss: false,
					canDismiss: true,
					cssClass: 'success-modal',
					componentProps: {
						title: "Delete Address",
						description: "Product avaialble in cart for this shipping address, do you want to clear cart first?",
						modalImage: "/assets/svg/warning.svg",
						submitBtnText: "Yes",
						cancelBtnText: "No",
						hideCloseBtn: true,
						cartAddressPopUp: true,
					}
				};
				let modal = this.modalService.openModal(obj);
				modal.then(obj => {
					if (obj['data']['isDraft']) {
						this.deleteData(deleteParam, i, deleteApiURL, data);
						this.commonService.localStorageRemove('shipping_address_id')
						this.commonService.localStorageRemove('deliverLocation');
					}
				}, error => {
					console.log(error);
				});
				return;

			}
		}
		else if (this.pageType == 'manage_user') {
			deleteApiURL = URLS.deleteUser
			message = 'You want to delete this user?';
			deleteParam = {
				enterprise_user_id: data.id,
				user_type: userType,
				type: loginType
			}
		}
		else if (this.pageType == 'manage_product') {
			deleteApiURL = URLS.deleteProduct + '/' + data.id;
			message = 'You want to delete this product ?';
		}
		const alert = await this.alertCtrl.create({
			header: 'Are you sure? ',
			message: message,
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					handler: () => {
					},
				},
				{
					text: 'Yes',
					role: 'confirm',
					handler: () => {
						if (i == 0) {
							this.pageIndex = this.pageIndex > 1 ? this.pageIndex - 1 : 1
						}
						this.deleteData(deleteParam, i, deleteApiURL, data);
					},
				},
			],
		});
		await alert.present();
		const { role } = await alert.onDidDismiss();

	}

	deleteData(params, index, apiURL, value?) {
		this.commonService.localStorageSet('pageIndex', this.pageIndex);
		this.isLoading = true;
		this.subscription.add(
			this.dataService.delete(apiURL, params ? { params: params } : null).subscribe(data => {
				if (data['code'] == 200) {
					if (this.pageType == 'customer_address') {
						let shipping_address_id = +this.commonService.localStorageGet('shipping_address_id');
						if (value.address_id == shipping_address_id) {
							this.commonService.localStorageRemove('shipping_address_id');
							this.commonService.localStorageRemove('deliverLocation');
						}
						this.commonService.addressData();
					}
					this.listOfAllData.splice(index, 1);
					this.commonService.setEvent(this.pageType, true)
					this.toasterService.successToast(data['message']);

					this.isLoading = false;
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);

			})
		)
	}

	async customBtnClick(data) {
		if (this.pageType == 'manage_quotes' || this.pageType == 'my_quote_request') {
			const link = this.pageType == 'manage_quotes' ? '/home/account-details/manage-quotes' : '/home/account-details/my-quote-requests';
			if (data.type === 'WITHDRAW') {
				let param = {
					quote_id: data.quote_id,
					is_active: false
				};
				const modal = await this.modalCtrl.create({
					component: ConfirmationModalComponent,
					id: "thankYouModal",
					backdropDismiss: true,
					componentProps: {
						description: 'Are You Sure You Want To Withdraw Quotation?',
						param: param,
						apiURL: URLS.quoteWithdrawl,
						link: '/home/account-details/manage-quotes'
					}
				});
				modal.present();
				modal.onWillDismiss().then((data) => {
					if (data['data']) {
						let index = this.listOfAllData.findIndex(obj => obj.quote_id === data['quote_id']);
						this.listOfAllData.splice(index, 1);
						this.commonService.setEvent(this.pageType, true)
						if (this.pageType == 'manage_quotes') {
							this.navCtrl.navigateBack('/home/account-details/manage-quotes')
						}
						else if (this.pageType == 'my_quote_request') {
							this.navCtrl.navigateBack('/home/account-details/my-quote-requests');
						}
					}
				})

			}
			else if (data.type === 'ADD-QUOTE') {
				const modal = await this.modalCtrl.create({
					component: AddQuoteComponent,
					id: "requestQuoteModal",
					backdropDismiss: true,
					componentProps: {
						quote_id: data.quote_id,
						quote_details: data,
					}
				});
				modal.present();
				modal.onWillDismiss().then((data) => {
					if (data['data']) {
						let index = this.listOfAllData.findIndex(obj => obj.quote_id === data['data']['quote_id']);
						if (data['data'] && data['data']['quote_id']) {
							this.listOfAllData.splice(index, 1);
							this.commonService.setEvent(this.pageType, true)
						}
					}
				})
			}
			else if (data.type === 'PLACE_ORDER') {
				this.placeOrderConfirmation(data);
			}
		}


	}


	async acceptRejectOrderRequest(data, type) {
		if (type === 'accept') {
			let obj = {
				component: ActionConfirmModalComponent,
				backdropDismiss: false,
				canDismiss: true,
				cssClass: 'success-modal',
				componentProps: {
					title: "Change Status of Order Item",
					description: "Are you sure you want to accept the request ",
					modalImage: "/assets/svg/shipping-cost.svg",
					submitBtnText: "Yes",
					cancelBtnText: "No",
					orderId: data.order_item_id,
					acceptOrder: true,
					hideCloseBtn: true
				}
			};
			let modal = this.modalService.openModal(obj);
			modal.then(value => {
				if (value.data.isDraft == true) {
					this.commonService.setEvent(this.pageType, true)
				}

			}, error => {
				console.log(error);
			});
		}
		else {
			let obj = {
				component: RejectProductComponent,
				backdropDismiss: false,
				canDismiss: true,
				cssClass: 'success-modal',
				componentProps: {
					title: "Reject Product",
					description: "Please specify reason, Why you are rejecting the product?",
					submitBtnText: "Submit",
					cancelBtnText: "Cancel",
					orderId: data.order_item_id,
					hideCloseBtn: true
				}
			};
			let modal = this.modalService.openModal(obj);
			modal.then(value => {
				if (value.data.isDraft == true) {
					this.navCtrl.navigateForward(['/home/account-details/my-orders'])
					this.commonService.setEvent(this.pageType, true)
				}

			}, error => {
				console.log(error);
			});
		}
	}



	async makePayment(data?) {

		let obj = {
			component: ActionConfirmModalComponent,
			backdropDismiss: false,
			canDismiss: true,
			cssClass: 'success-modal',
			componentProps: {
				title: "Proceed Payment",
				description: "Are you sure you want to proceed with the payment?",
				modalImage: "/assets/svg/warning.svg",
				submitBtnText: "Yes",
				cancelBtnText: "No",
				hideCloseBtn: true
			}
		};
		let modal = this.modalService.openModal(obj);
		modal.then(value => {
			if (value.data.isDraft == true) {

				let obj = {
					component: PaymentGatewayWaitingModalComponent,
					backdropDismiss: false,
					canDismiss: true,
					cssClass: 'success-modal',
				};
				this.modalService.openModal(obj);
				setTimeout(() => {
					this.modalCtrl.dismiss();
					this.paymentAPIMethodCall(data);
				}, 3000)

			}

		}, error => {
			console.log(error);
		});
	}


	paymentAPIMethodCall(data?) {
		let params = {
			"order_id": data.order_id,
			"productinfo": data.product_id.toString(),
		}

		this.subscription.add(
			this.dataService.post(URLS.paymentKey, params).subscribe(response => {
				if (response['code'] == 200) {
					const easebuzzCheckout = new EasebuzzCheckout(environment.paymentKey, environment.paymentMode);
					const options = {
						access_key: response.data['data'],
						onResponse: (response: any) => {
							if (response.status == "success") {
								this.toasterService.successToast(response['error_Message']);
								let obj = {
									component: PaymentGatewayWaitingModalComponent,
									backdropDismiss: false,
									canDismiss: true,
									cssClass: 'success-modal',
								};
								this.modalService.openModal(obj);
								setTimeout(() => {
									this.modalCtrl.dismiss();
									this.router.navigate(['/home/account-details/my-orders']);
									this.commonService.setEvent("paymentRefresh", null)
								}, 3000)

							}
							else if (response.status == "userCancelled") {
								this.toasterService.errorToast("User Cancelled Payment");
								let obj = {
									component: PaymentGatewayWaitingModalComponent,
									backdropDismiss: false,
									canDismiss: true,
									cssClass: 'success-modal',
								};
								this.modalService.openModal(obj);
								setTimeout(() => {
									this.modalCtrl.dismiss();
									this.router.navigate(['/home/account-details/my-orders']);
								}, 3000)
							}
							else if (
								response.status == "pending" ||
								response.status == "failure" ||
								response.status == "dropped" ||
								response.status == "bounced"
							) {
								this.toasterService.errorToast("Payment " + response.status);
								let obj = {
									component: PaymentGatewayWaitingModalComponent,
									backdropDismiss: false,
									canDismiss: true,
									cssClass: 'success-modal',
								};
								this.modalService.openModal(obj);
								setTimeout(() => {
									this.modalCtrl.dismiss();
									this.router.navigate(['/home/account-details/my-orders']);
								}, 3000)

							}
							else {
								this.toasterService.errorToast("Payment " + response.status);
							}
						},
						onclose: (response: any) => { console.log('close') },
						oncancel: (response: any) => { console.log('cancel') },

						theme: '#123456' // color hex
					};

					easebuzzCheckout.initiatePayment(options);
				}
				else {
					this.modalCtrl.dismiss()
					this.toasterService.errorToast(response['message']);
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
				this.modalCtrl.dismiss()
				this.toasterService.errorToast(error['message']);

			})
		)
	}


	async UTR_Number(data) {
		if (this.pageType == 'my_orders') {
			let options: any = {
				id: "escrow-modal",
				backdropDismiss: false,
				canDismiss: true,
				cssClass: 'success-modal'
			};
			options['component'] = EscrowComponent;
			options['componentProps'] = {
				order_Id: data.order_id,
				hideCloseBtn: true,
				submitBtnText: "Submit",
				cancelBtnText: "Cancel",
				cancelBtnLink: "/home/account-details/my-orders",
				modalType: "customer_order"
			};

			let modal = this.modalService.openModal(options)
			modal.then(value => {
				if (value['data']) {
					data['payment_status_type'] = 'wait';
					data['payment_status'] = "Pending Verification";
					data['UTR_Number'] = false;
					data['utr_NumberIcon'] = false;
				}
			}, error => {
				console.log(error);
			});
		}
		else if (this.pageType == 'my_quote_request') {
			let options: any = {
				id: "escrow-modal",
				backdropDismiss: false,
				canDismiss: true,
				cssClass: 'success-modal'
			};
			options['component'] = EscrowComponent;
			options['componentProps'] = {
				order_Id: data.order_id,
				hideCloseBtn: true,
				submitBtnText: "Submit",
				cancelBtnText: "Skip For Now",
				cancelBtnLink: "/home/account-details/my-quote-requests",
				modalType: "customer_order"
			};

			let modal = this.modalService.openModal(options)
			modal.then(value => {
				this.commonService.quoteListingData("ORDER_INITIATED");
				if (value['data']) {
					this.navCtrl.navigateForward("/home/account-details/my-quote-requests");
				}
			}, error => {
				console.log(error);
			});
		}

	}


	placeOrder(val) {
		const params = {
			"quote_id": val.quote_id,
			"quote_bid_id": val.quote_bid_id,
			"seller_id": val.seller_id
		}
		this.subscription.add(
			this.dataService.post(URLS.placeQuoteOrder, params).subscribe(data => {
				if (data['code'] == 200) {
					if (data['data']) {
						let tabValue = "ORDER_INITIATED";
						if (!val.is_credit_order) {
							let paymentObject = {
								'order_id': data['data'].order_id,
								'sub_total': parseFloat(val['total_price'].replace('₹', '').trim()),
								'product_id': val['product_id']
							}

							let obj = {
								component: PaymentGatewayWaitingModalComponent,
								backdropDismiss: false,
								canDismiss: true,
								cssClass: 'success-modal',
							};
							this.modalService.openModal(obj);
							setTimeout(() => {
								this.modalCtrl.dismiss();
								this.paymentAPIMethodCall(paymentObject);
							}, 3000)

						}
						else {
							this.commonService.quoteListingData(tabValue);
							this.navCtrl.navigateForward("/home/account-details/my-quote-requests");
							this.toasterService.successToast(data['message']);
						}

					}
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.toasterService.errorToast(error['message']);

			})
		)
	}

	async placeOrderConfirmation(data) {
		const alert = await this.alertCtrl.create({
			header: 'Are you sure? ',
			message: "You want to place an order?",
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					handler: () => {
					},
				},
				{
					text: 'Yes',
					role: 'confirm',
					handler: () => {
						this.placeOrder(data);
					},
				},
			],
		});
		await alert.present();
		const { role } = await alert.onDidDismiss();
	}

	async updateQuote(recordData) {

		let quantity = +recordData.quantity;
		let param = {
			quote_id: recordData.quote_id,
			is_active: recordData.is_active,
			product_id: recordData.product_id,
			image_url: recordData.product_image,
			address_id: recordData.shipping_address_id ? recordData.shipping_address_id : null,
			product_brand_mapping_id: recordData.product_brand_mapping_id ? recordData.product_brand_mapping_id : null,
			product_package_mapping_id: recordData.product_package_mapping_id ? recordData.product_package_mapping_id : null,
			sku: recordData.product_name,
			unit_name: recordData.unit_name,
			address: recordData.shipping_address,
			quantity: +quantity,
			best_deal_price: recordData.best_deal_price ? recordData.best_deal_price : 0
		};

		if (recordData.sub_product) {
			param['product_attributes'] = recordData.sub_product.product_attributes ? recordData.product_attributes : null
		}

		const modal = await this.modalCtrl.create({
			component: ConfirmationModalComponent,
			id: "thankYouModal",
			backdropDismiss: true,
			componentProps: {
				description: 'Are You Sure You Want To Request Re-Bid?',
				subDescription: 'Your Current Best Deal Price Will Be Discarded Once You Apply For Re-Bid',
				param: param,
				apiURL: URLS.quoteWithdrawl,
				link: '/home/account-details/manage-quotes',
				isRebid: true
			}
		});
		modal.present();
		modal.onWillDismiss().then((data) => {
		})
	}

	viewDetail(link, data) {
		this.commonService.localStorageSet('pageIndex', this.pageIndex);
		if (this.pageType == "my_orders") {
			this.commonService.localStorageSet('pageIndex_my_orders', this.pageIndexMyOrder);
			this.commonService.localStorageSet('pageIndex_manage_orders', 1);
		}
		if (this.pageType == "manage_orders") {
			this.commonService.localStorageSet('pageIndex_my_orders', 1);
			this.commonService.localStorageSet('pageIndex_manage_orders', this.pageIndexManageOrder);
		}
		if (this.pageType == 'manage_vehicles') {
			this.navCtrl.navigateForward(['/home/account-details/manage-vehicles/view-vehicle', data.id],
				{ queryParams: { isView: true } });
		}
		else {
			let breadCrumbLbl = this.selectedTab ? this.selectedTab.replace("_", " ").toLowerCase() + ' Quote #' : 'Quote #';
			let extraPramas = data.quote_id;
			let backUrl = link;
			if (link.includes('order-details')) {
				breadCrumbLbl = this.selectedTab ? this.selectedTab.replace("_", " ").toLowerCase() + " Order #" : "Order #";
				extraPramas = data.id
				backUrl = this.commonService.userData.isCustomer ? 'home/account-details/my-orders' : '/home/account-details/manage-orders'
			}

			this.navCtrl.navigateRoot([link, extraPramas], {
				"state": {
					"breadcrumb": breadCrumbLbl + extraPramas,
					"url": link + extraPramas,
					"back": true,
					"backUrl": backUrl
				},
				"queryParams": { "extraParams": extraPramas },
			});
			this.commonService.localStorageSet("status", data['status']);
		}
	}

	close() {
		this.modalCtrl.dismiss({
			address_id: this.addresCheck
		})
	}

	ngOnDestroy() {
		clearInterval(this.timer);
	}

	sortBy(data, sortBy) {
		if (data.header == "Action" || data.header == "Dispatch location" || data.header == "Live Status") {
			return;
		}
		this.sortByEvent.emit({
			sort_by: data,
			sort_type: sortBy
		});
	}


	selectAll() {
		this.allSelected = !this.allSelected;
		this.selectedCount = this.allSelected ? this.listOfAllData.length : 0;
		this.listOfAllData.forEach((record) => {
			record.selected = this.allSelected;
		});
		if (this.allSelected) {
			this.commonService.setEvent("enableAllSelectedToggle", true);
		} else {
			this.commonService.setEvent("enableAllSelectedToggle", false);
		}
	}

	toggleCheckbox() {
		this.selectedCount = this.listOfAllData.filter(record => record.selected).length;
		this.allSelected = this.selectedCount === this.listOfAllData.length;
		if (this.allSelected) {
			this.commonService.setEvent("enableAllSelectedToggle", true);
		} else {
			this.commonService.setEvent("enableAllSelectedToggle", false);
		}
	}

	async toggleProductEnableOrDisable(state?: any) {
		const enabledSellerProductsId = [];
		const disabledSellerProductsId = [];

		this.listOfAllData.forEach((record) => {
			if (record.selected) {
				enabledSellerProductsId.push(record.seller_product_id);
				disabledSellerProductsId.push(record.seller_product_id);
			}
		});

		let params;
		let message = '';
		if (state == 'enable') {
			message = 'You want to enable the ' + ' selected ' + (this.selectedCount === 1 ? 'product' : 'products') + '?';
			params = {
				"enabled_data": {
					"seller_product_ids": enabledSellerProductsId,
					"is_active": true
				}
			}
		} else if (state == 'disable') {
			message = 'You want to disable the ' + ' selected ' + (this.selectedCount === 1 ? 'product' : 'products') + '?';
			params = {
				"disabled_data": {
					"seller_product_ids": disabledSellerProductsId,
					"is_active": false
				}
			};
		}
		const alert = await this.alertCtrl.create({
			header: 'Are you sure? ',
			message: message,
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					handler: () => {

						this.toggleValue = !this.toggleValue

					},
				},
				{
					text: 'Yes',
					role: 'confirm',
					handler: () => {
						this.isToggleDisable = true;

						this.subscription.add(
							this.dataService.patch(URLS.sellerStatusUpdate, params).subscribe(data => {
								if (data['code'] == 200) {
									if (data['data']) {
										this.toasterService.successToast(data['message']);
										setTimeout(() => {
											this.toggleUpdating = false;
											this.isToggleDisable = false;
											this.commonService.setEvent("productAfterSubCategorySelected", true);
											this.allSelected = false
											this.selectedCount = 0
											this.listOfAllData.forEach((record) => {
												record.selected = false;
											});
										}, 3010);
									}
								} else {
									this.toasterService.errorToast(data['message']);
									setTimeout(() => {
										this.toggleUpdating = false;
										this.isToggleDisable = false;
										this.allSelected = false
										this.selectedCount = 0
										this.listOfAllData.forEach((record) => {
											record.selected = false;
										});
									}, 3010);
								}
							}, error => {
								this.toasterService.errorToast(error['message']);
								setTimeout(() => {
									this.toggleUpdating = false;
									this.isToggleDisable = false;
									this.allSelected = false
									this.selectedCount = 0
									this.listOfAllData.forEach((record) => {
										record.selected = false;
									});
								}, 3010);
							})
						);
					},
				},
			],
		});
		await alert.present();
		const { role } = await alert.onDidDismiss();
	}



	selectAllProductToogle(data?) {
		if (!this.toggleValue) {
			this.toggleProductEnableOrDisable("enable")
		}
		else {
			this.toggleProductEnableOrDisable("disable")
		}
	}

}
