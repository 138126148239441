<div class="home-page app-header">
    <app-header></app-header>
</div>

<ion-content>
    <ion-grid class="page-scroll-top">
        <ion-row [formGroup]="blogFormGroup">
            <ion-col size="12" *ngIf="!commonService.isLoggedIn">
                <app-personal-detail [fieldName]="fieldData" [formGroup]="$any(blogFormGroup)" [placeholder]="'Email'"
                    [isHideVerifiedBtn]="true"></app-personal-detail>
            </ion-col>
            <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
                <ion-label>Title <span
                        [ngClass]="blogFormGroup.get('title').hasError('required') && blogFormGroup.get('title').dirty ? 'error-msg' : ''">*</span></ion-label>
                <ion-input class="pt-20" type="title" formControlName="title" placeholder="Blog title"></ion-input>
                <ng-container *ngFor="let validation of validation_messages.title">
                    <ng-container
                        *ngIf="blogFormGroup.get('title').hasError(validation.type) && blogFormGroup.get('title').dirty">
                        <ion-text color="danger" class="error-msg">
                            {{validation.message}}
                        </ion-text>
                    </ng-container>
                </ng-container>
            </ion-col>
            <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6" class="address-row">
                <ion-label>Category <span
                        [ngClass]="blogFormGroup.get('category').hasError('required') && blogFormGroup.get('category').dirty ? 'error-msg' : ''">*</span></ion-label>
                <ng-select class="select-input mt-5" formControlName="category" [items]="categoryList" bindLabel="name"
                    bindValue="blog_category_id" [clearable]="false" (change)="categorySelected($event)"
                    placeholder="Select category">
                </ng-select>
                <ng-container *ngFor="let validation of validation_messages.category">
                    <ng-container
                        *ngIf="blogFormGroup.get('category').hasError(validation.type) && blogFormGroup.get('category').dirty">
                        <ion-text color="danger" class="error-msg">
                            {{validation.message }}
                        </ion-text>
                    </ng-container>
                </ng-container>
            </ion-col>
            <ion-col size="12" class="mb-10 mt-10">
                <ion-label>
                    Description <span
                        [ngClass]="blogFormGroup.get('description').hasError('required') && blogFormGroup.get('description').dirty ? 'error-msg' : ''">*</span>
                </ion-label>
                <ion-textarea formControlName="description" rows="7" placeholder="Blog description"></ion-textarea>
                <ng-container *ngFor="let validation of validation_messages.description">
                    <ng-container
                        *ngIf="blogFormGroup.get('description').hasError(validation.type) && blogFormGroup.get('description').dirty">
                        <ion-text color="danger" class="error-msg">
                            {{validation.message }}
                        </ion-text>
                    </ng-container>
                </ng-container>
            </ion-col>
            <ion-col size="12">
                <div class="blog-image-preview mb-10" *ngIf="imagePreview">
                    <img alt="Image Preview" [src]="imagePreview" height="100" width="100">
                </div>
                <div class="blog-image-upload">
                    <div class="button-container">
                        <button class="file-upload-button clear-btn pointer fs-14 fw-600">
                            <div class="upload-text"> Upload</div>
                            <input #file type="file" id="fileInput" (click)="file.value = null"
                                (change)="fileChangeEvent($event)" />
                        </button>
                    </div>
                    <div class="file-info fs-16 fw-500 pl-10">{{ selectedFileName }}</div>
                    <div class="upload-blog-text" *ngIf="!selectedFileName"> Upload Blog Image</div>
                </div>
                <ion-text *ngIf="imageUpload" color="danger" class="error-msg">
                    Please upload a blog image
                </ion-text>
            </ion-col>
            <ion-col size="12">
                <div class="form-button mt-30 mb-30">
                    <button type="primary" (click)="submit()" class="mr-20 w-147 submit-btn">
                        <ion-spinner class="spinner-btn " name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
                        Submit
                    </button>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
    <div class="wrapper-block home-page">
        <div class="app-footer">
            <app-footer></app-footer>
        </div>
    </div>
</ion-content>