
import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { LocationService } from 'src/app/services/location.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToasterService } from '../../services/toaster.service';
import { DeliveryAddressComponent } from '../delivery-address/delivery-address.component';

@Component({
	selector: 'app-upper-header',
	templateUrl: './upper-header.component.html',
	styleUrls: ['./upper-header.component.scss'],
})
export class UpperHeaderComponent implements OnInit {

	currentLocation: any;
	toastShow: boolean = false;
	completeLocation: any;
	constructor(private locationService: LocationService,
		private navCtrl: NavController,
		public modalService: ModalService,
		public toasterService: ToasterService) {

	}

	ngOnInit() {
		this.getLocation();
	}


	getLocation() {
		if (this.toastShow == true) {
			return;
		}
		if (navigator.permissions && navigator.permissions.query) {
			navigator.permissions.query({ name: "geolocation" }).then((result) => {
				// result.state can be granted or prompt or denied
				if (result.state === "denied") {
					this.toasterService.errorToast("Please enable location from browser settings or browser");
					this.toastShow = true;
					setTimeout(() => { this.toastShow = false }, 3000)
				}
				result.addEventListener("change", () => {
				});
			});
		}


		this.locationService.getCurrentLocation().then((location: any) => {
			let state;
			let city;
			let zipcode;
			let premise;
			let route;
			let locality;
			let subLocality;
			let streetNumber;
			let subLocality1;

			location.map((addr) => {
				if (addr['types'].includes("administrative_area_level_1")) {
					state = addr['long_name']
				}
				if (addr['types'].includes("administrative_area_level_3")) {
					city = addr['long_name']
				}
				if (addr['types'].includes("postal_code")) {
					zipcode = addr['long_name']
				}
				if (addr['types'].includes("premise")) {
					premise = addr['long_name'] ? addr['long_name'] : "";
				}
				if (addr['types'].includes('sublocality_level_1')) {
					locality = addr['long_name'] ? addr['long_name'] : "";
				}
				if (addr['types'].includes('sublocality_level_2')) {
					subLocality = addr['long_name'] ? addr['long_name'] : "";
				}
				if (addr['types'].includes('route')) {
					route = addr['long_name'] ? addr['long_name'] : "";
				}
				if (addr['types'].includes('street_number')) {
					streetNumber = addr['long_name'] ? addr['long_name'] : "";
				}
				if (addr['types'].includes('sublocality_level_2')) {
					subLocality1 = addr['long_name'] ? addr['long_name'] : "";
				}
			})
			this.currentLocation = city + ", " + state + ", " + zipcode;
			this.completeLocation = subLocality + ", " + route + ", " + subLocality1 + ", " + locality + ", " + city + ", " + state;
			let cleanedString = this.completeLocation.replace(/\bundefined\b/g, '').replace(/, ,/g, ',').replace(/,,/g, '').trim();
			this.locationService.currentLocation = this.currentLocation;
			this.locationService.completeLocation = this.removeDuplicatesFromString(cleanedString);
		});
	}

	removeDuplicatesFromString(inputString) {
		inputString = inputString.replace(/^,/, '');
		let values = inputString.split(', ');
		let uniqueValues = [...new Set(values)];
		let resultString = uniqueValues.join(', ');

		return resultString;
	}

	goToContact() {
		this.navCtrl.navigateForward('/contact-us', { animated: false, "state": { "breadcrumb": "Contact Us", "url": '/home/contact-us' } });
	}

	async addressPopUp() {
		let obj = {
			component: DeliveryAddressComponent,
			backdropDismiss: true,
			canDismiss: true,
			cssClass: 'delivery-modal',

		};
		let modal = this.modalService.openModal(obj);
		modal.then(res => {

		}, error => {
			console.log(error);
		});

	}
}
