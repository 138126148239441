import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { DeliveryAddressComponent } from 'src/app/components/delivery-address/delivery-address.component';
import { ApiService } from 'src/app/services/api-data.service';
import { CommonService } from 'src/app/services/common.service';
import { LocationService } from 'src/app/services/location.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToasterService } from 'src/app/services/toaster.service';
@Injectable({
    providedIn: 'root'
})
export class ProductDetailPageService {
    public subscription = new Subscription();
    constructor(
        private modalService: ModalService,
        private dataService: ApiService,
        private toasterService: ToasterService,
        private modalCtrl: ModalController,
        private commonService: CommonService,
        private alertController: AlertController,
        private navCtrl: NavController,
        private router: Router,
        private locationService: LocationService,
        public route: ActivatedRoute

    ) {
    }

    goToProductDetail(data, isBreadcrumbAvailable?: boolean, backUrl?) {
        if (data.name) data.product_name = data.name;
        if (data.category) data.category_name = data.category;
        let userdata = JSON.parse(this.commonService.localStorageGet('user_data'));
        if (userdata) {
            let isCustomer = userdata.isCustomer
            let isSeller = userdata.isSeller
            let deliverLocation = null;
            deliverLocation = this.commonService.localStorageGet('deliverLocation');
            let shipping_address_id = +this.commonService.localStorageGet('shipping_address_id');
            if (isCustomer) {
                if (deliverLocation && shipping_address_id) {
                    if (data.product_id) {
                        let categoryName = this.commonService.trimUrl(data.category_name);
                        let productName = this.commonService.trimUrl(data.product_name);

                        this.navCtrl.navigateForward('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                            {
                                animated: false, "state":
                                {
                                    "url": backUrl ? backUrl : '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                                    "productDetail": true,
                                    productData: data,
                                    "breadcrumb": isBreadcrumbAvailable ? data.type : null
                                }
                            });

                        this.commonService.localStorageRemove("searchData")
                    }
                }
                else {
                    if (!this.commonService.isLoggedIn) {
                        if (data.product_id && deliverLocation) {
                            let categoryName = this.commonService.trimUrl(data.category_name);
                            let productName = this.commonService.trimUrl(data.product_name);

                            this.navCtrl.navigateForward('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id, {
                                animated: false, "state": {
                                    "url": backUrl ? backUrl : '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                                    "productDetail": true, productData: data,
                                    "breadcrumb": isBreadcrumbAvailable ? data.type : null
                                }
                            });

                            this.commonService.localStorageRemove("searchData");
                        }
                        else {
                            this.addressPopUp(data)
                        }
                    }
                    else {
                        this.getAddresss(data)
                    }
                }
            }
            else if (isSeller) {
                let categoryName = this.commonService.trimUrl(data.category_name);
                let productName = this.commonService.trimUrl(data.product_name);

                this.navCtrl.navigateRoot('/home/product-detail/' + categoryName + "/" + productName + "/" + data.seller_product_id, {
                    animated: false,
                    "state": {
                        "url": backUrl ? backUrl : '/home/product-detail/' + categoryName + "/" + productName + "/" + data.seller_product_id,
                        "productDetail": true,
                        productData: data,
                        sellerDetail: true
                    }
                });
            }
        }
        else {
            /// if not login or user not found
            let deliverLocation = null;
            deliverLocation = this.commonService.localStorageGet('deliverLocation');
            let shipping_address_id = +this.commonService.localStorageGet('shipping_address_id');

            if (deliverLocation && shipping_address_id) {
                if (data.product_id) {
                    let categoryName = this.commonService.trimUrl(data.category_name);
                    let productName = this.commonService.trimUrl(data.product_name);

                    this.navCtrl.navigateForward('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                        {
                            animated: false, "state":
                            {
                                "url": backUrl ? backUrl : '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                                "productDetail": true,
                                productData: data,
                                "breadcrumb": isBreadcrumbAvailable ? data.type : null
                            }
                        });

                    this.commonService.localStorageRemove("searchData")
                }
            }
            else {
                if (!this.commonService.isLoggedIn) {
                    if (data.product_id && deliverLocation) {
                        let categoryName = this.commonService.trimUrl(data.category_name);
                        let productName = this.commonService.trimUrl(data.product_name);

                        this.navCtrl.navigateForward('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                            {
                                animated: false, "state": {
                                    "url": backUrl ? backUrl : '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                                    "productDetail": true, productData: data,
                                    "breadcrumb": isBreadcrumbAvailable ? data.type : null
                                }
                            });

                        this.commonService.localStorageRemove("searchData");
                    }
                    else {
                        this.addressPopUp(data)
                    }
                }
                else {
                    this.getAddresss(data)
                }
            }
        }


    }


    async addressPopUp(data?) {
        let obj = {
            component: DeliveryAddressComponent,
            backdropDismiss: true,
            canDismiss: true,
            cssClass: 'delivery-modal',

        };
        let modal = this.modalService.openModal(obj);
        modal.then(res => {
            if (res['data'] && res['data']['address']) {
                if (data.product_id) {
                    let categoryName = this.commonService.trimUrl(data.category_name);
                    let productName = this.commonService.trimUrl(data.product_name);

                    this.navCtrl.navigateForward('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                        {
                            animated: false, "state": {
                                "breadcrumb": data.type,
                                "url": '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                                "productDetail": true,
                                productData: data
                            }
                        });
                }
            }
        }, error => {
            console.log(error);
        });



    }


    getAddresss(productDat) {
        let params: any;
        if (this.commonService.userData && this.commonService.userData.customer_type != 'INDIVIDUAL') {
            params = {
                "user_type": "CUSTOMER",
                "company_id": this.commonService.localStorageGet('company_id')
            }
        }
        this.subscription.add(
            this.dataService.get(URLS.getAddress, params).subscribe(data => {
                if (data['code'] == 200) {
                    if (data['data']['default_shipping']) {
                        let defaultAddress = data['data']['default_shipping'];
                        this.locationService.latitude = defaultAddress['latitude'];
                        this.locationService.longitude = defaultAddress['longitude'];
                        this.commonService.localStorageSet('shipping_address_id', defaultAddress['company_address_id'] ? defaultAddress['company_address_id'] : defaultAddress['user_address_id']);
                        const deliveryLocationData = {
                            address: defaultAddress['address'],
                            address_type_id: defaultAddress['address_type_id'],
                            latitude: defaultAddress['latitude'],
                            longitude: defaultAddress['longitude'],
                            state: defaultAddress.state,
                            state_id: defaultAddress.state_id
                        }
                        this.commonService.localStorageSet('deliverLocation', JSON.stringify(deliveryLocationData))
                        if (data.product_id) {
                            let categoryName = this.commonService.trimUrl(data.category_name);
                            let productName = this.commonService.trimUrl(data.product_name);

                            this.navCtrl.navigateForward('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                                {
                                    animated: false,
                                    "state": {
                                        "breadcrumb": data.type,
                                        "url": '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
                                        "productDetail": true,
                                        productData: data
                                    }
                                });
                        }
                    }
                    else {
                        this.addressPopUp(productDat);
                    }
                }

            }, error => {
                this.toasterService.errorToast(error['message']);

            })
        )
    }
}
