import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { profileTypeEnum } from '../../_common/enum.enum';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { ToasterService } from './../../services/toaster.service';

@Component({
	selector: 'app-manage-user',
	templateUrl: './manage-user.component.html',
	styleUrls: ['./manage-user.component.scss'],
})
export class ManageUserComponent implements OnInit {
	isLoading: boolean = false;
	companyInfo: any;
	company_id: any;
	public isCustomerApproval: any;
	public isSellerApproval: any;
	profileEnum = profileTypeEnum;
	public subscription = new Subscription();
	widthConfig = ['20px', '160px', '180px', '170px', '150px', '200px'];
	column_settings = [
		{ primaryKey: 'id', header: '#' },
		{ primaryKey: 'user_name', header: 'User name' },
		{ primaryKey: 'user_email', header: 'User email' },
		{ primaryKey: 'user_rights', header: 'User rights' },
		{ primaryKey: 'is_admin', header: 'Admin role', toggle: true },
		{ primaryKey: '', header: 'Action', action: true }
	];
	listOfAllData = []

	constructor(
		private router: Router,
		public commonService: CommonService,
		private dataService: ApiService,
		private toasterService: ToasterService,
		private navCtrl: NavController,
	) {
		this.commonService.eventObservable.subscribe((data: any) => {
			if (data.event == "adminToggleUpdate") {
				this.isCustomerApproval = this.commonService.localStorageGet('customer_approval') == 'PENDING' ? true : false;
				this.isSellerApproval = this.commonService.localStorageGet('seller_approval') == 'PENDING' ? true : false;
				this.commonService.scrollToTop();
				this.getCompanyDetail().then(data => {
					if (data) {
						this.getUsers();
					}
				})
			}
		})

	}

	ngOnInit() {
		this.isCustomerApproval = this.commonService.localStorageGet('customer_approval') == 'PENDING' ? true : false;
		this.isSellerApproval = this.commonService.localStorageGet('seller_approval') == 'PENDING' ? true : false;
		this.commonService.scrollToTop();
		this.getCompanyDetail().then(data => {
			if (data) {
				this.getUsers();
			}
		})
	}

	addUser() {
		this.navCtrl.navigateForward(['/home/account-details/manage-user/add-user'])
	}

	getCompanyDetail() {
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.sellerCompanyDetail).subscribe(data => {
					if (data['code'] == 200) {
						this.companyInfo = data['data'];
						this.company_id = this.companyInfo.company_id;
						resolve(data);
					}
					else {
						reject(data);
						this.toasterService.errorToast(data['message']);
					}
				}, error => {
					reject(error);
					this.toasterService.errorToast(error['message']);
				})
			)
		});
	}

	getUsers(event?) {
		const params = {
			company_id: this.company_id,
			user_type: this.commonService.userData && this.commonService.userData['isCustomer'] ? this.profileEnum.Customer : this.profileEnum.Seller
		}
		if (event) {
			if (event.sort_by.primaryKey == "id") {
				params['sort_by'] = "enterprise_user_id"
			}
			else if (event.sort_by.primaryKey == "user_email") {
				params['sort_by'] = "email"
			}
			else if (event.sort_by.primaryKey == "isActive") {
				params['sort_by'] = "is_active"
			}
			else if (event.sort_by.primaryKey == "is_admin") {
				params['sort_by'] = "is_admin_user"
			}
			else {
				params['sort_by'] = event.sort_by.primaryKey
			}
			params['sort_type'] = event.sort_type;
		}
		this.isLoading = true;
		this.listOfAllData = [];
		this.subscription.add(
			this.dataService.get(URLS.getUser, params).subscribe(data => {
				if (data['code'] == 200) {
					this.isLoading = false;
					if (data['data']) {
						let userData = data['data']['enterpriseList'];
						if (userData && userData.length) {
							let user_rights_data: any = [];
							userData.forEach((element, index) => {
								user_rights_data = []
								if (element['accessright'] && element['accessright'].length) {
									element['accessright'].forEach(element => {
										user_rights_data.push(element.name);
									});
								}
								let obj = {
									index: index + 1,
									id: element.enterprise_user_id,
									user_name: element.user_name,
									user_email: element.email,
									user_rights: user_rights_data,
									is_admin: element.is_admin_user,
									isActive: element.is_active,
									edit: true,
									updateStatus: true,
									delete: true,
									updateToggle: true
								}

								this.listOfAllData.push(obj);

							});

						}
					}
				}
				else {
					this.toasterService.errorToast(data['message'], 'top');
				}
			}, error => {
				this.toasterService.errorToast(error['message'], 'top');
			})
		)
	}

}
