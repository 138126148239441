<div class="success-container">
	<div class="fs-28 fw-600 text-color mb-30 fs-24">
		E way Bill Details
	</div>
	<div class="close-div p-5" (click)="close(false)">
		<img alt="Close Icon" src="/assets/icon/close.svg" alt="" slot="end" class="close-icon">
	</div>
	<div class="description-container">
		<div class="top-div display-flex space-between p-30 mt-5">
			<div class="display-flex warehouse-details" *ngIf="orderDetails">
				<img alt="Image" [src]='orderDetails?.seller_product?.default_image' class="product_img"
					alt="Product Image" />
				<div class="pl-20 order-details">
					<div>
						<div class="label-head mb-10 custom-ellipse-text"
							[ngClass]="{'pointer': orderDetails?.seller_product.product.product_name.length > 26}"
							[attr.title]="(orderDetails?.seller_product.product.product_name.length > 26) ? orderDetails.seller_product.product.product_name : ''">
							{{orderDetails?.seller_product?.product?.product_name
							}}</div>
						<p class="fs-14 pb-7 light-grey-text m-0">SKU: <span class="text-color custom-ellipse-text"
								[ngClass]="{'pointer': orderDetails?.seller_product.product.sku.length > 26}"
								[attr.title]="(orderDetails?.seller_product.product.sku.length > 26) ? orderDetails.seller_product.product.sku : ''">{{orderDetails?.seller_product?.product?.sku}}</span>
						</p>
						<div class="product-attribute"
							*ngFor="let oneAttr of orderDetails?.seller_product.product.product_attributes">
							<div class="attribute-header">
								{{oneAttr.attribute_name ? oneAttr.attribute_name : '--'}}:&nbsp;
							</div>
							<div class="attribute attribute-value">
								{{oneAttr.value ? oneAttr.value : '--'}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="max-width-240" *ngIf="warehouseAddress">
				<div class="fs-14 pb-7 light-grey-text m-0">Dispatch Location</div>
				<div class="label-text">{{warehouseAddress}}</div>
			</div>

			<ng-container *ngIf="!warehouseAddress">
				<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6"
					class="warehouse-dropdown p-0 m-0" [formGroup]="warehouseformGroup">
					<div class="max-width-100" [ngClass]="getPlatformService.isDesktop ? null :'mb-10'">
						<ion-label class="warehouse-dropdown-label">Select A Dispatch Location *</ion-label>
						<ng-select [(ngModel)]="warehouseId" placeholder="Select A Dispatch Location"
							class="select-input" [items]="orderWareHouseData" bindLabel="address"
							bindValue="company_address_id" [clearable]="false" formControlName="warehouse_address">
							<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
								<div class="row warehouse-addresses" [title]="item['address']">
									<span [ngModelOptions]="{standalone: true}"
										class="select-checkbox ng-option-label"></span>
									{{item['address']}}
								</div>
							</ng-template>
						</ng-select>
					</div>
					<ng-container *ngFor="let validation of validation_messages.warehouse_address">
						<ng-container
							*ngIf="warehouseformGroup.get('warehouse_address').hasError(validation.type) && warehouseformGroup.get('warehouse_address').dirty">
							<ion-text color="danger" class="error-msg">
								{{validation.message }}
							</ion-text>
						</ng-container>
					</ng-container>
				</ion-col>
			</ng-container>
		</div>

		<ion-row class="p-0 mt-20" [formGroup]="ewayFormGroup">

			<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="4"
				class="warehouse-dropdown p-0 m-0 pr-10">
				<ion-label>E way Bill No</ion-label>
				<ion-input placeholder="Enter E way Bill No" capitalizeFirst
					formControlName="e_way_bill_no"></ion-input>

				<ng-container *ngFor="let validation of validation_messages.e_way_bill_no">
					<ng-container
						*ngIf="ewayFormGroup.get('e_way_bill_no').hasError(validation.type) && ewayFormGroup.get('e_way_bill_no').dirty">
						<ion-text color="danger" class="error-msg">
							{{validation.message }}
						</ion-text>
					</ng-container>
				</ng-container>

			</ion-col>

			<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="4"
				class="warehouse-dropdown p-0 m-0 pl-10">
				<ion-label>Invoice No</ion-label>
				<ion-input placeholder="Enter Invoice No" capitalizeFirst formControlName="invoice_no"></ion-input>

				<ng-container *ngFor="let validation of validation_messages.invoice_no">
					<ng-container
						*ngIf="ewayFormGroup.get('invoice_no').hasError(validation.type) && ewayFormGroup.get('invoice_no').dirty">
						<ion-text color="danger" class="error-msg">
							{{validation.message }}
						</ion-text>
					</ng-container>
				</ng-container>

			</ion-col>
		</ion-row>


		<div [ngClass]="getPlatformService.isDesktop ? 'align-center mt-20 width-100 ': 'mobile-full-btn'">
			<button [disabled]="isSubmitButtonClicked" type="primary" class="primary-btn"
				[ngClass]="!getPlatformService.isDesktop ? 'width-100': null" (click)="submitWareHouseDetail(true)">
				<ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isSubmitButtonClicked"></ion-spinner>

				<span>{{submitBtnText}}</span>
			</button>
		</div>

	</div>
</div>