import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/services/api-data.service';
import { CommonService } from 'src/app/services/common.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { ThankYouComponent } from '../thank-you/thank-you.component';


@Component({
	selector: 'app-add-quote',
	templateUrl: './add-quote.component.html',
	styleUrls: ['./add-quote.component.scss'],
})
export class AddQuoteComponent implements OnInit {
	public subscription = new Subscription();
	quoteForm: FormGroup;
	isLoading = false;
	estimatedDate: any;
	@Input() quote_id = null;
	@Input() quote_details = null;
	@Input() delivery_date = null;
	@Input() price = null;
	@Input() isEdit = false;
	@Input() quoteBidId = null;
	todaysDate = new Date();
	date: any
	minDate: any;

	public validation_messages = {
		quotePrice: [
			{ type: 'required', message: 'Please enter quote price' },
			{ type: 'quotePriceInvalid', message: 'Please enter valid quote price' },

		],
		estimatedDate: [
			{ type: 'required', message: 'Please select estimated delivery date' },

		],
	}

	quantity: any;
	constructor(
		private modalService: ModalService,
		public commonService: CommonService,
		private dataService: ApiService,
		private toasterService: ToasterService,
		private formBuilder: FormBuilder,
		private datePipe: DatePipe) { }

	ngOnInit() {
		this.minDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		if (this.price || this.delivery_date) {
			const formattedDate = this.datePipe.transform(this.delivery_date, 'YYYY-MM-dd');
			this.date = formattedDate;
		}
		this.quoteForm = this.initializeFormGroup();
		this.quoteForm.controls.estimatedDate.setValue(this.delivery_date)

		if (this.quote_details && this.quote_details.quantity.toString().includes("/")) {
			this.quantity = +this.quote_details.quantity.split("/")[0];
		}

	}

	async close() {
		this.modalService.dismissModal();
	}

	onDateChange(datetime) {
		datetime.cancel(true);
	}

	initializeFormGroup() {
		return this.formBuilder.group({
			quotePrice: new FormControl({ value: this.price ? this.price : '', disabled: false }, {
				validators: Validators.compose([Validators.required, this.quotePriceValidator]),
			}),
			estimatedDate: new FormControl({ value: this.delivery_date ? this.delivery_date : '', disabled: false }, {
				validators: Validators.compose([Validators.required]),
			}),

		})
	}


	isnumeric(e) {
		const keyCode = e.keyCode || e.which;
		const keyValue = String.fromCharCode(keyCode);

		// Check if the entered key is a number or a decimal point
		if (!/^\d{0,6}(\.\d*)?$/.test(keyValue) || (keyValue === '.' && (e.target as HTMLInputElement).value.includes('.'))) {
			e.preventDefault();
		}
	}
	submitQuote() {
		let dataChange = {};
		for (const i in this.quoteForm.controls) {
			this.quoteForm.controls[i].markAsDirty();
			this.quoteForm.controls[i].updateValueAndValidity();
			this.quoteForm.controls[i].markAsTouched();
		}

		if (this.quoteForm.invalid) {
			return;
		}
		if (this.isEdit) {
			if (this.price != this.quoteForm.value.quotePrice) {
				dataChange['price'] = this.quoteForm.value.quotePrice;
			}
			if (this.delivery_date != this.quoteForm.value.estimatedDate) {
				dataChange['estimatedDate'] = this.quoteForm.value.estimatedDate;
			}
			if (Object.keys(dataChange).length < 1) {
				return;
			}
			let param = {
				"quote_id": this.quote_id,
				"price": parseFloat(this.quoteForm.value.quotePrice),
				"quote_bid_id": this.quoteBidId,
				"estimated_delivery_date": this.quoteForm.value.estimatedDate
			};
			this.subscription.add(
				this.dataService.put(URLS.sellerQuoteEdit, param).subscribe(data => {
					if (data['code'] == 200) {
						this.modalService.dismissModal({
							quote_id: this.quote_id
						})
						let options: any = {
							id: "thankYouModal",
							backdropDismiss: false,
						};
						options['component'] = ThankYouComponent;
						options['componentProps'] = {
							btnText: "Manage Quote",
							btnLink: "/home/account-details/manage-quotes",
							title: "Your quote is submitted successfully",
							description: "Please wait for response, you will be notified if your quote gets selected",
							quote_id: this.quote_id
						};
						this.modalService.openModal(options)
					}
					else {
						this.toasterService.errorToast(data['message']);
					}
					this.isLoading = false;
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);

				})
			)

		}
		else {
			let param = {
				"quote_id": this.quote_id,
				"price": parseFloat(this.quoteForm.value.quotePrice),
				"estimated_delivery_date": this.quoteForm.value.estimatedDate
			};
			this.subscription.add(
				this.dataService.post(URLS.addSellerQuote, param).subscribe(data => {
					if (data['code'] == 200) {
						this.modalService.dismissModal({
							quote_id: this.quote_id
						})
						let options: any = {
							id: "thankYouModal",
							backdropDismiss: false,
						};
						options['component'] = ThankYouComponent;
						options['componentProps'] = {
							btnText: "Manage Quote",
							btnLink: "/home/account-details/manage-quotes",
							title: "Your quote is submitted successfully",
							description: "Please wait for response, you will be notified if your quote gets selected",
							quote_id: this.quote_id
						};
						this.modalService.openModal(options)
					}
					else {
						this.toasterService.errorToast(data['message']);
					}
					this.isLoading = false;
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);

				})
			)
		}


	}

	quotePriceValidator(control: AbstractControl): { [key: string]: any } | null {
		const value = control.value;

		if (value >= 1 || !value) {
			return null // Valid
		} else {
			return { 'quotePriceInvalid': true }; // Invalid
		}
	}
}
