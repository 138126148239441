<div clickOutside (clickOutside)="outsideClick()" id="scrollId" class="main-header">
	<app-upper-header *ngIf="getPlatformService.isDesktop"></app-upper-header>
	<div class="header-container">
		<div class="header">
			<div class="body-container m-auto d-flex">
				<div class="header-right">
					<div class="left-block">
						<div (click)="goToHomepage()" class="pointer company-logo">
							<img class="logo" src="./assets/svg/conkart-logo.svg" alt="company-logo">
						</div>
					</div>
					<div class="middle-block" [ngClass]="!getPlatformService.isDesktop ? 'displayHide':''"
						*ngIf="commonService.userData && !commonService.userData.isSeller">
						<div class="header-options-wrapper fs-16 fw-500">
							<div class="options">
								<h1 (click)="goToHomepage()" class="align-center header-item">Home</h1>
							</div>

							<div class="options category-option" [ngClass]="isMenuVisible ? 'bottom-color' : ''">
								<h1 [ngClass]="isMenuVisible ? 'header-menu-visible' : ''" class="header-item"
									(click)="toggleMenu()">
									Categories
								</h1>
								<div class="triangle-icon">
									<img *ngIf="!isMenuVisible" src="/assets/icon/dropdown-bottom.svg"
										alt="triangle-icon" height="100%" width="100%" (click)="toggleMenu()">
									<img *ngIf="isMenuVisible" class="rotate-180"
										src="/assets/icon/dropdown-blue-bottom.svg" alt="triangle-icon" height="100%"
										width="100%" (click)="toggleMenu()">
								</div>
							</div>
							<div class="options mr-m20">
								<p class="header-item" (click)="goToConsultantRequest()">Get a Consultant</p>
							</div>

						</div>
					</div>
					<div class="right-block">
						<div class="search-icon mr-25 display-flex"
							[ngClass]="!getPlatformService.isDesktop ? 'search-icon-class' : ''"
							*ngIf="commonService.userData && !commonService.userData.isSeller">
							<form *ngIf="getPlatformService.isDesktop">
								<ion-searchbar #searchbar autocomplete='off' autocorrect='off' autocapitalize="none"
									debounce="800" inputmode="search" type="search" name="search" enterkeyhint="search"
									placeholder="Search..." search-icon="./assets/svg/search.svg"
									[title]="searchValue && searchValue.length > 0 ? searchValue : 'Search'"
									[(ngModel)]="searchValue" (ionChange)="onSearch($event, 'onChange')"
									(keydown.enter)="searchedArray && searchedArray.length > 0 ? searchNavigation(selectedData) : null"
									(ionClear)="onSearchClear()" (ionInput)="onSearchInput($event)" autocorrect="off"
									[ngClass]="isSearched || searchValue ? 'hide-search' : ''"
									mode="md"></ion-searchbar>
								<div class="search-container" *ngIf="searchedArray && searchedArray.length > 0">
									<div class="products-container">
										<div class="products" *ngFor="let product of searchedArray; let j = index"
											[class.selected]="j === selectedIndex" (click)="searchNavigation(product)">
											<div class="d-flex">
												<ng-container
													*ngIf="product.product_name  && product.is_product == true">
													<div class="header-name">
														{{ product.product_name }} <span class="sub-header">in Products
														</span>
													</div>
												</ng-container>
												<ng-container
													*ngIf="product.category_name && product.is_product != true">
													<div class="header-name">
														{{ product.category_name }} <span class="sub-header">in
															Categories </span>
													</div>
												</ng-container>
												<ng-container *ngIf="product.brand_name  && product.is_product != true">
													<div class="header-name">
														{{ product.brand_name }} <span class="sub-header">in Brands
														</span>
													</div>
												</ng-container>
											</div>
										</div>
									</div>
								</div>
								<div class="search-container"
									*ngIf="!isLoading && searchValue && isSearched && searchedArray && searchedArray.length < 1">
									<div class="products-container">
										<div class="products">
											<div class="d-flex">
												<div class="header-name">
													No data found
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>

							<div>
								<img alt="Search Icon" (click)="goToSearch()" *ngIf="!getPlatformService.isDesktop"
									src="./assets/svg/search.svg">
							</div>

						</div>

						<div class="bell-icon mr-30" *ngIf="commonService.isLoggedIn">
							<div class="icon-div">
								<img alt="Notification Icon" (click)="notification()" src="./assets/svg/bell.svg">
								<ion-badge
									*ngIf="commonService.localStorageGet('unreadNotificationCount') && +commonService.localStorageGet('unreadNotificationCount') > 0"
									mode="ios">{{commonService.localStorageGet('unreadNotificationCount')}}</ion-badge>
							</div>
						</div>
						<ng-container *ngIf="getPlatformService.isDesktop">
							<div (click)="this.commonService.isCartDisable ? '': shoppingCart()" class="cart-icon"
								[ngClass]="[cartService.cartCount > 0 ? 'mr-30' : 'mr-30',this.commonService.isCartDisable ? 'not-allowed':'']"
								*ngIf="commonService.userData && !commonService.userData.isSeller">
								<img alt="Cart Icon" src="./assets/svg/cart.svg" alt="company-logo"
									[ngClass]="this.commonService.isCartDisable ? 'not-allowed':''">
								<ion-badge *ngIf="commonService.isLoggedIn && cartService.cartCount > 0"
									mode="ios">{{cartService.cartCount}}</ion-badge>
							</div>
						</ng-container>
						<div class="profile-icon mr-10" *ngIf="!commonService.isLoggedIn">
							<div class="icon-div mr-10" (click)="goToPage('login')">
								<img alt="User Icon" src="./assets/svg/user.svg">
							</div>
						</div>
						<div clickOutside (clickOutside)="outSideDropdown()" class="profile-details"
							*ngIf="commonService.isLoggedIn">
							<img alt="Modal Icon" (click)="openModal($event)"
								[src]="profileImage ? profileImage : '/assets/svg/user-dark.svg'"
								[ngClass]="[ profileImage ? 'image-uploaded pointer' : 'profileIcon pointer']">
							<div class="detail pb-10 fs-18 fw-500">
								<div class="name-box" (click)="openModal($event)">
									<div class="name" [title]="commonService.userData.name"> {{commonService.userData ?
										commonService.userData.name : ""}}
									</div>
									<img alt="Dropdown" height="8" class="pl-10 mt-5 pointer"
										src="/assets/icon/dropdown-bottom.svg">
								</div>
								<div *ngIf="!commonService.is_sub_user" class="pointer fs-14 fw-500">
									<div *ngIf=" commonService.userData && commonService.userData.user_type  == 'BOTH'">
										<span (click)="userSwitch('CUSTOMER')"
											*ngIf="commonService.userData && commonService.userData.isSeller"
											class="grey-box">
											Switch to Buyer
										</span>
										<span (click)="userSwitch('SELLER')"
											*ngIf="commonService.userData && commonService.userData.isCustomer"
											class="grey-box">
											Switch to Seller
										</span>
									</div>
									<div (click)="becomeAUser()"
										*ngIf="commonService.userData && commonService.userData.user_type  != 'BOTH'">
										<span
											*ngIf="commonService.userData && commonService.userData.user_type === profileEnum.Customer"
											class="label-box">Become
											a
											Seller</span>
										<span
											*ngIf="commonService.userData && commonService.userData.user_type === profileEnum.Seller"
											class="label-box">Become
											a
											Buyer</span>
									</div>
								</div>
							</div>
							<ion-list [ngClass]="commonService.is_sub_user ? 'top-4' :  'top-5'" class="info-dropdown"
								lines="none" *ngIf="isInfoDropdownOpen">
								<ion-item *ngIf="commonService.userData.isCustomer" (click)="goToDashboard()"
									class="hover-item fs-16 fw-500">
									<ion-label>Dashboard</ion-label>
								</ion-item>
								<ion-item (click)="goToAccount()" class="hover-item fs-16 fw-500">
									<ion-label>My Account</ion-label>
								</ion-item>
								<ion-item (click)="logoutAlert()" class="hover-item fs-16 fw-500">
									<ion-label>Logout</ion-label>
								</ion-item>
							</ion-list>
						</div>
						<div *ngIf="!commonService.isLoggedIn && getPlatformService.isDesktop"
							class="login fs-16 fw-500">
							<div class="login-register-header d-flex">
								<div class="pr-10">
									<button class="submit-btn" routerLink="/login">Login</button>
								</div>
								<div>
									<button class="submit-btn" (click)="signUpTypeModal()">Register</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="commonService.isLoggedIn" class="isMobile">
					<div class="menu-button">
						<ion-buttons slot="end">
							<ion-menu-button></ion-menu-button>
						</ion-buttons>
					</div>
				</div>
			</div>

		</div>
		<ng-container *ngIf="isMenuVisible">
			<app-header-menu class="desktop-visibility" (childEvent)="isMenuVisible=$event" #child
				[menuVisibility]="isMenuVisible">
			</app-header-menu>
		</ng-container>

	</div>

</div>
<div class="bread-crumbs" *ngIf="getPlatformService.isDesktop && breadcrumbsArray.length > 1"
	[ngClass]="[isProductDetail ? 'flex-dir-col':'',getPlatformService.isDesktop ?'':'mobile-breadcrumbs' ]">
	<div class="body-container m-auto">
		<div class="display-flex">
			<ion-breadcrumbs *ngIf="!isProductDetail">
				<ng-container *ngIf="!commonService.isLoggedIn || commonService.userData.isCustomer">
					<ng-container *ngFor="let breadcrumb of breadcrumbsArray; let last = index">
						<ion-breadcrumb *ngIf="last < breadcrumbsArray.length - 1"
							class="bread-crumb-label bread-crumb-with-url pointer"
							(click)="pageRedirect(breadcrumb.url)">
							{{breadcrumb.breadcrumb}}
							<ion-icon [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'"
								src="assets/icon/small-right-arrow.svg"></ion-icon>
						</ion-breadcrumb>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="commonService.isLoggedIn && !commonService.userData.isCustomer">
					<ng-container *ngFor="let breadcrumb of breadcrumbsArray; let last = index">
						<ion-breadcrumb *ngIf="last < breadcrumbsArray.length - 1 && last > 0"
							class="bread-crumb-label bread-crumb-with-url pointer"
							(click)="pageRedirect(breadcrumb.url)">
							{{breadcrumb.breadcrumb}}
							<ion-icon [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'"
								src="assets/icon/small-right-arrow.svg"></ion-icon>
						</ion-breadcrumb>
					</ng-container>
				</ng-container>
			</ion-breadcrumbs>

			<ion-breadcrumbs *ngIf="!isProductDetail">
				<ng-container>
					<div class="title fs-17 fw-600 d-flex bread-crumbs-flex">
						<h1 class="fs-17 fw-600">{{breadCrumbTitle}}</h1>
					</div>
				</ng-container>
			</ion-breadcrumbs>


			<ion-breadcrumbs *ngIf="isProductDetail">
				<ng-container *ngIf="commonService.isLoggedIn && !commonService.userData.isCustomer">
					<ng-container *ngFor="let breadcrumb of breadcrumbsArray; let last = index">
						<ion-breadcrumb *ngIf="breadcrumb.url != '/home'" class="bread-crumb-label bread-crumb-with-url"
							[ngClass]="last == breadcrumbsArray.length-1? 'fw-600 op-1':'pointer'"
							(click)="last == breadcrumbsArray.length-1? null:pageRedirect(breadcrumb.url)">
							{{breadcrumb.breadcrumb}}
							<ion-icon *ngIf="last != breadcrumbsArray.length-1"
								[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'"
								src="assets/icon/small-right-arrow.svg"></ion-icon>
						</ion-breadcrumb>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="!commonService.isLoggedIn || commonService.userData.isCustomer">
					<ng-container *ngFor="let breadcrumb of breadcrumbsArray; let last = index">
						<ion-breadcrumb class="bread-crumb-label bread-crumb-with-url"
							[ngClass]="last == breadcrumbsArray.length-1? 'fw-600  op-1':'pointer'"
							(click)="last == breadcrumbsArray.length-1? null : pageRedirect(breadcrumb.url)">
							{{breadcrumb.breadcrumb}}
							<ion-icon *ngIf="last != breadcrumbsArray.length-1"
								[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'"
								src="assets/icon/small-right-arrow.svg"></ion-icon>
						</ion-breadcrumb>
					</ng-container>
				</ng-container>
			</ion-breadcrumbs>
		</div>
		<ng-container *ngIf="isProductDetail">
			<div class="product-title fs-17 fw-600" (click)="goBack()"> <img alt="Back Icon" class="mr-10"
					src="assets/icon/arrow-left.svg">
				<h1 class="fs-17 fw-600">Back</h1>
			</div>
		</ng-container>
	</div>

</div>

<ng-container *ngIf="!getPlatformService.isDesktop && breadcrumbsArray.length > 1">
	<ng-container *ngIf="!isProductDetail">
		<p class="fs-18 fw-600 m-0 p-15 pb-0">{{breadCrumbTitle}}</p>
	</ng-container>
</ng-container>