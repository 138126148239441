<div class="registration-type">
    <div class="close-div p-5" (click)="close()">
        <img alt="Close Icon" src="/assets/icon/close.svg" alt="" slot="end" class="close-icon">
    </div>
    <div class="heading-label fs-28 fw-600 mb-0">
        Registration Type
    </div>
    <div class="mb-30 fs-18 fw-400">
        <p>How would you like to sign up ? </p>
    </div>
    <div class="reg-type">
        <button class="clear-btn w-200" (click)="createAccount('customer')">
            Sign up as buyer
        </button>

        <button class="clear-btn w-200" (click)="createAccount('seller')">
            Sign up as seller
        </button>

    </div>
</div>