<div class="add-user-details p-10 page-scroll-top">
    <ion-row class="mb-5">
        <div class="tab-container" *ngIf="userId">
            <div class="radio_container fs-16">
                <ng-container *ngFor="let item of tabList;let i = index">
                    <input (change)="tabChange()" [(ngModel)]="UserToggle" type="radio" name="radio" id="item{{i}}"
                        [value]="item.value" [checked]="item.value">
                    <label class="pointer" for="item{{i}}">{{item.name}}</label>
                </ng-container>
            </div>
        </div>
    </ion-row>
    <div class="section-wrapper mt-30 mb-30 personal-div-container " *ngIf="UserToggle === 'personal'">
        <ion-row>
            <ion-col class="mr-30 pl-30" size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="6" size-xxl="6">
                <div class="toggles ml-5">
                    <div class="align-center w-200">
                        <ion-toggle [(ngModel)]="is_active"></ion-toggle>
                        <div class=" ml-10 mt-m1 fs-16 fw-500">{{is_active ? 'Active' : 'Inactive'}} </div>
                    </div>
                    <div class="align-center">
                        <ion-toggle [(ngModel)]="is_admin" (ionChange)="toggleAdmin()"></ion-toggle>
                        <div class="orange-label ml-10 ">Admin </div>
                    </div>
                </div>
                <div class="categorie-form mt-30">
                    <div class="mt-15">
                        <form [formGroup]="userForm">
                            <app-personal-detail [isHideVerifiedBtn]="true" [fieldName]="fieldData"
                                [formGroup]="$any(userForm)">
                            </app-personal-detail>
                            <ion-row class="mb-5 mt-5 off-add">
                                <ion-col class="address-field">
                                    <ion-label>Designation <span
                                            [ngClass]="userForm.get('designation').hasError('required') && userForm.get('designation').dirty ? 'error-msg' : ''">*</span></ion-label>
                                    <ion-input autocomplete="new-designation" class="pt-20" type="text"
                                        formControlName="designation" placeholder="Enter Designation"></ion-input>
                                    <ng-container *ngFor="let validation of validation_messages.designation">
                                        <ng-container
                                            *ngIf="userForm.get('designation').hasError(validation.type) && userForm.get('designation').dirty">
                                            <ion-text color="danger" class="error-msg">
                                                {{validation.message}}
                                            </ion-text>
                                        </ng-container>
                                    </ng-container>
                                </ion-col>
                            </ion-row>
                            <ion-row class="mb-5 mt-5 off-add">
                                <ion-col class="address-field">
                                    <ion-label>Office Address <span
                                            [ngClass]="userForm.get('office_address').hasError('required') && userForm.get('office_address').dirty ? 'error-msg' : ''">*</span></ion-label>
                                    <ng-select [searchable]="false" placeholder="Select Office Address"
                                        class="select-input mt-5" [items]="officeAddress" bindLabel="name"
                                        bindValue="id" formControlName="office_address" [clearable]="false"
                                        (change)="officeAddressChange($event)">
                                    </ng-select>
                                    <ng-container *ngFor="let validation of validation_messages.office_address">
                                        <ng-container
                                            *ngIf="userForm.get('office_address').hasError(validation.type) && userForm.get('office_address').dirty">
                                            <ion-text color="danger" class="error-msg">
                                                {{validation.message }}
                                            </ion-text>
                                        </ng-container>
                                    </ng-container>

                                </ion-col>
                            </ion-row>
                        </form>
                        <div class="form-container form-button mt-30  ml-5 isDesktopTab">
                            <button (click)="onSubmit()" class="mr-20  w-147 submit-btn">
                                <ion-spinner class="spinner-btn" name="lines-sharp-small"
                                    *ngIf="isLoading"></ion-spinner>
                                {{ userId ? 'Update' : 'Save' }}
                            </button>
                            <button class="clear-btn w-147" (click)="onCancel()">Cancel</button>
                        </div>
                    </div>

                </div>
            </ion-col>
            <ion-col class="m-5 mt-20 cat-option pl-30" size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="5"
                size-xxl="6">
                <div class="title fs-16 fw-600 mt-40">
                    Categories
                </div>
                <app-categories [isManageUser]="true" [isCategoryDisable]="is_admin"
                    [subHeading]="'Give Category Bidding Rights'" [companyCategoryIds]="companyCategoryId"
                    [isCategoryRequired]="true" [isCompanyRequired]="true"
                    [categoryNotChecked]="categoryNotChecked"></app-categories>
            </ion-col>
            <ion-col size="12" class="category-btn pl-30">
                <div class="form-container d-flex form-button mt-30 ">
                    <button (click)="onSubmit()" class="mr-20  w-147 submit-btn">
                        <ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
                        {{ userId ? 'Update' : 'Save' }}
                    </button>
                    <button class="clear-btn w-147" (click)="onCancel()">Cancel</button>
                </div>
            </ion-col>

        </ion-row>
    </div>
    <div class="mt-30 m-15 manage-quotes-container" *ngIf="UserToggle === 'quotes'">
        <div>
            <ion-row class="justify-end">
                <ion-col size-xs="12" size-sm="12" size-md="5" size-lg="5" size-xl="5" class="align-center p-0 pr-20">
                    <div class="width-100" [ngClass]="getPlatformService.isDesktop ? null :'mb-10'">
                        <ion-input (keyup.enter)="search()" [(ngModel)]="searchText" type="text"
                            placeholder="Search By Name"></ion-input>
                    </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="8" size-md="3" size-lg="2" size-xl="2" class="align-center  p-0 m-0">
                    <div [ngClass]="getPlatformService.isDesktop ? 'ml-25':'mb-10'">
                        <button class="grey-btn align-center p-15" (click)="onSearch()"
                            [ngClass]="!getPlatformService.isDesktop ? 'width-100':null">
                            <img src="/assets/icon/search.svg" alt="Search Icon" />
                            <span *ngIf="!getPlatformService.isDesktop" class="ml-10">Search</span>
                        </button>
                    </div>
                    <div class="ml-25">
                        <div [ngClass]="getPlatformService.isDesktop ? 'close-div':''" class="p-5 cursor-pointer"
                            (click)="closeFilter()">
                            <img src="/assets/icon/close.svg" alt="Close Icon" slot="end" class="close-icon">
                        </div>
                    </div>
                </ion-col>
            </ion-row>
        </div>
        <ng-container *ngIf="!isQuoteList">
            <div class="mt-20">
                <app-table-listing [pageType]="'manage_user_quote'" [userId]="this.userId" [widthConfig]="widthConfig"
                    [isStickyColumn]="getPlatformService.isDesktop ? true : false" [settings]="column_settings"
                    [listOfAllData]="listOfAllData">
                </app-table-listing>
            </div>
            <div class="pagination-wrapper" *ngIf="listOfAllData && listOfAllData.length > 0" [ngClass]="[totalCount < 10 && getPlatformService.isDesktop ?'pagination-justify-content-end':totalCount < 10 &&
                !getPlatformService.isDesktop? 'pagination-content-start' :'']">
                <ng-container *ngIf="totalCount > 10">
                    <div class="items">
                        Items
                        <ng-select (change)="changeListItem($event)" [(ngModel)]="paginationVal" [clearable]="false"
                            class="pagination" [items]="commonService.paginationList" bindLabel="label"
                            bindValue="value" [searchable]="false" [editable]="false" [searchFn]="false">
                        </ng-select>
                        <ion-label class="perpage-title">of {{totalCount}}</ion-label>
                    </div>
                </ng-container>
                <div>
                    <ion-row class="pagination-div">
                        <ion-col size="12" class="pagination-col return-orders-pagination-col">
                            <app-tile class="grid-tile" *ngFor="let post of listOfAllData | paginate :
                                      { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount };
                                      let i = index" [current]="post"></app-tile>
                            <pagination-controls [maxSize]="5" class="paginator" previousLabel="" nextLabel=""
                                (pageChange)="onChange($event)"></pagination-controls>
                        </ion-col>
                    </ion-row>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isQuoteList">
            <app-loader></app-loader>
        </ng-container>
    </div>
</div>