<div class="confirmation">
    <div class="desc fs-16 fw-600 description">
        {{description}}
    </div>
    <div class="pt-10 fs-16 fw-500 subDescription">
        {{ subDescription }}
    </div>
    <div class="mt-20 flex-center rebid-button">
        <button (click)="onSuccess()" class="mr-20 submit-btn" [ngClass]="[ isRebid ? 'w-200' : 'w-130']">
            {{ isRebid ? 'Apply Re-Bid' : 'Yes' }}
        </button>
        <button (click)="onCancel()" class="clear-btn" [ngClass]="[ isRebid ? 'w-200' : 'w-130']">
            {{ isRebid ? "No, Don't Proceed" : "No" }}
        </button>
    </div>
</div>