<div class="success-container">
    <div class="close-div p-5" (click)="close(false)" *ngIf="hideCloseBtn != true">
        <img alt="Close Icon" src="/assets/icon/close.svg" alt="" slot="end" class="close-icon">
    </div>
    <div class="description-container">
        <div *ngIf="modalImage">
            <img [src]="modalImage" alt="Image">
        </div>
        <p class="description-text fs-16 fw-500">{{description}}</p>

        <div *ngIf="!cartAddressPopUp"
            [ngClass]="getPlatformService.isDesktop ? 'display-center width-100 ': 'display-center'">
            <button [disabled]="apiCall" type="primary" class="primary-btn" (click)="close(true,'yes')">
                <ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="apiCall"></ion-spinner>
                <span>{{submitBtnText}}</span>
            </button>

            <button [disabled]="apiCall" class="clear-btn ml-20 ph-50" (click)="close(false)">
                <span>{{cancelBtnText}}</span>
            </button>
        </div>
        <div *ngIf="cartAddressPopUp"
            [ngClass]="getPlatformService.isDesktop ? 'display-center width-100 ': 'mobile-full-btn'">
            <button type="primary" class="primary-btn" [ngClass]="!getPlatformService.isDesktop ? 'width-100': null"
                (click)="clear()">
                <span>{{submitBtnText}}</span>
            </button>

            <button class="clear-btn" (click)="close(false)"
                [ngClass]="!getPlatformService.isDesktop ? 'width-100 mt-20': 'ml-20'">
                <span [ngClass]="!getPlatformService.isDesktop ? 'ph-30': 'ph-50'">{{cancelBtnText}}</span>
            </button>
        </div>

    </div>
</div>