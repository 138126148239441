import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/services/api-data.service';
import { CommonService } from 'src/app/services/common.service';
import { LocationService } from 'src/app/services/location.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { DeliveryAddressComponent } from '../delivery-address/delivery-address.component';

@Component({
	selector: 'app-product-card',
	templateUrl: './product-card.component.html',
	styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit, OnDestroy {
	@Input() public data;
	@Input() public categories: boolean = false;
	@Input() public brand: boolean = false;
	@Input() public isNew: boolean = false;
	@Input() public faq: boolean = false;
	@Input() isLoading: boolean = false;
	@Input() fromSliderComponent: boolean;

	showPlayIcon: boolean = false;
	public initialBufferTime: any;
	modal: any;
	public subscription = new Subscription();

	isBrowser: boolean;
	@ViewChild('video') myVideo: ElementRef;


	constructor(
		private modalService: ModalService,
		private navCtrl: NavController,
		private commonService: CommonService,
		private dataService: ApiService,
		private toasterService: ToasterService,
		private locationService: LocationService,
		private router: Router,
		@Inject(PLATFORM_ID) private platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this.platformId);
	}


	ngOnInit() {
		setTimeout(() => {
			let initialBufferTime: any;
			if (this.myVideo && this.myVideo.nativeElement.buffered.length > 0) {
				initialBufferTime = this.myVideo ? this.myVideo.nativeElement.buffered.end(0) : null;
			}
			setTimeout(() => {
				this.showPlayIcon = true;
			}, initialBufferTime * 100)
		}, 300);

	}



	goToDetail(data) {
		let deliverLocation = null;
		deliverLocation = this.commonService.localStorageGet('deliverLocation');
		let shipping_address_id = +this.commonService.localStorageGet('shipping_address_id');
		if (deliverLocation && shipping_address_id) {
			if (data.product_id) {
				let categoryName = this.commonService.trimUrl(data.category);
				let productName = this.commonService.trimUrl(data.name);

				this.navCtrl.navigateForward('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
					{
						animated: false, "state":
						{
							"breadcrumb": data.type,
							"url": this.fromSliderComponent ? '/home' : '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
							"productDetail": true,
							productData: data
						}
					});

				this.commonService.localStorageRemove("searchData")
			}
		}
		else {
			if (!this.commonService.isLoggedIn) {
				if (data.product_id && deliverLocation) {
					let categoryName = this.commonService.trimUrl(data.category);
					let productName = this.commonService.trimUrl(data.name);


					this.navCtrl.navigateForward('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
						{
							animated: false, "state": {
								"breadcrumb": data.type,
								"url": this.fromSliderComponent ? '/home' : '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
								"productDetail": true, productData: data
							}
						});

					this.commonService.localStorageRemove("searchData");
				}
				else {
					this.addressPopUp(data)
				}
			}
			else {
				if (data.product_id && deliverLocation) {
					let categoryName = this.commonService.trimUrl(data.category);
					let productName = this.commonService.trimUrl(data.name);


					this.navCtrl.navigateForward('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
						{
							animated: false, "state": {
								"breadcrumb": data.type,
								"url": this.fromSliderComponent ? '/home' : '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
								"productDetail": true, productData: data
							}
						});

					this.commonService.localStorageRemove("searchData");
				}
				else {
					this.getAddress(data)
				}
			}
		}
	}

	async addressPopUp(data?) {
		let obj = {
			component: DeliveryAddressComponent,
			backdropDismiss: true,
			canDismiss: true,
			cssClass: 'delivery-modal',

		};
		let modal = this.modalService.openModal(obj);
		modal.then(res => {
			if (res['data'] && res['data']['address']) {
				if (data.product_id) {
					let categoryName = this.commonService.trimUrl(data.category);
					let productName = this.commonService.trimUrl(data.name);

					this.navCtrl.navigateForward('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
						{
							animated: false,
							"state": {
								"breadcrumb": data.type,
								"url": '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
								"productDetail": true,
								productData: data
							}
						});

				}
			}
		}, error => {
			console.log(error);
		});



	}

	handlePlayClick() {
		this.myVideo.nativeElement.play();
		this.showPlayIcon = false;
	}

	handleVideoPlay() {
		this.showPlayIcon = false;
	}

	handleVideoPause() {
		this.showPlayIcon = true;
	}

	async goToListing(data) {
		data['isDataCalled'] = true;
		if (data) {
			let category_brand_name = this.commonService.trimUrl(data.name);
			await this.navCtrl.navigateForward('/home/product-listing/' + category_brand_name,
				{
					animated: false, "state": {
						"breadcrumb": "Product List",
						"url": '/home/product-listing/' + category_brand_name,
						stateData: data
					}
				}).then(() => {
					this.commonService.localStorageRemove("searchData");
					this.commonService.filterDataEmit(data);
				})
		}
	}

	getAddress(productDat) {
		let params: any;
		if (this.commonService.userData && this.commonService.userData.customer_type != 'INDIVIDUAL') {
			params = {
				"user_type": "CUSTOMER",
				"company_id": this.commonService.localStorageGet('company_id')
			}
		}
		this.subscription.add(
			this.dataService.get(URLS.getAddress, params).subscribe(data => {
				if (data['code'] == 200) {
					if (data['data']['default_shipping']) {
						let defaultAddress = data['data']['default_shipping'];
						this.locationService.latitude = defaultAddress['latitude'];
						this.locationService.longitude = defaultAddress['longitude'];
						this.commonService.localStorageSet('shipping_address_id', defaultAddress['company_address_id'] ? defaultAddress['company_address_id'] : defaultAddress['user_address_id']);
						const deliveryLocationData = {
							address: defaultAddress['address'],
							address_type_id: defaultAddress['address_type_id'],
							latitude: defaultAddress['latitude'],
							longitude: defaultAddress['longitude'],
							state: defaultAddress.state,
							state_id: defaultAddress.state_id
						}
						this.commonService.localStorageSet('deliverLocation', JSON.stringify(deliveryLocationData))
						if (data.product_id) {

							let categoryName = this.commonService.trimUrl(data.category);
							let productName = this.commonService.trimUrl(data.name);

							this.navCtrl.navigateForward('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
								{
									animated: false, "state": {
										"breadcrumb": data.type,
										"url": '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
										"productDetail": true,
										productData: data
									}
								});

						}
					}
					else {
						this.addressPopUp(productDat);
					}
				}

			}, error => {
				this.toasterService.errorToast(error['message']);

			})
		)
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
	isFirefox(): boolean {
		if (isPlatformBrowser(this.platformId) && navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
			return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
		}
		if (isPlatformBrowser(this.platformId) && navigator.userAgent.toLowerCase().indexOf('safari') > -1) {
			return navigator.userAgent.toLowerCase().indexOf('safari') > -1
		}
		return false;
	}
}


