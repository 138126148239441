import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { CustomValidator } from './../../_common/customvalidators';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { ToasterService } from './../../services/toaster.service';

@Component({
	selector: 'app-create-new-password',
	templateUrl: './create-new-password.component.html',
	styleUrls: ['./create-new-password.component.scss'],
})
export class CreateNewPasswordComponent implements OnInit {

	public changePasswordForm: FormGroup;
	public subscription = new Subscription();
	isLoading = false;
	public userData: any;
	public mobile_no: any;
	oldPasswordType = 'password';
	newPasswordType = 'password';
	confirmPasswordType = 'password';
	token: any;
	paramsData: any;

	public validation_messages = {
		new_password: [
			{ type: 'required', message: 'Please enter a new password' },
			{ type: 'invalidPassword', message: 'Password mismatch' },
		],
		confirm_password: [
			{ type: 'required', message: 'Re-enter password' },
			{ type: 'passMismatch', message: 'Password mismatch' },
		],
	}

	constructor(
		private formBuilder: FormBuilder,
		private toasterService: ToasterService,
		private dataService: ApiService,
		private navCtrl: NavController,
		public commonService: CommonService,
		private route: ActivatedRoute

	) { }

	ngOnInit() {
		this.commonService.localStorageRemove('otpVerified');
		this.changePasswordForm = this.initializeForm();



	}

	initializeForm() {
		return this.formBuilder.group({
			new_password: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.passwordPatternValidator]),
			}),
			confirm_password: new FormControl(null, {
				validators: Validators.compose([Validators.required,
				CustomValidator.confirmPassword]),
			})
		});
	}

	save() {
		for (const i in this.changePasswordForm.controls) {
			this.changePasswordForm.controls[i].markAsDirty();
			this.changePasswordForm.controls[i].updateValueAndValidity();
			this.changePasswordForm.controls[i].markAsTouched();
		}
		if (this.changePasswordForm.invalid) {
			return
		}

		let forgotData = this.commonService.localStorageGet('ForgotUserData');
		let decrepatedData = forgotData ? JSON.parse(this.commonService.decryptData(forgotData)) : null;

		if (forgotData && decrepatedData) {
			this.paramsData = {
				reference_id: decrepatedData.reference_id,
				reference_type: decrepatedData.reference_type,
				new_password: this.changePasswordForm.controls.confirm_password.value
			}
		} else {
			let userInfo = this.commonService.localStorageGet("forgot_password_details");
			let decryptedUserData = this.commonService.decryptData(userInfo);
			this.userData = JSON.parse(decryptedUserData);
			this.mobile_no = this.userData.mobile_no;
			this.paramsData = {
				mobile_no: this.mobile_no,
				new_password: this.changePasswordForm.controls.confirm_password.value
			}
		}

		let encryptedData = this.commonService.encryptData(JSON.stringify(this.paramsData));
		const params = {
			data: encryptedData
		}
		this.isLoading = true;
		this.subscription.add(
			this.dataService.put(URLS.createPassword, params).subscribe(data => {
				if (data['code'] == 200) {
					this.toasterService.successToast(data['message']);
					this.changePasswordForm.reset();
					this.commonService.localStorageClear();
					this.navCtrl.navigateForward('/login');
					this.isLoading = false;
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);

			})
		)
	}
}
