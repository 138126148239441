import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { URLS } from './../../_config/api.config';
import { Subscription } from 'rxjs';
import { ApiService } from './../../services/api-data.service';
import { ToasterService } from './../../services/toaster.service';
import { CommonService } from '../../services/common.service';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {

  public subscription = new Subscription();
  public isLoading = false;
  public tutorialData = [];
  public faqData = [];
  public faq = true;
  public customerFaq = true;
  public tabList = [
    {
      "name": 'Customer FAQ`s',
      "id": 1,
      "value": "CUSTOMER_FAQ"
    },
    {
      "name": 'Seller FAQ`s',
      "id": 2,
      "value": "SELLER_FAQ"
    },

  ]
  public faqType =  "CUSTOMER_FAQ"

  config :  SwiperOptions = {
    speed: 400,
    spaceBetween: 30,
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        360: {
            slidesPerView: 2,
        },
        375: {
            slidesPerView: 2,
        },
        385:{
            slidesPerView: 2,
        },
        488: {
            slidesPerView: 3,
        },
        768: {
            slidesPerView: 3,
        },
        850: {
            slidesPerView: 3,
        },
        1100: {
            slidesPerView: 4,
        },

       
    },
};

  constructor(
    private dataService: ApiService,
    private toasterService: ToasterService,
    public commonService: CommonService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.getFaq();
  }

  getFaq() {
    const params = {
      faq_type: this.faqType
    }
    this.isLoading = true;
    this.subscription.add(
      this.dataService.get(URLS.getFaq, params).subscribe(data => {
        if (data['code'] == 200 && data['data']) {
          this.tutorialData = [];
          this.faqData = [];
          let videoData = data['data']['videoList'];
          let faqData = data['data']['faqList'];
          if(this.faqType == 'CUSTOMER_FAQ'){
            this.customerFaq = true;
          } else {
            this.customerFaq = false;
          }
          if (videoData && videoData.length > 0) {
            videoData.forEach(element => {
              let obj = {
                category_name: element.category_name ? element.category_name : '',
                title: element.title ? element.title : '',
                video_url: element.video_url ? element.video_url : null,
                thumbnail: element.thumbnail ? element.thumbnail : null
              }
              this.tutorialData.push(obj);
            });
          }
          if (faqData && faqData.length > 0) {
            faqData.forEach(element => {
              let obj = {
                category_name: element.category_name ? element.category_name : '',
                faq_list: element.faq_list ? element.faq_list : null,
                faq_category_id: element.faq_category_id ? element.faq_category_id : null
              }

              this.faqData.push(obj);
            });
          }
          this.isLoading = false;
        }
        else {
          this.isLoading = false;
          this.toasterService.errorToast(data['message']);
        }
      }, error => {
        this.isLoading = false;
        this.toasterService.errorToast(error['message']);

      })
    )
  }

  elementClicked(event: any) {
    const target = event.target || event.srcElement || event.currentTarget;
    if (!target) return;
    const href = target.attributes.href;
    if (!href) return;
    if (!isPlatformServer(this.platformId)) {
      if (href.value.includes('#')) {
        event.stopPropagation();
        event.preventDefault();
        const element = this.document.getElementById(href.value.replace('#', ''));
        element.scrollIntoView();
      }
    }
  }

  userTypeChange(value){
    this.faqType = value;
    this.getFaq();
  }
  

}
