import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { environment } from "src/environments/environment";
import { URLS } from './_config/api.config';
import { ApiService } from './services/api-data.service';
import { CommonService } from './services/common.service';
import { GetPlatformService } from './services/get-platform.service';
import { ToasterService } from './services/toaster.service';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})

export class AppComponent {
	private subscription: Subscription = new Subscription();
	public selectedIndex = 0;
	public appPages = [
		{
			title: "Inbox",
			url: "/folder/Inbox",
			icon: "mail"
		},
		{
			title: "Outbox",
			url: "/folder/Outbox",
			icon: "paper-plane"
		},
		{
			title: "Favorites",
			url: "/folder/Favorites",
			icon: "heart"
		},
		{
			title: "Archived",
			url: "/folder/Archived",
			icon: "archive"
		},
		{
			title: "Trash",
			url: "/folder/Trash",
			icon: "trash"
		},
		{
			title: "Spam",
			url: "/folder/Spam",
			icon: "warning"
		}
	];
	public labels = ["Family", "Friends", "Notes", "Work", "Travel", "Reminders"];
	disclaimerDialog = false;
	constructor(
		private getPlatformService: GetPlatformService,
		public getPlatfrom: GetPlatformService,
		private metaTagService: Meta,
		private titleService: Title,
		private router: Router,
		public commonService: CommonService,
		public toasterService: ToasterService,
		public dataService: ApiService,
		private modalCtrl: ModalController,
		public route: ActivatedRoute
	) {
		this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				const title = this.getTitle(
					router.routerState,
					router.routerState.root
				);
				this.commonService.isLoggedIn = this.commonService.localStorageGet('accessToken') || this.commonService.isLoggedIn ? true : false;
				if (this.commonService.isLoggedIn) {
					this.getNotificationCount();
				}
				if (title.length) {
					this.titleService.setTitle(
						"Conkart | " +
						title[title.length - 1]
					);
					this.metaTagService.updateTag({
						property: "og:title",
						content: "Conkart | " + title[title.length - 1],
					});

					const url = this.router.url;
					let segments = url.split('/');

					this.metaTagService.updateTag({
						name: "keywords",
						content:
							"ConKart, Construction material, Cement, Tiles, Aggregates, Bricks, Chemical, Sand, Brands, Categories, Steel, Rods, Consultant request, "
							+ title[title.length - 1]
							+ segments
					});

					setTimeout(() => {
						this.commonService.scrollToTop();
					}, 300)

				}

				let isModalOpen;
				this.modalCtrl.getTop().then((data) => {
					isModalOpen = data;
					if (isModalOpen) {
						this.modalCtrl.dismiss();
					}
				});
			}
		});

		setTimeout(() => {
			if (this.commonService.localStorageGet('disclaimerClosed') != 'true') {
				this.disclaimerDialog = true;
			}
		}, 3000)
	}

	closeDisclaimer() {
		this.commonService.localStorageSet('disclaimerClosed', 'true');
		this.disclaimerDialog = false;

	}


	ngOnInit() {

		const path = window.location.pathname.split("folder/")[1];
		this.getPlatformService.getPlatformType();
		if (path !== undefined) {
			this.selectedIndex = this.appPages.findIndex(
				page => page.title.toLowerCase() === path.toLowerCase()
			);
		}
		this.metaTagService.updateTag({
			property: "og:url",
			content: environment.webUrl,
		});
	}

	refreshing(event: any) {
		setTimeout(() => {
			event.target.complete();
		}, 2000);
	}

	public getTitle(state, parent) {
		const data = [];
		if (parent && parent.snapshot.data && parent.snapshot.data.title) {
			data.push(parent.snapshot.data.title);
		}

		if (state && parent) {
			data.push(...this.getTitle(state, state.firstChild(parent)));
		}
		return data;
	}

	getNotificationCount() {
		this.commonService.notificationCount = null;
		let param = {}
		param['page'] = 1;
		param['limit'] = 10;
		param['user_type'] = this.commonService.userData['isSeller'] ? 'SELLER' : 'CUSTOMER';
		this.subscription.add(
			this.dataService.get(URLS.notificationList, param).subscribe(data => {
				if (data['code'] == 200) {
					let result = data['data'];
					this.commonService.localStorageSet('unreadNotificationCount', result.total_unread_count.toString());
					this.commonService.notificationCount = result.total_unread_count;
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.toasterService.errorToast(error['message']);

			})
		)

	}
}