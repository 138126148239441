<div class="side-menu isDesktop" *ngIf="!isLoading && menuOptions && menuOptions.length > 0">
	<div class="profile-details">
		<img *ngIf="profileImage" [src]="profileImage" class="image-uploaded" alt="profile image" height="100"
			width="100">
		<img *ngIf="!profileImage" [src]="'/assets/svg/profile.svg'" class="ProfileIcon" alt="profile image"
			height="100" width="100">
		<div class="detail fw-600">

			<div class="user-name">
				{{commonService.userData ? commonService.userData['name'] : ''}}
			</div>
			<div class="capitalize type fs-14 fw-500" *ngIf="commonService.userData">
				<span class="capitalize UserType">
					<span> {{commonService.userData['user_profile'] == profileEnum.Individual ? 'Individual' :
						'Enterprise'}}</span>
					{{commonService.userData['isCustomer'] ? 'Buyer' :'Seller'}}</span>
			</div>
			<div *ngIf="commonService.is_admin" class="blue-label mt-5 fs-14 fw-500">
				Admin
			</div>
		</div>
	</div>
	<div class="menu">
		<ion-list>
			<ng-container *ngFor="let menu of menuOptions;let i = index;">
				<ion-item *ngIf="menu.menu_name !=  'Logout'" (click)="sidemenuChange(menu.link)" class="fs-16 fw-600"
					lines='none' [routerLinkActive]="menu.link ? 'item-active-left' : ''"
					[routerLink]="menu.link ? menu.link : '/'">
					<a>
						{{menu.menu_name }}
					</a>
				</ion-item>
				<ion-item *ngIf="menu.menu_name ==  'Logout'" (click)="logoutAlert() "
					class="fs-16 fw-600 cursor-pointer" lines='none'>
					<a>
						{{menu.menu_name }}
					</a>
				</ion-item>
			</ng-container>
		</ion-list>
	</div>
</div>

<div *ngIf="isMobileSideMenu && commonService.isLoggedIn" class="mobile-menu isMobile">
	<div class="profile-details">
		<img *ngIf="profileImage" [ngSrc]="profileImage" priority class="image-uploaded" alt="profile image"
			height="100" width="100">
		<img *ngIf="!profileImage" [ngSrc]="'/assets/svg/profile.svg'" priority class="ProfileIcon" alt="profile image"
			height="100" width="100">
		<div class="detail fs-22 fw-600">
			{{commonService.userData ? commonService.userData['name'] : ''}}
			<div class="capitalize type fs-14 fw-500" *ngIf="commonService.userData">
				<span class="capitalize UserType">
					<span> {{commonService.userData['user_profile'] == profileEnum.Individual ? 'Individual' :
						'Enterprise'}}</span>
					{{commonService.userData['isCustomer'] ? 'Buyer' :'Seller'}}</span>
			</div>
			<div *ngIf="commonService.is_admin" class="blue-label mt-5 fs-14 fw-500">
				Admin
			</div>
			<div class="pointer fs-14 fw-500 switch-profile" *ngIf="!commonService.is_sub_user">
				<div (click)="userSwitch(commonService.userData)" class="inline-block"
					*ngIf="commonService.userData.user_type  == 'BOTH'">
					<span *ngIf="commonService.userData.isSeller" class="grey-box"> Switch to Buyer
					</span>
					<span *ngIf="commonService.userData.isCustomer" class="grey-box"> Switch to Seller
					</span>
				</div>
				<div (click)="becomeAUser()"
					*ngIf="commonService.userData && commonService.userData.user_type  != 'BOTH'">
					<span *ngIf="commonService.userData && commonService.userData.user_type === profileEnum.Customer"
						class="label-box">Become
						a
						Seller</span>
					<span *ngIf="commonService.userData && commonService.userData.user_type === profileEnum.Seller"
						class="label-box">Become
						a
						Buyer</span>
				</div>
			</div>

		</div>
	</div>
	<div class="menu">
		<ion-list>
			<ng-container *ngFor="let menu of menuOptions;let i = index;">
				<ion-item *ngIf="menu.menu_name !=  'Logout'" class="fs-16 fw-600" lines='none'
					(click)="sidemenuChange(menu.link)" [routerLinkActive]="menu.link ? 'item-active-left' : ''"
					[routerLink]="menu.link ? menu.link : '/'">
					<a>
						{{menu.menu_name }}
					</a>
				</ion-item>
				<ion-item *ngIf="menu.menu_name ==  'Logout'" (click)="logoutAlert() " class="fs-16 fw-600"
					lines='none'>
					<a>
						{{menu.menu_name }}
					</a>
				</ion-item>
			</ng-container>
		</ion-list>
	</div>
</div>


<div class="side-menu isDesktop" *ngIf="isLoading || !menuOptions || menuOptions.length < 1">
	<div class="profile-details">
		<img class="ProfileIcon" alt="profile icon" height="100" width="100"
			[ngSrc]="isEnterprise ? 'assets/img/person.png': '/assets/svg/profile.svg'" priority>
		<div class="detail fs-22 fw-600">
			<ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
				[animated]="true"></ion-skeleton-text>
			<div class="capitalize type fs-14 fw-500">
				<div class="capitalize UserType">
					<div>
						<ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
							[animated]="true"></ion-skeleton-text>
					</div>
					<ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
						[animated]="true"></ion-skeleton-text>
				</div>
			</div>

		</div>
	</div>
	<div class="menu">
		<ion-list>
			<ion-item>
				<ion-skeleton-text class="skeleton-container  fs-14 fw-600" name="lines-sharp-small"
					[animated]="true"></ion-skeleton-text>
			</ion-item>
		</ion-list>
	</div>
</div>