<div class="change-password-container">
	<div class="inner-contain">
		<form [formGroup]="changePasswordForm" class="unselectable change-pass">
			<div class="form-inputs psw-position password common-input pb-15">
				<ion-label>Old password *</ion-label>
				<ion-input clearOnEdit="false" class="registration-pass" type="{{oldPasswordType}}"
					formControlName="old_password" placeholder="Enter Old Password"
					(keydown.space)="$event.preventDefault();">
				</ion-input>
				<img class="input-icon" *ngIf="oldPasswordType === 'text'" (click)="oldPasswordType = 'password'"
					src="/assets/icon/eye.svg" alt="Lock Icon">
				<img class="input-icon" *ngIf="oldPasswordType === 'password'" src="../../../assets/icon/eye-off.svg"
					(click)="oldPasswordType = 'text'" alt="Lock Icon">
				<ng-container *ngFor="let validation of validation_messages.old_password">
					<ng-container
						*ngIf="changePasswordForm.get('old_password').hasError(validation.type) && changePasswordForm.get('old_password').dirty">
						<ion-text color="danger" class="error-msg">
							{{validation.message }}
						</ion-text>
					</ng-container>
				</ng-container>
			</div>
			<div class="form-inputs password  psw-position common-input pb-15">
				<ion-label>New password *</ion-label>
				<ion-input clearOnEdit="false" placeholder="Enter New Password" class="registration-pass"
					type="{{newPasswordType}}" formControlName="new_password"
					(keydown.space)="$event.preventDefault();">
				</ion-input>
				<img alt="Open Lock" class="input-icon" *ngIf="newPasswordType === 'text'"
					(click)="newPasswordType = 'password'" src="/assets/icon/eye.svg">
				<img class="input-icon" *ngIf="newPasswordType === 'password'" src="../../../assets/icon/eye-off.svg"
					(click)="newPasswordType = 'text'" alt="Lock Icon" />
				<ng-container *ngFor="let validation of validation_messages.new_password">
					<ng-container
						*ngIf="changePasswordForm.get('new_password').hasError(validation.type) && changePasswordForm.get('new_password').dirty">
						<ion-text color="danger" class="error-msg">
							{{validation.message }}
						</ion-text>
					</ng-container>
				</ng-container>
			</div>
			<div class="form-inputs password psw-position common-input pb-15">
				<ion-label>Confirm password *</ion-label>
				<ion-input clearOnEdit="false" placeholder="Re-Enter New Password" class="registration-pass"
					type="{{confirmPasswordType}}" formControlName="confirm_password"
					(keydown.space)="$event.preventDefault();">
				</ion-input>
				<img alt="Open Lock" class="input-icon" *ngIf="confirmPasswordType === 'text'"
					(click)="confirmPasswordType = 'password'" src="/assets/icon/eye.svg">
				<img class="input-icon" *ngIf="confirmPasswordType === 'password'"
					src="../../../assets/icon/eye-off.svg" (click)="confirmPasswordType = 'text'" alt="Lock Icon" />
				<ng-container *ngFor="let validation of validation_messages.confirm_password">
					<ng-container
						*ngIf="changePasswordForm.get('confirm_password').hasError(validation.type) && changePasswordForm.get('confirm_password').dirty">
						<ion-text color="danger" class="error-msg">
							{{validation.message }}
						</ion-text>
					</ng-container>
				</ng-container>
			</div>

		</form>
		<div class="form-container mt-20 form-button">
			<button (click)="changePassword()" type="primary" class="mr-20 w-169 submit-btn"
				[disabled]="isLoading ||
			!changePasswordForm.get('old_password').value || !changePasswordForm.get('new_password').value || !changePasswordForm.get('confirm_password').value">
				<ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
				Save
			</button>
			<button class="clear-btn w-169" (click)="cancel()">Cancel</button>
		</div>
	</div>
</div>