import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IonInput, NavController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CartService } from 'src/app/services/cart.service';
import { LocationService } from 'src/app/services/location.service';
import { CustomValidator } from './../../_common/customvalidators';
import { profileTypeEnum } from './../../_common/enum.enum';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { ModalService } from './../../services/modal.service';
import { ToasterService } from './../../services/toaster.service';
import { RegistrationTypeComponent } from './../registration-type/registration-type.component';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	@ViewChild('usernameInput', { static: false }) usernameInput!: IonInput;
	profileEnum = profileTypeEnum;
	passwordType = 'password';
	modal: any;
	isLoading = false;
	public locationData: any;
	public validation_messages = {
		username: [
			{ type: 'required', message: 'Please enter your email address/phone number.' },
			{ type: 'invalidEmailPhone', message: 'Incorrect email address/phone number' }
		],
		password: [
			{ type: 'required', message: 'Please enter a password.' },
		],
	}

	loginFromGroup: FormGroup;
	private subscription: Subscription = new Subscription();
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private commonService: CommonService,
		private modalService: ModalService,
		private navCtrl: NavController,
		private toasterService: ToasterService,
		private dataService: ApiService,
		private platform: Platform,
		private cartService: CartService,
		private locationService: LocationService

	) {

		let state = this.router.getCurrentNavigation().extras.state;
		if (state && state.locationData) {
			this.locationData = state.locationData
		}

	}

	ngOnInit() {
		if (!this.locationData) {
			this.commonService.localStorageRemove('deliverLocation');
		}
		this.loginFromGroup = this.initializeLoginFromGroup();
		setTimeout(() => {
			if (this.usernameInput) {
				this.usernameInput.setFocus();
			}
		}, 1000);
	}

	ionViewWillEnter() {
		this.isLoading = false;
		this.subscription = new Subscription();
		if (!this.locationData) {
			this.commonService.localStorageRemove('deliverLocation');
		}
		this.loginFromGroup = this.initializeLoginFromGroup();

	}

	get f1() { return this.loginFromGroup.controls; }

	initializeLoginFromGroup() {
		return this.formBuilder.group({
			username: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.emailPhoneValidator]),
			}),
			password: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator]),
			})
		})
	}

	login() {
		for (const i in this.f1) {
			this.f1[i].markAsDirty();
			this.f1[i].updateValueAndValidity();
			this.f1[i].markAsTouched();
		}

		if (this.loginFromGroup.invalid) {
			return
		}
		const userInfo = {
			user_name: this.f1['username'].value,
			password: this.f1['password'].value,
			platform: this.platform.is('desktop') ? "WEB" : "MOBILE"
		}
		const encryptedData = this.commonService.encryptData(JSON.stringify(userInfo));
		if (!encryptedData) {
			return;
		}
		const params = {
			data: encryptedData
		}
		this.isLoading = true;
		this.subscription.add(
			this.dataService.post(URLS.login, params).subscribe(data => {
				this.isLoading = false;
				if (data['code'] == 200) {
					if (!this.locationData && this.commonService.localStorageGet('deliverLocation') != null) {
						this.commonService.localStorageRemove('deliverLocation');
					}
					this.commonService.is_sub_user = data['data']['is_sub_user'];
					this.commonService.localStorageSet('accessToken', data['data']['session_token']);
					this.commonService.localStorageSet('company_id', data['data']['company_id']);
					let userResponseInfo = {
						user_type: data['data']['user_type'] ? data['data']['user_type'] : '',
						customer_type: data['data']['customer_type'] ? data['data']['customer_type'] : '',
						seller_type: data['data']['seller_type'] ? data['data']['seller_type'] : '',
						user_profile: data['data']['seller_type'] && data['data']['seller_type'] != 'NONE' ? data['data']['seller_type'] : data['data']['customer_type'],
						approval_status: data['data']['approval_status'] ? data['data']['approval_status'] : '',
						isCustomer: data['data']['user_type'] == 'BOTH' || data['data']['user_type'] == this.profileEnum.Customer ? true : false,
						isSeller: data['data']['user_type'] == this.profileEnum.Seller ? true : false,
						name: data['data']['first_name'] + ' ' + data['data']['last_name'],
						profile_picture: data['data']['profile_picture']

					}
					this.commonService.accessToken = data['data']['session_token'];
					this.commonService.isLoggedIn = true;
					this.commonService.userData = userResponseInfo;
					this.commonService.localStorageSet('customer_approval', data['data']['approval_status'] ? data['data']['approval_status'] : 'NONE');
					this.commonService.localStorageSet('seller_approval', data['data']['seller_approval_status'] ? data['data']['seller_approval_status'] : 'NONE');
					this.commonService.localStorageSet('info', JSON.stringify(userResponseInfo));

					this.commonService.setEvent('userResponseInfo', userResponseInfo);
					this.commonService.setEvent('profileData', 'true');
					this.commonService.setEvent('sidemenuChnage', "true");
					this.cartService.getCartList();
					this.commonService.localStorageSet('is_sub_user_admin', data['data']['is_sub_user_admin']);
					this.commonService.getUserDetail();
					this.getNotificationCount();
					setTimeout(() => {
						if (data['data']['user_type'] == 'SELLER') {
							this.navCtrl.navigateRoot(['/home/account-details/my-profile'], { animated: false }).then(() => {
								this.loginFromGroup.reset();
							});
						} else {
							this.getAddress()
							if (this.locationData && this.locationData.productData) {
								let categoryName = this.commonService.trimUrl(this.locationData.productData.category);
								let productName = this.commonService.trimUrl(this.locationData.productData.name);
								this.navCtrl.navigateRoot('/home/product-detail/' + categoryName + "/" + productName + "/" + this.locationData.productData.product_id, { animated: false, "state": { "breadcrumb": this.locationData.productData.type, "url": '/home/product-detail/' + categoryName + "/" + productName + "/" + this.locationData.productData.product_id, "productDetail": true, productData: this.locationData.productData } }).then(() => {
									this.loginFromGroup.reset();
									this.commonService.setEvent('loginProduct', this.locationData.productData);
								});

							}
							else if (this.locationData && this.locationData.from && this.locationData.from == 'cart') {
								this.navCtrl.navigateRoot(['/cart'], { animated: false }).then(() => {
									this.loginFromGroup.reset();
								});
							}
							else {
								this.navCtrl.navigateRoot(['/home'], { animated: false }).then(() => {
									this.loginFromGroup.reset();
								});
							}
						}
					}, 500);

				}
				else {
					this.toasterService.errorToast(data['message'], 'top');
				}
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message'], "top");

			})
		)

	}

	getAddress() {
		let params: any;
		if (this.commonService.userData && this.commonService.userData.customer_type != 'INDIVIDUAL') {
			params = {
				"user_type": "CUSTOMER",
				"company_id": this.commonService.localStorageGet('company_id')
			}
		}
		this.subscription.add(
			this.dataService.get(URLS.getAddress, params).subscribe(data => {
				if (data['code'] == 200) {
					if (data['data']['default_shipping']) {
						let defaultAddress = data['data']['default_shipping'];
						this.locationService.latitude = defaultAddress['latitude'];
						this.locationService.longitude = defaultAddress['longitude'];
						const deliveryLocationData = {
							address: defaultAddress['address'],
							address_type_id: defaultAddress['address_type_id'],
							latitude: defaultAddress['latitude'],
							longitude: defaultAddress['longitude'],
							state: defaultAddress.state,
							state_id: defaultAddress.state_id
						}
						this.commonService.localStorageSet('deliverLocation', JSON.stringify(deliveryLocationData))
						this.commonService.localStorageSet('shipping_address_id', defaultAddress['company_address_id'] ? defaultAddress['company_address_id'] : defaultAddress['user_address_id']);
						this.commonService.localStorageSet('isAddressChanged', 'false')

					}
				}

			}, error => {
				this.toasterService.errorToast(error['message']);

			})
		)
	}
	createAccount() {
		let obj = {
			component: RegistrationTypeComponent,
			backdropDismiss: false,
			canDismiss: true,
			cssClass: 'success-modal',
			componentProps: {
				locationData: this.locationData
			}
		};
		this.modal = this.modalService.openModal(obj);
		this.modal.then(value => {
		}, error => {
			console.log(error);
		});

	}

	forgetPass() {
		this.loginFromGroup.reset();
		this.navCtrl.navigateForward('/forgot-password');
	}

	getNotificationCount() {
		this.commonService.notificationCount = null;
		let param = {}
		param['page'] = 1;
		param['limit'] = 10;
		param['user_type'] = this.commonService.userData['isSeller'] ? 'SELLER' : 'CUSTOMER';
		this.subscription.add(
			this.dataService.get(URLS.notificationList, param).subscribe(data => {
				if (data['code'] == 200) {
					let result = data['data'];
					this.commonService.localStorageSet('unreadNotificationCount', result.total_unread_count.toString());
					this.commonService.notificationCount = result.total_unread_count;
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.toasterService.errorToast(error['message']);

			})
		)

	}
}
