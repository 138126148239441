import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ModalService } from './../../services/modal.service';

@Component({
    selector: 'app-registration-type',
    templateUrl: './registration-type.component.html',
    styleUrls: ['./registration-type.component.scss'],
})
export class RegistrationTypeComponent implements OnInit {

    @Input() locationData: any;

    constructor(
        private router: Router,
        private modalService: ModalService,
        private navCtrl: NavController,
    ) { }

    ngOnInit() {
    }

    async createAccount(type) {
        this.modalService.dismissModal();
        if (type == 'seller') {
            this.navCtrl.navigateForward(['/seller-register'], { animated: false });
        }
        else if (type == "customer") {
            await this.navCtrl.navigateForward(['/registration'], { animated: false, state: { locationData: this.locationData } });
        }
    }

    async close() {
        this.modalService.dismissModal();
    }
}
