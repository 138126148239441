import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { ToasterService } from './../../services/toaster.service';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {

    public subscription = new Subscription();
    public isLoading = false;
    public aboutUsData: any;

    constructor(
        private dataService: ApiService,
        private toasterService: ToasterService,
        public commonService: CommonService,
    ) { }

    ngOnInit() {
        this.getAboutUs();
    }

    getAboutUs() {
        this.subscription.add(
            this.dataService.get(URLS.getAboutUs).subscribe(data => {
                if (data['code'] == 200 && data['data']) {
                    this.aboutUsData = data['data']['description'];
                }
                else {
                    this.isLoading = false;
                    this.toasterService.errorToast(data['message']);
                }
            }, error => {
                this.isLoading = false;
                this.toasterService.errorToast(error['message']);

            })
        )
    }


}
