import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'price'
})
export class PriceFormatPipe implements PipeTransform {
    transform(input: any, args?: any): any {
        if (!args) args = 1;
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "K" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function (item) {
            return input >= item.value;
        });
        return item ? (input / item.value).toFixed(args).replace(rx, "$1") + item.symbol : "0";

    }
}
