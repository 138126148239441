<div class="manage-user">
    <div class="form-button add-user" *ngIf="!commonService.userData.isCustomer">
        <button class="submit-btn w-200" (click)="addVehicle()" [disabled]="isSellerApproval"
            [ngClass]="[ isSellerApproval ? 'not-allowed' : '']">Add
            New Vehicle</button>
    </div>
    <ng-container>
        <div class="list mt-30">

            <app-table-listing [settings]="column_settings" [widthConfig]="widthConfig" [pageType]="'manage_vehicles'"
                [listOfAllData]="listOfAllData" [pageIndex]="pageNo"
                [isStickyColumn]="getPlatformService.isDesktop ? true : false" (sortByEvent)="getVehicleList($event)" [isLoading]="isLoading"></app-table-listing>

            <div class="pagination-wrapper" *ngIf="listOfAllData && listOfAllData.length > 0" [ngClass]="[totalCount < 10 && getPlatformService.isDesktop ?'pagination-justify-content-end':totalCount < 10 &&
                    !getPlatformService.isDesktop? 'pagination-content-start' :'']">
                <ng-container *ngIf="totalCount > 10">
                    <div class="items">
                        Items
                        <ng-select [searchable]="false" (change)="changeListItem($event)" [(ngModel)]="paginationVal"
                            [clearable]="false" class="pagination" [items]="commonService.paginationList"
                            bindLabel="label" bindValue="value" [searchable]="false" [editable]="false"
                            [searchFn]="false">
                        </ng-select>
                        <ion-label class="perpage-title">of {{totalCount}}</ion-label>
                    </div>
                </ng-container>
                <div>
                    <ion-row class="pagination-div">
                        <ion-col size="12" class="pagination-col return-orders-pagination-col">
                            <app-tile class="grid-tile" *ngFor="let post of listOfAllData | paginate :
                                                      { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount };
                                                      let i = index" [current]="post"></app-tile>
                            <pagination-controls [maxSize]="5" class="paginator" previousLabel="" nextLabel=""
                                (pageChange)="onChange($event)"></pagination-controls>
                        </ion-col>
                    </ion-row>
                </div>
            </div>
        </div>
    </ng-container>
</div>