import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ProductDetailPageService } from 'src/app/services/product-detail-page.service';
import { CommonService } from '../../services/common.service';
import { LocationService } from '../../services/location.service';
import { ModalService } from '../../services/modal.service';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { ToasterService } from './../../services/toaster.service';
@Component({
    selector: 'app-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {

    public subscription = new Subscription();
    @Input() menuVisibility;
    @ViewChild('menu') menu: ElementRef;
    @Output() public childEvent = new EventEmitter();
    menuHeight = 0;
    selectedId: number;
    selectedCatId: number;
    selectedImage: any;
    isSubMenuVisible = true;
    valueOption = [];
    selectedSubCategory: any;
    menuOptions = [];
    menuOptions2 = [];
    horizontalCordinates: any;
    verticalCordinates: any;
    selectedValue: any;
    public itemsPerPage = 9;
    public currentPage = 1;
    productsList = [];
    isLoading = false;
    constructor(
        private dataService: ApiService,
        private toasterService: ToasterService,
        public commonService: CommonService,
        private navCtrl: NavController,
        private locationService: LocationService,
        private modalService: ModalService,
        private productDetailPageService: ProductDetailPageService
    ) { }

    ngOnInit() {
        this.getMasterCategory();
    }


    // hide menu on category clicks
    menuHide() {
        this.menuVisibility = false;
        this.childEvent.emit(this.menuVisibility);
    }


    async openItem(menu, option) {
        await this.navCtrl.navigateForward(['/home/product-listing'], { animated: false, "state": { "breadcrumb": "Product List", "url": '/home/product-listing' } })
        let menuObj = {
            category_id: menu.category_id,
            attribute_id: menu.id,
            isDataCalled: true
        }
        this.menuVisibility = false;
        this.menuHide();
        this.commonService.filterDataEmit(menuObj, option);
    }

    async viewAll(menu) {
        await this.navCtrl.navigateForward(['/home/product-listing'], { animated: false, "state": { "breadcrumb": "Product List", "url": '/home/product-listing' } })
        let menuObj = {
            category_id: menu.category_id,
            isDataCalled: true
        }
        this.menuVisibility = false;
        this.menuHide();
        this.commonService.filterDataEmit(menuObj, null);
    }

    async catItemChange(menu) {
        this.isLoading = true;
        this.selectedValue = null;
        this.selectedCatId = menu.id;
        this.selectedImage = menu.image_url;
        this.menuOptions2 = menu.sub_categories ? menu.sub_categories : [];
        this.productsList = [];
        if (this.menuOptions2 && this.menuOptions2.length) {
            this.selectedSubCategory = this.menuOptions2[0]['category_id']
            this.getProductList(this.selectedSubCategory);
        } else {
            this.isLoading = false
        }
    }

    async subCatItemChange(menu) {
        this.selectedSubCategory = menu.category_id;
        await this.getProductList(menu.category_id);
    }

    getMasterCategory() {
        this.isLoading = true;
        this.subscription.add(
            this.dataService.get(URLS.masterCategory).subscribe(data => {
                if (data['code'] == 200 && data['data']) {
                    let categoryData = data['data']['masterCategoryList'];
                    this.menuOptions = [];
                    let subCategoryData = [];
                    if (categoryData && categoryData.length) {
                        categoryData.forEach(element => {
                            if (element.sub_categories && element.sub_categories.length) {
                                subCategoryData = element.sub_categories;
                            } else {
                                subCategoryData = [];
                            }
                            let categoryObj = {
                                id: element.master_category_id ? element.master_category_id : null,
                                menu_name: element.master_category_name ? element.master_category_name : '',
                                image_url: element.master_category_image_url ? element.master_category_image_url : '',
                                sub_categories: subCategoryData ? subCategoryData : []
                            }

                            this.menuOptions.push(categoryObj);
                        });
                    }
                    if (this.menuOptions && this.menuOptions.length) {
                        this.selectedCatId = +this.menuOptions[0].id;
                        this.catItemChange(this.menuOptions[0]);
                        this.selectedImage = this.menuOptions[0].image_url
                        this.menuOptions2 = this.menuOptions[0]['sub_categories'] ? this.menuOptions[0]['sub_categories'] : [];
                        if (this.menuOptions2 && this.menuOptions2.length) {
                            this.selectedSubCategory = this.menuOptions2[0]['category_id']
                            this.getProductList(this.selectedSubCategory);
                        }
                    }

                }
                else {
                    this.toasterService.errorToast(data['message']);
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this.toasterService.errorToast(error['message']);
            })
        )
    }

    getProductList(categoryId) {
        return new Promise((resolve, reject) => {
            this.isLoading = true;
            let params = {}
            params = {
                page: this.currentPage,
                limit: this.itemsPerPage,
                category_id: categoryId
            }

            this.subscription.add(
                this.dataService.get(URLS.getProducts, params).subscribe(data => {
                    if (data['code'] == 200) {
                        if (data['data']) {
                            this.productsList = [];
                            let products = data['data']['productsList'];
                            if (products && products.length) {
                                products.forEach(element => {
                                    let obj = {
                                        img: element.image_url ? element.image_url : "../../../assets/product/product7.svg",
                                        name: element.product_name ? element.product_name : "--",
                                        unit_name: element.unit_name ? element.unit_name : "--",
                                        type: element.category_name ? element.category_name : "--",
                                        product_id: element.product_id ? element.product_id : null,
                                        sub_product_id: element.sub_product_id ? element.sub_product_id : null,
                                        product_brand_mapping_id: element.product_brand_mapping_id ? element.product_brand_mapping_id : null,
                                        product_package_mapping_id: element.product_package_mapping_id ? element.product_package_mapping_id : null,
                                        category: element.category_name ? element.category_name : null,
                                        category_id: element.category_id ? element.category_id : null,
                                        is_new: element.is_new ? element.is_new : false,
                                        rating_avg: element.productAvgRatings,
                                        package_value: element.package_value ? element.package_value : null
                                    }
                                    if (element.product_reviews_ratings && element.product_reviews_ratings.length) {
                                        obj['ratings'] = element.product_reviews_ratings[0]['rating_avg'] ? element.product_reviews_ratings[0]['rating_avg'] : null
                                    }
                                    this.productsList.push(obj);
                                });
                            }
                            this.isLoading = false;
                        }
                        resolve(data);
                        this.isLoading = false;
                    }
                    else {
                        reject(data);
                        this.isLoading = false;
                        this.toasterService.errorToast(data['message']);
                    }
                }, error => {
                    reject(error);
                    this.isLoading = false;
                    this.toasterService.errorToast(error['message']);
                })
            )
        })
    }

    goToDetail(data) {
        this.menuHide();
        this.productDetailPageService.goToProductDetail(data, true, '/home');
    }

}

