import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import SwiperCore, { A11y, Autoplay, Controller, Navigation, Pagination, Scrollbar, Thumbs, Virtual, Zoom } from 'swiper';
import { SwiperComponent } from "swiper/angular";
import { CommonService } from '../../services/common.service';
import { ApiService } from './../../services/api-data.service';
import { ToasterService } from './../../services/toaster.service';
import { URLS } from './../../_config/api.config';

SwiperCore.use([
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Virtual,
    Zoom,
    Autoplay,
    Thumbs,
    Controller
]);
@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
    @ViewChild('swiper', { static: false }) swiper: SwiperComponent;
    public subscription = new Subscription();
    isLoading = false;
    public page = 1;
    public limit = 5;

    img: string[] = [
        '/assets/img/deafult.jpg',
        '/assets/img/deafult.jpg',
        '/assets/img/deafult.jpg',

    ]

    bannerData: object[] = []

    constructor(private commonService: CommonService,
        private dataService: ApiService,
        private toasterService: ToasterService) { }

    ngOnInit() {
        this.getBannerData();
    }

    getBannerData() {
        const params = {
            page: this.page,
            limit: this.limit,
        }
        this.isLoading = true;
        this.subscription.add(
            this.dataService.get(URLS.getBannerData, params).subscribe(data => {
                if (data['code'] == 200) {
                    if (data['data']) {
                        let brandList = data['data']['brandsList'];
                        if (brandList && brandList.length) {
                            brandList.forEach(element => {
                                let obj = {
                                    description: element.description,
                                    banner_img: element.image_url
                                }
                                this.bannerData.push(obj);
                            });
                            if (this.bannerData && this.bannerData.length < 1) {
                                let obj = {
                                    description: "",
                                    banner_img: "assets/img/conkart-banner.png"
                                }
                                this.bannerData.push(obj);
                            }
                        } else {
                            if (this.bannerData && this.bannerData.length < 1) {
                                let obj = {
                                    description: "",
                                    banner_img: "assets/img/conkart-banner.png"
                                }
                                this.bannerData.push(obj);
                            }
                        }
                    }
                }
                else {
                    if (this.bannerData && this.bannerData.length < 1) {
                        let obj = {
                            description: "",
                            banner_img: "assets/img/conkart-banner.png"
                        }
                        this.bannerData.push(obj);
                    }
                    this.toasterService.errorToast(data['message']);
                }
                this.isLoading = false;
            }, error => {
                if (this.bannerData && this.bannerData.length < 1) {
                    let obj = {
                        description: "",
                        banner_img: "assets/img/conkart-banner.png"
                    }
                    this.bannerData.push(obj);
                }
                this.isLoading = false;
                this.toasterService.errorToast(error['message']);

            })
        )
    }

}
