<div class="list ">
    <ng-container *ngIf="!isLoading">
        <div class="table-listing" *ngIf="listData && listData.length > 0">
            <ng-container *ngFor="let data of listData;let i = index">
                <div class="align-center space-between p-15 notification-div" (click)="notificationRedirect(data)">
                    <div class="flex notification-flex">
                        <div class="mr-15 image-conatiner"
                            [ngClass]="data.is_read ? 'notification-icon-read':'notification-icon-unread'">
                            <img class="notif-img" alt="Notification"
                                [src]="data.is_read ? './assets/svg/bell.svg':'./assets/svg/bell-white.svg'">
                        </div>

                        <div class="notify-content">
                            <p class="p-0 mb-5 mt-0 fs-18 notification_type"
                                [ngClass]="data.is_read ? 'fw-500':'fw-600'">
                                {{data.notification_type}}</p>
                            <p class="p-0 m-0 fs-16 notification_title" [ngClass]="data.is_read ? 'fw-500':'fw-600'">
                                {{data.notification_title}}</p>
                        </div>
                    </div>
                    <div class="notification-time-container">
                        <p class="p-0 m-0 fs-14 notification-date" [ngClass]="data.is_read ? 'fw-500':'fw-600'">
                            {{dateTimeShow(data.created_at)}}</p>
                    </div>
                </div>
                <div *ngIf="i < listData.length-1" class="border-top"></div>
            </ng-container>
        </div>
        <div class="table-listing" *ngIf="listData && listData.length < 1">

            <div class="no-data p-20">
                No Records
            </div>

        </div>
        <div class="pagination-wrapper" *ngIf="listData && listData.length > 0"
            [ngClass]="[totalCount < 10 && getPlatformService.isDesktop
            ?'pagination-justify-content-end':totalCount < 10 && !getPlatformService.isDesktop? 'pagination-content-start':'']">
            <ng-container *ngIf="totalCount > 10">
                <div class="items">
                    Items
                    <ng-select (change)="changeListItem($event)" [(ngModel)]="paginationVal" [clearable]="false"
                        class="pagination" [items]="commonService.paginationList" bindLabel="label" bindValue="value"
                        [searchable]="false" [editable]="false" [searchFn]="false">
                    </ng-select>
                    <ion-label class="perpage-title">of {{totalCount}}</ion-label>
                </div>
            </ng-container>
            <div>
                <ion-row class="pagination-div">
                    <ion-col size="12" class="pagination-col return-orders-pagination-col">
                        <app-tile class="grid-tile" *ngFor="let post of listData | paginate :
                                            { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount };
                                            let i = index" [current]="post"></app-tile>
                        <pagination-controls [maxSize]="5" class="paginator" previousLabel="" nextLabel=""
                            (pageChange)="onChange($event)"></pagination-controls>
                    </ion-col>
                </ion-row>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="isLoading">
        <app-loader></app-loader>
    </ng-container>
</div>