<div class="banner" id="scrollId" *ngIf="!isLoading">
    <swiper [autoHeight]="true" [allowTouchMove]="true" [autoplay]="{ delay: 3000, disableOnInteraction: false }"
        [pagination]="{ clickable: true }" class="slides top-slider in-english" #swiper>
        <ng-template *ngFor="let dataObj of bannerData" swiperSlide>
            <div class="slide-content">
                <div class="video-cotainer">
                    <img fetchpriority="high" class="banner-image" alt="Image" [ngSrc]="dataObj['banner_img']"
                        height="100" width="100" priority />
                </div>
            </div>
        </ng-template>
    </swiper>
</div>
<div class="banner" *ngIf="isLoading">
    <ion-skeleton-text class="skeleton-container width-100" name="lines-sharp-small"
        [animated]="true"></ion-skeleton-text>
</div>