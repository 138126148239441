import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { ToasterService } from './../../services/toaster.service';
@Component({
	selector: 'app-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit, OnDestroy {
	public subscription = new Subscription();
	@Input() checkoutAddress = false;
	@Input() isBtn = false;
	@Input() selected_address?: any;
	isSeller = false;
	isLoading = false;
	profileDetails: any;
	company_id: any;
	isCustomerApproval = false;
	companyInfo: any;
	widthConfig = ['80px', '150px', '150px', '150px', '145px'];

	addressList = [];
	column_settings = [
		{ primaryKey: 'address_id', header: '#' },
		{ primaryKey: 'tag', header: 'Tag' },
		{ primaryKey: 'address', header: 'Additional address' },
		{ primaryKey: 'gst_number', header: 'GST No' },
		{ primaryKey: '', header: 'Action', action: true }
	];
	listOfAllData = [];
	isSellerApproval = false;
	constructor(
		private router: Router,
		private commonService: CommonService,
		private dataService: ApiService,
		private toasterService: ToasterService,
		private navCtrl: NavController,
		public getPlatformService: GetPlatformService
	) {

		this.subscription = this.commonService.callAddressData.subscribe((callData: any) => {
			this.commonService.getUserDetail().then((data) => {
				let details = data
				this.profileDetails = details;
				if (this.profileDetails && this.profileDetails.customer_type != 'INDIVIDUAL') {
					if (data) {
						this.getAddress();
					}

				} else {
					this.getAddress();
				}
			})
		})


	}

	ngOnInit() {
		if (this.checkoutAddress) {
			this.column_settings = [
				{ primaryKey: 'checked', header: 'selection' },
				{ primaryKey: 'tag', header: 'Tag' },
				{ primaryKey: 'address', header: 'Additional address' },
			]
		}
		else {
			this.column_settings = [
				{ primaryKey: 'address_id', header: '#' },
				{ primaryKey: 'tag', header: 'Tag' },
				{ primaryKey: 'address', header: 'Additional address' },
				{ primaryKey: 'gst_number', header: 'GST No' },
				{ primaryKey: '', header: 'Action', action: true }
			]
		}
		this.isCustomerApproval = this.commonService.localStorageGet('customer_approval') == 'PENDING' ? true : false;
		this.commonService.getUserDetail().then((data) => {
			let details = data
			this.profileDetails = details;
			this.getAddress();
		})
		this.commonService.scrollToTop();
	}

	addAddress() {
		this.navCtrl.navigateRoot('/home/account-details/addresses/add-address');
		this.commonService.scrollToTop();
	}

	editAddress(address) {
		if (address.company_address_id) {
			this.navCtrl.navigateRoot(['/home/account-details/addresses/edit-address', address.company_address_id]);
			this.commonService.scrollToTop();
		} else {
			this.navCtrl.navigateRoot(['/home/account-details/addresses/edit-address', address.user_address_id]);
			this.commonService.scrollToTop();
		}
	}

	getAddress() {
		let params: any;

		if (this.profileDetails && this.profileDetails.customer_type != 'INDIVIDUAL') {
			params = {
				"user_type": "CUSTOMER",
				"company_id": this.profileDetails.company_id
			}
		}
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.getAddress, params).subscribe(data => {
				if (data['code'] == 200) {
					if (data['data']) {
						let addressData = data['data'];
						let default_billing = data['data']['default_billing']
						let default_shipping = data['data']['default_shipping'];
						let addressList = data['data']['addressList'];
						this.addressList = [];
						this.listOfAllData = [];
						let addressType: any;
						if (default_billing) {
							if (addressList) {
								if (this.profileDetails && this.profileDetails.customer_type != 'INDIVIDUAL') {
									addressType = addressList.find((element) => element.company_address_id == default_billing['company_address_id']);
								} else {
									addressType = addressList.find((element) => element.user_address_id == default_billing['user_address_id']);
								}
								if (addressType) {
									default_billing['address_label'] = addressType.address_type;
								}
							}
							default_billing['heading'] = 'Default Billing address';
							default_billing['title'] = 'Billing address';
							default_billing['company_name'] = default_billing['name'];
							delete default_billing['name'];
							this.addressList.unshift(default_billing);

						}
						if (default_shipping) {
							const deliveryLocationData = {
								address: default_shipping.address,
								address_type_id: default_shipping.address_type_id,
								latitude: default_shipping.latitude.toString(),
								longitude: default_shipping.longitude.toString(),
								state: default_shipping.state,
								state_id: default_shipping.state_id
							}
							let ship_address = this.commonService.localStorageGet('shipping_address_id');
							this.commonService.localStorageSet('shipping_address_id', default_shipping.company_address_id ? default_shipping.company_address_id : default_shipping.user_address_id);
							this.commonService.localStorageSet('deliverLocation', JSON.stringify(deliveryLocationData));
							let addressList = data['data']['addressList'];
							if (addressList) {
								if (this.profileDetails && this.profileDetails.customer_type != 'INDIVIDUAL') {
									addressType = addressList.find((element) => element.company_address_id == default_shipping['company_address_id']);
								} else {
									addressType = addressList.find((element) => element.user_address_id == default_shipping['user_address_id']);
								}
								if (addressType) {
									default_shipping['address_label'] = addressType.address_type;
								}
							}
							default_shipping['heading'] = 'Default Shipping address';
							default_shipping['title'] = 'Shipping address';
							default_shipping['company_name'] = default_shipping['name'];
							delete default_shipping['name'];
							this.addressList.push(default_shipping);
						}
						if (addressList && addressList.length) {
							addressList.forEach((element, index) => {
								let obj = {
									index: index + 1,
									address_id: this.profileDetails && this.profileDetails.customer_type != 'INDIVIDUAL' ? element.company_address_id : element.user_address_id,
									tag: element.address_type,
									gst_number: element.gst_number || '--',
									address: element.address,
									edit: !this.checkoutAddress ? true : false,
									delete: !this.checkoutAddress && element.address_type != 'HEADQUARTER' ? true : false
								}

								this.listOfAllData.push(obj);
							});
						}
					}
					this.isLoading = false;
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);

			})
		)
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

}
