<div class="add-quote">
    <div class="close-div p-5 pointer" (click)="close()">
        <img src="/assets/icon/close.svg" alt="Close Icon" slot="end" class="close-icon" />
    </div>
    <div class="heading-label fs-28 fw-600 mb-0">
        Enter Quote
    </div>
    <div class="mb-30 fs-18 fw-500">
        <form [formGroup]="quoteForm">
            <p class="pb-10 desc">Please enter quote price & estimated date of delivery to submit quote. </p>

            <ng-container>
                <div class="align-center row-css">
                    <div class="p-0 m-0">
                        <div class="align-center">
                            <div class="mr-10">
                                <img [src]="quote_details.product_image" alt="Product Image" class="product-img" />
                            </div>
                            <div>
                                <p class="fs-15 fw-400 p-0 m-0 pb-5 max-260">{{quote_details.product_name}}</p>
                                <p class="fs-15 fw-500 p-0 m-0 pb-5">Price: <span class="fw-500">
                                        {{commonService.currencySymbol}}{{quote_details.price_per_unit|number}}/{{quote_details.package_value
                                        ?
                                        quote_details.package_value :
                                        quote_details.unit_name}}</span>
                                </p>
                                <p class="fs-14 fw-500 p-0 m-0 pb-5">Total (Including Shipping charge): <span
                                        class="fw-600">{{commonService.currencySymbol}}{{quote_details.best_deal_price|number}}</span>
                                </p>
                            </div>
                        </div>

                    </div>
                    <div class="p-0 m-0">
                        <p class="fs-15 fw-400 p-0 m-0 pb-5">Quantity: <span
                                class="fw-500">{{quote_details.quantity.toString().includes('/') ? quantity :
                                quote_details.quantity}}</span>
                        </p>
                        <p class="fs-15 fw-400 p-0 m-0 pb-5">Subtotal: <span
                                class="fw-500">{{commonService.currencySymbol}}{{quote_details.total|number}}</span></p>
                    </div>
                </div>

            </ng-container>
            <div class="quote-filed mt-20">
                <ion-label class="fw-500 fs-16">Enter Total Quote Price *(Excluding GST & Including Shipping
                    charge)</ion-label>
                <ion-input type="text" maxlength="10" placeholder="Enter Quote Price" (keypress)="isnumeric($event)"
                    formControlName="quotePrice" autocomplete="new-quote" class="pt-20" appTwoDigitDecimalNumber
                    appBlockCopyPaste></ion-input>
                <ng-container *ngFor="let validation of validation_messages.quotePrice">
                    <ng-container
                        *ngIf="quoteForm.get('quotePrice').hasError(validation.type) && quoteForm.get('quotePrice').dirty">
                        <ion-text color="danger" class="error-msg">
                            {{validation.message }}
                        </ion-text>
                    </ng-container>
                </ng-container>
                <p class="mt-10" *ngIf="quoteForm.get('quotePrice').value">
                    {{commonService.currencySymbol}}{{(quoteForm.get('quotePrice').value/quote_details.quantity).toFixed(2)}}
                    /
                    {{quote_details.package_value ? quote_details.package_value:
                    quote_details.unit_name}} </p>
            </div>
            <div class="mt-20  quote-filed dateField">
                <div id="toDateId">
                    <ion-label class="fw-500 fs-16">Estimated Delivery Date *</ion-label>
                    <ion-input id="toDateId" placeholder="Enter Estimated Delivery Date"
                        value="{{ date | date: 'MMM d, y' }}"></ion-input>
                    <img class="calender-icon" src="assets/icon/calender.svg" alt="Calender Icon">
                    <ng-container *ngFor="let validation of validation_messages.estimatedDate">
                        <ng-container
                            *ngIf="quoteForm.get('estimatedDate').hasError(validation.type) && quoteForm.get('estimatedDate').dirty">
                            <ion-text color="danger" class="error-msg">
                                {{validation.message }}
                            </ion-text>
                        </ng-container>
                    </ng-container>
                </div>
                <ion-popover trigger="toDateId" id="datePopover">
                    <ng-template>
                        <ion-datetime [min]="minDate" [(ngModel)]="date" presentation="date"
                            (ionChange)="onDateChange(fromdate)" formControlName="estimatedDate"
                            #fromdate></ion-datetime>
                    </ng-template>
                </ion-popover>
            </div>
        </form>
    </div>
    <div class="form-btn flex-center align-center">
        <button (click)="submitQuote()" class="submit-btn w-200">
            Submit
        </button>
    </div>
</div>