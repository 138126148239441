import { Injectable, NgZone } from "@angular/core";
import { Geolocation } from '@capacitor/geolocation';
import { NativeGeocoder, NativeGeocoderOptions, NativeGeocoderResult } from '@ionic-native/native-geocoder/ngx';
declare var google;
declare var navigator;


@Injectable({
    providedIn: 'root'
})
export class LocationService {
    autocompleteItems: any = [];
    latitude;
    longitude;
    geocoder;
    country;
    country_shortname;
    state;
    state_shortname;
    city;
    district;
    zipcode_shortname;
    district_shortname;
    taluka;
    taluka_shortname;
    zipcode;
    currentLocation;
    locationName;
    completeLocation;
    constructor(
        private nativeGeocoder: NativeGeocoder,
        private zone: NgZone,
    ) { }



    getCurrentLocation() {

        return new Promise((resolve, reject) => {
            Geolocation.getCurrentPosition().then(coordinates => {
                this.latitude = coordinates.coords.latitude;
                this.longitude = coordinates.coords.longitude;
                let options: NativeGeocoderOptions = {
                    useLocale: true,
                    maxResults: 5
                };

                this.nativeGeocoder.reverseGeocode(this.latitude, this.longitude, options)
                    .then((result: NativeGeocoderResult[]) => {
                        if (result[0]) {
                            let results: any = result[0];
                            resolve(results)
                        }
                    })
                    .catch((error: any) => {
                        this.getGeoLocation(this.latitude, this.longitude, 'reverseGeocode').then(data => {
                            resolve(data)
                        })
                    });
            },
                ((error: any) => {
                    reject(error);
                })
            )
        })

    }



    getGeoLocation(lat: number, lng: number, type?) {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                let geocoder = new google.maps.Geocoder();
                let latlng = new google.maps.LatLng(lat, lng);

                let request = { latLng: latlng };
                geocoder.geocode(request, (results, status) => {
                    if (results && results[0]) {
                        results.forEach(element => {
                            if (element['address_components'].length > 6) {
                                let location = element.address_components;
                                resolve(location)
                            }
                        });
                    }
                });
            }
            else {
                reject(null);
            }
        });
    }

    removeDuplicatesFromString(inputString) {

        let values = inputString.split(', ');
        let uniqueValues = [...new Set(values)];
        let resultString = uniqueValues.join(', ');

        return resultString;
    }

    getPlaceAutocomplete(GoogleAutocomplete, inputValue) {
        return new Promise((resolve, reject) => {
            GoogleAutocomplete.getPlacePredictions({ input: inputValue, componentRestrictions: { country: ['IN'] } },
                (predictions) => {
                    this.autocompleteItems = [];
                    this.zone.run(() => {
                        if (predictions && predictions.length > 0) {
                            predictions.forEach((prediction) => {
                                this.autocompleteItems.push(prediction);
                                resolve(this.autocompleteItems)
                            });
                        }
                        else {
                            reject(predictions);
                        }
                    });
                });
        })

    }

    getselectedAutoMap(geocoder, item, formcontrolName?) {
        return new Promise((resolve, reject) => {
            this.latitude = '';
            this.longitude = '';
            this.locationName = item['description'];
            if (formcontrolName) formcontrolName.setValue(this.locationName);
            geocoder.geocode({ 'placeId': item['place_id'] }, (result, status) => {
                result.forEach(element => {
                    if (element['address_components'].length > 6) {
                        let location = element.address_components;
                        resolve(location);
                        location.map((addr) => {
                            if (addr['types'].includes("postal_code")) {
                                this.zipcode = addr['long_name']
                            }
                        })
                    }
                });

                this.country_shortname = null;
                this.state_shortname = null;
                this.district_shortname = null;
                this.taluka_shortname = null;
                this.zipcode_shortname = null;

                for (let component in result[0]['address_components']) {
                    for (let i in result[0]['address_components'][component]['types']) {
                        if (result[0]['address_components'][component]['types'][i] === "country") {
                            this.country = result[0]['address_components'][component]['long_name'];
                            this.country_shortname = result[0]['address_components'][component]['short_name'];
                        }
                        if (result[0]['address_components'][component]['types'][i] === "administrative_area_level_1") {
                            this.state = result[0]['address_components'][component]['long_name'];
                            this.state_shortname = result[0]['address_components'][component]['short_name'];
                        }
                        if (result[0]['address_components'][component]['types'][i] === "administrative_area_level_3") {
                            this.district = result[0]['address_components'][component]['long_name'];
                            this.district_shortname = result[0]['address_components'][component]['short_name'];
                        }
                        if (result[0]['address_components'][component]['types'][i] === 'locality') {
                            this.city = result[0]['address_components'][component]['long_name'];
                            this.taluka = result[0]['address_components'][component]['long_name'];
                            this.taluka_shortname = result[0]['address_components'][component]['short_name'];
                        }
                        if (result[0]['address_components'][component]['types'][i] === 'postal_code') {
                            this.zipcode_shortname = result[0]['address_components'][component]['short_name'];
                        }

                    }
                }
                this.latitude = result[0].geometry.location.lat();
                this.longitude = result[0].geometry.location.lng();
                resolve(result);

            }, error => {
                reject(error);
            });
        })
    }
}