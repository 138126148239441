import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { GetPlatformService } from '../../services/get-platform.service';
import { EnterpriseDetailComponent } from '../enterprise-detail/enterprise-detail.component';
import { CustomValidator } from './../../_common/customvalidators';
import { profileTypeEnum } from './../../_common/enum.enum';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { ToasterService } from './../../services/toaster.service';

// IMAGE FILE SIZE 40mb MAX
const MAX_FILE_SIZE_IMAGE = 5 * 1024 * 1024;

export interface MyProfile {
	first_name?: string;
	last_name?: string;
	platform?: string;
	email?: string;
	mobile_no?: number;
	company_address?: string;
	company_name?: string;
	file?: any;
}
@Component({
	selector: 'app-my-profile',
	providers: [EnterpriseDetailComponent],
	templateUrl: './my-profile.component.html',
	styleUrls: ['./my-profile.component.scss'],
})

export class MyProfileComponent implements OnInit, OnDestroy {

	registrationType = 'individual';
	profileEnum = profileTypeEnum;
	public subscription = new Subscription();
	public profileForm: FormGroup;
	public isEnterpriced = false;
	isLoading: boolean = false;
	isBecomeEnterprise: boolean = false;
	isIndividualCust: boolean = true;
	imageChangedEvent: any = '';
	imageFile: any = '';
	isShowEnterPriceBox = true;
	isSeller = false;
	isCustomer = false;
	profileImage: any;
	profileImageChange: boolean = false;
	isCurrentProfile = '';
	showMsg = false;
	fieldData = {
		"name": "Name",
		"email": "Email",
		"mobile_no": "Mobile number"
	}
	user_type: string;
	customer_type: string;
	seller_type: string;
	toastRef;
	public profileDetails: any;
	public fromPage: any;
	isDisabled = false;
	type = 'warning';
	messageData: any = {};

	constructor(
		private formBuilder: FormBuilder,
		public commonService: CommonService,
		private toasterService: ToasterService,
		private dataService: ApiService,
		private platform: Platform,
		private navCtrl: NavController,
		public enterpriseData: EnterpriseDetailComponent,
		public getPlatformService: GetPlatformService

	) {

		this.showMsg = false;
		this.commonService.eventObservable.subscribe((data: any) => {
			this.messageData = {};
			this.showMsg = false;
			if (data.event == 'alertMessage' && data['data']['message']) {
				this.showMsg = true;
				this.messageData = data['data'];
				if (this.commonService.userData.isCustomer && this.commonService.userData.customer_type == "INDIVIDUAL") {
					this.messageData['type'] = 'success';
				}
			}
		})
		this.commonService.getUserDetail().then((data) => {
			if ((this.commonService.userData['isSeller'] && data['seller_approval_status'] == 'APPROVED') || (this.commonService.userData['isCustomer'] && data['approval_status'] == 'APPROVED')) {
				this.messageData['type'] = 'success';
			}
			else {
				this.messageData['type'] = 'warning';
			}
			if (data['show_message']) {
				if (data['seller_approval_status'] != 'REJECTED' || data['approval_status'] != 'REJECTED') {
					this.showMsg = true;
					this.messageData['message'] = data['message'];
				}
				if (this.commonService.userData.isCustomer && this.commonService.userData.customer_type == "INDIVIDUAL") {
					this.messageData['type'] = 'success';
				}
			}

			this.isLoading = true;
			if (data) {
				this.profileDetails = data;
				this.setProfileDetails();
			} else {
				this.isLoading = false;
			}
			this.subscription = this.commonService.updateProfile.subscribe((data) => {
				let User_type = this.commonService.userData.isCustomer ? "CUSTOMER_" : '' + this.profileDetails.customer_type == 'INDIVIDUAL' ? 'INDIVIDUAL' : '';
				if (User_type != 'CUSTOMER_INDIVIDUAL') {
					if ((this.commonService.userData.isSeller && this.profileDetails.seller_approval_status == 'APPROVED') ||
						(this.commonService.userData.isCustomer && this.profileDetails.approval_status == 'APPROVED') ||
						(this.profileDetails.enterprise_user_id) || (this.profileDetails.is_admin)) {
						this.f1.first_name.enable();
						this.f1.last_name.enable();
						this.f1.phone_no.enable();
						this.f1.company_name.enable();
						this.f1.hq_location.disable();
						this.isDisabled = false;
					}
					else {
						this.f1.first_name.disable();
						this.f1.last_name.disable();
						this.f1.phone_no.disable();
						this.f1.company_name.disable();
						this.f1.hq_location.disable();
						this.isDisabled = true;
					}
				}
			})
		}, error => {
			this.profileDetails = null;
		});

	}

	get f1() { return this.profileForm.controls; }

	showToast = () => {

	}
	ngOnInit() {
		this.commonService.profilePicture = null;
		this.profileForm = this.initializeprofileFromGroup();
		if (this.commonService.userData.user_profile == this.profileEnum.Enterprise) {
			this.profileForm.controls['company_name'].setValidators([Validators.required]);
			this.profileForm.controls['hq_location'].setValidators([Validators.required]);
		}
		this.isLoading = true;
		setTimeout(() => {
			const data = this.commonService.localStorageGet('info');
			this.commonService.userData = JSON.parse(data);
			this.setProfileDetails();
		}, 500);
		this.commonService.localStorageSet('user_data', JSON.stringify(this.commonService.userData));
		this.commonService.scrollToTop();
	}

	ionViewWillEnter() {
		this.profileForm = this.initializeprofileFromGroup();
		if (this.commonService.userData.user_profile == this.profileEnum.Enterprise) {
			this.profileForm.controls['company_name'].setValidators([Validators.required]);
			this.profileForm.controls['hq_location'].setValidators([Validators.required]);
		}
		setTimeout(() => {
			const data = this.commonService.localStorageGet('info');
			this.commonService.userData = JSON.parse(data);
			this.commonService.eventObservable.subscribe((data: any) => {
				if (data.event == 'userResponseInfo') this.commonService.userData = data['data'] ? data['data'] : '';
			})
		}, 500);

	}

	initializeprofileFromGroup() {
		const profileForm = this.formBuilder.group({
			first_name: new FormControl({ disabled: false }, {
				validators: Validators.compose([Validators.required, Validators.maxLength(15), CustomValidator.nameValidator]),
			}),
			last_name: new FormControl({ disabled: false }, {
				validators: Validators.compose([Validators.required, Validators.maxLength(15), CustomValidator.nameValidator]),
			}),
			email: new FormControl({ disabled: true }, {
				validators: Validators.compose([Validators.required, CustomValidator.emailValidator]),
			}),
			phone_no: new FormControl({ disabled: false }, {
				validators: Validators.compose([Validators.required, CustomValidator.phoneValidator]),
			}),
			company_name: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			hq_location: new FormControl(null, {
				validators: Validators.compose([]),
			}),

		})
		profileForm.markAsPristine();
		return profileForm;



	}

	createEnterpriseProfile() {
		this.isEnterpriced = true;
		this.toasterService.warningToast('Your Request For Enterprise Customer Has Sent To Admin. You Will Be Notified Once Verification Completed.');
	}
	/**
	* MANUALLY TRIGGER CLICK EVENT
	*/
	public callInput(): void {
		let element: HTMLElement = document.getElementById('fileInput') as HTMLElement;
		element.click();
	}

	/**
   * FILE CHANGE EVENT FOR SELECTING IMAGES
   * @param event EVENT OBJECT
   */
	public fileChangeEvent(event: any): void {
		this.profileImageChange = false;
		let fileObj = event.target.files[0];
		let fileExtension = fileObj['name'].split('.').pop().toLocaleLowerCase();
		let fileSize = fileObj['size'];
		const reader = new FileReader();
		if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
			if (fileSize < MAX_FILE_SIZE_IMAGE) {
				this.imageChangedEvent = event;
				this.imageFile = event['target']['files'][0];
				reader.readAsDataURL(event['target']['files'][0]);
				reader.onload = () => {
					this.profileImage = reader.result;
					this.profileImageChange = true;
				};
			} else {
				this.toasterService.errorToast("The selected image has exceeded the maximum allowed size of 5 Mb.", 'top');
			}
		} else {
			this.toasterService.errorToast("Please select JPG, JPEG and PNG format image!", 'top');
		}
	}

	showToastWithCloseButton() {
		this.toasterService.successToast('Your Request Accepted, Start To Add The Product');

	}

	restrictSpecialChar(event) {
		var k;
		k = event.charCode;  //         k = event.keyCode;  (Both can be used)
		return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 39 || (k >= 48 && k <= 57));
	}


	setProfileDetails() {
		if (this.profileDetails) {
			if (this.profileDetails.first_name) {
				this.f1.first_name.setValue(this.profileDetails.first_name?.trim());
			}
			if (this.profileDetails.last_name) {
				this.f1.last_name.setValue(this.profileDetails.last_name?.trim());
				this.f1.email.disable();
			}
			if (this.profileDetails.email) {
				this.f1.email.setValue(this.profileDetails.email);
			}
			if (this.profileDetails.mobile_no) {
				this.f1.phone_no.setValue(this.profileDetails.mobile_no);
			}

			if (this.profileDetails.company_name) {
				this.f1.company_name.setValue(this.profileDetails.company_name?.trim());
			}
			if (this.profileDetails.address) {
				this.f1.hq_location.setValue(this.profileDetails.address);
			}
			let User_type = this.commonService.userData.isCustomer ? "CUSTOMER_" : '' + this.profileDetails.customer_type == 'INDIVIDUAL' ? 'INDIVIDUAL' : '';
			if (User_type != 'CUSTOMER_INDIVIDUAL') {
				if ((this.commonService.userData.isSeller && this.profileDetails.seller_approval_status == 'APPROVED') ||
					(this.commonService.userData.isCustomer && this.profileDetails.approval_status == 'APPROVED') ||
					(this.profileDetails.enterprise_user_id)) {
					this.f1.first_name.enable();
					this.f1.last_name.enable();
					this.f1.phone_no.enable();
					if (this.profileDetails.is_admin) {
						this.f1.company_name.enable();
					} else {
						this.f1.company_name.disable();
					}
					this.f1.hq_location.disable();
					this.isDisabled = false;
				}
				else {
					this.f1.first_name.disable();
					this.f1.last_name.disable();
					this.f1.phone_no.disable();
					this.f1.company_name.disable();
					this.f1.hq_location.disable();
					this.isDisabled = true;
				}
			}
			if (this.profileDetails.profile_picture) {
				this.profileImage = this.profileDetails.profile_picture;
			}
		} else {
			this.f1.first_name.setValue(null);
			this.f1.last_name.setValue(null);
			this.f1.email.setValue(null);
			this.f1.phone_no.setValue(null);
			this.f1.company_name.setValue(null);
			this.f1.hq_location.setValue(null);
		}
		this.isLoading = false;
	}

	updateProfile() {

		for (const i in this.f1) {
			this.f1[i].markAsDirty();
			this.f1[i].updateValueAndValidity();
			this.f1[i].markAsTouched();
		}

		if (this.profileForm.invalid) {
			return
		}
		if (!this.isEnterpriced) {
			// this.isShowEnterPriceBox = false;
			// this.isBecomeEnterprise  =  true;
		}
		if (this.commonService.isAutoSelected) {
			return;
		}

		let myProfile: MyProfile = {}
		myProfile.first_name = this.profileForm.value.first_name?.trim();
		myProfile.last_name = this.profileForm.value.last_name?.trim();

		if (this.profileDetails.mobile_no != this.profileForm.value.phone_no) {
			myProfile.mobile_no = this.profileForm.value.phone_no
		}
		if (this.profileDetails.customer_type != "INDIVIDUAL") {
			if (this.profileDetails.company_name != this.profileForm.value.company_name) {
				myProfile.company_name = this.profileForm.value.company_name?.trim();
			}
		}
		if (this.profileDetails.customer_type != "INDIVIDUAL") {
			if (this.profileForm.value.hq_location && this.profileDetails.address != this.profileForm.value.hq_location) {
				myProfile.company_address = this.profileForm.value.hq_location;
			}
		}
		if (this.imageFile && this.imageFile != this.profileDetails.profile_picture) {
			myProfile.file = this.imageFile;
			this.commonService.profilePicture = this.imageFile;
		}
		let myProfileLength = Object.keys(myProfile).length;
		if (myProfileLength >= 1) {
			myProfile.platform = this.platform.is('desktop') ? "WEB" : "MOBILE";
		} else {
			return;
		}
		let encryptedData = this.commonService.encryptData(JSON.stringify(myProfile));
		let profileFormData = new FormData();
		if (this.profileDetails.mobile_no != this.profileForm.value.phone_no) {
			let otpParam = {
				"mobile_no": this.profileForm.value.phone_no,
				"purpose_type": "PROFILE",
				"platform": this.platform.is('desktop') ? "WEB" : "MOBILE",
				"reference_type": "USERS",
				"reference_id": this.profileDetails.user_id
			}
			this.isLoading = true;
			this.subscription.add(
				this.dataService.post(URLS.generateOtp, otpParam).subscribe(data => {
					if (data['code'] == 200) {
						if (data['data']) {
							let myProfileData = data['data'];
							this.commonService.isAutoSelected = false;
							if (myProfileData) {
								let encryptedUserDetails = this.commonService.encryptData(JSON.stringify(myProfileData));
								this.commonService.localStorageSet('my_profile', encryptedUserDetails);
								this.commonService.localStorageSet('my_profile_otp', encryptedData);
								this.fromPage = "my-profile";
								this.navCtrl.navigateRoot("/two-factor-authentication", { queryParams: { fromPage: this.fromPage } });
								this.commonService.localStorageSet("uuid", data['data']['uuid']);
								this.toasterService.successToast(data['message'], 'top');
							}
						}
						this.isLoading = false;
					}
					else {
						this.setProfileDetails();
						this.toasterService.errorToast(data['message']);
					}
					this.isLoading = false;
				}, error => {
					this.setProfileDetails();
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);

				})
			)
		}
		else {
			let dataChanged: boolean = false


			profileFormData.append("first_name", this.profileForm.value.first_name.trim());
			profileFormData.append("last_name", this.profileForm.value.last_name.trim());

			if (this.profileDetails.customer_type != "INDIVIDUAL") {
				if (this.profileDetails.company_name !== this.profileForm.value.company_name) {
					dataChanged = true;
					profileFormData.append("company_name", this.profileForm.value.company_name?.trim());
				}
			}
			if (this.profileDetails.customer_type != "INDIVIDUAL") {
				if (this.profileForm.value.hq_location && this.profileDetails.address != this.profileForm.value.hq_location) {
					// dataChanged = true;
					profileFormData.append("company_address", this.profileForm.value.hq_location);
				}
			}
			if (this.imageFile) {
				profileFormData.append('files', this.imageFile);
			}

			if (myProfileLength >= 1) {
				profileFormData.append("platform", this.platform.is('desktop') ? "WEB" : "MOBILE");

			}
			if (this.f1.first_name.value.trim() != this.profileDetails.first_name) {
				dataChanged = true;
			}
			if (this.f1.last_name.value.trim() != this.profileDetails.last_name) {
				dataChanged = true;
			}

			if (this.profileImage != this.profileDetails.profile_picture) {
				dataChanged = true;
			}

			if (this.imageFile == this.profileDetails.profile_picture) {
				dataChanged = true;
			}
			if (!dataChanged) {
				this.toasterService.warningToast("No data updated")
				return
			}
			this.isLoading = true;
			this.subscription.add(
				this.dataService.put(URLS.updateProfile, profileFormData).subscribe(data => {
					if (data['code'] == 200) {
						if (data['data']) {
							let myProfileData = data['data']
							if (myProfileData) {
								this.commonService.isAutoSelected = false;
								if (this.profileDetails.last_name != this.profileForm.value.last_name || this.profileDetails.first_name != this.profileForm.value.first_name) {
									let userResponseInfo = {
										user_type: this.commonService.userData['user_type'] ? this.commonService.userData['user_type'] : '',
										customer_type: this.commonService.userData['customer_type'] ? this.commonService.userData['customer_type'] : '',
										seller_type: this.commonService.userData['seller_type'] ? this.commonService.userData['seller_type'] : '',
										user_profile: this.commonService.userData['seller_type'] && this.commonService.userData['seller_type'] != 'NONE' ? this.commonService.userData['seller_type'] : this.commonService.userData['customer_type'],
										approval_status: this.commonService.userData['approval_status'] ? this.commonService.userData['approval_status'] : '',
										isCustomer: this.commonService.userData['isCustomer'],
										isSeller: this.commonService.userData['isSeller'],
										name: this.profileForm.value.first_name + ' ' + this.profileForm.value.last_name,
									}
									this.commonService.localStorageSet('info', JSON.stringify(userResponseInfo));
									this.commonService.setEvent('userResponseInfo', userResponseInfo);
								}
								this.commonService.getUserDetail().then((data) => {
									if (data) {
										this.profileDetails = data;
										this.setProfileDetails();
									}
								})
								this.commonService.scrollToTop();
								this.toasterService.successToast(data['message'], 'top');
							}
						}
						this.isLoading = false;
					}
					else {
						this.setProfileDetails();
						this.toasterService.errorToast(data['message']);
					}
					this.isLoading = false;
				}, error => {
					this.setProfileDetails();
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);

				})
			)
		}
	}

	cancel() {
		this.isLoading = true;
		this.commonService.getUserDetail().then((data) => {
			if (data) {
				this.profileDetails = data;
				this.setProfileDetails();
				this.profileImageChange = false;
				this.profileForm.markAsPristine();
				this.isLoading = false;
			}
		})
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
