import { Location } from "@angular/common";
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { CommonService } from './../services/common.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
	isModalOpen = false
	modal: any
	constructor(private router: Router,
		private location: Location,
		private commonService: CommonService,
		private navCtrl: NavController
	) { }
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		let routeUrl: any;
		if (next.url && next.url.length > 0) {
			routeUrl = next.url[0].path;
		}

		const accessToken = this.commonService.localStorageGet('accessToken');
		const uuid = this.commonService.localStorageGet('uuid')
		const otpVerified = this.commonService.localStorageGet('otpVerified');
		const userType = this.commonService.localStorageGet('user_type');
		const userData = JSON.parse(this.commonService.localStorageGet('user_data'));
		const sellerRequest = this.commonService.localStorageGet('becomeSeller');
		const customerRequest = this.commonService.localStorageGet('becomeCustomer');


		if ((accessToken || this.commonService.accessToken)) {

			if (sellerRequest == "true") {
				if (routeUrl && routeUrl.includes('seller-register')) {
					return true;
				}
			}
			if (customerRequest == "true") {
				if (routeUrl && routeUrl.includes('registration')) {
					return true;
				}
			}
			if (routeUrl && (routeUrl.includes('login') || routeUrl.includes('forgot-password') || routeUrl.includes('create-new-password') || routeUrl.includes('seller-register') || routeUrl.includes('registration'))) {
				if (this.location.getState()) {
					this.location.back();
				} else {
					this.navCtrl.navigateRoot(['/']);
				}
				return false;
			}
			if (userType == "BOTH") {
				if (routeUrl && (routeUrl.includes('seller-register') || routeUrl.includes('registration'))) {
					this.navCtrl.navigateRoot(['/']);
					return false;
				}
			}

			if (uuid == "undefined" || !uuid) {
				if (routeUrl && (routeUrl.includes('two-factor-authentication'))) {
					this.navCtrl.navigateRoot(['/']);
					return false;
				}
			}

			if (userData && userData.isSeller) {
				if (next.data.url && (!next.data.url.includes('/account-details'))) {
					if (this.location.getState()) {
						this.location.back();
					} else {
						this.commonService.localStorageClear();
						localStorage.clear();
						this.navCtrl.navigateRoot(['/']);
					}
				}
			}
			return true;
		}
		else {
			if (routeUrl && routeUrl.includes('create-new-password') && next.queryParams.token) {
				return true;
			}

			if (uuid && uuid != "undefined") {
				if (routeUrl && (routeUrl.includes('two-factor-authentication'))) {
					return true;
				}
				return true;
			}
			if (otpVerified) {
				if (routeUrl && (routeUrl.includes('create-new-password'))) {
					return true;
				}
				return true;
			}

			if (routeUrl && (routeUrl.includes('login') || routeUrl.includes('forgot-password') || routeUrl.includes('registration') || routeUrl.includes('seller-register'))) {
				return true;
			}


			if (next.data && next.data.url && next.data.url.includes('/cart') || next.data.url.includes('/account-details')) {
				this.navCtrl.navigateRoot(['/login']);
				return true;
			}

			if (next.data && next.data.url && next.data.url.includes('/cart')) {
				this.navCtrl.navigateRoot(['/login']);
				return true;
			}

			if (next.data && next.data.url && next.data.url.includes('/')) {
				return true;
			}

			if (next.data && next.data.url && next.data.url.includes('/product-listing')) {
				return true;
			}

		}
		// not logged in so redirect to login page with the return url
		this.navCtrl.navigateRoot(['/login']);
		return true;
	}
	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		return this.canActivate(childRoute, state)
	}

}
