<div class="thank-you-container">
    <div class="cross-icon ion-text-right" (click)="onCancel()" *ngIf="hideCloseBtn != true">
        <img alt="Close Icon" src="assets/icon/close.svg">
    </div>
    <div class="thank-you">
        <div class="success-icon">
            <img alt="Success Icon" src="assets/icon/success_check.svg">
        </div>
        <div class="thank-you-header">
            <div class="thank-you-text">
                {{title}}
            </div>
        </div>
        <div class="success-container">
            <div class="success-text">
                {{description}}
            </div>
        </div>
        <div class="quote-requests-btn">
            <button class="mr-20 submit-btn" *ngIf="shoppingCartBTnText" (click)="redirectBtn1()">
                {{shoppingCartBTnText}}
            </button>
            <button *ngIf="!isBtnHide" (click)="redirect()" class="mr-20 w-215"
                [ngClass]="btnColor == 'white' ? 'clear-btn' : 'submit-btn submit-button'">
                <ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
                {{btnText}}
            </button>
        </div>
    </div>
</div>