<div class="header-container my-quote">
	<ion-row>
		<ion-col size-xs="12" size-sm="12" size-md="2" size-lg="2" size-xl="2">
			<div class="btn-start">
				<button id="open-modal"
					[ngClass]="showFilter && getPlatformService.isDesktop ?'filter-btn-blue':'filter-btn-grey'"
					class=" align-center" (click)="filterShowButton()">
					<img alt="Filter" [ngClass]="showFilter && getPlatformService.isDesktop ?'rotate-90':''"
						[src]="showFilter && getPlatformService.isDesktop ? '/assets/icon/filter_white.svg':'/assets/icon/filter_grey.svg'">
					<div class="filter-text ml-15">
						{{filterApplied ? 'Filter Applied': 'Filter'}}
					</div>
				</button>
			</div>
		</ion-col>
		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="10" size-xxl="10">
			<div class="tab-container" *ngIf="getPlatformService.isDesktop">
				<div class="radio_container fs-16">
					<ng-container *ngFor="let item of tabs;let i = index">
						<input [(ngModel)]="tabVal" type="radio" name="radio" id="item{{i}}" [value]="item.value"
							[checked]="item.value">
						<label (click)="tabChange(item.value)" class="pointer" for="item{{i}}">{{item.name}}</label>
					</ng-container>
				</div>
			</div>

			<!-- below code for mobile to provide horizontal scroll tab view -->
			<div class="tab-containers-mob" *ngIf="!getPlatformService.isDesktop">
				<div class="tab-list">
					<ng-container *ngFor="let item of tabs;let i = index">
						<input [(ngModel)]="tabVal" type="radio" name="radio" id="item{{i}}" [value]="item.value"
							[checked]="item.value">
						<label (click)="tabChange(item.value)" class="pointer tab-item"
							for="item{{i}}">{{item.name}}</label>
					</ng-container>
				</div>
			</div>
		</ion-col>
	</ion-row>
</div>


<ion-modal #modal id="filtermodal" trigger="open-modal" [presentingElement]="" *ngIf="!getPlatformService.isDesktop">
	<ng-template>
		<ion-header>
			<ion-toolbar>
				<ion-title>Filters</ion-title>
				<ion-buttons slot="end">
					<ion-button (click)="closeFilterModal()"><img alt="Close Icon" src="/assets/icon/close.svg" alt=""
							slot="end" class="close-icon"></ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content>

			<ion-row class="p-10 ">
				<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" class="align-center p-0">
					<div class="width-100">
						<ion-input (keyup.enter)="search()" [(ngModel)]="searchText" type="text"
							placeholder="Search By Name"></ion-input>
					</div>
				</ion-col>
				<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12"
					class="align-center p-0 m-0 mt-10">
					<div class="width-100">
						<ng-select [(ngModel)]="paymentType" placeholder="Select Payment Type" class="select-input"
							[items]="paymentData" bindLabel="title" bindValue="id" [clearable]="false">
						</ng-select>
					</div>
				</ion-col>


				<ion-col class="dateField mt-10" size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
					<div id="fromDateId" class="dateFieldInput"> <ion-input placeholder="From Date"
							value="{{ fromDate | date: 'MMM dd, YYYY' }}"></ion-input>
						<img alt="Calender" class="input-icon" src="assets/icon/calender.svg">
					</div>
					<ion-popover trigger="fromDateId" id="datePopover1">
						<ng-template>
							<ion-datetime presentation="date" [max]="toDate ? toDate : maxDate"
								(ngModelChange)="onDateChange(fromdate)" [(ngModel)]="fromDate"
								#fromdate></ion-datetime>
						</ng-template>
					</ion-popover>
				</ion-col>
				<ion-col class="dateField toDate mt-10" size-xs="12" size-sm="12" size-md="12" size-lg="12"
					size-xl="12">
					<div id="toDateId" class="dateFieldInput dateFieldInputToDate">
						<ion-input placeholder="To Date" value="{{ toDate | date: 'MMM dd, YYYY' }}"></ion-input>
						<img alt="Calender" class="input-icon" src="assets/icon/calender.svg">
					</div>
					<ion-popover trigger="toDateId" id="datePopover">
						<ng-template>
							<ion-datetime presentation="date" [(ngModel)]="toDate" #todate [max]="maxDate"
								(ngModelChange)="onDateChange(todate)"></ion-datetime>
						</ng-template>
					</ion-popover>
				</ion-col>
			</ion-row>

		</ion-content>
		<div class="filter-button-modal-container">
			<div class="apply-filter-modal-button">
				<button class="grey-btn align-center p-15 ion-activatable ripple-parent" (click)="onSearch()"
					[ngClass]="!getPlatformService.isDesktop ? 'width-100':null">
					<ion-icon name="filter-outline"></ion-icon>
					<span *ngIf="!getPlatformService.isDesktop" class="ml-10">
						Apply</span><ion-ripple-effect></ion-ripple-effect>
				</button>
			</div>
			<div class="cursor-pointer clear-fliter-modal-button ml-10" (click)="closeFilter()">
				<button class="grey-btn" (click)="closeFilter()"
					[ngClass]="!getPlatformService.isDesktop ? 'width-100':null">
					<ion-icon name="close-outline"></ion-icon>
					<span *ngIf="!getPlatformService.isDesktop" class="ml-10">Clear</span>
				</button>

			</div>

		</div>
	</ng-template>
</ion-modal>

<ng-container *ngIf="showFilter && getPlatformService.isDesktop">
	<ion-row class="p-0 filter-row-mobile">
		<ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6" class="align-center p-0">
			<div class="width-100">
				<ion-input (keyup.enter)="search()" [(ngModel)]="searchText" type="text"
					placeholder="Search By Name"></ion-input>
			</div>
		</ion-col>
		<ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="6" class="align-center p-0 m-0">
			<div class="width-100">
				<ng-select [(ngModel)]="paymentType" placeholder="Select Payment Type" class="select-input"
					[items]="paymentData" bindLabel="title" bindValue="id" [clearable]="false">
				</ng-select>
			</div>
		</ion-col>

	</ion-row>

	<ion-row class="p-0 flex space-between filter-row-mobile">
		<ion-col class="dateField" size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="5">
			<div id="fromDateId" class="dateFieldInput"> <ion-input placeholder="From Date"
					value="{{ fromDate | date: 'MMM dd, YYYY' }}"></ion-input>
				<img alt="Calender" class="input-icon" src="assets/icon/calender.svg">
			</div>
			<ion-popover trigger="fromDateId" id="datePopover1">
				<ng-template>
					<ion-datetime presentation="date" [max]="toDate ? toDate : maxDate"
						(ngModelChange)="onDateChange(fromdate)" [(ngModel)]="fromDate" #fromdate></ion-datetime>
				</ng-template>
			</ion-popover>
		</ion-col>
		<ion-col class="dateField toDate" size-xs="12" size-sm="6" size-md="6" size-lg="6" size-xl="5">
			<div id="toDateId" class="dateFieldInput dateFieldInputToDate">
				<ion-input placeholder="To Date" value="{{ toDate | date: 'MMM dd, YYYY' }}"></ion-input>
				<img alt="Calender" class="input-icon" src="assets/icon/calender.svg">
			</div>
			<ion-popover trigger="toDateId" id="datePopover">
				<ng-template>
					<ion-datetime presentation="date" [(ngModel)]="toDate" #todate [max]="maxDate"
						(ngModelChange)="onDateChange(todate)"></ion-datetime>
				</ng-template>
			</ion-popover>
		</ion-col>
		<ion-col size-xs="6" size-sm="4" size-md="3" size-lg="2" size-xl="2"
			class="search-column align-center  p-0 m-0">
			<div>
				<button class="grey-btn align-center p-15" (click)="onSearch()"
					[ngClass]="!getPlatformService.isDesktop ? 'width-100':null">
					<img alt="Search" src="/assets/icon/search.svg">
					<span *ngIf="!getPlatformService.isDesktop" class="ml-10">Search</span>
				</button>
			</div>
			<div class="ml-25">
				<div class="close-div p-5 cursor-pointer" (click)="closeFilter()">
					<img alt="Close Icon" src="/assets/icon/close.svg" alt="" slot="end" class="close-icon">
				</div>
			</div>
		</ion-col>
	</ion-row>
</ng-container>
<ng-container *ngIf="!isQuoteLoading">

	<div class="list mt-30" *ngIf="tabVal == 'REQUESTED' || tabVal == 'EXPIRED'">
		<app-table-listing [pageType]="'my_quote_request'" [settings]="column_settings" [widthConfig]="widthConfig"
			[listOfAllData]="listOfAllData" [selectedTab]="tabVal"
			[isStickyColumn]="getPlatformService.isDesktop ? true : false"></app-table-listing>
	</div>

	<div class="list mt-30" *ngIf="tabVal == 'RECEIVED'">
		<app-table-listing [pageType]="'my_quote_request'" [selectedTab]="tabVal" [settings]="column_settings"
			[widthConfig]="widthConfig" [listOfAllData]="listOfAllData"
			[isStickyColumn]="getPlatformService.isDesktop ? true : false" [expiredAfter]="true"></app-table-listing>
	</div>

	<div class="list mt-30" *ngIf="tabVal == 'CLOSED'">
		<app-table-listing [pageType]="'my_quote_request'" [selectedTab]="tabVal" [settings]="column_settings"
			[widthConfig]="widthConfig" [listOfAllData]="listOfAllData"
			[isStickyColumn]="getPlatformService.isDesktop ? true : false"></app-table-listing>
	</div>

	<div class="list mt-30" *ngIf="tabVal == 'ORDER_INITIATED'">
		<app-table-listing [pageType]="'my_quote_request'" [selectedTab]="tabVal" [settings]="column_settings"
			[widthConfig]="widthConfig" [listOfAllData]="listOfAllData"
			[isStickyColumn]="getPlatformService.isDesktop ? true : false"></app-table-listing>
	</div>

	<div class="pagination-wrapper" *ngIf="listOfAllData && listOfAllData.length > 0" [ngClass]="[totalCount < 10 && getPlatformService.isDesktop ?'pagination-justify-content-end':totalCount < 10 &&
        !getPlatformService.isDesktop? 'pagination-content-start' :'']">
		<ng-container *ngIf="totalCount > 10">
			<div class="items">
				Items
				<ng-select (change)="changeListItem($event)" [(ngModel)]="paginationVal" [clearable]="false"
					class="pagination" [items]="commonService.paginationList" bindLabel="label" bindValue="value"
					[searchable]="false" [editable]="false" [searchFn]="false">
				</ng-select>
				<ion-label class="perpage-title">of {{totalCount}}</ion-label>

			</div>
		</ng-container>
		<div>
			<ion-row class="pagination-div">
				<ion-col size="12" class="pagination-col return-orders-pagination-col">
					<app-tile class="grid-tile" *ngFor="let post of listOfAllData | paginate :
                          { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount };
                          let i = index" [current]="post"></app-tile>
					<pagination-controls [maxSize]="5" class="paginator" previousLabel="" nextLabel=""
						(pageChange)="onChange($event)"></pagination-controls>
				</ion-col>
			</ion-row>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="isQuoteLoading">
	<app-loader></app-loader>
</ng-container>